/* THIS IS AN AUTO-GENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
/* eslint-disable */

import { DocumentNode } from 'graphql'
import * as Urql from 'urql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** Date custom scalar type */
  Date: { input: any; output: any }
  /** JSON custom scalar type */
  JSON: { input: any; output: any }
  /** File upload scalar type */
  Upload: { input: any; output: any }
}

export type ActivityLog = {
  __typename?: 'ActivityLog'
  activity: Scalars['String']['output']
  createdAt: Scalars['Date']['output']
  data: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  targetIds: Array<Scalars['ID']['output']>
  updatedAt: Scalars['Date']['output']
  user?: Maybe<User>
  userId?: Maybe<Scalars['ID']['output']>
}

export type ActivityLogConnection = {
  __typename?: 'ActivityLogConnection'
  edges?: Maybe<Array<ActivityLogEdge>>
  nodes?: Maybe<Array<ActivityLog>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type ActivityLogEdge = {
  __typename?: 'ActivityLogEdge'
  cursor: Scalars['String']['output']
  node: ActivityLog
}

export enum ActivityLogTopic {
  MENTOR_SESSION_EVENT = 'MENTOR_SESSION_EVENT',
}

export type ActivityType = {
  __typename?: 'ActivityType'
  id: Scalars['ID']['output']
  type: Scalars['String']['output']
}

export type AddMentorToCourseInput = {
  courseId: Scalars['ID']['input']
  mentorId: Scalars['ID']['input']
}

export type AddToWishlistInput = {
  courseId?: InputMaybe<Scalars['ID']['input']>
  courseRefId: Scalars['String']['input']
  wishlistId: Scalars['ID']['input']
}

export type AppToken = {
  __typename?: 'AppToken'
  createdAt: Scalars['Date']['output']
  description: Scalars['String']['output']
  expires?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  isExpired: Scalars['Boolean']['output']
  lastUsed?: Maybe<Scalars['Date']['output']>
  permissions: Array<Permission>
  token: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
}

export type AuthResult = {
  __typename?: 'AuthResult'
  token: Scalars['String']['output']
  user: AuthUser
}

export type AuthUser = {
  __typename?: 'AuthUser'
  avatar?: Maybe<CloudFile>
  avatarFileId?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  discordId?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  gender?: Maybe<Gender>
  hasPassword: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  lastLogin?: Maybe<Scalars['Date']['output']>
  learner?: Maybe<Learner>
  learnerId?: Maybe<Scalars['ID']['output']>
  lwId: Scalars['ID']['output']
  mentor?: Maybe<Mentor>
  mentorId?: Maybe<Scalars['ID']['output']>
  name: Scalars['String']['output']
  permissions: Array<Permission>
  phone?: Maybe<Scalars['String']['output']>
  roles: Array<UserRole>
  slackId?: Maybe<Scalars['String']['output']>
  status: UserStatus
  textSearch?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['Date']['output']
  wishlist?: Maybe<Wishlist>
  zoomUserEmail?: Maybe<Scalars['String']['output']>
}

export type BookTaSessionResult = {
  __typename?: 'BookTASessionResult'
  debug: Scalars['String']['output']
  instantMeeting: InstantMeeting
}

export type Calendar = {
  __typename?: 'Calendar'
  createdAt: Scalars['Date']['output']
  createdByUser?: Maybe<User>
  createdByUserId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isPublic: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
}

export type CalendarEvent = {
  __typename?: 'CalendarEvent'
  attendees: Array<UserEvent>
  createdAt: Scalars['Date']['output']
  description?: Maybe<Scalars['String']['output']>
  endsAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  mentorSessionEvent?: Maybe<MentorSessionEvent>
  name: Scalars['String']['output']
  origin: CalendarEventOrigin
  recurrence?: Maybe<Scalars['String']['output']>
  recurrenceEventId?: Maybe<Scalars['ID']['output']>
  startsAt: Scalars['Date']['output']
  updatedAt: Scalars['Date']['output']
}

export enum CalendarEventOrigin {
  NON_PLATFORM = 'NON_PLATFORM',
  PLATFORM = 'PLATFORM',
}

export enum CalendarEventRecurrenceUpdateOption {
  ALL = 'ALL',
  FOLLOWING = 'FOLLOWING',
  ONLY = 'ONLY',
}

export type CancelMentorSessionEventRescheduleRequestInput = {
  reason?: InputMaybe<Scalars['String']['input']>
}

export type CancelMentorSessionInput = {
  mentorSessionId: Scalars['ID']['input']
  reason?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type ChatChannel = {
  __typename?: 'ChatChannel'
  _members: Array<ChatChannelMember>
  _messages: Array<ChatMessage>
  access: ChatChannelAccess
  createdAt: Scalars['Date']['output']
  createdByUser?: Maybe<User>
  createdByUserId?: Maybe<Scalars['ID']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastMessage?: Maybe<ChatMessage>
  /** The current user's membership in the channel */
  member?: Maybe<ChatChannelMember>
  /** The current user's membership in the channel */
  members: Array<ChatChannelMember>
  name: Scalars['String']['output']
  type: ChatChannelType
  updatedAt: Scalars['Date']['output']
}

export enum ChatChannelAccess {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type ChatChannelMember = {
  __typename?: 'ChatChannelMember'
  channel: ChatChannel
  channelId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  lastReadAt?: Maybe<Scalars['Date']['output']>
  role: ChatChannelMemberRole
  updatedAt: Scalars['Date']['output']
  user: User
  userId: Scalars['ID']['output']
}

export enum ChatChannelMemberRole {
  ADMIN = 'ADMIN',
  BOT = 'BOT',
  MEMBER = 'MEMBER',
}

export enum ChatChannelType {
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  DUSTINE_GPT = 'DUSTINE_GPT',
  GENERAL = 'GENERAL',
  MENTOR_SESSION = 'MENTOR_SESSION',
}

export type ChatMessage = {
  __typename?: 'ChatMessage'
  author?: Maybe<User>
  authorId?: Maybe<Scalars['ID']['output']>
  channel: ChatChannel
  channelId: Scalars['ID']['output']
  content: Scalars['String']['output']
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  replyToMessage?: Maybe<ChatMessage>
  replyToMessageId?: Maybe<Scalars['ID']['output']>
  temp?: Maybe<Scalars['Boolean']['output']>
  type: ChatMessageType
  updatedAt: Scalars['Date']['output']
}

export type ChatMessageConnection = {
  __typename?: 'ChatMessageConnection'
  edges?: Maybe<Array<ChatMessageEdge>>
  nodes?: Maybe<Array<ChatMessage>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type ChatMessageEdge = {
  __typename?: 'ChatMessageEdge'
  cursor: Scalars['String']['output']
  node: ChatMessage
}

export enum ChatMessageType {
  EVENT = 'EVENT',
  TEXT = 'TEXT',
}

export type CloudFile = {
  __typename?: 'CloudFile'
  blurhash?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  mime?: Maybe<Scalars['String']['output']>
  signedUrl?: Maybe<Scalars['String']['output']>
  size: Scalars['Float']['output']
  targets: Array<CloudFileTarget>
  updatedAt: Scalars['Date']['output']
}

export type CloudFileTarget = {
  __typename?: 'CloudFileTarget'
  cloudFile: CloudFile
  cloudFileId: Scalars['ID']['output']
  targetId: Scalars['ID']['output']
  type: CloudFileTargetType
}

export enum CloudFileTargetType {
  USER_AVATAR = 'USER_AVATAR',
}

export type Comment = {
  __typename?: 'Comment'
  author?: Maybe<User>
  authorId: Scalars['ID']['output']
  content?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  deletedAt?: Maybe<Scalars['Date']['output']>
  discussionId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  level: Scalars['Int']['output']
  replyToCommentId?: Maybe<Scalars['ID']['output']>
  updatedAt: Scalars['Date']['output']
}

export type ConnectionPageInfo = {
  __typename?: 'ConnectionPageInfo'
  endCursor?: Maybe<Scalars['String']['output']>
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  startCursor?: Maybe<Scalars['String']['output']>
}

export type Course = {
  __typename?: 'Course'
  access: CourseAccess
  courseMembers: Array<CourseMember>
  createdAt: Scalars['Date']['output']
  createdByUserId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  discordServerId?: Maybe<Scalars['String']['output']>
  durationOptions: Array<CourseDurationOption>
  id: Scalars['ID']['output']
  lwCourse?: Maybe<LwCourse>
  lwCourseId?: Maybe<Scalars['String']['output']>
  sections: Array<CourseSection>
  title: Scalars['String']['output']
  totalAllowedTASessions?: Maybe<Scalars['Float']['output']>
  units: Array<CourseUnit>
  updatedAt: Scalars['Date']['output']
  zohoProductId?: Maybe<Scalars['String']['output']>
}

export enum CourseAccess {
  DRAFT = 'DRAFT',
  FREE = 'FREE',
  PAID = 'PAID',
  PRIVATE = 'PRIVATE',
}

export type CourseConnection = {
  __typename?: 'CourseConnection'
  edges?: Maybe<Array<CourseEdge>>
  nodes?: Maybe<Array<Course>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type CourseDurationOption = {
  __typename?: 'CourseDurationOption'
  createdAt: Scalars['Date']['output']
  deletedAt?: Maybe<Scalars['Date']['output']>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
  unit: CourseDurationOptionUnit
  updatedAt: Scalars['Date']['output']
  value: Scalars['Int']['output']
}

export type CourseDurationOptionInput = {
  label: Scalars['String']['input']
  unit?: CourseDurationOptionUnit
  value: Scalars['Int']['input']
}

export enum CourseDurationOptionUnit {
  MONTH = 'MONTH',
  NUMBER_OF_SESSIONS = 'NUMBER_OF_SESSIONS',
  WEEK = 'WEEK',
}

export type CourseEdge = {
  __typename?: 'CourseEdge'
  cursor: Scalars['String']['output']
  node: Course
}

export type CourseJob = {
  __typename?: 'CourseJob'
  companyLogo?: Maybe<CloudFile>
  companyLogoFileId?: Maybe<Scalars['String']['output']>
  companyName: Scalars['String']['output']
  courses: Array<Course>
  createdAt: Scalars['Date']['output']
  createdByUser?: Maybe<User>
  createdByUserId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  isHidden?: Maybe<Scalars['Boolean']['output']>
  jdLink: Scalars['String']['output']
  jobLevels: Array<CourseJobLevel>
  jobTitle: Scalars['String']['output']
  locations: Array<CourseJobLocation>
  salaryFrom?: Maybe<Scalars['Float']['output']>
  salaryNegotiable: Scalars['Boolean']['output']
  salaryTo?: Maybe<Scalars['Float']['output']>
  skillsRequirement: Array<Scalars['String']['output']>
  status: CourseJobStatus
  textSearch?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['Date']['output']
}

export type CourseJobConnection = {
  __typename?: 'CourseJobConnection'
  edges?: Maybe<Array<CourseJobEdge>>
  nodes?: Maybe<Array<CourseJob>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type CourseJobEdge = {
  __typename?: 'CourseJobEdge'
  cursor: Scalars['String']['output']
  node: CourseJob
}

export enum CourseJobLevel {
  FRESHER = 'FRESHER',
  INTERN = 'INTERN',
  JUNIOR = 'JUNIOR',
}

export enum CourseJobLocation {
  DA_NANG = 'DA_NANG',
  HA_NOI = 'HA_NOI',
  HO_CHI_MINH = 'HO_CHI_MINH',
  OTHER = 'OTHER',
}

export type CourseJobStats = {
  __typename?: 'CourseJobStats'
  createdJobs7Days: Scalars['Int']['output']
  totalJobs: Scalars['Int']['output']
}

export enum CourseJobStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export type CourseMember = {
  __typename?: 'CourseMember'
  course?: Maybe<Course>
  courseExpectation?: Maybe<Scalars['String']['output']>
  courseId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  enrollmentDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  jobExpectation?: Maybe<Scalars['String']['output']>
  latestProgressReport?: Maybe<CourseMemberProgressReport>
  latestProgressReportId?: Maybe<Scalars['ID']['output']>
  learner?: Maybe<Learner>
  learnerId?: Maybe<Scalars['ID']['output']>
  learningPurpose?: Maybe<Scalars['String']['output']>
  learningType?: Maybe<Scalars['String']['output']>
  mentor?: Maybe<Mentor>
  mentorId?: Maybe<Scalars['ID']['output']>
  mentorSessions?: Maybe<Array<MentorSession>>
  monthToChangeJob?: Maybe<Scalars['Float']['output']>
  paymentType?: Maybe<Scalars['String']['output']>
  reasonToSwitchCareer?: Maybe<Scalars['String']['output']>
  redeemedMentorSessionEvents?: Maybe<Scalars['Int']['output']>
  remainingMentorSessionEvents?: Maybe<Scalars['Int']['output']>
  source?: Maybe<Scalars['String']['output']>
  status: CourseMemberStatus
  studyTimePerWeek?: Maybe<Scalars['Float']['output']>
  totalAllowedTASessions?: Maybe<Scalars['Int']['output']>
  totalMentorSessionEvents?: Maybe<Scalars['Int']['output']>
  type: CourseMemberType
  updatedAt: Scalars['Date']['output']
  user?: Maybe<User>
  userId?: Maybe<Scalars['ID']['output']>
}

export type CourseMemberConnection = {
  __typename?: 'CourseMemberConnection'
  edges?: Maybe<Array<CourseMemberEdge>>
  nodes?: Maybe<Array<CourseMember>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type CourseMemberDropStatusActivityLog = {
  __typename?: 'CourseMemberDropStatusActivityLog'
  createdAt: Scalars['Date']['output']
  dropReason?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  performUserId?: Maybe<Scalars['ID']['output']>
  updatedAt: Scalars['Date']['output']
}

export type CourseMemberEdge = {
  __typename?: 'CourseMemberEdge'
  cursor: Scalars['String']['output']
  node: CourseMember
}

export type CourseMemberGraduateStatusActivityLog = {
  __typename?: 'CourseMemberGraduateStatusActivityLog'
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  performUserId?: Maybe<Scalars['ID']['output']>
  updatedAt: Scalars['Date']['output']
}

export enum CourseMemberPaymentStatus {
  FULFILLED = 'FULFILLED',
  PENDING = 'PENDING',
}

export type CourseMemberPostponeStatusActivityLog = {
  __typename?: 'CourseMemberPostponeStatusActivityLog'
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  performUserId?: Maybe<Scalars['ID']['output']>
  postponeReason?: Maybe<Scalars['String']['output']>
  returnDate?: Maybe<Scalars['Date']['output']>
  updatedAt: Scalars['Date']['output']
}

export type CourseMemberProgressReport = {
  __typename?: 'CourseMemberProgressReport'
  averageScore?: Maybe<Scalars['Float']['output']>
  courseMember: CourseMember
  courseMemberId: Scalars['String']['output']
  createdAt: Scalars['Date']['output']
  currentMiniCourse?: Maybe<CourseUnit>
  currentMiniCourseId?: Maybe<Scalars['String']['output']>
  currentSection?: Maybe<CourseUnit>
  currentSectionId?: Maybe<Scalars['String']['output']>
  currentUnit: CourseUnit
  currentUnitId: Scalars['String']['output']
  currentUnitPath?: Maybe<CourseUnitPath>
  endSyncedAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  learningVelocity?: Maybe<LearningVelocity>
  nextUnitPath?: Maybe<CourseUnitPath>
  overallProgress: Scalars['Float']['output']
  recommendedProgress?: Maybe<Scalars['Float']['output']>
  startSyncedAt?: Maybe<Scalars['Date']['output']>
  studyTimeDuringPeriod: Scalars['Float']['output']
  updatedAt: Scalars['Date']['output']
  weeksOfftrack?: Maybe<Scalars['Int']['output']>
  weeksSinceEnrollment?: Maybe<Scalars['Int']['output']>
}

export enum CourseMemberStatus {
  ACTIVE = 'ACTIVE',
  DROPPED = 'DROPPED',
  FREE = 'FREE',
  GRADUATED = 'GRADUATED',
  PENDING = 'PENDING',
  POSTPONED = 'POSTPONED',
  REFUNDED = 'REFUNDED',
}

export enum CourseMemberType {
  LEARNER = 'LEARNER',
  MENTOR = 'MENTOR',
}

export type CourseMemberWithNextMentorSessionEvent = {
  __typename?: 'CourseMemberWithNextMentorSessionEvent'
  course?: Maybe<Course>
  courseExpectation?: Maybe<Scalars['String']['output']>
  courseId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  enrollmentDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  jobExpectation?: Maybe<Scalars['String']['output']>
  latestProgressReport?: Maybe<CourseMemberProgressReport>
  latestProgressReportId?: Maybe<Scalars['ID']['output']>
  learner?: Maybe<Learner>
  learnerId?: Maybe<Scalars['ID']['output']>
  learningPurpose?: Maybe<Scalars['String']['output']>
  learningType?: Maybe<Scalars['String']['output']>
  mentor?: Maybe<Mentor>
  mentorId?: Maybe<Scalars['ID']['output']>
  mentorSessions?: Maybe<Array<MentorSession>>
  monthToChangeJob?: Maybe<Scalars['Float']['output']>
  nextMentorSessionEvent?: Maybe<MentorSessionEvent>
  paymentType?: Maybe<Scalars['String']['output']>
  reasonToSwitchCareer?: Maybe<Scalars['String']['output']>
  redeemedMentorSessionEvents?: Maybe<Scalars['Int']['output']>
  remainingMentorSessionEvents?: Maybe<Scalars['Int']['output']>
  source?: Maybe<Scalars['String']['output']>
  status: CourseMemberStatus
  studyTimePerWeek?: Maybe<Scalars['Float']['output']>
  totalAllowedTASessions?: Maybe<Scalars['Int']['output']>
  totalMentorSessionEvents?: Maybe<Scalars['Int']['output']>
  type: CourseMemberType
  updatedAt: Scalars['Date']['output']
  user?: Maybe<User>
  userId?: Maybe<Scalars['ID']['output']>
}

export type CourseSection = {
  __typename?: 'CourseSection'
  course?: Maybe<Course>
  courseId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  createdByUserId: Scalars['String']['output']
  deletedAt?: Maybe<Scalars['Date']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lwCourse?: Maybe<LwCourse>
  lwCourseId?: Maybe<Scalars['String']['output']>
  lwSection?: Maybe<LwCourseSection>
  lwSectionId?: Maybe<Scalars['String']['output']>
  nextCourseSection?: Maybe<CourseSection>
  order: Scalars['Int']['output']
  title: Scalars['String']['output']
  units: Array<CourseUnit>
  updatedAt: Scalars['Date']['output']
}

export type CourseSectionInput = {
  deleted?: Scalars['Boolean']['input']
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  lwCourseId?: InputMaybe<Scalars['String']['input']>
  lwSectionId?: InputMaybe<Scalars['String']['input']>
  order: Scalars['Int']['input']
  title: Scalars['String']['input']
  units: Array<CourseUnitInput>
}

export type CourseStaff = {
  __typename?: 'CourseStaff'
  course: Course
  courseId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  status: CourseStaffStatus
  updatedAt: Scalars['Date']['output']
  user: User
  userId: Scalars['ID']['output']
}

export type CourseStaffConnection = {
  __typename?: 'CourseStaffConnection'
  edges?: Maybe<Array<CourseStaffEdge>>
  nodes?: Maybe<Array<CourseStaff>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type CourseStaffEdge = {
  __typename?: 'CourseStaffEdge'
  cursor: Scalars['String']['output']
  node: CourseStaff
}

export enum CourseStaffStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type CourseSubscribe = {
  __typename?: 'CourseSubscribe'
  courseRefId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
}

export type CourseSubscribeConnection = {
  __typename?: 'CourseSubscribeConnection'
  edges?: Maybe<Array<CourseSubscribeEdge>>
  nodes?: Maybe<Array<CourseSubscribe>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type CourseSubscribeEdge = {
  __typename?: 'CourseSubscribeEdge'
  cursor: Scalars['String']['output']
  node: CourseSubscribe
}

export type CourseUnit = {
  __typename?: 'CourseUnit'
  children?: Maybe<Array<CourseUnit>>
  courseSectionId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  deletedAt?: Maybe<Scalars['Date']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lwCourse?: Maybe<LwCourse>
  lwCourseId?: Maybe<Scalars['String']['output']>
  lwLearningActivity?: Maybe<LwLearningUnit>
  lwLearningActivityId?: Maybe<Scalars['String']['output']>
  lwSection?: Maybe<LwCourseSection>
  lwSectionId?: Maybe<Scalars['String']['output']>
  nextCourseUnit?: Maybe<CourseUnit>
  order: Scalars['Int']['output']
  parentUnitId?: Maybe<Scalars['String']['output']>
  section?: Maybe<CourseSection>
  title: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
}

export type CourseUnitInput = {
  children: Array<CourseUnitInput>
  deleted?: Scalars['Boolean']['input']
  description?: InputMaybe<Scalars['String']['input']>
  discordServerId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  lwCourseId?: InputMaybe<Scalars['String']['input']>
  lwLearningActivityId?: InputMaybe<Scalars['String']['input']>
  lwSectionId?: InputMaybe<Scalars['String']['input']>
  order: Scalars['Int']['input']
  parentUnitId?: InputMaybe<Scalars['ID']['input']>
  title: Scalars['String']['input']
}

export type CourseUnitPath = {
  __typename?: 'CourseUnitPath'
  id: Scalars['String']['output']
  path: Array<CourseUnit>
  section: CourseSection
}

export type CreateAppTokenInput = {
  description: Scalars['String']['input']
  expiration: Scalars['Int']['input']
  permissions: Array<Permission>
}

export type CreateCalendarEventInput = {
  attendees: Array<EventAttendeeInput>
  calendarId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  endsAt: Scalars['Date']['input']
  name: Scalars['String']['input']
  recurrence?: InputMaybe<Scalars['String']['input']>
  recurringCount: Scalars['Int']['input']
  recurringEventId?: InputMaybe<Scalars['ID']['input']>
  startsAt: Scalars['Date']['input']
}

export type CreateCalendarInput = {
  description?: InputMaybe<Scalars['String']['input']>
  isPublic?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  userId: Scalars['ID']['input']
}

export type CreateChatChannelInput = {
  access?: InputMaybe<ChatChannelAccess>
  description?: InputMaybe<Scalars['String']['input']>
  memberUserIds?: InputMaybe<Array<Scalars['String']['input']>>
  name: Scalars['String']['input']
  type?: InputMaybe<ChatChannelType>
}

export type CreateChatMessageInput = {
  content: Scalars['String']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<ChatMessageType>
}

export type CreateCourseInput = {
  access?: CourseAccess
  description: Scalars['String']['input']
  discordServerId?: InputMaybe<Scalars['String']['input']>
  lwCourseId?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
  totalAllowedTASessions?: InputMaybe<Scalars['Float']['input']>
  zohoProductId?: InputMaybe<Scalars['String']['input']>
}

export type CreateCourseJobInput = {
  companyLogoFileId?: InputMaybe<Scalars['String']['input']>
  companyName: Scalars['String']['input']
  courseIds: Array<Scalars['ID']['input']>
  jdLink: Scalars['String']['input']
  jobLevels: Array<CourseJobLevel>
  jobTitle: Scalars['String']['input']
  locations: Array<CourseJobLocation>
  salaryFrom?: InputMaybe<Scalars['Float']['input']>
  salaryNegotiable?: Scalars['Boolean']['input']
  salaryTo?: InputMaybe<Scalars['Float']['input']>
  skillsRequirement: Array<Scalars['String']['input']>
}

export type CreateCustomFieldInput = {
  dataType: CustomFieldDataType
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  options: Array<CustomFieldOptionInput>
  targetGroup: CustomFieldTargetGroup
}

export type CreateDealEnrollmentInput = {
  courseId?: InputMaybe<Scalars['String']['input']>
  courseMemberId?: InputMaybe<Scalars['String']['input']>
  dealId: Scalars['String']['input']
  learnerId?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['String']['input']>
}

export type CreateDemoSessionRecapInput = {
  courseRefId: Scalars['String']['input']
  feedback: Scalars['String']['input']
  rating: Scalars['String']['input']
}

export type CreateEmployeeProfileInput = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  jobTitle?: InputMaybe<Scalars['String']['input']>
  lastName: Scalars['String']['input']
  signedOffDate?: InputMaybe<Scalars['Date']['input']>
  signedOnDate?: InputMaybe<Scalars['Date']['input']>
}

export type CreateEmployeeTeamInput = {
  color?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  teamLeaderEmployeeProfileId?: InputMaybe<Scalars['ID']['input']>
  teamOwnerEmployeeTeamMemberId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateEmployeeTeamMemberInput = {
  employeeProfileId: Scalars['ID']['input']
  employeeTeamId: Scalars['ID']['input']
  lineManagerEmployeeTeamMemberId: Scalars['ID']['input']
}

export type CreateInstantMeetingInput = {
  courseId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  endsAt?: InputMaybe<Scalars['Date']['input']>
  externalMeetingUrl?: InputMaybe<Scalars['String']['input']>
  hostUserId?: InputMaybe<Scalars['String']['input']>
  participantUserIds: Array<Scalars['String']['input']>
  recurrence?: InputMaybe<Scalars['Float']['input']>
  startsAt?: InputMaybe<Scalars['Date']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  type: InstantMeetingType
  useZoom?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateLearnerInput = {
  address?: InputMaybe<Scalars['String']['input']>
  codingSkill?: InputMaybe<Scalars['String']['input']>
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>
  discordId?: InputMaybe<Scalars['String']['input']>
  englishLevel?: InputMaybe<Scalars['String']['input']>
  expYears?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<Gender>
  industry?: InputMaybe<Scalars['String']['input']>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  linkedinURL?: InputMaybe<Scalars['String']['input']>
  lwUserId?: InputMaybe<Scalars['ID']['input']>
  nationalIdIssuedDate?: InputMaybe<Scalars['Date']['input']>
  nationalIdIssuedPlace?: InputMaybe<Scalars['String']['input']>
  nationalIdNumber?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  organization?: InputMaybe<Scalars['String']['input']>
  priorCodingCourses?: InputMaybe<Scalars['String']['input']>
  resumeURL?: InputMaybe<Scalars['String']['input']>
  secondaryEmail?: InputMaybe<Scalars['String']['input']>
  secondaryPhoneNumber?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['ID']['input']
  workingStatus?: InputMaybe<Scalars['String']['input']>
}

export type CreateMentorInput = {
  address?: InputMaybe<Scalars['String']['input']>
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>
  bankBranch?: InputMaybe<Scalars['String']['input']>
  bankName?: InputMaybe<Scalars['String']['input']>
  beneficiary?: InputMaybe<Scalars['String']['input']>
  capacity?: InputMaybe<Scalars['Float']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['Date']['input']>
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>
  discordId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  expYears?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<Gender>
  hourlyRate?: InputMaybe<Scalars['Float']['input']>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  lwUserId?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  nationalIdIssuedDate?: InputMaybe<Scalars['Date']['input']>
  nationalIdIssuedPlace?: InputMaybe<Scalars['String']['input']>
  nationalIdNumber?: InputMaybe<Scalars['String']['input']>
  oldNationalIdNumber?: InputMaybe<Scalars['String']['input']>
  organization?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  pitCode?: InputMaybe<Scalars['String']['input']>
  secondaryEmail?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<MentorStatus>
  type?: InputMaybe<MentorType>
}

export type CreateMentorSessionBookingRequestInput = {
  courseId: Scalars['ID']['input']
  endsAt: Scalars['Date']['input']
  learnerIds: Array<Scalars['ID']['input']>
  mentorIds: Array<Scalars['ID']['input']>
  recurrence: Scalars['String']['input']
  startsAt: Scalars['Date']['input']
}

export type CreateMentorSessionBookingResponseInput = {
  action: UserResponseAction
  content: Scalars['String']['input']
  userRequestId: Scalars['ID']['input']
}

export type CreateMentorSessionEventInput = {
  endsAt: Scalars['Date']['input']
  mentorSessionId: Scalars['ID']['input']
  reason?: InputMaybe<Scalars['String']['input']>
  startsAt: Scalars['Date']['input']
}

export type CreateMentorSessionEventRescheduleResponseInput = {
  action: UserResponseAction
  reason?: InputMaybe<Scalars['String']['input']>
  userRequestId: Scalars['ID']['input']
}

export type CreateMentorSessionInput = {
  courseId: Scalars['ID']['input']
  courseMemberIds: Array<Scalars['ID']['input']>
  endsAt: Scalars['Date']['input']
  recurrence?: InputMaybe<Scalars['String']['input']>
  startsAt: Scalars['Date']['input']
  userRequestId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateMentorSessionRecapInput = {
  calendarEventId: Scalars['ID']['input']
  content: Scalars['String']['input']
  learnerId: Scalars['ID']['input']
  mentorId: Scalars['ID']['input']
  mentorSessionId: Scalars['ID']['input']
  type: MentorSessionRecapType
}

export type CreateReviewCampaignInput = {
  access: Array<ReviewCampaignAccess>
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type CreateUserInput = {
  email: Scalars['String']['input']
  name: Scalars['String']['input']
  phone?: InputMaybe<Scalars['String']['input']>
  roles: Array<UserRole>
  slackId?: InputMaybe<Scalars['String']['input']>
}

export type CreateUserOutput = {
  __typename?: 'CreateUserOutput'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  isExists: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  phone?: Maybe<Scalars['String']['output']>
}

export type CustomField = {
  __typename?: 'CustomField'
  createdAt: Scalars['Date']['output']
  dataType: CustomFieldDataType
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  options: Array<CustomFieldOption>
  targetGroup: CustomFieldTargetGroup
  updatedAt: Scalars['Date']['output']
}

export enum CustomFieldDataType {
  SINGLE_SELECT = 'SINGLE_SELECT',
  TEXT = 'TEXT',
}

export type CustomFieldOption = {
  __typename?: 'CustomFieldOption'
  createdAt: Scalars['Date']['output']
  customFieldId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
}

export type CustomFieldOptionInput = {
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
}

export enum CustomFieldTargetGroup {
  LEARNER = 'LEARNER',
}

export type CustomizeCourseInput = {
  courseRefId: Scalars['String']['input']
  topics: Array<Scalars['String']['input']>
}

export type Deal = {
  __typename?: 'Deal'
  address?: Maybe<Scalars['String']['output']>
  classInterested?: Maybe<Scalars['String']['output']>
  codingSkill?: Maybe<Scalars['String']['output']>
  courseExpectation?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  dateOfBirth?: Maybe<Scalars['Date']['output']>
  enrollment?: Maybe<DealEnrollment>
  enrollmentDate?: Maybe<Scalars['Date']['output']>
  gender?: Maybe<Gender>
  id: Scalars['ID']['output']
  industry?: Maybe<Scalars['String']['output']>
  jobTitle?: Maybe<Scalars['String']['output']>
  leadForm?: Maybe<Scalars['String']['output']>
  learner?: Maybe<Learner>
  learningPurpose?: Maybe<Scalars['String']['output']>
  linkedInUrl?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  lookingForJob?: Maybe<Scalars['String']['output']>
  nationalIdNumber?: Maybe<Scalars['String']['output']>
  newJobExpectation?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  organization?: Maybe<Scalars['String']['output']>
  ownerName?: Maybe<Scalars['String']['output']>
  paymentType?: Maybe<Scalars['String']['output']>
  personEmail?: Maybe<Scalars['String']['output']>
  personName: Scalars['String']['output']
  personPhone?: Maybe<Scalars['String']['output']>
  resumeUrl?: Maybe<Scalars['String']['output']>
  source?: Maybe<Scalars['String']['output']>
  status: PipedriveDealStatus
  studyTimePerWeek?: Maybe<Scalars['Float']['output']>
  studyingCodingCoursesBefore?: Maybe<Scalars['String']['output']>
  subject?: Maybe<Scalars['String']['output']>
  testScoreResult?: Maybe<Scalars['Float']['output']>
  updatedAt: Scalars['Date']['output']
  user?: Maybe<User>
  value?: Maybe<Scalars['Int']['output']>
  wonAt?: Maybe<Scalars['Date']['output']>
  workingStatus?: Maybe<Scalars['String']['output']>
  yearsOfExp?: Maybe<Scalars['String']['output']>
}

export type DealConnection = {
  __typename?: 'DealConnection'
  edges?: Maybe<Array<DealEdge>>
  nodes?: Maybe<Array<Deal>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type DealEdge = {
  __typename?: 'DealEdge'
  cursor: Scalars['String']['output']
  node: Deal
}

export type DealEnrollment = {
  __typename?: 'DealEnrollment'
  courseId?: Maybe<Scalars['String']['output']>
  courseMemberId?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  dealId: Scalars['ID']['output']
  learnerId?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['String']['output']>
}

export type DemoSessionRecap = {
  __typename?: 'DemoSessionRecap'
  courseRefId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  feedback: Scalars['String']['output']
  id: Scalars['ID']['output']
  rating: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
}

export type DemoSessionRecapConnection = {
  __typename?: 'DemoSessionRecapConnection'
  edges?: Maybe<Array<DemoSessionRecapEdge>>
  nodes?: Maybe<Array<DemoSessionRecap>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type DemoSessionRecapEdge = {
  __typename?: 'DemoSessionRecapEdge'
  cursor: Scalars['String']['output']
  node: DemoSessionRecap
}

export type DiscordChannel = {
  __typename?: 'DiscordChannel'
  courseId?: Maybe<Scalars['ID']['output']>
  createdAt: Scalars['Date']['output']
  discordServerId: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastMessageCreatedAt?: Maybe<Scalars['Date']['output']>
  messagesSyncedAt?: Maybe<Scalars['Date']['output']>
  name: Scalars['String']['output']
  status: DiscordChannelStatus
  targetIds: Array<Scalars['String']['output']>
  type: DiscordChannelType
  updatedAt: Scalars['Date']['output']
}

export type DiscordChannelEdge = {
  __typename?: 'DiscordChannelEdge'
  cursor: Scalars['String']['output']
  node: DiscordChannel
}

export enum DiscordChannelStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}

export enum DiscordChannelType {
  LEARNER_SUPPORT = 'LEARNER_SUPPORT',
  MENTORSHIP = 'MENTORSHIP',
}

export type DiscordMessage = {
  __typename?: 'DiscordMessage'
  authorData?: Maybe<Scalars['JSON']['output']>
  authorDiscordId: Scalars['String']['output']
  content: Scalars['String']['output']
  createdAt: Scalars['Date']['output']
  data?: Maybe<Scalars['JSON']['output']>
  discordChannelId: Scalars['String']['output']
  id: Scalars['ID']['output']
  replyToMessageId?: Maybe<Scalars['String']['output']>
  syncedAt: Scalars['Date']['output']
  updatedAt: Scalars['Date']['output']
}

export type DiscordMessageEdge = {
  __typename?: 'DiscordMessageEdge'
  cursor: Scalars['String']['output']
  node: DiscordMessage
}

export type DropCourseMemberInput = {
  dropDate?: InputMaybe<Scalars['Date']['input']>
  dropReason?: InputMaybe<Scalars['String']['input']>
}

export type EmployeeProfile = {
  __typename?: 'EmployeeProfile'
  createdAt: Scalars['Date']['output']
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  jobTitle?: Maybe<Scalars['String']['output']>
  lastName: Scalars['String']['output']
  signedOffDate?: Maybe<Scalars['Date']['output']>
  signedOnDate: Scalars['Date']['output']
  teamMembers: Array<EmployeeTeamMember>
  teams: Array<EmployeeTeam>
  updatedAt: Scalars['Date']['output']
  user: User
  userId: Scalars['ID']['output']
}

export type EmployeeTeam = {
  __typename?: 'EmployeeTeam'
  color?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  members: Array<EmployeeProfile>
  name: Scalars['String']['output']
  teamLeaderMember?: Maybe<EmployeeTeamMember>
  teamLeaderMemberId?: Maybe<Scalars['ID']['output']>
  teamOwner?: Maybe<EmployeeTeamMember>
  teamOwnerMember?: Maybe<EmployeeTeamMember>
  teamOwnerMemberId?: Maybe<Scalars['ID']['output']>
  updatedAt: Scalars['Date']['output']
}

export type EmployeeTeamMember = {
  __typename?: 'EmployeeTeamMember'
  createdAt: Scalars['Date']['output']
  employeeProfile: EmployeeProfile
  employeeProfileId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isLeader: Scalars['Boolean']['output']
  isOwner: Scalars['Boolean']['output']
  lineManager?: Maybe<EmployeeTeamMember>
  lineManagerId?: Maybe<Scalars['ID']['output']>
  peers: Array<EmployeeTeamMember>
  subordinates: Array<EmployeeTeamMember>
  team: EmployeeTeam
  teamId: Scalars['ID']['output']
  updatedAt: Scalars['Date']['output']
}

export type EnrollLearnerToCourseInput = {
  courseExpectation?: InputMaybe<Scalars['String']['input']>
  courseId: Scalars['ID']['input']
  dealId?: InputMaybe<Scalars['String']['input']>
  duration: Scalars['Float']['input']
  enrollmentDate: Scalars['Date']['input']
  jobExpectation?: InputMaybe<Scalars['String']['input']>
  learnerId: Scalars['ID']['input']
  learningPurpose?: InputMaybe<Scalars['String']['input']>
  learningType?: InputMaybe<Scalars['String']['input']>
  monthToChangeJob?: InputMaybe<Scalars['Int']['input']>
  paymentType?: InputMaybe<Scalars['String']['input']>
  reasonToSwitchCareer?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<Scalars['String']['input']>
  studyTimePerWeek?: InputMaybe<Scalars['Int']['input']>
  totalAllowedTASessions?: InputMaybe<Scalars['Int']['input']>
}

export type EnrollmentBatch = {
  __typename?: 'EnrollmentBatch'
  date: Scalars['Date']['output']
  id: Scalars['ID']['output']
}

export type EventAgenda = {
  __typename?: 'EventAgenda'
  agendaItems: Array<EventAgendaItem>
  createdAt: Scalars['Date']['output']
  createdByUser?: Maybe<User>
  createdByUserId?: Maybe<Scalars['ID']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActivityCreatableByUser: Scalars['Boolean']['output']
  isEditableByUser: Scalars['Boolean']['output']
  isItemCreatableByUser: Scalars['Boolean']['output']
  state: EventAgendaState
  title?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['Date']['output']
}

export type EventAgendaIsActivityCreatableByUserArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type EventAgendaIsEditableByUserArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type EventAgendaIsItemCreatableByUserArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type EventAgendaActivityInput = {
  eventAgendaItemId?: InputMaybe<Scalars['ID']['input']>
  type: EventAgendaActivityType
}

export enum EventAgendaActivityType {
  ITEM_STARTED = 'ITEM_STARTED',
  ITEM_STOPPED = 'ITEM_STOPPED',
}

export type EventAgendaItem = {
  __typename?: 'EventAgendaItem'
  actualDuration?: Maybe<Scalars['Int']['output']>
  agendaId: Scalars['ID']['output']
  content?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  estDuration?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  isDeletableByUser: Scalars['Boolean']['output']
  isEditableByUser: Scalars['Boolean']['output']
  order: Scalars['Int']['output']
  title?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['Date']['output']
}

export type EventAgendaItemIsDeletableByUserArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type EventAgendaItemIsEditableByUserArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type EventAgendaItemCreateInput = {
  content?: InputMaybe<Scalars['String']['input']>
  estDuration?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAgendaItemState = {
  __typename?: 'EventAgendaItemState'
  duration: Scalars['Int']['output']
  id: Scalars['ID']['output']
  isLastActive?: Maybe<Scalars['Boolean']['output']>
  isOngoing: Scalars['Boolean']['output']
  startedAt?: Maybe<Scalars['Date']['output']>
}

export type EventAgendaItemUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>
  estDuration?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAgendaState = {
  __typename?: 'EventAgendaState'
  eventAgendaItems: Array<EventAgendaItemState>
  startedAt?: Maybe<Scalars['Date']['output']>
  stoppedAt?: Maybe<Scalars['Date']['output']>
}

export type EventAgendaStateChanged = {
  __typename?: 'EventAgendaStateChanged'
  eventAgendaId: Scalars['ID']['output']
  state: EventAgendaState
}

export type EventAttendeeInput = {
  isHost?: InputMaybe<Scalars['Boolean']['input']>
  isOptional?: InputMaybe<Scalars['Boolean']['input']>
  userId: Scalars['ID']['input']
}

export type ExtendMentorSessionInput = {
  endsAt: Scalars['Date']['input']
  numberOfEvents: Scalars['Int']['input']
  startsAt: Scalars['Date']['input']
}

export type FormSubmission = {
  __typename?: 'FormSubmission'
  content: Scalars['String']['output']
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  mentorSessionEvent?: Maybe<MentorSessionEvent>
  mentorSessionEventCalendarEventId?: Maybe<Scalars['ID']['output']>
  submittedByUser: User
  type: FormSubmissionType
  updatedAt: Scalars['Date']['output']
  userId: Scalars['ID']['output']
}

export enum FormSubmissionType {
  MENTEE_RECAP = 'MENTEE_RECAP',
  MENTOR_RECAP = 'MENTOR_RECAP',
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}

export type GenerateVideoSdkTokenInput = {
  expiresIn?: InputMaybe<Scalars['String']['input']>
  permissions: Array<MeetingPermission>
}

export type GraduateCourseMemberInput = {
  graduateDate?: InputMaybe<Scalars['Date']['input']>
}

export enum IncidentSeverity {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  URGENT = 'URGENT',
}

export type InstantMeeting = {
  __typename?: 'InstantMeeting'
  courseId?: Maybe<Scalars['ID']['output']>
  createdAt: Scalars['Date']['output']
  description?: Maybe<Scalars['String']['output']>
  endsAt?: Maybe<Scalars['Date']['output']>
  externalMeetingUrl?: Maybe<Scalars['String']['output']>
  externalRecordingId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  meetingRecordings: Array<MeetingRecording>
  participantUserIds: Array<Scalars['String']['output']>
  participantUsers: Array<User>
  recurrence?: Maybe<Scalars['Float']['output']>
  startsAt?: Maybe<Scalars['Date']['output']>
  status: InstantMeetingStatus
  title?: Maybe<Scalars['String']['output']>
  type: InstantMeetingType
  updatedAt: Scalars['Date']['output']
  zoomMeetingId?: Maybe<Scalars['String']['output']>
  zoomMeetingRecordings: Array<ZoomRecording>
}

export type InstantMeetingConnection = {
  __typename?: 'InstantMeetingConnection'
  edges?: Maybe<Array<InstantMeetingEdge>>
  nodes?: Maybe<Array<InstantMeeting>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type InstantMeetingEdge = {
  __typename?: 'InstantMeetingEdge'
  cursor: Scalars['String']['output']
  node: InstantMeeting
}

export type InstantMeetingRoomInfo = {
  __typename?: 'InstantMeetingRoomInfo'
  description?: Maybe<Scalars['String']['output']>
  endsAt?: Maybe<Scalars['Date']['output']>
  instantMeetingId: Scalars['ID']['output']
  meetingRoom: MeetingRoom
  participantUserIds?: Maybe<Array<Scalars['String']['output']>>
  startsAt?: Maybe<Scalars['Date']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export enum InstantMeetingStatus {
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  SCHEDULED = 'SCHEDULED',
}

export enum InstantMeetingType {
  FINAL_PROJECT_CHECKIN = 'FINAL_PROJECT_CHECKIN',
  FINAL_PROJECT_GRADING = 'FINAL_PROJECT_GRADING',
  GROUP_SESSION = 'GROUP_SESSION',
  ORIENTATION = 'ORIENTATION',
  OTHER = 'OTHER',
  TA_SESSION = 'TA_SESSION',
  TRIAL_SESSION = 'TRIAL_SESSION',
}

export type Learner = {
  __typename?: 'Learner'
  address?: Maybe<Scalars['String']['output']>
  codingSkill?: Maybe<Scalars['String']['output']>
  /** @deprecated Use learnerCourseMembers instead */
  courseMembers: Array<CourseMember>
  createdAt: Scalars['Date']['output']
  dateOfBirth?: Maybe<Scalars['Date']['output']>
  discordId?: Maybe<Scalars['String']['output']>
  englishLevel?: Maybe<Scalars['String']['output']>
  expYears?: Maybe<Scalars['String']['output']>
  gender?: Maybe<Gender>
  id: Scalars['ID']['output']
  industry?: Maybe<Scalars['String']['output']>
  jobTitle?: Maybe<Scalars['String']['output']>
  learnerCode: Scalars['Int']['output']
  learnerCourseMembers: Array<LearnerCourseMember>
  linkedinURL?: Maybe<Scalars['String']['output']>
  lwUser?: Maybe<LwUser>
  lwUserId?: Maybe<Scalars['ID']['output']>
  mentorSessionAvailabilitySchedules: Array<MentorSessionAvailabilitySchedule>
  metadata?: Maybe<Scalars['String']['output']>
  nationalIdIssuedDate?: Maybe<Scalars['Date']['output']>
  nationalIdIssuedPlace?: Maybe<Scalars['String']['output']>
  nationalIdNumber?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  organization?: Maybe<Scalars['String']['output']>
  priorCodingCourses?: Maybe<Scalars['String']['output']>
  resumeURL?: Maybe<Scalars['String']['output']>
  secondaryEmail?: Maybe<Scalars['String']['output']>
  secondaryPhoneNumber?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['Date']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
  workingStatus?: Maybe<Scalars['String']['output']>
}

export type LearnerConnection = {
  __typename?: 'LearnerConnection'
  edges?: Maybe<Array<LearnerEdge>>
  nodes?: Maybe<Array<Learner>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type LearnerCourseMember = {
  __typename?: 'LearnerCourseMember'
  course: Course
  courseExpectation?: Maybe<Scalars['String']['output']>
  courseId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  enrollmentDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  jobExpectation?: Maybe<Scalars['String']['output']>
  latestProgressReport?: Maybe<CourseMemberProgressReport>
  latestProgressReportId?: Maybe<Scalars['ID']['output']>
  learner: Learner
  learnerId: Scalars['ID']['output']
  learningPurpose?: Maybe<Scalars['String']['output']>
  learningType?: Maybe<Scalars['ID']['output']>
  lsUser?: Maybe<User>
  lsUserId?: Maybe<Scalars['String']['output']>
  monthToChangeJob?: Maybe<Scalars['Float']['output']>
  paymentStatus?: Maybe<CourseMemberPaymentStatus>
  paymentType?: Maybe<Scalars['String']['output']>
  reasonToSwitchCareer?: Maybe<Scalars['String']['output']>
  redeemedMentorSessionEvents?: Maybe<Scalars['Int']['output']>
  source?: Maybe<Scalars['String']['output']>
  status: CourseMemberStatus
  studyTimePerWeek?: Maybe<Scalars['Float']['output']>
  thisWeekTASessionCount: Scalars['Int']['output']
  totalAllowedTASessions?: Maybe<Scalars['Int']['output']>
  totalMentorSessionEvents?: Maybe<Scalars['Int']['output']>
  updatedAt: Scalars['Date']['output']
  user: User
  userId: Scalars['ID']['output']
}

export type LearnerCourseMemberOffsetBasedPagination = {
  __typename?: 'LearnerCourseMemberOffsetBasedPagination'
  nodes: Array<LearnerCourseMember>
  pageInfo: OffsetBasedPaginationPageInfo
}

export type LearnerEdge = {
  __typename?: 'LearnerEdge'
  cursor: Scalars['String']['output']
  node: Learner
}

export type LearnerIncident = {
  __typename?: 'LearnerIncident'
  course?: Maybe<Course>
  courseId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  createdBy?: Maybe<User>
  createdById?: Maybe<Scalars['ID']['output']>
  data: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  learner?: Maybe<Learner>
  learnerId: Scalars['ID']['output']
  severity: IncidentSeverity
  status: LearnerIncidentStatus
  type: LearnerIncidentType
  updatedAt: Scalars['Date']['output']
}

export type LearnerIncidentConnection = {
  __typename?: 'LearnerIncidentConnection'
  edges?: Maybe<Array<LearnerIncidentEdge>>
  nodes?: Maybe<Array<LearnerIncident>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type LearnerIncidentEdge = {
  __typename?: 'LearnerIncidentEdge'
  cursor: Scalars['String']['output']
  node: LearnerIncident
}

export type LearnerIncidentReportStatus = {
  __typename?: 'LearnerIncidentReportStatus'
  completed: Scalars['Int']['output']
  completedAt?: Maybe<Scalars['Date']['output']>
  incidents: Scalars['Int']['output']
  progress: Scalars['Int']['output']
  startedAt: Scalars['Date']['output']
  total: Scalars['Int']['output']
}

export enum LearnerIncidentStatus {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
  SOLVING = 'SOLVING',
}

export enum LearnerIncidentType {
  MS_ATTENDANCE = 'MS_ATTENDANCE',
}

export enum LearningVelocity {
  OFF_TRACK = 'OFF_TRACK',
  ON_TRACK = 'ON_TRACK',
}

export type LinkLearnerToLwUserInput = {
  courseId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  learnerId: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type LwCourse = {
  __typename?: 'LwCourse'
  access: LwCourseAccess
  courseImage?: Maybe<Scalars['String']['output']>
  created: Scalars['Date']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  modified: Scalars['Date']['output']
  sections: Array<LwCourseSection>
  title: Scalars['String']['output']
}

export enum LwCourseAccess {
  COMING_SOON = 'COMING_SOON',
  DRAFT = 'DRAFT',
  ENROLLMENT_CLOSED = 'ENROLLMENT_CLOSED',
  FREE = 'FREE',
  PAID = 'PAID',
  PRIVATE = 'PRIVATE',
}

export type LwCourseSection = {
  __typename?: 'LwCourseSection'
  access: LwCourseSectionAccess
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  learningUnits: Array<LwLearningUnit>
  title: Scalars['String']['output']
}

export enum LwCourseSectionAccess {
  DRAFT = 'DRAFT',
  FREE = 'FREE',
  PAID = 'PAID',
  PREMIUM = 'PREMIUM',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  SOON = 'SOON',
}

export type LwLearningUnit = {
  __typename?: 'LwLearningUnit'
  icon: Scalars['String']['output']
  id: Scalars['ID']['output']
  subtitle: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type LwUser = {
  __typename?: 'LwUser'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  role: LwUserRole
  tags: Array<Scalars['String']['output']>
  username: Scalars['String']['output']
}

export type LwUserRole = {
  __typename?: 'LwUserRole'
  level: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type MeetingParticipant = {
  __typename?: 'MeetingParticipant'
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  meetingRoom: MeetingRoom
  meetingRoomId: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
  user: User
  userId: Scalars['String']['output']
}

export enum MeetingPermission {
  ALLOW_JOIN = 'ALLOW_JOIN',
  ALLOW_MOD = 'ALLOW_MOD',
  ASK_JOIN = 'ASK_JOIN',
}

export type MeetingRecording = {
  __typename?: 'MeetingRecording'
  createdAt: Scalars['Date']['output']
  duration?: Maybe<Scalars['Float']['output']>
  format?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isReady: Scalars['Boolean']['output']
  meetingSession: MeetingSession
  meetingSessionId: Scalars['String']['output']
  resolution?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['Float']['output']>
  startsAt?: Maybe<Scalars['Date']['output']>
  type: MeetingRecordingType
  updatedAt: Scalars['Date']['output']
}

export enum MeetingRecordingType {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
}

export type MeetingRoom = {
  __typename?: 'MeetingRoom'
  createdAt: Scalars['Date']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  meetingParticipants: Array<MeetingParticipant>
  meetingSessions: Array<MeetingSession>
  mentorSession?: Maybe<MentorSession>
  name?: Maybe<Scalars['String']['output']>
  status: MeetingRoomStatus
  updatedAt: Scalars['Date']['output']
}

export type MeetingRoomInfo = {
  __typename?: 'MeetingRoomInfo'
  calendarEventId?: Maybe<Scalars['ID']['output']>
  endsAt?: Maybe<Scalars['Date']['output']>
  meetingRoom: MeetingRoom
  participantUserIds?: Maybe<Array<Scalars['String']['output']>>
  roomTitle?: Maybe<Scalars['String']['output']>
  startsAt?: Maybe<Scalars['Date']['output']>
}

export enum MeetingRoomStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export type MeetingSession = {
  __typename?: 'MeetingSession'
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  meetingRecordings: Array<MeetingRecording>
  meetingRoom: MeetingRoom
  meetingRoomId: Scalars['String']['output']
  mentorSessionEvent?: Maybe<MentorSessionEvent>
  mentorSessionEventId?: Maybe<Scalars['String']['output']>
  startsAt: Scalars['Date']['output']
  status: MeetingSessionStatus
  updatedAt: Scalars['Date']['output']
}

export enum MeetingSessionStatus {
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
}

export type Mentor = {
  __typename?: 'Mentor'
  address?: Maybe<Scalars['String']['output']>
  bankAccountNumber?: Maybe<Scalars['String']['output']>
  bankBranch?: Maybe<Scalars['String']['output']>
  bankName?: Maybe<Scalars['String']['output']>
  beneficiary?: Maybe<Scalars['String']['output']>
  capacity?: Maybe<Scalars['Int']['output']>
  city?: Maybe<Scalars['String']['output']>
  /** @deprecated Use mentorCourseMembers instead */
  courseMembers?: Maybe<Array<CourseMember>>
  createdAt: Scalars['Date']['output']
  dateOfBirth?: Maybe<Scalars['Date']['output']>
  discordId?: Maybe<Scalars['String']['output']>
  expYears?: Maybe<Scalars['String']['output']>
  gender?: Maybe<Gender>
  hourlyRate?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  jobTitle?: Maybe<Scalars['String']['output']>
  lwUserId?: Maybe<Scalars['String']['output']>
  mentorCourseMembers: Array<MentorCourseMember>
  mentorSessionAvailabilitySchedules: Array<MentorSessionAvailabilitySchedule>
  nationalIdIssuedDate?: Maybe<Scalars['Date']['output']>
  nationalIdIssuedPlace?: Maybe<Scalars['String']['output']>
  nationalIdNumber?: Maybe<Scalars['String']['output']>
  oldNationalIdNumber?: Maybe<Scalars['String']['output']>
  organization?: Maybe<Scalars['String']['output']>
  pendingBookingRequestCount: Scalars['Int']['output']
  pitCode?: Maybe<Scalars['String']['output']>
  secondaryEmail?: Maybe<Scalars['String']['output']>
  status: MentorStatus
  type: MentorType
  updatedAt: Scalars['Date']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type MentorConnection = {
  __typename?: 'MentorConnection'
  edges?: Maybe<Array<MentorEdge>>
  nodes?: Maybe<Array<Mentor>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type MentorCourseMember = {
  __typename?: 'MentorCourseMember'
  course: Course
  courseId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  mentor: Mentor
  mentorId: Scalars['ID']['output']
  status: CourseMemberStatus
  updatedAt: Scalars['Date']['output']
  user: User
  userId: Scalars['ID']['output']
}

export type MentorEdge = {
  __typename?: 'MentorEdge'
  cursor: Scalars['String']['output']
  node: Mentor
}

export type MentorMenteeCount = {
  __typename?: 'MentorMenteeCount'
  id: Scalars['ID']['output']
  mentee: Scalars['Int']['output']
  mentor: Scalars['Int']['output']
}

export type MentorSession = {
  __typename?: 'MentorSession'
  activityLogs: Array<ActivityLog>
  bookingRequestId: Scalars['ID']['output']
  calendarEvents: Array<CalendarEvent>
  course?: Maybe<Course>
  courseId: Scalars['ID']['output']
  courseMembers: Array<CourseMember>
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  isFinished: Scalars['Boolean']['output']
  mentorSessionEvents: Array<MentorSessionEvent>
  mentorSessionRecapReport: MentorSessionRecapReport
  mentorSessionRecaps: Array<MentorSessionRecap>
  nextMentorSessionEvent?: Maybe<MentorSessionEvent>
  status: MentorSessionStatus
  updatedAt: Scalars['Date']['output']
}

export type MentorSessionAvailabilityInput = {
  /** 0 = Sun - 7 = Sat */
  dayOfWeek: Scalars['Int']['input']
  end: Scalars['Date']['input']
  start: Scalars['Date']['input']
}

export type MentorSessionAvailabilitySchedule = {
  __typename?: 'MentorSessionAvailabilitySchedule'
  /** 0 = Sun - 7 = Sat */
  dayOfWeek: Scalars['Int']['output']
  end: Scalars['Date']['output']
  id: Scalars['ID']['output']
  start: Scalars['Date']['output']
  targetId: Scalars['ID']['output']
  targetType?: Maybe<MentorSessionAvailabilityScheduleTargetType>
}

export enum MentorSessionAvailabilityScheduleTargetType {
  LEARNER = 'LEARNER',
  MENTOR = 'MENTOR',
}

export type MentorSessionBookingRequest = {
  __typename?: 'MentorSessionBookingRequest'
  activityLogs: Array<ActivityLog>
  course: Course
  courseId: Scalars['String']['output']
  endsAt: Scalars['Date']['output']
  expiresOn: Scalars['Date']['output']
  id: Scalars['ID']['output']
  learnerIds: Array<Scalars['ID']['output']>
  learnerMembers: Array<CourseMember>
  learners: Array<Learner>
  mentorIds: Array<Scalars['ID']['output']>
  mentors: Array<Mentor>
  recurrence?: Maybe<Scalars['String']['output']>
  responses: Array<UserResponse>
  startsAt: Scalars['Date']['output']
  status: UserRequestStatus
  userRequest: UserRequest
  userRequestId: Scalars['ID']['output']
}

export type MentorSessionBookingRequestConnection = {
  __typename?: 'MentorSessionBookingRequestConnection'
  edges?: Maybe<Array<MentorSessionBookingRequestEdge>>
  nodes?: Maybe<Array<MentorSessionBookingRequest>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type MentorSessionBookingRequestEdge = {
  __typename?: 'MentorSessionBookingRequestEdge'
  cursor: Scalars['String']['output']
  node: MentorSessionBookingRequest
}

export type MentorSessionConnection = {
  __typename?: 'MentorSessionConnection'
  edges?: Maybe<Array<MentorSessionEdge>>
  nodes?: Maybe<Array<MentorSession>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type MentorSessionEdge = {
  __typename?: 'MentorSessionEdge'
  cursor: Scalars['String']['output']
  node: MentorSession
}

export type MentorSessionEvent = {
  __typename?: 'MentorSessionEvent'
  agendas: Array<MentorSessionEventAgenda>
  calendarEvent: CalendarEvent
  calendarEventId: Scalars['ID']['output']
  course?: Maybe<Course>
  createdAt: Scalars['Date']['output']
  endsAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  /** Index of the mentor session event in the mentor session */
  indexInMentorSession: Scalars['Int']['output']
  learnerCourseMembers: Array<LearnerCourseMember>
  meetingRecordings: Array<MeetingRecording>
  mentorCourseMembers: Array<MentorCourseMember>
  mentorSession: MentorSession
  mentorSessionId: Scalars['ID']['output']
  mentorSessionRecapReport: MentorSessionRecapReport
  mentorSessionRecaps: Array<MentorSessionRecap>
  rescheduleRequests?: Maybe<Array<MentorSessionEventRescheduleRequest>>
  startedAt?: Maybe<Scalars['Date']['output']>
  startsAt: Scalars['Date']['output']
  status: MentorSessionEventStatus
  updatedAt: Scalars['Date']['output']
  zoomMeetingId?: Maybe<Scalars['String']['output']>
  zoomMeetingRecordings: Array<ZoomRecording>
}

export type MentorSessionEventAgenda = {
  __typename?: 'MentorSessionEventAgenda'
  agenda: EventAgenda
  agendaId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  mentorSessionEvent: MentorSessionEvent
  mentorSessionEventId: Scalars['ID']['output']
  updatedAt: Scalars['Date']['output']
}

export type MentorSessionEventConnection = {
  __typename?: 'MentorSessionEventConnection'
  edges?: Maybe<Array<MentorSessionEventEdge>>
  nodes?: Maybe<Array<MentorSessionEvent>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type MentorSessionEventEdge = {
  __typename?: 'MentorSessionEventEdge'
  cursor: Scalars['String']['output']
  node: MentorSessionEvent
}

export type MentorSessionEventRescheduleRequest = {
  __typename?: 'MentorSessionEventRescheduleRequest'
  activityLogs?: Maybe<Array<ActivityLog>>
  endsAt: Scalars['Date']['output']
  expiresOn: Scalars['Date']['output']
  id: Scalars['ID']['output']
  mentorSessionEvent: MentorSessionEvent
  mentorSessionEventId: Scalars['String']['output']
  reason?: Maybe<Scalars['String']['output']>
  startsAt: Scalars['Date']['output']
  status: UserRequestStatus
  userRequest: UserRequest
  userRequestId: Scalars['ID']['output']
}

export enum MentorSessionEventStatus {
  ABSENT = 'ABSENT',
  CANCELED_BY_LEARNER = 'CANCELED_BY_LEARNER',
  CANCELED_BY_MENTOR = 'CANCELED_BY_MENTOR',
  COMPLETED = 'COMPLETED',
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  SCHEDULED = 'SCHEDULED',
}

export type MentorSessionRecap = {
  __typename?: 'MentorSessionRecap'
  createdAt: Scalars['Date']['output']
  createdByUser: User
  createdByUserId: Scalars['ID']['output']
  formSubmission: FormSubmission
  formSubmissionId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  learner: Learner
  learnerId: Scalars['ID']['output']
  mentor: Mentor
  mentorId: Scalars['ID']['output']
  mentorSessionEvent: MentorSessionEvent
  mentorSessionEventCalendarEventId: Scalars['ID']['output']
  type: MentorSessionRecapType
  updatedAt: Scalars['Date']['output']
}

export type MentorSessionRecapReport = {
  __typename?: 'MentorSessionRecapReport'
  id: Scalars['ID']['output']
  mentorSessionRecapCount: MentorMenteeCount
  requiredRecapCount: MentorMenteeCount
}

export enum MentorSessionRecapType {
  MENTEE_RECAP = 'MENTEE_RECAP',
  MENTOR_RECAP = 'MENTOR_RECAP',
}

export enum MentorSessionStatus {
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  SCHEDULED = 'SCHEDULED',
}

export type MentorSessionTimeSuggestion = {
  __typename?: 'MentorSessionTimeSuggestion'
  end: Scalars['Date']['output']
  id: Scalars['ID']['output']
  notes?: Maybe<Scalars['String']['output']>
  score: Scalars['Float']['output']
  start: Scalars['Date']['output']
}

export enum MentorStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type MentorTimesheetItem = {
  __typename?: 'MentorTimesheetItem'
  absentSessions: Scalars['Int']['output']
  canceledSessions: Scalars['Int']['output']
  courseTitle: Scalars['String']['output']
  finishedSessions: Scalars['Int']['output']
  finishedSessionsWithMentorRecap: Scalars['Int']['output']
  mentorEmail: Scalars['String']['output']
  mentorId: Scalars['ID']['output']
  mentorName?: Maybe<Scalars['String']['output']>
  mentorUserId: Scalars['ID']['output']
}

export enum MentorType {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export type Mutation = {
  __typename?: 'Mutation'
  addItemToWishlist: Wishlist
  addMentorToCourse: CourseMember
  assignLSStaff: LearnerCourseMember
  bookTASession?: Maybe<BookTaSessionResult>
  cancelCalendarEvent: Scalars['Boolean']['output']
  cancelInstantMeeting: InstantMeeting
  cancelMentorSession: MentorSession
  cancelMentorSessionEvent: MentorSessionEvent
  cancelMentorSessionEventRescheduleRequest: UserRequest
  cancelPendingMentorSessionBookingRequest: MentorSessionBookingRequest
  checkExpiredBookingRequests: Scalars['Int']['output']
  connectDiscordAccount: User
  createAppToken: AppToken
  createCalendar: Calendar
  createCalendarEvent: CalendarEvent
  createChatChannel: ChatChannel
  createChatMessage: ChatMessage
  createComment: Comment
  createCourse: Course
  createCourseDurationOption: CourseDurationOption
  createCourseJob: CourseJob
  createCourseMemberProgressReport?: Maybe<CourseMemberProgressReport>
  createCourseStaff: CourseStaff
  createCustomField: CustomField
  createDealEnrollment: DealEnrollment
  createDemoSessionRecap: DemoSessionRecap
  createEmployeeProfile: EmployeeProfile
  createEmployeeTeam: EmployeeTeam
  createEmployeeTeamMember: EmployeeTeamMember
  createEventAgendaActivity: EventAgenda
  createEventAgendaItem: EventAgendaItem
  createFreeLearner: Learner
  createInstantMeeting: InstantMeeting
  createInstantMeetingNote: Note
  createLearner: Learner
  createMagicProfile: AuthResult
  createMentor: Mentor
  createMentorSession: MentorSession
  createMentorSessionBookingRequest: MentorSessionBookingRequest
  createMentorSessionBookingResponse: UserResponse
  createMentorSessionEvent: MentorSessionEvent
  createMentorSessionEventAgenda: MentorSessionEventAgenda
  createMentorSessionEventNote: Note
  createMentorSessionEventRescheduleRequest: MentorSessionEventRescheduleRequest
  createMentorSessionEventRescheduleResponse: MentorSessionEvent
  createMentorSessionRecap: MentorSessionRecap
  createNoteToDoItem: NoteToDoItem
  createReviewCampaign: ReviewCampaign
  createReviewCampaignParticipant: ReviewCampaignParticipant
  createReviewCampaignQuestion: ReviewCampaignQuestion
  createReviewCampaignTimelineItem: ReviewCampaignTimelineItem
  createUser: CreateUserOutput
  customizeCourse: PersonalizeCourse
  deleteAppToken: Scalars['Boolean']['output']
  deleteComment: Comment
  deleteCourse: Scalars['Boolean']['output']
  deleteCourseDurationOption: CourseDurationOption
  deleteCourseStaff: CourseStaff
  deleteCustomField: CustomField
  deleteEmployeeTeam: EmployeeTeam
  deleteEmployeeTeamMember: EmployeeTeamMember
  deleteEventAgendaItem: EventAgendaItem
  deleteMentorSession: Scalars['Boolean']['output']
  deleteNoteToDoItem: NoteToDoItem
  deleteReviewCampaign: ReviewCampaign
  deleteReviewCampaignParticipant: ReviewCampaignParticipant
  deleteReviewCampaignQuestion: ReviewCampaignQuestion
  deleteReviewCampaignTimelineItem: ReviewCampaign
  disconnectDiscord: AuthUser
  dropCourseMember: CourseMember
  enrollFreeLearner: CourseMember
  enrollLearner: CourseMember
  enrollPendingMember: CourseMember
  ensureEveryLearnerHasLwAccountTags: Scalars['String']['output']
  extendMentorSession: Array<MentorSessionEvent>
  flattenAllCourseUnits: Scalars['Date']['output']
  generateReviewRequest?: Maybe<ReviewRequest>
  generateSecretSantaPairs: Scalars['Date']['output']
  generateVideoSDKToken: Scalars['String']['output']
  getEventMeetingUrl: Scalars['String']['output']
  graduateCourseMember: CourseMember
  hardDeleteCourseStaff: CourseStaff
  importMentors: Scalars['Boolean']['output']
  joinEvent: CalendarEvent
  linkLearnerToLwUser: Learner
  lwSSO: Scalars['String']['output']
  markEventAsFinished: MentorSessionEvent
  markReviewRequestAsReviewed: ReviewRequest
  migrateMentorSessionEventsOfAMentor: Scalars['Date']['output']
  migrateMentorSessionEventsOfAllMentor: Scalars['Date']['output']
  normalizeAllUserEmails: Scalars['Date']['output']
  notifyAboutReturningPostponedCourseMembers: Scalars['Boolean']['output']
  passwordSignIn: Scalars['String']['output']
  platformSignIn: Scalars['String']['output']
  postponeCourseMember: CourseMember
  proposeNewTime: MentorSessionEventRescheduleRequest
  publishReviewCampaign: ReviewCampaign
  publishReviewRequest: ReviewRequest
  reactivateCourseMember: CourseMember
  registerUser: CreateUserOutput
  removeItemFromWishlist: Wishlist
  requestAllLearnersIncidentReports: LearnerIncidentReportStatus
  requestResetPassword: Scalars['String']['output']
  rescheduleMentorSessionEvent: MentorSessionEvent
  rescheduleMentorSessionEventBatch: Array<MentorSessionEvent>
  resetCourseCustom: Scalars['Boolean']['output']
  resetUserPassword: User
  resyncZoomMeetingsOfAllMSEs: Scalars['Date']['output']
  revealSecretSanta: SecretSanta
  saveReviewResponseQuestionAnswer: ReviewResponseQuestionItem
  sendResetPassword: Scalars['String']['output']
  setupUserPassword: User
  signInWithPassword: AuthResult
  signInWithSocial?: Maybe<AuthResult>
  signUpWithMagicLink?: Maybe<AuthResult>
  submitGift: SecretSanta
  submitReviewResponse: ReviewResponse
  subscribeCourse: CourseSubscribe
  swapReviewCampaignQuestionsOrder: Array<ReviewCampaignQuestion>
  syncAllCourseMemberProgressFromLw: Scalars['Date']['output']
  syncAllRecordingsFromVSDKToDb: Scalars['Date']['output']
  syncAllRecordingsFromVSDKToS3: Scalars['Date']['output']
  syncAssignedLSStaff: Scalars['Boolean']['output']
  syncDealsFromPipeDrive: Scalars['Date']['output']
  syncSchoolEvents: Scalars['Date']['output']
  updateAllChatChannelsTypeToMentorSession: Scalars['Date']['output']
  updateAppToken: AppToken
  updateCalendar: Calendar
  updateCalendarEvent: CalendarEvent
  updateComment: Comment
  updateCourse: Course
  updateCourseContents: Course
  updateCourseDurationOption: CourseDurationOption
  updateCourseJob: CourseJob
  updateCourseMember: CourseMember
  updateCustomField: CustomField
  updateDaxLearnerMemberTotalMentorSessionEvents: Array<CourseMember>
  updateDropActivityLog: ActivityLog
  updateEmployeeProfile: EmployeeProfile
  updateEmployeeTeam: EmployeeTeam
  updateEnrollmentBatches: Array<EnrollmentBatch>
  updateEventAgendaItem: EventAgendaItem
  updateExternalRecordingId: InstantMeeting
  updateFullTextSearchForAllBookingRequests: Scalars['Date']['output']
  updateFullTextSearchForAllUsers: Scalars['Date']['output']
  updateGraduateActivityLog: ActivityLog
  updateInstantMeeting: InstantMeeting
  updateLearner: Learner
  updateLearnerCode: Learner
  updateLwUser: Learner
  updateMentor: Mentor
  updateMentorSession: MentorSession
  updateMentorSessionAvailabilitySchedules: Array<MentorSessionAvailabilitySchedule>
  updateMentorSessionsStatusBatch: Scalars['Date']['output']
  updateNote: Note
  updateNoteLinks: Array<NoteLink>
  updateNoteToDoItem: NoteToDoItem
  updatePostponeActivityLog: ActivityLog
  updateReviewCampaign: ReviewCampaign
  updateReviewCampaignQuestion: ReviewCampaignQuestion
  updateReviewCampaignQuestionOrder: Array<ReviewCampaignQuestion>
  updateReviewCampaignTimelineItem: ReviewCampaignTimelineItem
  updateSecretSantaSeen: SecretSanta
  updateTargetIdsOfAllRecapActivities: Scalars['Date']['output']
  updateTargetIdsOfAllRescheduleRequestActivities: Scalars['Date']['output']
  updateUser?: Maybe<User>
  updateUserAvatar: User
  updateUserEvent: UserEvent
  updateUserPassword: User
  updateUserRequestStatus: UserRequest
  uploadCourseJobCompanyLogo: CloudFile
  upsertSecretSantaNote: SecretSantaNote
  verifyUserEmailToken?: Maybe<AuthResult>
}

export type MutationAddItemToWishlistArgs = {
  input: AddToWishlistInput
}

export type MutationAddMentorToCourseArgs = {
  input: AddMentorToCourseInput
}

export type MutationAssignLsStaffArgs = {
  learnerCourseMemberId: Scalars['ID']['input']
  lsUserId: Scalars['ID']['input']
}

export type MutationBookTaSessionArgs = {
  courseId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  duration: Scalars['Float']['input']
  timeSlot: Scalars['Date']['input']
}

export type MutationCancelCalendarEventArgs = {
  id: Scalars['ID']['input']
  type?: InputMaybe<CalendarEventRecurrenceUpdateOption>
}

export type MutationCancelInstantMeetingArgs = {
  cancelAllRecurrences?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
}

export type MutationCancelMentorSessionArgs = {
  args: CancelMentorSessionInput
}

export type MutationCancelMentorSessionEventArgs = {
  cancelReason?: InputMaybe<Scalars['String']['input']>
  canceledByUserId?: InputMaybe<Scalars['ID']['input']>
  createMakeUpEvent?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
}

export type MutationCancelMentorSessionEventRescheduleRequestArgs = {
  input: CancelMentorSessionEventRescheduleRequestInput
  userRequestId: Scalars['ID']['input']
}

export type MutationCancelPendingMentorSessionBookingRequestArgs = {
  reason?: InputMaybe<Scalars['String']['input']>
  userRequestId: Scalars['ID']['input']
}

export type MutationConnectDiscordAccountArgs = {
  accessToken: Scalars['String']['input']
}

export type MutationCreateAppTokenArgs = {
  input: CreateAppTokenInput
}

export type MutationCreateCalendarArgs = {
  input: CreateCalendarInput
}

export type MutationCreateCalendarEventArgs = {
  input: CreateCalendarEventInput
}

export type MutationCreateChatChannelArgs = {
  input: CreateChatChannelInput
}

export type MutationCreateChatMessageArgs = {
  channelId: Scalars['ID']['input']
  input: CreateChatMessageInput
}

export type MutationCreateCommentArgs = {
  content: Scalars['String']['input']
  discussionId: Scalars['ID']['input']
  replyToCommentId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationCreateCourseArgs = {
  input: CreateCourseInput
}

export type MutationCreateCourseDurationOptionArgs = {
  courseId: Scalars['ID']['input']
  input: CourseDurationOptionInput
}

export type MutationCreateCourseJobArgs = {
  input: CreateCourseJobInput
}

export type MutationCreateCourseMemberProgressReportArgs = {
  courseMemberId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['Date']['input']>
  startDate?: InputMaybe<Scalars['Date']['input']>
}

export type MutationCreateCourseStaffArgs = {
  courseId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type MutationCreateCustomFieldArgs = {
  input: CreateCustomFieldInput
}

export type MutationCreateDealEnrollmentArgs = {
  input: CreateDealEnrollmentInput
}

export type MutationCreateDemoSessionRecapArgs = {
  input: CreateDemoSessionRecapInput
}

export type MutationCreateEmployeeProfileArgs = {
  input: CreateEmployeeProfileInput
}

export type MutationCreateEmployeeTeamArgs = {
  input: CreateEmployeeTeamInput
}

export type MutationCreateEmployeeTeamMemberArgs = {
  input: CreateEmployeeTeamMemberInput
}

export type MutationCreateEventAgendaActivityArgs = {
  id: Scalars['ID']['input']
  input: EventAgendaActivityInput
}

export type MutationCreateEventAgendaItemArgs = {
  eventAgendaId: Scalars['ID']['input']
  input: EventAgendaItemCreateInput
}

export type MutationCreateFreeLearnerArgs = {
  input: CreateLearnerInput
}

export type MutationCreateInstantMeetingArgs = {
  input: CreateInstantMeetingInput
}

export type MutationCreateInstantMeetingNoteArgs = {
  input: NoteCreateInput
  instantMeeting: Scalars['ID']['input']
}

export type MutationCreateLearnerArgs = {
  input: CreateLearnerInput
}

export type MutationCreateMagicProfileArgs = {
  name: Scalars['String']['input']
  password?: InputMaybe<Scalars['String']['input']>
  phone: Scalars['String']['input']
}

export type MutationCreateMentorArgs = {
  input: CreateMentorInput
}

export type MutationCreateMentorSessionArgs = {
  input: CreateMentorSessionInput
}

export type MutationCreateMentorSessionBookingRequestArgs = {
  input: CreateMentorSessionBookingRequestInput
}

export type MutationCreateMentorSessionBookingResponseArgs = {
  input: CreateMentorSessionBookingResponseInput
}

export type MutationCreateMentorSessionEventArgs = {
  input: CreateMentorSessionEventInput
}

export type MutationCreateMentorSessionEventAgendaArgs = {
  mentorSessionEventId: Scalars['ID']['input']
}

export type MutationCreateMentorSessionEventNoteArgs = {
  calendarEventId: Scalars['ID']['input']
  input: NoteCreateInput
}

export type MutationCreateMentorSessionEventRescheduleRequestArgs = {
  input: RescheduleMentorSessionEventInput
}

export type MutationCreateMentorSessionEventRescheduleResponseArgs = {
  input: CreateMentorSessionEventRescheduleResponseInput
}

export type MutationCreateMentorSessionRecapArgs = {
  input: CreateMentorSessionRecapInput
}

export type MutationCreateNoteToDoItemArgs = {
  input: NoteToDoItemCreateInput
  noteId: Scalars['ID']['input']
}

export type MutationCreateReviewCampaignArgs = {
  input: CreateReviewCampaignInput
}

export type MutationCreateReviewCampaignParticipantArgs = {
  employeeProfileId?: InputMaybe<Scalars['ID']['input']>
  employeeTeamId?: InputMaybe<Scalars['ID']['input']>
  reviewCampaignId: Scalars['ID']['input']
}

export type MutationCreateReviewCampaignQuestionArgs = {
  campaignId: Scalars['ID']['input']
  input: ReviewCampaignQuestionInput
}

export type MutationCreateReviewCampaignTimelineItemArgs = {
  campaignId: Scalars['ID']['input']
  date: Scalars['Date']['input']
  text: Scalars['String']['input']
  type: ReviewCampaignTimelineItemType
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationCustomizeCourseArgs = {
  input: CustomizeCourseInput
}

export type MutationDeleteAppTokenArgs = {
  idOrToken: Scalars['String']['input']
}

export type MutationDeleteCommentArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCourseArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCourseDurationOptionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCourseStaffArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCustomFieldArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteEmployeeTeamArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteEmployeeTeamMemberArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteEventAgendaItemArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteMentorSessionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteNoteToDoItemArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteReviewCampaignArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteReviewCampaignParticipantArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteReviewCampaignQuestionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteReviewCampaignTimelineItemArgs = {
  id: Scalars['ID']['input']
}

export type MutationDropCourseMemberArgs = {
  courseMemberId: Scalars['String']['input']
  data: DropCourseMemberInput
}

export type MutationEnrollFreeLearnerArgs = {
  courseId: Scalars['ID']['input']
  learnerId: Scalars['ID']['input']
}

export type MutationEnrollLearnerArgs = {
  input: EnrollLearnerToCourseInput
}

export type MutationEnrollPendingMemberArgs = {
  courseMemberId: Scalars['String']['input']
}

export type MutationExtendMentorSessionArgs = {
  id: Scalars['ID']['input']
  input: ExtendMentorSessionInput
}

export type MutationGenerateReviewRequestArgs = {
  campaignId: Scalars['ID']['input']
  employeeTeamMemberId: Scalars['ID']['input']
}

export type MutationGenerateVideoSdkTokenArgs = {
  input: GenerateVideoSdkTokenInput
}

export type MutationGetEventMeetingUrlArgs = {
  eventId: Scalars['ID']['input']
}

export type MutationGraduateCourseMemberArgs = {
  courseMemberId: Scalars['String']['input']
  data: GraduateCourseMemberInput
}

export type MutationHardDeleteCourseStaffArgs = {
  id: Scalars['ID']['input']
}

export type MutationJoinEventArgs = {
  eventId: Scalars['ID']['input']
}

export type MutationLinkLearnerToLwUserArgs = {
  input: LinkLearnerToLwUserInput
}

export type MutationMarkEventAsFinishedArgs = {
  calendarEventId: Scalars['ID']['input']
}

export type MutationMarkReviewRequestAsReviewedArgs = {
  id: Scalars['ID']['input']
}

export type MutationMigrateMentorSessionEventsOfAMentorArgs = {
  mentorId: Scalars['ID']['input']
}

export type MutationPasswordSignInArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
  remember: Scalars['Boolean']['input']
}

export type MutationPlatformSignInArgs = {
  credential?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  remember?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPostponeCourseMemberArgs = {
  courseMemberId: Scalars['String']['input']
  data: PostponeCourseMemberInput
}

export type MutationProposeNewTimeArgs = {
  newRequestArgs: RescheduleMentorSessionEventInput
  userRequestId: Scalars['ID']['input']
}

export type MutationPublishReviewCampaignArgs = {
  id: Scalars['ID']['input']
}

export type MutationPublishReviewRequestArgs = {
  id: Scalars['ID']['input']
}

export type MutationReactivateCourseMemberArgs = {
  courseMemberId: Scalars['ID']['input']
}

export type MutationRegisterUserArgs = {
  input: RegisterUserInput
}

export type MutationRemoveItemFromWishlistArgs = {
  itemId: Scalars['ID']['input']
  wishlistId: Scalars['ID']['input']
}

export type MutationRequestResetPasswordArgs = {
  email: Scalars['String']['input']
  fromCrossroads?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationRescheduleMentorSessionEventArgs = {
  input: RescheduleMentorSessionEventInput
}

export type MutationRescheduleMentorSessionEventBatchArgs = {
  input: RescheduleMentorSessionEventBatchInput
}

export type MutationResetCourseCustomArgs = {
  courseRefId: Scalars['ID']['input']
}

export type MutationResetUserPasswordArgs = {
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type MutationRevealSecretSantaArgs = {
  id: Scalars['ID']['input']
}

export type MutationSaveReviewResponseQuestionAnswerArgs = {
  answer: Scalars['String']['input']
  id: Scalars['ID']['input']
}

export type MutationSendResetPasswordArgs = {
  email: Scalars['String']['input']
}

export type MutationSetupUserPasswordArgs = {
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type MutationSignInWithPasswordArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
  remember: Scalars['Boolean']['input']
}

export type MutationSignInWithSocialArgs = {
  accessToken: Scalars['String']['input']
  provider: SocialProvider
  providerToken: Scalars['String']['input']
  shouldCreateUser?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSignUpWithMagicLinkArgs = {
  email: Scalars['String']['input']
  fromCrossroads?: InputMaybe<Scalars['Boolean']['input']>
  next?: InputMaybe<Scalars['String']['input']>
}

export type MutationSubmitGiftArgs = {
  secretSantaId: Scalars['ID']['input']
}

export type MutationSubmitReviewResponseArgs = {
  id: Scalars['ID']['input']
  signature: Scalars['String']['input']
}

export type MutationSubscribeCourseArgs = {
  input: SubscribeCourseInput
}

export type MutationSwapReviewCampaignQuestionsOrderArgs = {
  otherQuestionId: Scalars['ID']['input']
  questionId: Scalars['ID']['input']
}

export type MutationUpdateAppTokenArgs = {
  id: Scalars['ID']['input']
  input: UpdateAppTokenInput
}

export type MutationUpdateCalendarArgs = {
  id: Scalars['ID']['input']
  input: UpdateCalendarInput
}

export type MutationUpdateCalendarEventArgs = {
  id: Scalars['ID']['input']
  input: UpdateCalendarEventInput
}

export type MutationUpdateCommentArgs = {
  content: Scalars['String']['input']
  id: Scalars['ID']['input']
}

export type MutationUpdateCourseArgs = {
  id: Scalars['ID']['input']
  input: UpdateCourseInput
}

export type MutationUpdateCourseContentsArgs = {
  deletedSectionIds?: Array<Scalars['String']['input']>
  deletedUnitIds?: Array<Scalars['String']['input']>
  id: Scalars['ID']['input']
  sections: Array<CourseSectionInput>
}

export type MutationUpdateCourseDurationOptionArgs = {
  id: Scalars['ID']['input']
  input: CourseDurationOptionInput
}

export type MutationUpdateCourseJobArgs = {
  id: Scalars['ID']['input']
  input: UpdateCourseJobInput
}

export type MutationUpdateCourseMemberArgs = {
  courseMemberId: Scalars['ID']['input']
  updates: UpdateCourseMemberInput
}

export type MutationUpdateCustomFieldArgs = {
  id: Scalars['ID']['input']
  input: UpdateCustomFieldInput
}

export type MutationUpdateDaxLearnerMemberTotalMentorSessionEventsArgs = {
  courseId: Scalars['ID']['input']
  durationMonth: Scalars['Int']['input']
}

export type MutationUpdateDropActivityLogArgs = {
  data: DropCourseMemberInput
  id: Scalars['ID']['input']
}

export type MutationUpdateEmployeeProfileArgs = {
  id: Scalars['ID']['input']
  input: UpdateEmployeeProfileInput
}

export type MutationUpdateEmployeeTeamArgs = {
  id: Scalars['ID']['input']
  input: UpdateEmployeeTeamInput
}

export type MutationUpdateEventAgendaItemArgs = {
  eventAgendaItemId: Scalars['ID']['input']
  input: EventAgendaItemUpdateInput
}

export type MutationUpdateExternalRecordingIdArgs = {
  externalRecordingId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type MutationUpdateGraduateActivityLogArgs = {
  data: GraduateCourseMemberInput
  id: Scalars['ID']['input']
}

export type MutationUpdateInstantMeetingArgs = {
  id: Scalars['ID']['input']
  input: UpdateInstantMeetingInput
  updateAllRecurrences?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateLearnerArgs = {
  id: Scalars['ID']['input']
  input: UpdateLearnerInput
}

export type MutationUpdateLearnerCodeArgs = {
  code: Scalars['Int']['input']
  id: Scalars['ID']['input']
}

export type MutationUpdateLwUserArgs = {
  email: Scalars['String']['input']
  learnerId: Scalars['ID']['input']
}

export type MutationUpdateMentorArgs = {
  id: Scalars['ID']['input']
  input: UpdateMentorInput
}

export type MutationUpdateMentorSessionArgs = {
  id: Scalars['ID']['input']
  input: UpdateMentorSessionInput
}

export type MutationUpdateMentorSessionAvailabilitySchedulesArgs = {
  schedules: Array<MentorSessionAvailabilityInput>
  targetId: Scalars['ID']['input']
}

export type MutationUpdateNoteArgs = {
  input: NoteCreateInput
  noteId: Scalars['ID']['input']
}

export type MutationUpdateNoteLinksArgs = {
  noteId: Scalars['ID']['input']
  urls: Array<Scalars['String']['input']>
}

export type MutationUpdateNoteToDoItemArgs = {
  id: Scalars['ID']['input']
  input: NoteToDoItemUpdateInput
}

export type MutationUpdatePostponeActivityLogArgs = {
  data: PostponeCourseMemberInput
  id: Scalars['ID']['input']
}

export type MutationUpdateReviewCampaignArgs = {
  id: Scalars['ID']['input']
  input: UpdateReviewCampaignInput
}

export type MutationUpdateReviewCampaignQuestionArgs = {
  id: Scalars['ID']['input']
  input: ReviewCampaignQuestionInput
}

export type MutationUpdateReviewCampaignQuestionOrderArgs = {
  id: Scalars['ID']['input']
  order: Scalars['Int']['input']
}

export type MutationUpdateReviewCampaignTimelineItemArgs = {
  date: Scalars['Date']['input']
  id: Scalars['ID']['input']
  text: Scalars['String']['input']
  type: ReviewCampaignTimelineItemType
}

export type MutationUpdateSecretSantaSeenArgs = {
  secretSantaId: Scalars['ID']['input']
}

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input']
  input: UpdateUserInput
}

export type MutationUpdateUserAvatarArgs = {
  avatar?: InputMaybe<Scalars['Upload']['input']>
}

export type MutationUpdateUserEventArgs = {
  eventId: Scalars['ID']['input']
  input: UpdateUserEventInput
}

export type MutationUpdateUserPasswordArgs = {
  currentPassword: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}

export type MutationUpdateUserRequestStatusArgs = {
  UpdateUserRequestStatusInput: UpdateUserRequestStatusInput
}

export type MutationUploadCourseJobCompanyLogoArgs = {
  file: Scalars['Upload']['input']
}

export type MutationUpsertSecretSantaNoteArgs = {
  input: SecretSantaNoteInput
  noteId: Scalars['ID']['input']
  secretSantaId: Scalars['ID']['input']
}

export type MutationVerifyUserEmailTokenArgs = {
  token: Scalars['String']['input']
}

export type Note = {
  __typename?: 'Note'
  content?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  createdByUserId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  links: Array<NoteLink>
  mentorSessionEventId?: Maybe<Scalars['ID']['output']>
  title?: Maybe<Scalars['String']['output']>
  toDoItems: Array<NoteToDoItem>
  updatedAt: Scalars['Date']['output']
}

export type NoteCreateInput = {
  content?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type NoteLink = {
  __typename?: 'NoteLink'
  createdAt: Scalars['Date']['output']
  createdByUserId?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['String']['output']>
  noteId: Scalars['ID']['output']
  title?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['Date']['output']
  url: Scalars['String']['output']
}

export type NoteToDoItem = {
  __typename?: 'NoteToDoItem'
  completed: Scalars['Boolean']['output']
  content?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  createdByUserId?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  noteId: Scalars['ID']['output']
  updatedAt: Scalars['Date']['output']
}

export type NoteToDoItemCreateInput = {
  completed?: InputMaybe<Scalars['Boolean']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  dueDate?: InputMaybe<Scalars['Date']['input']>
}

export type NoteToDoItemUpdateInput = {
  completed?: InputMaybe<Scalars['Boolean']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  dueDate?: InputMaybe<Scalars['Date']['input']>
}

export type OffsetBasedPaginationPageInfo = {
  __typename?: 'OffsetBasedPaginationPageInfo'
  hasMore: Scalars['Boolean']['output']
  limit: Scalars['Int']['output']
  offset: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
  totalPages: Scalars['Int']['output']
}

export enum Permission {
  ACCESS_ACADEMIC_MENTOR_SESSIONS_MODULE = 'ACCESS_ACADEMIC_MENTOR_SESSIONS_MODULE',
  ACCESS_LEARNER_APP = 'ACCESS_LEARNER_APP',
  ACCESS_MENTOR_APP = 'ACCESS_MENTOR_APP',
  ACCESS_SALES_MODULE = 'ACCESS_SALES_MODULE',
  ACCESS_WORKPLACE_APP = 'ACCESS_WORKPLACE_APP',
  ADD_MENTOR_TO_COURSE = 'ADD_MENTOR_TO_COURSE',
  ASSIGN_LS_STAFF = 'ASSIGN_LS_STAFF',
  CANCEL_ANY_MENTOR_SESSION = 'CANCEL_ANY_MENTOR_SESSION',
  CANCEL_ANY_MENTOR_SESSION_BOOKING_REQUEST = 'CANCEL_ANY_MENTOR_SESSION_BOOKING_REQUEST',
  CANCEL_ANY_MENTOR_SESSION_EVENT = 'CANCEL_ANY_MENTOR_SESSION_EVENT',
  CANCEL_MENTOR_SESSION_EVENT_RESCHEDULE_REQUEST = 'CANCEL_MENTOR_SESSION_EVENT_RESCHEDULE_REQUEST',
  CANCEL_SELF_MENTOR_SESSION_EVENT = 'CANCEL_SELF_MENTOR_SESSION_EVENT',
  CREATE_APP_TOKEN = 'CREATE_APP_TOKEN',
  CREATE_CHAT_CHANNEL = 'CREATE_CHAT_CHANNEL',
  CREATE_CM_PROGRESS_REPORT = 'CREATE_CM_PROGRESS_REPORT',
  CREATE_COURSE = 'CREATE_COURSE',
  CREATE_COURSE_JOB = 'CREATE_COURSE_JOB',
  CREATE_COURSE_STAFF = 'CREATE_COURSE_STAFF',
  CREATE_CUSTOM_FIELD = 'CREATE_CUSTOM_FIELD',
  CREATE_DEAL_ENROLLMENT = 'CREATE_DEAL_ENROLLMENT',
  CREATE_LEARNER = 'CREATE_LEARNER',
  CREATE_MENTOR = 'CREATE_MENTOR',
  CREATE_MENTOR_SESSION = 'CREATE_MENTOR_SESSION',
  CREATE_MENTOR_SESSION_BOOKING_REQUEST = 'CREATE_MENTOR_SESSION_BOOKING_REQUEST',
  CREATE_MENTOR_SESSION_EVENT = 'CREATE_MENTOR_SESSION_EVENT',
  CREATE_MENTOR_SESSION_EVENT_RESCHEDULE_REQUEST = 'CREATE_MENTOR_SESSION_EVENT_RESCHEDULE_REQUEST',
  CREATE_MENTOR_SESSION_RECAP = 'CREATE_MENTOR_SESSION_RECAP',
  CREATE_NON_GENERAL_CHAT_CHANNEL = 'CREATE_NON_GENERAL_CHAT_CHANNEL',
  CREATE_NON_PRIVATE_CHAT_CHANNEL = 'CREATE_NON_PRIVATE_CHAT_CHANNEL',
  CREATE_USER = 'CREATE_USER',
  DELETE_APP_TOKEN = 'DELETE_APP_TOKEN',
  DELETE_COURSE = 'DELETE_COURSE',
  DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD',
  DELETE_MENTOR_SESSION = 'DELETE_MENTOR_SESSION',
  DROP_MEMBER = 'DROP_MEMBER',
  ENROLL_FREE_LEARNER = 'ENROLL_FREE_LEARNER',
  ENROLL_LEARNER = 'ENROLL_LEARNER',
  ENROLL_PENDING_MEMBER = 'ENROLL_PENDING_MEMBER',
  EXPERIMENTAL = 'EXPERIMENTAL',
  GENERATE_ENROLLMENT_BATCHES = 'GENERATE_ENROLLMENT_BATCHES',
  GET_LW_DATA = 'GET_LW_DATA',
  GRADUATE_MEMBER = 'GRADUATE_MEMBER',
  IMPORT_MENTORS = 'IMPORT_MENTORS',
  JOIN_ANY_MENTOR_SESSION = 'JOIN_ANY_MENTOR_SESSION',
  JOIN_ANY_MENTOR_SESSION_CHAT_CHANNEL = 'JOIN_ANY_MENTOR_SESSION_CHAT_CHANNEL',
  LIST_ALL_MENTOR_SESSION_BOOKING_REQUESTS = 'LIST_ALL_MENTOR_SESSION_BOOKING_REQUESTS',
  LIST_APP_TOKEN = 'LIST_APP_TOKEN',
  LIST_COURSE_JOB = 'LIST_COURSE_JOB',
  LIST_COURSE_MEMBER = 'LIST_COURSE_MEMBER',
  LIST_DISCORD_CHANNELS = 'LIST_DISCORD_CHANNELS',
  LIST_FULL_COURSE_JOB = 'LIST_FULL_COURSE_JOB',
  LIST_LS_STAFF = 'LIST_LS_STAFF',
  LIST_MENTOR_SESSION = 'LIST_MENTOR_SESSION',
  LIST_SALES_DEALS = 'LIST_SALES_DEALS',
  LIST_SELF_MENTOR_SESSION = 'LIST_SELF_MENTOR_SESSION',
  LIST_SELF_MENTOR_SESSION_BOOKING_REQUESTS = 'LIST_SELF_MENTOR_SESSION_BOOKING_REQUESTS',
  LIST_SELF_USER_REQUESTS = 'LIST_SELF_USER_REQUESTS',
  LIST_USERS = 'LIST_USERS',
  MANAGE_EVENT_AGENDA = 'MANAGE_EVENT_AGENDA',
  MANAGE_LEARNER_CUSTOM_FIELDS = 'MANAGE_LEARNER_CUSTOM_FIELDS',
  MANAGE_MENTORS = 'MANAGE_MENTORS',
  MANAGE_REVIEW_CAMPAIGNS = 'MANAGE_REVIEW_CAMPAIGNS',
  MARK_EVENT_AS_FINISHED = 'MARK_EVENT_AS_FINISHED',
  POSTPONE_MEMBER = 'POSTPONE_MEMBER',
  REACTIVATE_MEMBER = 'REACTIVATE_MEMBER',
  READ_ANY_MENTOR_SESSION_CHAT_CHANNEL_MESSAGES = 'READ_ANY_MENTOR_SESSION_CHAT_CHANNEL_MESSAGES',
  RESCHEDULE_MENTOR_SESSION_EVENT = 'RESCHEDULE_MENTOR_SESSION_EVENT',
  RESCHEDULE_MENTOR_SESSION_EVENT_BATCH = 'RESCHEDULE_MENTOR_SESSION_EVENT_BATCH',
  RESCHEDULE_SELF_MENTOR_SESSION_EVENT_BATCH = 'RESCHEDULE_SELF_MENTOR_SESSION_EVENT_BATCH',
  RESPOND_MENTOR_BOOKING_REQUEST = 'RESPOND_MENTOR_BOOKING_REQUEST',
  RESPONSE_MENTOR_SESSION_EVENT_RESCHEDULE_REQUEST = 'RESPONSE_MENTOR_SESSION_EVENT_RESCHEDULE_REQUEST',
  RESTRICTED = 'RESTRICTED',
  SCHEDULED_NOTIFIER = 'SCHEDULED_NOTIFIER',
  SEND_ANY_USER_RESET_PASSWORD = 'SEND_ANY_USER_RESET_PASSWORD',
  SEND_MESSAGE_TO_ANY_MENTOR_SESSION_CHAT_CHANNEL = 'SEND_MESSAGE_TO_ANY_MENTOR_SESSION_CHAT_CHANNEL',
  SYNC_LEARNERS_FROM_SHEETS = 'SYNC_LEARNERS_FROM_SHEETS',
  SYNC_LW_CM_PROGRESS = 'SYNC_LW_CM_PROGRESS',
  SYNC_MENTORS_FROM_SHEETS = 'SYNC_MENTORS_FROM_SHEETS',
  SYNC_SALES_DEALS = 'SYNC_SALES_DEALS',
  UPDATE_ANY_MENTOR_SESSION = 'UPDATE_ANY_MENTOR_SESSION',
  UPDATE_APP_TOKEN = 'UPDATE_APP_TOKEN',
  UPDATE_COURSE = 'UPDATE_COURSE',
  UPDATE_COURSE_CONTENTS = 'UPDATE_COURSE_CONTENTS',
  UPDATE_COURSE_JOB = 'UPDATE_COURSE_JOB',
  UPDATE_CUSTOM_FIELD = 'UPDATE_CUSTOM_FIELD',
  UPDATE_ENROLLMENT_BATCHES = 'UPDATE_ENROLLMENT_BATCHES',
  UPDATE_LEARNER = 'UPDATE_LEARNER',
  UPDATE_LEARNER_CODE = 'UPDATE_LEARNER_CODE',
  UPDATE_LEARNER_TAGS = 'UPDATE_LEARNER_TAGS',
  UPDATE_MENTOR = 'UPDATE_MENTOR',
  UPDATE_MENTOR_AVAILABILITY_SCHEDULES = 'UPDATE_MENTOR_AVAILABILITY_SCHEDULES',
  UPDATE_MENTOR_SESSION = 'UPDATE_MENTOR_SESSION',
  UPDATE_ORG_EMPLOYEES = 'UPDATE_ORG_EMPLOYEES',
  UPDATE_SELF_AVAILABILITY_SCHEDULES = 'UPDATE_SELF_AVAILABILITY_SCHEDULES',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_TAGS = 'UPDATE_USER_TAGS',
  VIEW_ANY_CHAT_CHANNEL = 'VIEW_ANY_CHAT_CHANNEL',
  VIEW_ANY_DISCORD_MESSAGE = 'VIEW_ANY_DISCORD_MESSAGE',
  VIEW_ANY_INSTANT_MEETING = 'VIEW_ANY_INSTANT_MEETING',
  VIEW_ANY_MENTOR_PAYMENT_INFO = 'VIEW_ANY_MENTOR_PAYMENT_INFO',
  VIEW_ANY_MENTOR_SESSION = 'VIEW_ANY_MENTOR_SESSION',
  VIEW_CM_PROGRESS_REPORT = 'VIEW_CM_PROGRESS_REPORT',
  VIEW_COURSE = 'VIEW_COURSE',
  VIEW_COURSE_JOB = 'VIEW_COURSE_JOB',
  VIEW_LEARNER = 'VIEW_LEARNER',
  VIEW_MENTOR_SESSION = 'VIEW_MENTOR_SESSION',
  VIEW_MENTOR_TIMESHEET = 'VIEW_MENTOR_TIMESHEET',
  VIEW_ORG_EMPLOYEES = 'VIEW_ORG_EMPLOYEES',
  VIEW_SELF_INSTANT_MEETING = 'VIEW_SELF_INSTANT_MEETING',
  VIEW_SELF_MENTOR_SESSION = 'VIEW_SELF_MENTOR_SESSION',
  VIEW_SELF_SCHOOL_EVENT = 'VIEW_SELF_SCHOOL_EVENT',
  VIEW_USER = 'VIEW_USER',
}

export type PersonalizeCourse = {
  __typename?: 'PersonalizeCourse'
  courseRefId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  topics: Array<Scalars['String']['output']>
  updatedAt: Scalars['Date']['output']
}

export type PersonalizeCourseConnection = {
  __typename?: 'PersonalizeCourseConnection'
  edges?: Maybe<Array<PersonalizeCourseEdge>>
  nodes?: Maybe<Array<PersonalizeCourse>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type PersonalizeCourseEdge = {
  __typename?: 'PersonalizeCourseEdge'
  cursor: Scalars['String']['output']
  node: PersonalizeCourse
}

export enum PipedriveDealStatus {
  LOST = 'LOST',
  OPEN = 'OPEN',
  WON = 'WON',
}

export type PostponeCourseMemberInput = {
  postponeDate?: InputMaybe<Scalars['Date']['input']>
  postponeReason?: InputMaybe<Scalars['String']['input']>
  returnDate?: InputMaybe<Scalars['Date']['input']>
}

export type Query = {
  __typename?: 'Query'
  activeLsStaff: Array<User>
  activeReviewCampaigns: Array<ReviewCampaign>
  activityLogConnection: ActivityLogConnection
  activityLogTopicConnection: ActivityLogConnection
  activityTypes: Array<ActivityType>
  appTokens: Array<AppToken>
  availableMentorCourseMembers: Array<CourseMember>
  availableTimeSlots: TimeSlots
  calendar: Calendar
  canUserUpdateCalendar: Scalars['Boolean']['output']
  chatChannels: Array<ChatChannel>
  chatMessageConnection: ChatMessageConnection
  comments: Array<Comment>
  course?: Maybe<Course>
  courseConnection: CourseConnection
  courseDurationOptions: Array<CourseDurationOption>
  courseJob?: Maybe<CourseJob>
  courseJobCompanyLogo?: Maybe<CloudFile>
  courseJobConnection: CourseJobConnection
  courseJobCreators: Array<User>
  courseJobStats: CourseJobStats
  courseJobs: Array<CourseJob>
  courseMember?: Maybe<CourseMember>
  courseMemberConnection: CourseMemberConnection
  courseMembers: Array<CourseMember>
  courseMembersWithNoSessions: Array<CourseMember>
  courseStaffConnection: CourseStaffConnection
  courseSubscribeConnection: CourseSubscribeConnection
  courses: Array<Course>
  currentMentorSessionEvent?: Maybe<MentorSessionEvent>
  currentUser?: Maybe<AuthUser>
  customFields: Array<CustomField>
  deal?: Maybe<Deal>
  dealConnection: DealConnection
  demoSessionRecap?: Maybe<DemoSessionRecap>
  demoSessionRecapConnection: DemoSessionRecapConnection
  employeeProfiles: Array<EmployeeProfile>
  employeeTeam?: Maybe<EmployeeTeam>
  employeeTeamMember: EmployeeTeamMember
  employeeTeamMembers: Array<EmployeeTeamMember>
  employeeTeams: Array<EmployeeTeam>
  eventAgenda?: Maybe<EventAgenda>
  findLearnerMembersByMentorId: Array<CourseMemberWithNextMentorSessionEvent>
  findNoteByInstantMeeting?: Maybe<Note>
  findNoteByMentorSessionEvent?: Maybe<Note>
  getAllEnrollmentBatches?: Maybe<Array<EnrollmentBatch>>
  getCourseMemberDropActivityLog: CourseMemberDropStatusActivityLog
  getCourseMemberGraduateActivityLog: CourseMemberGraduateStatusActivityLog
  getCourseMemberPostponeActivityLog: CourseMemberPostponeStatusActivityLog
  getCourseMemberProgressReport?: Maybe<CourseMemberProgressReport>
  getPipedriveDeals: Scalars['JSON']['output']
  instantMeeting?: Maybe<InstantMeeting>
  instantMeetingConnection: InstantMeetingConnection
  instantMeetingRoomInfo?: Maybe<InstantMeetingRoomInfo>
  instantMeetings: Array<InstantMeeting>
  latestCourseMemberMentorSession?: Maybe<MentorSession>
  latestLearnerLwSSOActivity?: Maybe<ActivityLog>
  latestLearnerMentorSessions?: Maybe<Array<MentorSession>>
  learner?: Maybe<Learner>
  learnerByUserId?: Maybe<Learner>
  learnerConnection: LearnerConnection
  learnerCourseMembers: LearnerCourseMemberOffsetBasedPagination
  learnerIncident?: Maybe<LearnerIncident>
  learnerIncidentConnection: LearnerIncidentConnection
  learnerIncidentReportStatus?: Maybe<LearnerIncidentReportStatus>
  learnerMembersAvailableForBookingRequest: Array<CourseMember>
  lwCourse?: Maybe<LwCourse>
  lwCourses: Array<LwCourse>
  /** @deprecated Use mentorSessionMeetingRoomInfo instead */
  meetingRoomInfo?: Maybe<MeetingRoomInfo>
  mentor?: Maybe<Mentor>
  mentorByUserId?: Maybe<Mentor>
  mentorConnection: MentorConnection
  mentorSession?: Maybe<MentorSession>
  mentorSessionAvailabilitySchedules: Array<MentorSessionAvailabilitySchedule>
  mentorSessionBookingRequest?: Maybe<MentorSessionBookingRequest>
  mentorSessionBookingRequestConnection: MentorSessionBookingRequestConnection
  mentorSessionBookingRequests: Array<MentorSessionBookingRequest>
  mentorSessionConnection: MentorSessionConnection
  mentorSessionEvent: MentorSessionEvent
  mentorSessionEventAgendas: Array<MentorSessionEventAgenda>
  mentorSessionEventConnection: MentorSessionEventConnection
  /** @deprecated This connection doesn't support pagination by `startsAt` and doesn't follow the standards. Use `mentorSessionEventConnection` instead. */
  mentorSessionEventConnection_deprecated: MentorSessionEventConnection
  mentorSessionEventRescheduleRequest?: Maybe<MentorSessionEventRescheduleRequest>
  mentorSessionEventRescheduleRequests: Array<MentorSessionEventRescheduleRequest>
  mentorSessionEvents: Array<MentorSessionEvent>
  mentorSessionMeetingRoomInfo?: Maybe<MeetingRoomInfo>
  mentorSessionRecap?: Maybe<MentorSessionRecap>
  mentorSessionRecapReport: MentorSessionRecapReport
  mentorSessionRecaps: Array<MentorSessionRecap>
  mentorSessionTimeSuggestions: Array<MentorSessionTimeSuggestion>
  mentorSessions: Array<MentorSession>
  mentorTimesheet: Array<MentorTimesheetItem>
  /** Get learner by the current user */
  myLearner?: Maybe<Learner>
  /** Get mentor by the current user */
  myMentor?: Maybe<Mentor>
  nextEvent?: Maybe<MentorSessionEvent>
  note: Note
  numberOfAvailableTASessionsLeft?: Maybe<Scalars['Float']['output']>
  personalizeCourse?: Maybe<PersonalizeCourse>
  personalizeCourseConnection: PersonalizeCourseConnection
  previousEvent?: Maybe<MentorSessionEvent>
  recurringInstantMeetings: Array<InstantMeeting>
  reviewCampaign?: Maybe<ReviewCampaign>
  reviewCampaignParticipant?: Maybe<ReviewCampaignParticipant>
  reviewCampaignParticipants: Array<ReviewCampaignParticipant>
  reviewCampaignQuestion: ReviewCampaignQuestion
  reviewCampaignQuestions: Array<ReviewCampaignQuestion>
  reviewCampaignTimelineItems: Array<ReviewCampaignTimelineItem>
  reviewCampaigns: ReviewCampaignOffsetBasedPagination
  reviewRequest?: Maybe<ReviewRequest>
  reviewRequests: Array<ReviewRequest>
  reviewResponse?: Maybe<ReviewResponse>
  reviewResponses: Array<ReviewResponse>
  secretSanta?: Maybe<SecretSanta>
  secretSantaPairs: Array<SecretSanta>
  secretSantaStats: SecretSantaStats
  secretSantee?: Maybe<SecretSanta>
  selfInstantMeeting?: Maybe<InstantMeeting>
  selfInstantMeetingConnection: InstantMeetingConnection
  selfMentorSessionEventConnection: MentorSessionEventConnection
  selfSchoolEventConnection: SchoolEventConnection
  staffByRole: Array<CourseStaff>
  suitableMentorsForMentorSession: Array<SuitableCourseMember>
  user?: Maybe<User>
  userCalendar?: Maybe<UserCalendar>
  userCalendars: Array<UserCalendar>
  userConnection: UserConnection
  userEvent?: Maybe<UserEvent>
  userEvents: Array<UserEvent>
  userRequest?: Maybe<UserRequest>
  userRequests: Array<UserRequest>
  users: UserOffsetBasedPagination
  usersWithZoomAccount: Array<User>
  wishlistItemConnection: WishlistItemConnection
  workplaceStatistic: WorkplaceStatistic
  zohoProducts: Array<ZohoProduct>
}

export type QueryActiveReviewCampaignsArgs = {
  access?: InputMaybe<Array<ReviewCampaignAccess>>
}

export type QueryActivityLogConnectionArgs = {
  activities?: InputMaybe<Array<Scalars['String']['input']>>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Scalars['Date']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  performedBy?: InputMaybe<Array<Scalars['ID']['input']>>
  searchText?: InputMaybe<Scalars['String']['input']>
  targetId?: InputMaybe<Scalars['ID']['input']>
  to?: InputMaybe<Scalars['Date']['input']>
}

export type QueryActivityLogTopicConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  targetId: Scalars['ID']['input']
  topic: ActivityLogTopic
}

export type QueryAvailableMentorCourseMembersArgs = {
  courseId: Scalars['ID']['input']
  intervals: Array<TimeInterval>
}

export type QueryAvailableTimeSlotsArgs = {
  courseId: Scalars['ID']['input']
  slotDuration: Scalars['Float']['input']
}

export type QueryCalendarArgs = {
  id: Scalars['ID']['input']
}

export type QueryCanUserUpdateCalendarArgs = {
  calendarId: Scalars['ID']['input']
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryChatMessageConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  channelId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCommentsArgs = {
  before?: InputMaybe<Scalars['ID']['input']>
  discussionId?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  replyToCommentId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryCourseArgs = {
  id: Scalars['ID']['input']
}

export type QueryCourseConnectionArgs = {
  access?: InputMaybe<CourseAccess>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  createdByUserId?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  lwCourseId?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type QueryCourseDurationOptionsArgs = {
  courseId: Scalars['ID']['input']
}

export type QueryCourseJobArgs = {
  id: Scalars['ID']['input']
}

export type QueryCourseJobCompanyLogoArgs = {
  fileId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryCourseJobConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  courseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdByUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdFrom?: InputMaybe<Scalars['String']['input']>
  createdTo?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  isHidden?: InputMaybe<Scalars['Boolean']['input']>
  jobLevels?: InputMaybe<Array<CourseJobLevel>>
  last?: InputMaybe<Scalars['Int']['input']>
  locations?: InputMaybe<Array<CourseJobLocation>>
  salaryFrom?: InputMaybe<Scalars['Float']['input']>
  salaryNegotiable?: InputMaybe<Scalars['Boolean']['input']>
  salaryTo?: InputMaybe<Scalars['Float']['input']>
  skillsRequirement?: InputMaybe<Array<Scalars['String']['input']>>
  status?: InputMaybe<CourseJobStatus>
  textSearch?: InputMaybe<Scalars['String']['input']>
}

export type QueryCourseJobsArgs = {
  courseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdByUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdFrom?: InputMaybe<Scalars['String']['input']>
  createdTo?: InputMaybe<Scalars['String']['input']>
  isHidden?: InputMaybe<Scalars['Boolean']['input']>
  jobLevels?: InputMaybe<Array<CourseJobLevel>>
  locations?: InputMaybe<Array<CourseJobLocation>>
  orderBy?: InputMaybe<Scalars['String']['input']>
  salaryFrom?: InputMaybe<Scalars['Float']['input']>
  salaryNegotiable?: InputMaybe<Scalars['Boolean']['input']>
  salaryTo?: InputMaybe<Scalars['Float']['input']>
  skillsRequirement?: InputMaybe<Array<Scalars['String']['input']>>
  skip?: InputMaybe<Scalars['Float']['input']>
  status?: InputMaybe<CourseJobStatus>
  take?: InputMaybe<Scalars['Float']['input']>
  textSearch?: InputMaybe<Scalars['String']['input']>
}

export type QueryCourseMemberArgs = {
  id: Scalars['ID']['input']
}

export type QueryCourseMemberConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  courseId?: InputMaybe<Scalars['ID']['input']>
  enrollmentDate?: InputMaybe<Scalars['Date']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  learnerId?: InputMaybe<Scalars['ID']['input']>
  mentorId?: InputMaybe<Scalars['ID']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<CourseMemberStatus>
  type?: InputMaybe<CourseMemberType>
}

export type QueryCourseMembersArgs = {
  courseId?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<CourseMemberStatus>
  type?: InputMaybe<CourseMemberType>
}

export type QueryCourseMembersWithNoSessionsArgs = {
  courseId?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<CourseMemberType>
}

export type QueryCourseStaffConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  courseId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<CourseStaffStatus>
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryCourseSubscribeConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCurrentMentorSessionEventArgs = {
  mentorSessionId: Scalars['ID']['input']
}

export type QueryCustomFieldsArgs = {
  targetGroup: CustomFieldTargetGroup
}

export type QueryDealArgs = {
  id: Scalars['ID']['input']
}

export type QueryDealConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<PipedriveDealStatus>
}

export type QueryDemoSessionRecapArgs = {
  courseRefId: Scalars['ID']['input']
}

export type QueryDemoSessionRecapConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryEmployeeProfilesArgs = {
  sort?: InputMaybe<Scalars['String']['input']>
}

export type QueryEmployeeTeamArgs = {
  id: Scalars['ID']['input']
}

export type QueryEmployeeTeamMemberArgs = {
  id: Scalars['ID']['input']
}

export type QueryEmployeeTeamMembersArgs = {
  employeeProfileId?: InputMaybe<Scalars['ID']['input']>
  lineManagerId?: InputMaybe<Scalars['ID']['input']>
  sort?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryEmployeeTeamsArgs = {
  sort?: InputMaybe<Scalars['String']['input']>
}

export type QueryEventAgendaArgs = {
  id: Scalars['ID']['input']
}

export type QueryFindLearnerMembersByMentorIdArgs = {
  courseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  courseMemberStatus?: InputMaybe<Array<CourseMemberStatus>>
  learningVelocity?: InputMaybe<LearningVelocity>
  mentorId: Scalars['ID']['input']
  searchText?: InputMaybe<Scalars['String']['input']>
}

export type QueryFindNoteByInstantMeetingArgs = {
  instantMeetingId: Scalars['ID']['input']
}

export type QueryFindNoteByMentorSessionEventArgs = {
  calendarEventId: Scalars['ID']['input']
}

export type QueryGetCourseMemberDropActivityLogArgs = {
  courseMemberId: Scalars['ID']['input']
}

export type QueryGetCourseMemberGraduateActivityLogArgs = {
  courseMemberId: Scalars['ID']['input']
}

export type QueryGetCourseMemberPostponeActivityLogArgs = {
  courseMemberId: Scalars['ID']['input']
}

export type QueryGetCourseMemberProgressReportArgs = {
  courseMemberId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['Date']['input']>
  startDate?: InputMaybe<Scalars['Date']['input']>
}

export type QueryInstantMeetingArgs = {
  id: Scalars['ID']['input']
}

export type QueryInstantMeetingConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  anchor?: InputMaybe<Scalars['Date']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  isExactDate?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  participantUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sort?: InputMaybe<Scalars['String']['input']>
  statuses?: InputMaybe<Array<InstantMeetingStatus>>
  types?: InputMaybe<Array<InstantMeetingType>>
}

export type QueryInstantMeetingRoomInfoArgs = {
  meetingId: Scalars['ID']['input']
}

export type QueryInstantMeetingsArgs = {
  anchor?: InputMaybe<Scalars['Date']['input']>
  isExactDate?: InputMaybe<Scalars['Boolean']['input']>
  participantUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  statuses?: InputMaybe<Array<InstantMeetingStatus>>
  types?: InputMaybe<Array<InstantMeetingType>>
}

export type QueryLatestCourseMemberMentorSessionArgs = {
  courseMemberId: Scalars['ID']['input']
}

export type QueryLatestLearnerLwSsoActivityArgs = {
  learnerId: Scalars['ID']['input']
}

export type QueryLatestLearnerMentorSessionsArgs = {
  learnerId: Scalars['ID']['input']
}

export type QueryLearnerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryLearnerByUserIdArgs = {
  userId: Scalars['ID']['input']
}

export type QueryLearnerConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  courseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  courseMemberStatus?: InputMaybe<Array<CourseMemberStatus>>
  enrollmentDate?: InputMaybe<Scalars['Date']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  learnerIds?: InputMaybe<Array<Scalars['ID']['input']>>
  learningVelocity?: InputMaybe<LearningVelocity>
  searchText?: InputMaybe<Scalars['String']['input']>
  userStatus?: InputMaybe<UserStatus>
}

export type QueryLearnerCourseMembersArgs = {
  courseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  enrollmentDate?: InputMaybe<Scalars['Date']['input']>
  learningVelocity?: InputMaybe<LearningVelocity>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<Scalars['String']['input']>
  statuses?: InputMaybe<Array<CourseMemberStatus>>
}

export type QueryLearnerIncidentArgs = {
  id: Scalars['ID']['input']
}

export type QueryLearnerIncidentConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  courseId?: InputMaybe<Array<Scalars['ID']['input']>>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  severity?: InputMaybe<Array<IncidentSeverity>>
  status?: InputMaybe<Array<LearnerIncidentStatus>>
  type?: InputMaybe<Array<LearnerIncidentType>>
}

export type QueryLearnerMembersAvailableForBookingRequestArgs = {
  courseId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryLwCourseArgs = {
  id: Scalars['ID']['input']
}

export type QueryMeetingRoomInfoArgs = {
  availableToJoinOnly?: InputMaybe<Scalars['Boolean']['input']>
  mentorSessionId: Scalars['ID']['input']
}

export type QueryMentorArgs = {
  id: Scalars['ID']['input']
}

export type QueryMentorByUserIdArgs = {
  userId: Scalars['ID']['input']
}

export type QueryMentorConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  courseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  mentorIds?: InputMaybe<Array<Scalars['ID']['input']>>
  searchText?: InputMaybe<Scalars['String']['input']>
  statuses?: InputMaybe<Array<MentorStatus>>
  types?: InputMaybe<Array<MentorType>>
}

export type QueryMentorSessionArgs = {
  id: Scalars['ID']['input']
}

export type QueryMentorSessionAvailabilitySchedulesArgs = {
  targetId?: InputMaybe<Scalars['ID']['input']>
  targetIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryMentorSessionBookingRequestArgs = {
  userRequestId: Scalars['ID']['input']
}

export type QueryMentorSessionBookingRequestConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  courseId?: InputMaybe<Scalars['ID']['input']>
  end?: InputMaybe<Scalars['Date']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  learnerIds?: InputMaybe<Array<Scalars['ID']['input']>>
  mentorIds?: InputMaybe<Array<Scalars['ID']['input']>>
  searchText?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['Date']['input']>
  status?: InputMaybe<Array<UserRequestStatus>>
}

export type QueryMentorSessionBookingRequestsArgs = {
  courseId?: InputMaybe<Scalars['ID']['input']>
  end?: InputMaybe<Scalars['Date']['input']>
  learnerIds?: InputMaybe<Array<Scalars['ID']['input']>>
  mentorIds?: InputMaybe<Array<Scalars['ID']['input']>>
  searchText?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['Date']['input']>
  status?: InputMaybe<Array<UserRequestStatus>>
}

export type QueryMentorSessionConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  courseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  enrollmentDate?: InputMaybe<Scalars['Date']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  learnerIds?: InputMaybe<Array<Scalars['ID']['input']>>
  mentorIds?: InputMaybe<Array<Scalars['ID']['input']>>
  startDate?: InputMaybe<Scalars['Date']['input']>
  statuses?: InputMaybe<Array<MentorSessionStatus>>
}

export type QueryMentorSessionEventArgs = {
  id: Scalars['ID']['input']
}

export type QueryMentorSessionEventAgendasArgs = {
  mentorSessionEventId: Scalars['ID']['input']
}

export type QueryMentorSessionEventConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  anchorDate?: InputMaybe<Scalars['Date']['input']>
  anchorEventId?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  courseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  learnerId?: InputMaybe<Scalars['ID']['input']>
  mentorId?: InputMaybe<Scalars['ID']['input']>
  mentorSessionId?: InputMaybe<Scalars['ID']['input']>
  participantUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  queryKey?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<MentorSessionEventStatus>>
}

export type QueryMentorSessionEventConnection_DeprecatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Scalars['Date']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  learnerId?: InputMaybe<Scalars['ID']['input']>
  mentorId?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<Array<MentorSessionEventStatus>>
  to?: InputMaybe<Scalars['Date']['input']>
}

export type QueryMentorSessionEventRescheduleRequestArgs = {
  userRequestId: Scalars['ID']['input']
}

export type QueryMentorSessionEventRescheduleRequestsArgs = {
  mentorSessionEventIds?: InputMaybe<Array<Scalars['ID']['input']>>
  recipientUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  searchText?: InputMaybe<Scalars['String']['input']>
  statuses?: InputMaybe<Array<UserRequestStatus>>
}

export type QueryMentorSessionEventsArgs = {
  from?: InputMaybe<Scalars['Date']['input']>
  learnerId?: InputMaybe<Scalars['ID']['input']>
  mentorId?: InputMaybe<Scalars['ID']['input']>
  mentorSessionId?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<Array<MentorSessionEventStatus>>
  to?: InputMaybe<Scalars['Date']['input']>
}

export type QueryMentorSessionMeetingRoomInfoArgs = {
  availableToJoinOnly?: InputMaybe<Scalars['Boolean']['input']>
  mentorSessionId: Scalars['ID']['input']
}

export type QueryMentorSessionRecapArgs = {
  id: Scalars['ID']['input']
}

export type QueryMentorSessionRecapReportArgs = {
  mentorSessionId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryMentorSessionRecapsArgs = {
  courseMemberId?: InputMaybe<Scalars['ID']['input']>
  learnerId?: InputMaybe<Scalars['ID']['input']>
  mentorId?: InputMaybe<Scalars['ID']['input']>
  mentorSessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  type?: InputMaybe<MentorSessionRecapType>
}

export type QueryMentorSessionTimeSuggestionsArgs = {
  courseId: Scalars['ID']['input']
  durationInMinutes: Scalars['Int']['input']
  endsAfterMonths?: InputMaybe<Scalars['Int']['input']>
  intervalsFrom?: InputMaybe<Scalars['Date']['input']>
  learnerCourseMemberId: Scalars['String']['input']
  mentorCourseMemberId: Scalars['String']['input']
}

export type QueryMentorSessionsArgs = {
  courseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  courseMemberId?: InputMaybe<Scalars['ID']['input']>
  learnerId?: InputMaybe<Scalars['ID']['input']>
  mentorId?: InputMaybe<Scalars['ID']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<MentorSessionStatus>>
}

export type QueryMentorTimesheetArgs = {
  from?: InputMaybe<Scalars['Date']['input']>
  to?: InputMaybe<Scalars['Date']['input']>
}

export type QueryNextEventArgs = {
  eventId: Scalars['ID']['input']
}

export type QueryNoteArgs = {
  id: Scalars['ID']['input']
}

export type QueryPersonalizeCourseArgs = {
  courseRefId: Scalars['ID']['input']
}

export type QueryPersonalizeCourseConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryPreviousEventArgs = {
  eventId: Scalars['ID']['input']
}

export type QueryRecurringInstantMeetingsArgs = {
  id: Scalars['ID']['input']
}

export type QueryReviewCampaignArgs = {
  id: Scalars['ID']['input']
}

export type QueryReviewCampaignParticipantArgs = {
  campaignId?: InputMaybe<Scalars['ID']['input']>
  employeeProfileId?: InputMaybe<Scalars['ID']['input']>
  employeeTeamId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type QueryReviewCampaignParticipantsArgs = {
  campaignId?: InputMaybe<Scalars['ID']['input']>
  employeeProfileId?: InputMaybe<Scalars['ID']['input']>
  employeeTeamId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryReviewCampaignQuestionArgs = {
  id: Scalars['ID']['input']
}

export type QueryReviewCampaignQuestionsArgs = {
  askedTo?: InputMaybe<Array<ReviewCampaignQuestionAskedTo>>
  campaignId: Scalars['ID']['input']
}

export type QueryReviewCampaignTimelineItemsArgs = {
  campaignId: Scalars['ID']['input']
}

export type QueryReviewCampaignsArgs = {
  access?: InputMaybe<Array<ReviewCampaignAccess>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<ReviewCampaignStatus>
}

export type QueryReviewRequestArgs = {
  id: Scalars['ID']['input']
}

export type QueryReviewRequestsArgs = {
  campaignId: Scalars['ID']['input']
}

export type QueryReviewResponseArgs = {
  id: Scalars['ID']['input']
}

export type QueryReviewResponsesArgs = {
  access?: InputMaybe<Array<ReviewCampaignAccess>>
  campaignId?: InputMaybe<Scalars['ID']['input']>
  employeeProfileId?: InputMaybe<Scalars['ID']['input']>
  selfOnly?: InputMaybe<Scalars['Boolean']['input']>
}

export type QuerySecretSantaArgs = {
  userId: Scalars['ID']['input']
}

export type QuerySecretSanteeArgs = {
  userId: Scalars['ID']['input']
}

export type QuerySelfInstantMeetingArgs = {
  id: Scalars['ID']['input']
}

export type QuerySelfInstantMeetingConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  anchor?: InputMaybe<Scalars['Date']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  isExactDate?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  participantUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sort?: InputMaybe<Scalars['String']['input']>
  statuses?: InputMaybe<Array<InstantMeetingStatus>>
  types?: InputMaybe<Array<InstantMeetingType>>
}

export type QuerySelfMentorSessionEventConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  anchorDate?: InputMaybe<Scalars['Date']['input']>
  anchorEventId?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  courseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  learnerId?: InputMaybe<Scalars['ID']['input']>
  mentorId?: InputMaybe<Scalars['ID']['input']>
  mentorSessionId?: InputMaybe<Scalars['ID']['input']>
  participantUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  queryKey?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<MentorSessionEventStatus>>
}

export type QuerySelfSchoolEventConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  anchorDate?: InputMaybe<Scalars['Date']['input']>
  anchorEventId?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  instantMeeting?: InputMaybe<SchoolEventImFilter>
  last?: InputMaybe<Scalars['Int']['input']>
  mentorSessionEvent?: InputMaybe<SchoolEventMseFilter>
  queryKey?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<SchoolEventType>
}

export type QueryStaffByRoleArgs = {
  courseId: Scalars['ID']['input']
  roles: Array<UserRole>
}

export type QuerySuitableMentorsForMentorSessionArgs = {
  courseId: Scalars['ID']['input']
  learnerCourseMemberId: Scalars['ID']['input']
}

export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type QueryUserCalendarArgs = {
  calendarId: Scalars['ID']['input']
}

export type QueryUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  excludedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  first?: InputMaybe<Scalars['Int']['input']>
  includedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
  roles?: InputMaybe<Array<UserRole>>
  searchText?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<UserStatus>
}

export type QueryUserEventArgs = {
  eventId: Scalars['ID']['input']
}

export type QueryUserEventsArgs = {
  between?: InputMaybe<Array<Scalars['Date']['input']>>
  calendarIds?: InputMaybe<Array<Scalars['ID']['input']>>
  eventId?: InputMaybe<Scalars['ID']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  statuses?: InputMaybe<Array<UserEventStatus>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryUserRequestArgs = {
  id: Scalars['ID']['input']
}

export type QueryUserRequestsArgs = {
  from?: InputMaybe<Scalars['Date']['input']>
  recipientUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  searchText?: InputMaybe<Scalars['String']['input']>
  statuses?: InputMaybe<Array<UserRequestStatus>>
  to?: InputMaybe<Scalars['Date']['input']>
  types?: InputMaybe<Array<UserRequestType>>
}

export type QueryUsersArgs = {
  excludedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  includedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  roles?: InputMaybe<Array<UserRole>>
  searchText?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<UserStatus>
}

export type QueryWishlistItemConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  wishlistId: Scalars['ID']['input']
}

/** Input for self registering a new user. Performed by an un-auth user. */
export type RegisterUserInput = {
  email: Scalars['String']['input']
  name: Scalars['String']['input']
  password: Scalars['String']['input']
  phone?: InputMaybe<Scalars['String']['input']>
}

export type RescheduleMentorSessionEventBatchInput = {
  anchorEventId: Scalars['ID']['input']
  mentorSessionId: Scalars['ID']['input']
  newEndsAt: Scalars['Date']['input']
  newStartsAt: Scalars['Date']['input']
  reason?: InputMaybe<Scalars['String']['input']>
}

export type RescheduleMentorSessionEventInput = {
  calendarEventId: Scalars['ID']['input']
  newEndsAt: Scalars['Date']['input']
  newStartsAt: Scalars['Date']['input']
  reason?: InputMaybe<Scalars['String']['input']>
}

export type ReviewCampaign = {
  __typename?: 'ReviewCampaign'
  access: Array<ReviewCampaignAccess>
  createdAt: Scalars['Date']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  participants: Array<ReviewCampaignParticipant>
  stats?: Maybe<ReviewCampaignStats>
  status: ReviewCampaignStatus
  timelines: Array<ReviewCampaignTimelineItem>
  updatedAt: Scalars['Date']['output']
}

export enum ReviewCampaignAccess {
  EMPLOYEES = 'EMPLOYEES',
  LEARNERS = 'LEARNERS',
  MANAGERS = 'MANAGERS',
  MENTORS = 'MENTORS',
}

export type ReviewCampaignOffsetBasedPagination = {
  __typename?: 'ReviewCampaignOffsetBasedPagination'
  nodes: Array<ReviewCampaign>
  pageInfo: OffsetBasedPaginationPageInfo
}

export type ReviewCampaignParticipant = {
  __typename?: 'ReviewCampaignParticipant'
  campaign: ReviewCampaign
  campaignId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  employeeProfile?: Maybe<EmployeeProfile>
  employeeProfileId?: Maybe<Scalars['ID']['output']>
  employeeTeam?: Maybe<EmployeeTeam>
  employeeTeamId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  updatedAt: Scalars['Date']['output']
}

export type ReviewCampaignQuestion = {
  __typename?: 'ReviewCampaignQuestion'
  allowComment: Scalars['Boolean']['output']
  askedTo: Array<ReviewCampaignQuestionAskedTo>
  campaignId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  options: Array<Scalars['String']['output']>
  order: Scalars['Int']['output']
  question: Scalars['String']['output']
  questionType: ReviewCampaignQuestionType
  required: Scalars['Boolean']['output']
  updatedAt: Scalars['Date']['output']
}

export enum ReviewCampaignQuestionAskedTo {
  MANAGER = 'MANAGER',
  REVIEWEE = 'REVIEWEE',
}

export type ReviewCampaignQuestionInput = {
  allowComment: Scalars['Boolean']['input']
  askedTo: Array<ReviewCampaignQuestionAskedTo>
  description?: InputMaybe<Scalars['String']['input']>
  options: Array<Scalars['String']['input']>
  order?: InputMaybe<Scalars['Int']['input']>
  question: Scalars['String']['input']
  questionType: ReviewCampaignQuestionType
  required?: InputMaybe<Scalars['Boolean']['input']>
}

export enum ReviewCampaignQuestionType {
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  PERFORMANCE_RATING = 'PERFORMANCE_RATING',
  TEXT = 'TEXT',
}

export type ReviewCampaignStats = {
  __typename?: 'ReviewCampaignStats'
  assignments: Scalars['Int']['output']
  closeDate?: Maybe<Scalars['Date']['output']>
  completedAssignments: Scalars['Int']['output']
  id: Scalars['ID']['output']
  managerQuestions: Scalars['Int']['output']
  participants: Scalars['Int']['output']
  questions: Scalars['Int']['output']
  revieweeQuestions: Scalars['Int']['output']
  startDate?: Maybe<Scalars['Date']['output']>
}

export enum ReviewCampaignStatus {
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export type ReviewCampaignTimelineItem = {
  __typename?: 'ReviewCampaignTimelineItem'
  campaignId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  date: Scalars['Date']['output']
  id: Scalars['ID']['output']
  text: Scalars['String']['output']
  type: ReviewCampaignTimelineItemType
  updatedAt: Scalars['Date']['output']
}

export enum ReviewCampaignTimelineItemType {
  CAMPAIGN_CLOSE = 'CAMPAIGN_CLOSE',
  CAMPAIGN_FINALIZE = 'CAMPAIGN_FINALIZE',
  CAMPAIGN_LAUNCH = 'CAMPAIGN_LAUNCH',
  MANAGER_REVIEW_CLOSE = 'MANAGER_REVIEW_CLOSE',
  MANAGER_REVIEW_OPEN = 'MANAGER_REVIEW_OPEN',
  REVIEWEE_REVIEW_CLOSE = 'REVIEWEE_REVIEW_CLOSE',
  REVIEWEE_REVIEW_OPEN = 'REVIEWEE_REVIEW_OPEN',
}

export type ReviewRequest = {
  __typename?: 'ReviewRequest'
  campaign: ReviewCampaign
  campaignId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  employeeTeam?: Maybe<EmployeeTeam>
  employeeTeamId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  responses: Array<ReviewResponse>
  reviewTo?: Maybe<EmployeeProfile>
  status: ReviewRequestStatus
  updatedAt: Scalars['Date']['output']
}

export enum ReviewRequestStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  RELEASED = 'RELEASED',
  REVIEWED = 'REVIEWED',
}

export type ReviewResponse = {
  __typename?: 'ReviewResponse'
  campaignAccess: ReviewCampaignAccess
  closeTimelineTypes: Array<ReviewCampaignTimelineItemType>
  createdAt: Scalars['Date']['output']
  employeeProfile?: Maybe<EmployeeProfile>
  employeeProfileId?: Maybe<Scalars['ID']['output']>
  endDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  isOpen: Scalars['Boolean']['output']
  openTimelineTypes: Array<ReviewCampaignTimelineItemType>
  questionItems: Array<ReviewResponseQuestionItem>
  rating?: Maybe<Scalars['String']['output']>
  request: ReviewRequest
  requestId: Scalars['ID']['output']
  signature?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['Date']['output']>
  status: ReviewResponseStatus
  updatedAt: Scalars['Date']['output']
  userId?: Maybe<Scalars['ID']['output']>
}

export type ReviewResponseQuestionItem = {
  __typename?: 'ReviewResponseQuestionItem'
  allowComment: Scalars['Boolean']['output']
  answer?: Maybe<Scalars['String']['output']>
  askedTo: Array<ReviewCampaignQuestionAskedTo>
  comment?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  options: Array<Scalars['String']['output']>
  order: Scalars['Int']['output']
  question: Scalars['String']['output']
  questionType: ReviewCampaignQuestionType
  required: Scalars['Boolean']['output']
  responseId: Scalars['ID']['output']
  updatedAt: Scalars['Date']['output']
}

export enum ReviewResponseStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
}

export type SchoolEvent = {
  __typename?: 'SchoolEvent'
  createdAt: Scalars['Date']['output']
  endsAt?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  instantMeeting?: Maybe<InstantMeeting>
  instantMeetingId?: Maybe<Scalars['ID']['output']>
  mentorSessionEvent?: Maybe<MentorSessionEvent>
  mentorSessionEventID?: Maybe<Scalars['ID']['output']>
  startsAt?: Maybe<Scalars['Date']['output']>
  type: SchoolEventType
  updatedAt: Scalars['Date']['output']
}

export type SchoolEventConnection = {
  __typename?: 'SchoolEventConnection'
  edges?: Maybe<Array<SchoolEventEdge>>
  nodes?: Maybe<Array<SchoolEvent>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type SchoolEventEdge = {
  __typename?: 'SchoolEventEdge'
  cursor: Scalars['String']['output']
  node: SchoolEvent
}

export type SchoolEventImFilter = {
  anchor?: InputMaybe<Scalars['Date']['input']>
  isExactDate?: InputMaybe<Scalars['Boolean']['input']>
  participantUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  statuses?: InputMaybe<Array<InstantMeetingStatus>>
  types?: InputMaybe<Array<InstantMeetingType>>
}

export type SchoolEventMseFilter = {
  learnerId?: InputMaybe<Scalars['ID']['input']>
  mentorId?: InputMaybe<Scalars['ID']['input']>
  mentorSessionId?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<Array<MentorSessionEventStatus>>
}

export enum SchoolEventType {
  INSTANT_MEETING = 'INSTANT_MEETING',
  MENTOR_SESSION_EVENT = 'MENTOR_SESSION_EVENT',
}

export type SecretSanta = {
  __typename?: 'SecretSanta'
  createdAt: Scalars['Date']['output']
  giftSubmitted: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  note?: Maybe<SecretSantaNote>
  recipientNumber: Scalars['Float']['output']
  recipientUser?: Maybe<User>
  recipientUserEmail: Scalars['String']['output']
  revealed: Scalars['Boolean']['output']
  santaUser?: Maybe<User>
  santaUserEmail: Scalars['String']['output']
  secretSantaNoteId?: Maybe<Scalars['ID']['output']>
  seen: Scalars['Boolean']['output']
  updatedAt: Scalars['Date']['output']
}

export type SecretSantaNote = {
  __typename?: 'SecretSantaNote'
  createdAt: Scalars['Date']['output']
  hint: Scalars['String']['output']
  id: Scalars['ID']['output']
  secretSantaId: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
  wish: Scalars['String']['output']
}

export type SecretSantaNoteInput = {
  hint: Scalars['String']['input']
  wish: Scalars['String']['input']
}

export type SecretSantaStats = {
  __typename?: 'SecretSantaStats'
  giftsDelivered: Scalars['Float']['output']
  overallProgress: Scalars['Float']['output']
  santeeRevealed: Scalars['Float']['output']
  totalParticipants: Scalars['Float']['output']
  wishesSent: Scalars['Float']['output']
}

export enum SocialProvider {
  DISCORD = 'DISCORD',
  FACEBOOK = 'FACEBOOK',
  GITHUB = 'GITHUB',
  GOOGLE = 'GOOGLE',
}

export type SubscribeCourseInput = {
  courseRefId: Scalars['String']['input']
  email: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type Subscription = {
  __typename?: 'Subscription'
  chatMessageCreated: ChatMessage
  eventAgendaStateChanged: EventAgendaStateChanged
  noteLinksUpdated: Array<NoteLink>
}

export type SubscriptionEventAgendaStateChangedArgs = {
  id: Scalars['ID']['input']
}

export type SubscriptionNoteLinksUpdatedArgs = {
  noteId: Scalars['ID']['input']
}

export type SuitableCourseMember = {
  __typename?: 'SuitableCourseMember'
  bookedSessionsAndPendingRequests: Scalars['Float']['output']
  course?: Maybe<Course>
  courseExpectation?: Maybe<Scalars['String']['output']>
  courseId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  enrollmentDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  jobExpectation?: Maybe<Scalars['String']['output']>
  latestProgressReport?: Maybe<CourseMemberProgressReport>
  latestProgressReportId?: Maybe<Scalars['ID']['output']>
  learner?: Maybe<Learner>
  learnerId?: Maybe<Scalars['ID']['output']>
  learningPurpose?: Maybe<Scalars['String']['output']>
  learningType?: Maybe<Scalars['String']['output']>
  mentor?: Maybe<Mentor>
  mentorId?: Maybe<Scalars['ID']['output']>
  mentorSessions?: Maybe<Array<MentorSession>>
  monthToChangeJob?: Maybe<Scalars['Float']['output']>
  paymentType?: Maybe<Scalars['String']['output']>
  reasonToSwitchCareer?: Maybe<Scalars['String']['output']>
  redeemedMentorSessionEvents?: Maybe<Scalars['Int']['output']>
  remainingMentorSessionEvents?: Maybe<Scalars['Int']['output']>
  score: Scalars['Float']['output']
  scoreSpecs: Scalars['JSON']['output']
  source?: Maybe<Scalars['String']['output']>
  status: CourseMemberStatus
  studyTimePerWeek?: Maybe<Scalars['Float']['output']>
  timeSuggestions: Array<MentorSessionTimeSuggestion>
  totalAllowedTASessions?: Maybe<Scalars['Int']['output']>
  totalMentorSessionEvents?: Maybe<Scalars['Int']['output']>
  type: CourseMemberType
  updatedAt: Scalars['Date']['output']
  user?: Maybe<User>
  userId?: Maybe<Scalars['ID']['output']>
}

export type TimeInterval = {
  end: Scalars['Date']['input']
  start: Scalars['Date']['input']
}

export type TimeSlots = {
  __typename?: 'TimeSlots'
  allTimeSlots: Array<Scalars['Date']['output']>
  availableTimeSlots: Array<Scalars['Date']['output']>
}

export type UpdateAppTokenInput = {
  description: Scalars['String']['input']
  permissions: Array<Permission>
}

export type UpdateCalendarEventInput = {
  attendees: Array<EventAttendeeInput>
  calendarId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  endsAt: Scalars['Date']['input']
  name: Scalars['String']['input']
  recurrence?: InputMaybe<Scalars['String']['input']>
  recurringCount: Scalars['Int']['input']
  recurringEventId?: InputMaybe<Scalars['ID']['input']>
  startsAt: Scalars['Date']['input']
  updateRecurringEvents?: InputMaybe<CalendarEventRecurrenceUpdateOption>
}

export type UpdateCalendarInput = {
  description?: InputMaybe<Scalars['String']['input']>
  isPublic?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCourseInput = {
  access?: CourseAccess
  description: Scalars['String']['input']
  discordServerId?: InputMaybe<Scalars['String']['input']>
  lwCourseId?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
  totalAllowedTASessions?: InputMaybe<Scalars['Float']['input']>
  zohoProductId?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCourseJobInput = {
  companyLogoFileId?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  courseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  isHidden?: InputMaybe<Scalars['Boolean']['input']>
  jdLink?: InputMaybe<Scalars['String']['input']>
  jobLevels?: InputMaybe<Array<CourseJobLevel>>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  locations?: InputMaybe<Array<CourseJobLocation>>
  salaryFrom?: InputMaybe<Scalars['Float']['input']>
  salaryNegotiable?: InputMaybe<Scalars['Boolean']['input']>
  salaryTo?: InputMaybe<Scalars['Float']['input']>
  skillsRequirement?: InputMaybe<Array<Scalars['String']['input']>>
  status?: InputMaybe<CourseJobStatus>
}

export type UpdateCourseMemberInput = {
  courseExpectation?: InputMaybe<Scalars['String']['input']>
  enrollmentDate?: InputMaybe<Scalars['Date']['input']>
  jobExpectation?: InputMaybe<Scalars['String']['input']>
  learningPurpose?: InputMaybe<Scalars['String']['input']>
  learningType?: InputMaybe<Scalars['String']['input']>
  monthToChangeJob?: InputMaybe<Scalars['Int']['input']>
  paymentType?: InputMaybe<Scalars['String']['input']>
  reasonToSwitchCareer?: InputMaybe<Scalars['String']['input']>
  redeemedMentorSessionEvents?: InputMaybe<Scalars['Int']['input']>
  source?: InputMaybe<Scalars['String']['input']>
  studyTimePerWeek?: InputMaybe<Scalars['Int']['input']>
  totalAllowedTASessions?: InputMaybe<Scalars['Int']['input']>
  totalMentorSessionEvents?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateCustomFieldInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  options: Array<CustomFieldOptionInput>
}

export type UpdateEmployeeProfileInput = {
  firstName?: InputMaybe<Scalars['String']['input']>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  signedOffDate?: InputMaybe<Scalars['Date']['input']>
  signedOnDate?: InputMaybe<Scalars['Date']['input']>
}

export type UpdateEmployeeTeamInput = {
  color?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateInstantMeetingInput = {
  courseId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  endsAt?: InputMaybe<Scalars['Date']['input']>
  externalMeetingUrl?: InputMaybe<Scalars['String']['input']>
  hostUserId?: InputMaybe<Scalars['String']['input']>
  participantUserIds: Array<Scalars['String']['input']>
  recurrence?: InputMaybe<Scalars['Float']['input']>
  startsAt?: InputMaybe<Scalars['Date']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<InstantMeetingType>
  useZoom?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateLearnerInput = {
  address?: InputMaybe<Scalars['String']['input']>
  codingSkill?: InputMaybe<Scalars['String']['input']>
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>
  discordId?: InputMaybe<Scalars['String']['input']>
  englishLevel?: InputMaybe<Scalars['String']['input']>
  expYears?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<Gender>
  industry?: InputMaybe<Scalars['String']['input']>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  learnerCode?: InputMaybe<Scalars['Int']['input']>
  linkedinURL?: InputMaybe<Scalars['String']['input']>
  nationalIdIssuedDate?: InputMaybe<Scalars['Date']['input']>
  nationalIdIssuedPlace?: InputMaybe<Scalars['String']['input']>
  nationalIdNumber?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  organization?: InputMaybe<Scalars['String']['input']>
  priorCodingCourses?: InputMaybe<Scalars['String']['input']>
  resumeURL?: InputMaybe<Scalars['String']['input']>
  secondaryEmail?: InputMaybe<Scalars['String']['input']>
  secondaryPhoneNumber?: InputMaybe<Scalars['String']['input']>
  workingStatus?: InputMaybe<Scalars['String']['input']>
}

export type UpdateMentorInput = {
  address?: InputMaybe<Scalars['String']['input']>
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>
  bankBranch?: InputMaybe<Scalars['String']['input']>
  bankName?: InputMaybe<Scalars['String']['input']>
  beneficiary?: InputMaybe<Scalars['String']['input']>
  capacity?: InputMaybe<Scalars['Float']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>
  discordId?: InputMaybe<Scalars['String']['input']>
  expYears?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<Gender>
  hourlyRate?: InputMaybe<Scalars['Float']['input']>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  lwUserId?: InputMaybe<Scalars['String']['input']>
  nationalIdIssuedDate?: InputMaybe<Scalars['Date']['input']>
  nationalIdIssuedPlace?: InputMaybe<Scalars['String']['input']>
  nationalIdNumber?: InputMaybe<Scalars['String']['input']>
  oldNationalIdNumber?: InputMaybe<Scalars['String']['input']>
  organization?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  pitCode?: InputMaybe<Scalars['String']['input']>
  secondaryEmail?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<MentorStatus>
  type?: InputMaybe<MentorType>
}

export type UpdateMentorSessionInput = {
  courseMemberIds: Array<Scalars['ID']['input']>
  endsAt: Scalars['Date']['input']
  recurrence?: InputMaybe<Scalars['String']['input']>
  startsAt: Scalars['Date']['input']
}

export type UpdateReviewCampaignInput = {
  access?: InputMaybe<Array<ReviewCampaignAccess>>
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<ReviewCampaignStatus>
}

export type UpdateUserEventInput = {
  status?: InputMaybe<UserEventStatus>
}

export type UpdateUserInput = {
  discordId?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  roles?: InputMaybe<Array<UserRole>>
  slackId?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<UserStatus>
  zoomUserEmail?: InputMaybe<Scalars['String']['input']>
}

export type UpdateUserRequestStatusInput = {
  notes?: InputMaybe<Scalars['String']['input']>
  targetStatus: UserRequestStatus
  userRequestId: Scalars['ID']['input']
}

export type User = {
  __typename?: 'User'
  avatar?: Maybe<CloudFile>
  avatarFileId?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  discordId?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  gender?: Maybe<Gender>
  id: Scalars['ID']['output']
  lastLogin?: Maybe<Scalars['Date']['output']>
  learner?: Maybe<Learner>
  learnerId?: Maybe<Scalars['ID']['output']>
  lwId: Scalars['ID']['output']
  mentor?: Maybe<Mentor>
  mentorId?: Maybe<Scalars['ID']['output']>
  name: Scalars['String']['output']
  phone?: Maybe<Scalars['String']['output']>
  roles: Array<UserRole>
  slackId?: Maybe<Scalars['String']['output']>
  status: UserStatus
  textSearch?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['Date']['output']
  wishlist?: Maybe<Wishlist>
  zoomUserEmail?: Maybe<Scalars['String']['output']>
}

export type UserCalendar = {
  __typename?: 'UserCalendar'
  calendar?: Maybe<Calendar>
  calendarId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  permission: UserCalendarPermission
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export enum UserCalendarPermission {
  MANAGE_SHARING = 'MANAGE_SHARING',
  OWNER = 'OWNER',
  UPDATE_EVENTS = 'UPDATE_EVENTS',
  VIEW_AVAILABILITY = 'VIEW_AVAILABILITY',
  VIEW_EVENTS = 'VIEW_EVENTS',
}

export type UserConnection = {
  __typename?: 'UserConnection'
  edges?: Maybe<Array<UserEdge>>
  nodes?: Maybe<Array<User>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type UserEdge = {
  __typename?: 'UserEdge'
  cursor: Scalars['String']['output']
  node: User
}

export type UserEvent = {
  __typename?: 'UserEvent'
  calendar: Calendar
  calendarId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  event: CalendarEvent
  eventId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isCurrentUserHost: Scalars['Boolean']['output']
  isHost: Scalars['Boolean']['output']
  isOptional: Scalars['Boolean']['output']
  status: UserEventStatus
  updatedAt: Scalars['Date']['output']
  user: User
  userId: Scalars['ID']['output']
}

export enum UserEventStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  NEEDS_ACTION = 'NEEDS_ACTION',
  TENTATIVE = 'TENTATIVE',
}

export type UserOffsetBasedPagination = {
  __typename?: 'UserOffsetBasedPagination'
  nodes: Array<User>
  pageInfo: OffsetBasedPaginationPageInfo
}

export type UserRequest = {
  __typename?: 'UserRequest'
  bookingRequest?: Maybe<MentorSessionBookingRequest>
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  mentorSessionEventRescheduleRequest?: Maybe<MentorSessionEventRescheduleRequest>
  recipientUserIds: Array<Scalars['ID']['output']>
  requesterUser?: Maybe<User>
  responses?: Maybe<Array<UserResponse>>
  status: UserRequestStatus
  type: UserRequestType
  updatedAt: Scalars['Date']['output']
}

export enum UserRequestStatus {
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum UserRequestType {
  MENTOR_SESSION_BOOKING = 'MENTOR_SESSION_BOOKING',
  MENTOR_SESSION_EVENT_RESCHEDULE = 'MENTOR_SESSION_EVENT_RESCHEDULE',
}

export type UserResponse = {
  __typename?: 'UserResponse'
  action: UserResponseAction
  content?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  updatedAt: Scalars['Date']['output']
  user: User
  userId: Scalars['String']['output']
  userRequest: UserRequest
  userRequestId: Scalars['String']['output']
}

export enum UserResponseAction {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export enum UserRole {
  ACADEMIC_MANAGER = 'ACADEMIC_MANAGER',
  ACADEMIC_STAFF = 'ACADEMIC_STAFF',
  ACCOUNTANT = 'ACCOUNTANT',
  ADMIN = 'ADMIN',
  DEVELOPER = 'DEVELOPER',
  GROWTH_MANAGER = 'GROWTH_MANAGER',
  GROWTH_STAFF = 'GROWTH_STAFF',
  HR = 'HR',
  LEARNER = 'LEARNER',
  LEARNER_EXP = 'LEARNER_EXP',
  MENTOR = 'MENTOR',
  OAUTH_APP = 'OAUTH_APP',
  OPS_MANAGER = 'OPS_MANAGER',
  OPS_STAFF = 'OPS_STAFF',
  SALES_MANAGER = 'SALES_MANAGER',
  SALES_STAFF = 'SALES_STAFF',
  TEACHING_ASSISTANT = 'TEACHING_ASSISTANT',
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
}

export type Wishlist = {
  __typename?: 'Wishlist'
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  items?: Maybe<Array<WishlistItem>>
  updatedAt: Scalars['Date']['output']
  userId: Scalars['ID']['output']
}

export type WishlistItem = {
  __typename?: 'WishlistItem'
  courseId?: Maybe<Scalars['ID']['output']>
  courseRefId: Scalars['ID']['output']
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  updatedAt: Scalars['Date']['output']
}

export type WishlistItemConnection = {
  __typename?: 'WishlistItemConnection'
  edges?: Maybe<Array<WishlistItemEdge>>
  nodes?: Maybe<Array<WishlistItem>>
  pageInfo: ConnectionPageInfo
  totalCount: Scalars['Int']['output']
}

export type WishlistItemEdge = {
  __typename?: 'WishlistItemEdge'
  cursor: Scalars['String']['output']
  node: WishlistItem
}

export type WorkplaceStatistic = {
  __typename?: 'WorkplaceStatistic'
  deals: Scalars['Int']['output']
  droppedLearnerMembers: Scalars['Int']['output']
  graduatedLearnerMembers: Scalars['Int']['output']
  id: Scalars['ID']['output']
  learnerMembers: Scalars['Int']['output']
  learners: Scalars['Int']['output']
  mentors: Scalars['Int']['output']
  pendingDSMembers: Scalars['Int']['output']
  pendingLearners: Scalars['Int']['output']
  pendingMentors: Scalars['Int']['output']
  pendingWebMembers: Scalars['Int']['output']
  postponedLearnerMembers: Scalars['Int']['output']
  users: Scalars['Int']['output']
}

export type ZohoProduct = {
  __typename?: 'ZohoProduct'
  id: Scalars['String']['output']
  productName: Scalars['String']['output']
}

export type ZoomRecording = {
  __typename?: 'ZoomRecording'
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  meetingUUID: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
}

export type AppAuthQueryVariables = Exact<{ [key: string]: never }>

export type AppAuthQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'AuthUser'
    id: string
    name: string
    email: string
    roles: Array<UserRole>
    status: UserStatus
    createdAt: any
    permissions: Array<Permission>
    avatarFileId?: string | null
    hasPassword: boolean
    discordId?: string | null
  } | null
}

export type ConnectionPageInfoFragment = {
  __typename?: 'ConnectionPageInfo'
  endCursor?: string | null
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor?: string | null
}

export type ActivityTypesFilterListQueryVariables = Exact<{
  [key: string]: never
}>

export type ActivityTypesFilterListQuery = {
  __typename?: 'Query'
  activityTypes: Array<{
    __typename?: 'ActivityType'
    id: string
    type: string
  }>
}

export type CourseMemberItemFragment = {
  __typename?: 'CourseMember'
  id: string
  courseId: string
  status: CourseMemberStatus
  type: CourseMemberType
  userId?: string | null
  learnerId?: string | null
  mentorId?: string | null
  createdAt: any
  user?: { __typename?: 'User'; id: string; email: string } | null
  learner?: {
    __typename?: 'Learner'
    id: string
    userId: string
    lwUser?: { __typename?: 'LwUser'; id: string; email: string } | null
  } | null
  mentor?: {
    __typename?: 'Mentor'
    id: string
    userId: string
    lwUserId?: string | null
    status: MentorStatus
  } | null
}

export type CourseMemberListQueryVariables = Exact<{
  courseId?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<CourseMemberStatus>
  type?: InputMaybe<CourseMemberType>
}>

export type CourseMemberListQuery = {
  __typename?: 'Query'
  courseMembers: Array<{
    __typename?: 'CourseMember'
    id: string
    courseId: string
    status: CourseMemberStatus
    type: CourseMemberType
    userId?: string | null
    learnerId?: string | null
    mentorId?: string | null
    createdAt: any
    user?: { __typename?: 'User'; id: string; email: string } | null
    learner?: {
      __typename?: 'Learner'
      id: string
      userId: string
      lwUser?: { __typename?: 'LwUser'; id: string; email: string } | null
    } | null
    mentor?: {
      __typename?: 'Mentor'
      id: string
      userId: string
      lwUserId?: string | null
      status: MentorStatus
    } | null
  }>
}

export type CoursesFilterListQueryVariables = Exact<{ [key: string]: never }>

export type CoursesFilterListQuery = {
  __typename?: 'Query'
  courses: Array<{
    __typename?: 'Course'
    id: string
    title: string
    description?: string | null
    totalAllowedTASessions?: number | null
  }>
}

export type MyLearnerFragment = {
  __typename?: 'Learner'
  id: string
  userId: string
  lwUserId?: string | null
  discordId?: string | null
  mentorSessionAvailabilitySchedules: Array<{
    __typename?: 'MentorSessionAvailabilitySchedule'
    id: string
    dayOfWeek: number
    targetId: string
    start: any
    end: any
  }>
  user?: {
    __typename?: 'User'
    id: string
    name: string
    avatarFileId?: string | null
    avatar?: {
      __typename?: 'CloudFile'
      id: string
      signedUrl?: string | null
      blurhash?: string | null
    } | null
  } | null
}

export type MyLearnerQueryVariables = Exact<{ [key: string]: never }>

export type MyLearnerQuery = {
  __typename?: 'Query'
  myLearner?: {
    __typename?: 'Learner'
    id: string
    userId: string
    lwUserId?: string | null
    discordId?: string | null
    mentorSessionAvailabilitySchedules: Array<{
      __typename?: 'MentorSessionAvailabilitySchedule'
      id: string
      dayOfWeek: number
      targetId: string
      start: any
      end: any
    }>
    user?: {
      __typename?: 'User'
      id: string
      name: string
      avatarFileId?: string | null
      avatar?: {
        __typename?: 'CloudFile'
        id: string
        signedUrl?: string | null
        blurhash?: string | null
      } | null
    } | null
  } | null
}

export type LearnersFilterListQueryVariables = Exact<{
  first?: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  learnerIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
}>

export type LearnersFilterListQuery = {
  __typename?: 'Query'
  learnerConnection: {
    __typename?: 'LearnerConnection'
    nodes?: Array<{
      __typename?: 'Learner'
      id: string
      userId: string
      user?: {
        __typename?: 'User'
        id: string
        name: string
        email: string
      } | null
    }> | null
  }
}

export type LwSingleSignOnMutationVariables = Exact<{ [key: string]: never }>

export type LwSingleSignOnMutation = { __typename?: 'Mutation'; lwSSO: string }

export type MyMentorFragment = {
  __typename?: 'Mentor'
  id: string
  lwUserId?: string | null
  status: MentorStatus
  type: MentorType
  discordId?: string | null
  userId: string
  pendingBookingRequestCount: number
  secondaryEmail?: string | null
}

export type MyMentorQueryVariables = Exact<{ [key: string]: never }>

export type MyMentorQuery = {
  __typename?: 'Query'
  myMentor?: {
    __typename?: 'Mentor'
    id: string
    lwUserId?: string | null
    status: MentorStatus
    type: MentorType
    discordId?: string | null
    userId: string
    pendingBookingRequestCount: number
    secondaryEmail?: string | null
  } | null
}

export type MentorsFilterListQueryVariables = Exact<{
  first?: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  mentorIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type MentorsFilterListQuery = {
  __typename?: 'Query'
  mentorConnection: {
    __typename?: 'MentorConnection'
    nodes?: Array<{
      __typename?: 'Mentor'
      id: string
      userId: string
      user?: {
        __typename?: 'User'
        id: string
        name: string
        email: string
      } | null
    }> | null
  }
}

export type UsersFilterListQueryVariables = Exact<{
  roles?: InputMaybe<Array<UserRole> | UserRole>
  status?: InputMaybe<UserStatus>
}>

export type UsersFilterListQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'UserOffsetBasedPagination'
    nodes: Array<{
      __typename?: 'User'
      id: string
      name: string
      email: string
      mentor?: { __typename?: 'Mentor'; id: string } | null
      learner?: { __typename?: 'Learner'; id: string } | null
    }>
  }
}

export type MyMentorSchedulesFragment = {
  __typename?: 'MentorSessionAvailabilitySchedule'
  id: string
  dayOfWeek: number
  targetId: string
  start: any
  end: any
}

export type MyMentorSchedulesQueryVariables = Exact<{
  targetId: Scalars['ID']['input']
}>

export type MyMentorSchedulesQuery = {
  __typename?: 'Query'
  mentorSessionAvailabilitySchedules: Array<{
    __typename?: 'MentorSessionAvailabilitySchedule'
    id: string
    dayOfWeek: number
    targetId: string
    start: any
    end: any
  }>
}

export type UpdateMyMentorSchedulesMutationVariables = Exact<{
  targetId: Scalars['ID']['input']
  schedules:
    | Array<MentorSessionAvailabilityInput>
    | MentorSessionAvailabilityInput
}>

export type UpdateMyMentorSchedulesMutation = {
  __typename?: 'Mutation'
  updateMentorSessionAvailabilitySchedules: Array<{
    __typename?: 'MentorSessionAvailabilitySchedule'
    id: string
    dayOfWeek: number
    targetId: string
    start: any
    end: any
  }>
}

export type CustomFieldsSettingsQueryVariables = Exact<{
  targetGroup: CustomFieldTargetGroup
}>

export type CustomFieldsSettingsQuery = {
  __typename?: 'Query'
  customFields: Array<{
    __typename?: 'CustomField'
    id: string
    name: string
    description?: string | null
    dataType: CustomFieldDataType
    targetGroup: CustomFieldTargetGroup
    options: Array<{
      __typename?: 'CustomFieldOption'
      id: string
      name: string
    }>
  }>
}

export type CreateCustomFieldMutationVariables = Exact<{
  input: CreateCustomFieldInput
}>

export type CreateCustomFieldMutation = {
  __typename?: 'Mutation'
  createCustomField: {
    __typename?: 'CustomField'
    id: string
    name: string
    targetGroup: CustomFieldTargetGroup
  }
}

export type UpdateCustomFieldMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateCustomFieldInput
}>

export type UpdateCustomFieldMutation = {
  __typename?: 'Mutation'
  updateCustomField: {
    __typename?: 'CustomField'
    id: string
    name: string
    description?: string | null
    dataType: CustomFieldDataType
    targetGroup: CustomFieldTargetGroup
    options: Array<{
      __typename?: 'CustomFieldOption'
      id: string
      name: string
    }>
  }
}

export type DeleteCustomFieldMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteCustomFieldMutation = {
  __typename?: 'Mutation'
  deleteCustomField: { __typename?: 'CustomField'; id: string }
}

export type AvailabilitySchedulesViewQueryVariables = Exact<{
  targetId?: InputMaybe<Scalars['ID']['input']>
  targetIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type AvailabilitySchedulesViewQuery = {
  __typename?: 'Query'
  mentorSessionAvailabilitySchedules: Array<{
    __typename?: 'MentorSessionAvailabilitySchedule'
    id: string
    targetId: string
    dayOfWeek: number
    start: any
    end: any
  }>
}

export type UserAvatarQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UserAvatarQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    id: string
    email: string
    name: string
    status: UserStatus
    roles: Array<UserRole>
    avatar?: {
      __typename?: 'CloudFile'
      id: string
      signedUrl?: string | null
      blurhash?: string | null
    } | null
  } | null
}

export type UserDisplayNameQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UserDisplayNameQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    id: string
    email: string
    name: string
    roles: Array<UserRole>
    status: UserStatus
  } | null
}

export type CourseMemberSelectFragment = {
  __typename?: 'CourseMember'
  id: string
  type: CourseMemberType
  status: CourseMemberStatus
  mentorId?: string | null
  learnerId?: string | null
  user?: {
    __typename?: 'User'
    id: string
    status: UserStatus
    email: string
    name: string
    roles: Array<UserRole>
  } | null
}

export type CourseMemberSelectQueryVariables = Exact<{
  courseId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type CourseMemberSelectQuery = {
  __typename?: 'Query'
  courseMemberConnection: {
    __typename?: 'CourseMemberConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'CourseMember'
      id: string
      type: CourseMemberType
      status: CourseMemberStatus
      mentorId?: string | null
      learnerId?: string | null
      user?: {
        __typename?: 'User'
        id: string
        status: UserStatus
        email: string
        name: string
        roles: Array<UserRole>
      } | null
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type UserAutocompleteQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']['input']>
  excludedUserIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
}>

export type UserAutocompleteQuery = {
  __typename?: 'Query'
  userConnection: {
    __typename?: 'UserConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'User'
      id: string
      name: string
      email: string
      status: UserStatus
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type UserSelectFragment = {
  __typename?: 'User'
  id: string
  name: string
  email: string
}

export type UserSelectConnectionQueryVariables = Exact<{
  first?: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  includedUserIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
}>

export type UserSelectConnectionQuery = {
  __typename?: 'Query'
  userConnection: {
    __typename?: 'UserConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'User'
      id: string
      name: string
      email: string
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type GetEventMeetingUrlMutationVariables = Exact<{
  eventId: Scalars['ID']['input']
}>

export type GetEventMeetingUrlMutation = {
  __typename?: 'Mutation'
  getEventMeetingUrl: string
  joinEvent: { __typename?: 'CalendarEvent'; id: string }
}

export type MentorSessionEventViewQueryVariables = Exact<{
  eventId: Scalars['ID']['input']
}>

export type MentorSessionEventViewQuery = {
  __typename?: 'Query'
  mentorSessionEvent: {
    __typename?: 'MentorSessionEvent'
    id: string
    calendarEventId: string
    mentorSessionId: string
    calendarEvent: {
      __typename?: 'CalendarEvent'
      id: string
      startsAt: any
      endsAt: any
      createdAt: any
      attendees: Array<{
        __typename?: 'UserEvent'
        id: string
        userId: string
        status: UserEventStatus
        user: { __typename?: 'User'; id: string; email: string }
      }>
    }
    mentorSessionRecaps: Array<{
      __typename?: 'MentorSessionRecap'
      id: string
      learnerId: string
      mentorId: string
      type: MentorSessionRecapType
      createdByUserId: string
      mentorSessionEventCalendarEventId: string
    }>
    mentorSessionRecapReport: {
      __typename?: 'MentorSessionRecapReport'
      id: string
      requiredRecapCount: {
        __typename?: 'MentorMenteeCount'
        id: string
        mentee: number
        mentor: number
      }
      mentorSessionRecapCount: {
        __typename?: 'MentorMenteeCount'
        id: string
        mentee: number
        mentor: number
      }
    }
  }
}

export type LearnerMentorSessionRecapsQueryVariables = Exact<{
  mentorSessionIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
  learnerId: Scalars['ID']['input']
}>

export type LearnerMentorSessionRecapsQuery = {
  __typename?: 'Query'
  mentorSessionRecaps: Array<{
    __typename?: 'MentorSessionRecap'
    id: string
    learnerId: string
    type: MentorSessionRecapType
    createdAt: any
    createdByUserId: string
    formSubmission: {
      __typename?: 'FormSubmission'
      id: string
      createdAt: any
      type: FormSubmissionType
      content: string
    }
    mentorSessionEvent: {
      __typename?: 'MentorSessionEvent'
      id: string
      mentorSession: {
        __typename?: 'MentorSession'
        id: string
        course?: { __typename?: 'Course'; id: string; title: string } | null
      }
    }
  }>
}

export type LearnerMemberProgressReportFragment = {
  __typename?: 'CourseMemberProgressReport'
  id: string
  createdAt: any
  courseMemberId: string
  averageScore?: number | null
  overallProgress: number
  studyTimeDuringPeriod: number
  learningVelocity?: LearningVelocity | null
  weeksOfftrack?: number | null
  startSyncedAt?: any | null
  endSyncedAt: any
  currentUnit: {
    __typename?: 'CourseUnit'
    id: string
    title: string
    nextCourseUnit?: {
      __typename?: 'CourseUnit'
      id: string
      title: string
    } | null
  }
  currentUnitPath?: {
    __typename?: 'CourseUnitPath'
    id: string
    section: { __typename?: 'CourseSection'; id: string; title: string }
    path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
  } | null
  nextUnitPath?: {
    __typename?: 'CourseUnitPath'
    id: string
    section: { __typename?: 'CourseSection'; id: string; title: string }
    path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
  } | null
  currentSection?: {
    __typename?: 'CourseUnit'
    id: string
    title: string
    nextCourseUnit?: {
      __typename?: 'CourseUnit'
      id: string
      title: string
    } | null
  } | null
  currentMiniCourse?: {
    __typename?: 'CourseUnit'
    id: string
    title: string
    section?: {
      __typename?: 'CourseSection'
      nextCourseSection?: {
        __typename?: 'CourseSection'
        id: string
        title: string
      } | null
    } | null
    nextCourseUnit?: {
      __typename?: 'CourseUnit'
      id: string
      title: string
    } | null
  } | null
}

export type LearnerMemberProgressReportQueryVariables = Exact<{
  courseMemberId: Scalars['ID']['input']
}>

export type LearnerMemberProgressReportQuery = {
  __typename?: 'Query'
  getCourseMemberProgressReport?: {
    __typename?: 'CourseMemberProgressReport'
    id: string
    createdAt: any
    courseMemberId: string
    averageScore?: number | null
    overallProgress: number
    studyTimeDuringPeriod: number
    learningVelocity?: LearningVelocity | null
    weeksOfftrack?: number | null
    startSyncedAt?: any | null
    endSyncedAt: any
    currentUnit: {
      __typename?: 'CourseUnit'
      id: string
      title: string
      nextCourseUnit?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
      } | null
    }
    currentUnitPath?: {
      __typename?: 'CourseUnitPath'
      id: string
      section: { __typename?: 'CourseSection'; id: string; title: string }
      path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
    } | null
    nextUnitPath?: {
      __typename?: 'CourseUnitPath'
      id: string
      section: { __typename?: 'CourseSection'; id: string; title: string }
      path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
    } | null
    currentSection?: {
      __typename?: 'CourseUnit'
      id: string
      title: string
      nextCourseUnit?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
      } | null
    } | null
    currentMiniCourse?: {
      __typename?: 'CourseUnit'
      id: string
      title: string
      section?: {
        __typename?: 'CourseSection'
        nextCourseSection?: {
          __typename?: 'CourseSection'
          id: string
          title: string
        } | null
      } | null
      nextCourseUnit?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
      } | null
    } | null
  } | null
}

export type CreateLearnerMemberProgressReportMutationVariables = Exact<{
  courseMemberId: Scalars['ID']['input']
}>

export type CreateLearnerMemberProgressReportMutation = {
  __typename?: 'Mutation'
  createCourseMemberProgressReport?: {
    __typename?: 'CourseMemberProgressReport'
    id: string
    createdAt: any
    courseMemberId: string
    averageScore?: number | null
    overallProgress: number
    studyTimeDuringPeriod: number
    learningVelocity?: LearningVelocity | null
    weeksOfftrack?: number | null
    startSyncedAt?: any | null
    endSyncedAt: any
    currentUnit: {
      __typename?: 'CourseUnit'
      id: string
      title: string
      nextCourseUnit?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
      } | null
    }
    currentUnitPath?: {
      __typename?: 'CourseUnitPath'
      id: string
      section: { __typename?: 'CourseSection'; id: string; title: string }
      path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
    } | null
    nextUnitPath?: {
      __typename?: 'CourseUnitPath'
      id: string
      section: { __typename?: 'CourseSection'; id: string; title: string }
      path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
    } | null
    currentSection?: {
      __typename?: 'CourseUnit'
      id: string
      title: string
      nextCourseUnit?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
      } | null
    } | null
    currentMiniCourse?: {
      __typename?: 'CourseUnit'
      id: string
      title: string
      section?: {
        __typename?: 'CourseSection'
        nextCourseSection?: {
          __typename?: 'CourseSection'
          id: string
          title: string
        } | null
      } | null
      nextCourseUnit?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
      } | null
    } | null
  } | null
}

export type MentorMentorSessionRecapsQueryVariables = Exact<{
  mentorSessionIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
  mentorId: Scalars['ID']['input']
}>

export type MentorMentorSessionRecapsQuery = {
  __typename?: 'Query'
  mentorSessionRecaps: Array<{
    __typename?: 'MentorSessionRecap'
    id: string
    mentorId: string
    type: MentorSessionRecapType
    createdAt: any
    createdByUserId: string
    formSubmission: {
      __typename?: 'FormSubmission'
      id: string
      createdAt: any
      type: FormSubmissionType
      content: string
    }
    mentorSessionEvent: {
      __typename?: 'MentorSessionEvent'
      id: string
      mentorSession: {
        __typename?: 'MentorSession'
        id: string
        course?: { __typename?: 'Course'; id: string; title: string } | null
      }
    }
  }>
}

export type RecapLearnerFragment = {
  __typename?: 'Learner'
  id: string
  user?: {
    __typename?: 'User'
    id: string
    name: string
    status: UserStatus
  } | null
}

export type RecapMentorFragment = {
  __typename?: 'Mentor'
  id: string
  user?: {
    __typename?: 'User'
    id: string
    name: string
    status: UserStatus
  } | null
}

export type MentorSessionRecapListQueryVariables = Exact<{
  mentorSessionIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
  courseMemberId?: InputMaybe<Scalars['ID']['input']>
  learnerId?: InputMaybe<Scalars['ID']['input']>
  mentorId?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<MentorSessionRecapType>
}>

export type MentorSessionRecapListQuery = {
  __typename?: 'Query'
  mentorSessionRecaps: Array<{
    __typename?: 'MentorSessionRecap'
    id: string
    learnerId: string
    mentorId: string
    type: MentorSessionRecapType
    createdAt: any
    createdByUserId: string
    learner: {
      __typename?: 'Learner'
      id: string
      user?: {
        __typename?: 'User'
        id: string
        name: string
        status: UserStatus
      } | null
    }
    mentor: {
      __typename?: 'Mentor'
      id: string
      user?: {
        __typename?: 'User'
        id: string
        name: string
        status: UserStatus
      } | null
    }
    formSubmission: {
      __typename?: 'FormSubmission'
      id: string
      createdAt: any
      type: FormSubmissionType
      content: string
    }
    mentorSessionEvent: {
      __typename?: 'MentorSessionEvent'
      id: string
      mentorSession: {
        __typename?: 'MentorSession'
        id: string
        course?: { __typename?: 'Course'; id: string; title: string } | null
      }
    }
  }>
}

export type ViewMentorSessionRecapQueryVariables = Exact<{
  recapId: Scalars['ID']['input']
}>

export type ViewMentorSessionRecapQuery = {
  __typename?: 'Query'
  mentorSessionRecap?: {
    __typename?: 'MentorSessionRecap'
    id: string
    type: MentorSessionRecapType
    createdAt: any
    createdByUserId: string
    formSubmissionId: string
    learnerId: string
    mentorId: string
    mentorSessionEventCalendarEventId: string
    learner: { __typename?: 'Learner'; id: string; userId: string }
    mentor: { __typename?: 'Mentor'; id: string; userId: string }
    formSubmission: {
      __typename?: 'FormSubmission'
      id: string
      content: string
      type: FormSubmissionType
    }
    mentorSessionEvent: {
      __typename?: 'MentorSessionEvent'
      calendarEvent: { __typename?: 'CalendarEvent'; startsAt: any }
    }
  } | null
}

export type MentorSessionActivityLogItemFragment = {
  __typename?: 'ActivityLog'
  id: string
  activity: string
  updatedAt: any
  createdAt: any
  data: any
  targetIds: Array<string>
  userId?: string | null
  user?: {
    __typename?: 'User'
    id: string
    email: string
    phone?: string | null
  } | null
}

export type UserRequestItemFragment = {
  __typename?: 'UserRequest'
  createdAt: any
  id: string
  recipientUserIds: Array<string>
  status: UserRequestStatus
  type: UserRequestType
  updatedAt: any
}

export type RescheduleRequestItemFragment = {
  __typename?: 'MentorSessionEventRescheduleRequest'
  id: string
  startsAt: any
  endsAt: any
  userRequestId: string
  status: UserRequestStatus
  expiresOn: any
  reason?: string | null
  userRequest: {
    __typename?: 'UserRequest'
    createdAt: any
    id: string
    recipientUserIds: Array<string>
    status: UserRequestStatus
    type: UserRequestType
    updatedAt: any
  }
}

export type MentorSessionRecapItemFragment = {
  __typename?: 'MentorSessionRecap'
  id: string
  createdAt: any
  type: MentorSessionRecapType
  mentorSessionEventCalendarEventId: string
  createdByUser: {
    __typename?: 'User'
    id: string
    name: string
    email: string
    phone?: string | null
  }
  mentor: {
    __typename?: 'Mentor'
    id: string
    user?: {
      __typename?: 'User'
      id: string
      name: string
      email: string
      phone?: string | null
    } | null
  }
  learner: {
    __typename?: 'Learner'
    id: string
    user?: {
      __typename?: 'User'
      id: string
      name: string
      email: string
      phone?: string | null
    } | null
  }
  formSubmission: { __typename?: 'FormSubmission'; id: string; content: string }
}

export type MentorSessionEventItemFragment = {
  __typename?: 'MentorSessionEvent'
  id: string
  status: MentorSessionEventStatus
  zoomMeetingId?: string | null
  meetingRecordings: Array<{ __typename?: 'MeetingRecording'; id: string }>
  mentorSessionRecapReport: {
    __typename?: 'MentorSessionRecapReport'
    id: string
    requiredRecapCount: {
      __typename?: 'MentorMenteeCount'
      id: string
      mentee: number
      mentor: number
    }
    mentorSessionRecapCount: {
      __typename?: 'MentorMenteeCount'
      id: string
      mentee: number
      mentor: number
    }
  }
  mentorSessionRecaps: Array<{
    __typename?: 'MentorSessionRecap'
    id: string
    createdAt: any
    type: MentorSessionRecapType
    mentorSessionEventCalendarEventId: string
    createdByUser: {
      __typename?: 'User'
      id: string
      name: string
      email: string
      phone?: string | null
    }
    mentor: {
      __typename?: 'Mentor'
      id: string
      user?: {
        __typename?: 'User'
        id: string
        name: string
        email: string
        phone?: string | null
      } | null
    }
    learner: {
      __typename?: 'Learner'
      id: string
      user?: {
        __typename?: 'User'
        id: string
        name: string
        email: string
        phone?: string | null
      } | null
    }
    formSubmission: {
      __typename?: 'FormSubmission'
      id: string
      content: string
    }
  }>
  rescheduleRequests?: Array<{
    __typename?: 'MentorSessionEventRescheduleRequest'
    id: string
    startsAt: any
    endsAt: any
    userRequestId: string
    status: UserRequestStatus
    expiresOn: any
    reason?: string | null
    userRequest: {
      __typename?: 'UserRequest'
      createdAt: any
      id: string
      recipientUserIds: Array<string>
      status: UserRequestStatus
      type: UserRequestType
      updatedAt: any
    }
  }> | null
  zoomMeetingRecordings: Array<{ __typename?: 'ZoomRecording'; id: string }>
}

export type MentorSessionViewFragment = {
  __typename?: 'MentorSession'
  id: string
  status: MentorSessionStatus
  course?: { __typename?: 'Course'; id: string; title: string } | null
  courseMembers: Array<{
    __typename?: 'CourseMember'
    id: string
    userId?: string | null
    status: CourseMemberStatus
    type: CourseMemberType
    learnerId?: string | null
    mentorId?: string | null
    learningPurpose?: string | null
    learningType?: string | null
    monthToChangeJob?: number | null
    studyTimePerWeek?: number | null
    courseExpectation?: string | null
    jobExpectation?: string | null
    enrollmentDate?: any | null
    user?: {
      __typename?: 'User'
      id: string
      name: string
      email: string
      phone?: string | null
    } | null
    learner?: {
      __typename?: 'Learner'
      id: string
      codingSkill?: string | null
      discordId?: string | null
      expYears?: string | null
      industry?: string | null
      jobTitle?: string | null
      userId: string
    } | null
    mentor?: {
      __typename?: 'Mentor'
      id: string
      userId: string
      jobTitle?: string | null
      discordId?: string | null
      expYears?: string | null
      status: MentorStatus
      organization?: string | null
      city?: string | null
    } | null
    latestProgressReport?: {
      __typename?: 'CourseMemberProgressReport'
      id: string
      createdAt: any
      courseMemberId: string
      averageScore?: number | null
      overallProgress: number
      studyTimeDuringPeriod: number
      learningVelocity?: LearningVelocity | null
      weeksOfftrack?: number | null
      startSyncedAt?: any | null
      endSyncedAt: any
      currentUnit: {
        __typename?: 'CourseUnit'
        id: string
        title: string
        nextCourseUnit?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
        } | null
      }
      currentUnitPath?: {
        __typename?: 'CourseUnitPath'
        id: string
        section: { __typename?: 'CourseSection'; id: string; title: string }
        path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
      } | null
      nextUnitPath?: {
        __typename?: 'CourseUnitPath'
        id: string
        section: { __typename?: 'CourseSection'; id: string; title: string }
        path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
      } | null
      currentSection?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
        nextCourseUnit?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
        } | null
      } | null
      currentMiniCourse?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
        section?: {
          __typename?: 'CourseSection'
          nextCourseSection?: {
            __typename?: 'CourseSection'
            id: string
            title: string
          } | null
        } | null
        nextCourseUnit?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
        } | null
      } | null
    } | null
  }>
  calendarEvents: Array<{
    __typename?: 'CalendarEvent'
    id: string
    name: string
    startsAt: any
    endsAt: any
    recurrence?: string | null
    origin: CalendarEventOrigin
    attendees: Array<{
      __typename?: 'UserEvent'
      id: string
      userId: string
      status: UserEventStatus
    }>
    mentorSessionEvent?: {
      __typename?: 'MentorSessionEvent'
      id: string
      status: MentorSessionEventStatus
      zoomMeetingId?: string | null
      meetingRecordings: Array<{ __typename?: 'MeetingRecording'; id: string }>
      mentorSessionRecapReport: {
        __typename?: 'MentorSessionRecapReport'
        id: string
        requiredRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
        mentorSessionRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
      }
      mentorSessionRecaps: Array<{
        __typename?: 'MentorSessionRecap'
        id: string
        createdAt: any
        type: MentorSessionRecapType
        mentorSessionEventCalendarEventId: string
        createdByUser: {
          __typename?: 'User'
          id: string
          name: string
          email: string
          phone?: string | null
        }
        mentor: {
          __typename?: 'Mentor'
          id: string
          user?: {
            __typename?: 'User'
            id: string
            name: string
            email: string
            phone?: string | null
          } | null
        }
        learner: {
          __typename?: 'Learner'
          id: string
          user?: {
            __typename?: 'User'
            id: string
            name: string
            email: string
            phone?: string | null
          } | null
        }
        formSubmission: {
          __typename?: 'FormSubmission'
          id: string
          content: string
        }
      }>
      rescheduleRequests?: Array<{
        __typename?: 'MentorSessionEventRescheduleRequest'
        id: string
        startsAt: any
        endsAt: any
        userRequestId: string
        status: UserRequestStatus
        expiresOn: any
        reason?: string | null
        userRequest: {
          __typename?: 'UserRequest'
          createdAt: any
          id: string
          recipientUserIds: Array<string>
          status: UserRequestStatus
          type: UserRequestType
          updatedAt: any
        }
      }> | null
      zoomMeetingRecordings: Array<{ __typename?: 'ZoomRecording'; id: string }>
    } | null
  }>
  mentorSessionRecapReport: {
    __typename?: 'MentorSessionRecapReport'
    id: string
    requiredRecapCount: {
      __typename?: 'MentorMenteeCount'
      id: string
      mentee: number
      mentor: number
    }
    mentorSessionRecapCount: {
      __typename?: 'MentorMenteeCount'
      id: string
      mentee: number
      mentor: number
    }
  }
  activityLogs: Array<{
    __typename?: 'ActivityLog'
    id: string
    activity: string
    updatedAt: any
    createdAt: any
    data: any
    targetIds: Array<string>
    userId?: string | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      phone?: string | null
    } | null
  }>
}

export type MentorSessionViewQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type MentorSessionViewQuery = {
  __typename?: 'Query'
  mentorSession?: {
    __typename?: 'MentorSession'
    id: string
    status: MentorSessionStatus
    course?: { __typename?: 'Course'; id: string; title: string } | null
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      userId?: string | null
      status: CourseMemberStatus
      type: CourseMemberType
      learnerId?: string | null
      mentorId?: string | null
      learningPurpose?: string | null
      learningType?: string | null
      monthToChangeJob?: number | null
      studyTimePerWeek?: number | null
      courseExpectation?: string | null
      jobExpectation?: string | null
      enrollmentDate?: any | null
      user?: {
        __typename?: 'User'
        id: string
        name: string
        email: string
        phone?: string | null
      } | null
      learner?: {
        __typename?: 'Learner'
        id: string
        codingSkill?: string | null
        discordId?: string | null
        expYears?: string | null
        industry?: string | null
        jobTitle?: string | null
        userId: string
      } | null
      mentor?: {
        __typename?: 'Mentor'
        id: string
        userId: string
        jobTitle?: string | null
        discordId?: string | null
        expYears?: string | null
        status: MentorStatus
        organization?: string | null
        city?: string | null
      } | null
      latestProgressReport?: {
        __typename?: 'CourseMemberProgressReport'
        id: string
        createdAt: any
        courseMemberId: string
        averageScore?: number | null
        overallProgress: number
        studyTimeDuringPeriod: number
        learningVelocity?: LearningVelocity | null
        weeksOfftrack?: number | null
        startSyncedAt?: any | null
        endSyncedAt: any
        currentUnit: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        }
        currentUnitPath?: {
          __typename?: 'CourseUnitPath'
          id: string
          section: { __typename?: 'CourseSection'; id: string; title: string }
          path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
        } | null
        nextUnitPath?: {
          __typename?: 'CourseUnitPath'
          id: string
          section: { __typename?: 'CourseSection'; id: string; title: string }
          path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
        } | null
        currentSection?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        } | null
        currentMiniCourse?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          section?: {
            __typename?: 'CourseSection'
            nextCourseSection?: {
              __typename?: 'CourseSection'
              id: string
              title: string
            } | null
          } | null
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        } | null
      } | null
    }>
    calendarEvents: Array<{
      __typename?: 'CalendarEvent'
      id: string
      name: string
      startsAt: any
      endsAt: any
      recurrence?: string | null
      origin: CalendarEventOrigin
      attendees: Array<{
        __typename?: 'UserEvent'
        id: string
        userId: string
        status: UserEventStatus
      }>
      mentorSessionEvent?: {
        __typename?: 'MentorSessionEvent'
        id: string
        status: MentorSessionEventStatus
        zoomMeetingId?: string | null
        meetingRecordings: Array<{
          __typename?: 'MeetingRecording'
          id: string
        }>
        mentorSessionRecapReport: {
          __typename?: 'MentorSessionRecapReport'
          id: string
          requiredRecapCount: {
            __typename?: 'MentorMenteeCount'
            id: string
            mentee: number
            mentor: number
          }
          mentorSessionRecapCount: {
            __typename?: 'MentorMenteeCount'
            id: string
            mentee: number
            mentor: number
          }
        }
        mentorSessionRecaps: Array<{
          __typename?: 'MentorSessionRecap'
          id: string
          createdAt: any
          type: MentorSessionRecapType
          mentorSessionEventCalendarEventId: string
          createdByUser: {
            __typename?: 'User'
            id: string
            name: string
            email: string
            phone?: string | null
          }
          mentor: {
            __typename?: 'Mentor'
            id: string
            user?: {
              __typename?: 'User'
              id: string
              name: string
              email: string
              phone?: string | null
            } | null
          }
          learner: {
            __typename?: 'Learner'
            id: string
            user?: {
              __typename?: 'User'
              id: string
              name: string
              email: string
              phone?: string | null
            } | null
          }
          formSubmission: {
            __typename?: 'FormSubmission'
            id: string
            content: string
          }
        }>
        rescheduleRequests?: Array<{
          __typename?: 'MentorSessionEventRescheduleRequest'
          id: string
          startsAt: any
          endsAt: any
          userRequestId: string
          status: UserRequestStatus
          expiresOn: any
          reason?: string | null
          userRequest: {
            __typename?: 'UserRequest'
            createdAt: any
            id: string
            recipientUserIds: Array<string>
            status: UserRequestStatus
            type: UserRequestType
            updatedAt: any
          }
        }> | null
        zoomMeetingRecordings: Array<{
          __typename?: 'ZoomRecording'
          id: string
        }>
      } | null
    }>
    mentorSessionRecapReport: {
      __typename?: 'MentorSessionRecapReport'
      id: string
      requiredRecapCount: {
        __typename?: 'MentorMenteeCount'
        id: string
        mentee: number
        mentor: number
      }
      mentorSessionRecapCount: {
        __typename?: 'MentorMenteeCount'
        id: string
        mentee: number
        mentor: number
      }
    }
    activityLogs: Array<{
      __typename?: 'ActivityLog'
      id: string
      activity: string
      updatedAt: any
      createdAt: any
      data: any
      targetIds: Array<string>
      userId?: string | null
      user?: {
        __typename?: 'User'
        id: string
        email: string
        phone?: string | null
      } | null
    }>
  } | null
}

export type DeleteMentorSessionMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteMentorSessionMutation = {
  __typename?: 'Mutation'
  deleteMentorSession: boolean
}

export type CanceledMentorSessionEventQueryVariables = Exact<{
  eventId: Scalars['ID']['input']
}>

export type CanceledMentorSessionEventQuery = {
  __typename?: 'Query'
  mentorSessionEvent: {
    __typename?: 'MentorSessionEvent'
    id: string
    calendarEvent: {
      __typename?: 'CalendarEvent'
      id: string
      startsAt: any
      endsAt: any
    }
  }
}

export type MentorSessionEventRescheduleRequestActivityLogItemFragment = {
  __typename?: 'ActivityLog'
  id: string
  activity: string
  updatedAt: any
  createdAt: any
  data: any
  targetIds: Array<string>
}

export type MentorSessionEventRescheduleRequestQueryVariables = Exact<{
  userRequestId: Scalars['ID']['input']
}>

export type MentorSessionEventRescheduleRequestQuery = {
  __typename?: 'Query'
  mentorSessionEventRescheduleRequest?: {
    __typename?: 'MentorSessionEventRescheduleRequest'
    id: string
    userRequestId: string
    endsAt: any
    startsAt: any
    mentorSessionEventId: string
    reason?: string | null
    status: UserRequestStatus
    expiresOn: any
    userRequest: {
      __typename?: 'UserRequest'
      id: string
      recipientUserIds: Array<string>
      status: UserRequestStatus
    }
    mentorSessionEvent: {
      __typename?: 'MentorSessionEvent'
      id: string
      createdAt: any
      status: MentorSessionEventStatus
      mentorSessionId: string
      calendarEvent: {
        __typename?: 'CalendarEvent'
        id: string
        startsAt: any
        endsAt: any
      }
      mentorSession: {
        __typename?: 'MentorSession'
        id: string
        course?: { __typename?: 'Course'; id: string; title: string } | null
      }
    }
    activityLogs?: Array<{
      __typename?: 'ActivityLog'
      id: string
      activity: string
      updatedAt: any
      createdAt: any
      data: any
      targetIds: Array<string>
    }> | null
  } | null
}

export type CancelMentorSessionEventRescheduleRequestMutationVariables = Exact<{
  userRequestId: Scalars['ID']['input']
  input: CancelMentorSessionEventRescheduleRequestInput
}>

export type CancelMentorSessionEventRescheduleRequestMutation = {
  __typename?: 'Mutation'
  cancelMentorSessionEventRescheduleRequest: {
    __typename?: 'UserRequest'
    id: string
    status: UserRequestStatus
  }
}

export type ResponseMentorSessionEventRescheduleRequestMutationVariables =
  Exact<{
    input: CreateMentorSessionEventRescheduleResponseInput
  }>

export type ResponseMentorSessionEventRescheduleRequestMutation = {
  __typename?: 'Mutation'
  createMentorSessionEventRescheduleResponse: {
    __typename?: 'MentorSessionEvent'
    mentorSessionId: string
    id: string
    status: MentorSessionEventStatus
    zoomMeetingId?: string | null
    meetingRecordings: Array<{ __typename?: 'MeetingRecording'; id: string }>
    mentorSessionRecapReport: {
      __typename?: 'MentorSessionRecapReport'
      id: string
      requiredRecapCount: {
        __typename?: 'MentorMenteeCount'
        id: string
        mentee: number
        mentor: number
      }
      mentorSessionRecapCount: {
        __typename?: 'MentorMenteeCount'
        id: string
        mentee: number
        mentor: number
      }
    }
    mentorSessionRecaps: Array<{
      __typename?: 'MentorSessionRecap'
      id: string
      createdAt: any
      type: MentorSessionRecapType
      mentorSessionEventCalendarEventId: string
      createdByUser: {
        __typename?: 'User'
        id: string
        name: string
        email: string
        phone?: string | null
      }
      mentor: {
        __typename?: 'Mentor'
        id: string
        user?: {
          __typename?: 'User'
          id: string
          name: string
          email: string
          phone?: string | null
        } | null
      }
      learner: {
        __typename?: 'Learner'
        id: string
        user?: {
          __typename?: 'User'
          id: string
          name: string
          email: string
          phone?: string | null
        } | null
      }
      formSubmission: {
        __typename?: 'FormSubmission'
        id: string
        content: string
      }
    }>
    rescheduleRequests?: Array<{
      __typename?: 'MentorSessionEventRescheduleRequest'
      id: string
      startsAt: any
      endsAt: any
      userRequestId: string
      status: UserRequestStatus
      expiresOn: any
      reason?: string | null
      userRequest: {
        __typename?: 'UserRequest'
        createdAt: any
        id: string
        recipientUserIds: Array<string>
        status: UserRequestStatus
        type: UserRequestType
        updatedAt: any
      }
    }> | null
    zoomMeetingRecordings: Array<{ __typename?: 'ZoomRecording'; id: string }>
  }
}

export type CreateMentorSessionRecapForMentorMutationVariables = Exact<{
  input: CreateMentorSessionRecapInput
}>

export type CreateMentorSessionRecapForMentorMutation = {
  __typename?: 'Mutation'
  createMentorSessionRecap: {
    __typename?: 'MentorSessionRecap'
    id: string
    formSubmissionId: string
    mentorSessionEvent: {
      __typename?: 'MentorSessionEvent'
      id: string
      mentorSessionRecaps: Array<{
        __typename?: 'MentorSessionRecap'
        id: string
      }>
    }
  }
}

export type LearnerLatestLwSsoActivityQueryVariables = Exact<{
  learnerId: Scalars['ID']['input']
}>

export type LearnerLatestLwSsoActivityQuery = {
  __typename?: 'Query'
  latestLearnerLwSSOActivity?: {
    __typename?: 'ActivityLog'
    id: string
    user?: { __typename?: 'User'; id: string } | null
  } | null
}

export type CancelSessionEventForLearnerMutationVariables = Exact<{
  id: Scalars['ID']['input']
  cancelReason?: InputMaybe<Scalars['String']['input']>
}>

export type CancelSessionEventForLearnerMutation = {
  __typename?: 'Mutation'
  cancelMentorSessionEvent: {
    __typename?: 'MentorSessionEvent'
    id: string
    status: MentorSessionEventStatus
    mentorSession: {
      __typename?: 'MentorSession'
      id: string
      status: MentorSessionStatus
      course?: { __typename?: 'Course'; id: string; title: string } | null
      courseMembers: Array<{
        __typename?: 'CourseMember'
        id: string
        userId?: string | null
        status: CourseMemberStatus
        type: CourseMemberType
        learnerId?: string | null
        mentorId?: string | null
        learningPurpose?: string | null
        learningType?: string | null
        monthToChangeJob?: number | null
        studyTimePerWeek?: number | null
        courseExpectation?: string | null
        jobExpectation?: string | null
        enrollmentDate?: any | null
        user?: {
          __typename?: 'User'
          id: string
          name: string
          email: string
          phone?: string | null
        } | null
        learner?: {
          __typename?: 'Learner'
          id: string
          codingSkill?: string | null
          discordId?: string | null
          expYears?: string | null
          industry?: string | null
          jobTitle?: string | null
          userId: string
        } | null
        mentor?: {
          __typename?: 'Mentor'
          id: string
          userId: string
          jobTitle?: string | null
          discordId?: string | null
          expYears?: string | null
          status: MentorStatus
          organization?: string | null
          city?: string | null
        } | null
        latestProgressReport?: {
          __typename?: 'CourseMemberProgressReport'
          id: string
          createdAt: any
          courseMemberId: string
          averageScore?: number | null
          overallProgress: number
          studyTimeDuringPeriod: number
          learningVelocity?: LearningVelocity | null
          weeksOfftrack?: number | null
          startSyncedAt?: any | null
          endSyncedAt: any
          currentUnit: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          }
          currentUnitPath?: {
            __typename?: 'CourseUnitPath'
            id: string
            section: { __typename?: 'CourseSection'; id: string; title: string }
            path: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
            }>
          } | null
          nextUnitPath?: {
            __typename?: 'CourseUnitPath'
            id: string
            section: { __typename?: 'CourseSection'; id: string; title: string }
            path: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
            }>
          } | null
          currentSection?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          } | null
          currentMiniCourse?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            section?: {
              __typename?: 'CourseSection'
              nextCourseSection?: {
                __typename?: 'CourseSection'
                id: string
                title: string
              } | null
            } | null
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          } | null
        } | null
      }>
      calendarEvents: Array<{
        __typename?: 'CalendarEvent'
        id: string
        name: string
        startsAt: any
        endsAt: any
        recurrence?: string | null
        origin: CalendarEventOrigin
        attendees: Array<{
          __typename?: 'UserEvent'
          id: string
          userId: string
          status: UserEventStatus
        }>
        mentorSessionEvent?: {
          __typename?: 'MentorSessionEvent'
          id: string
          status: MentorSessionEventStatus
          zoomMeetingId?: string | null
          meetingRecordings: Array<{
            __typename?: 'MeetingRecording'
            id: string
          }>
          mentorSessionRecapReport: {
            __typename?: 'MentorSessionRecapReport'
            id: string
            requiredRecapCount: {
              __typename?: 'MentorMenteeCount'
              id: string
              mentee: number
              mentor: number
            }
            mentorSessionRecapCount: {
              __typename?: 'MentorMenteeCount'
              id: string
              mentee: number
              mentor: number
            }
          }
          mentorSessionRecaps: Array<{
            __typename?: 'MentorSessionRecap'
            id: string
            createdAt: any
            type: MentorSessionRecapType
            mentorSessionEventCalendarEventId: string
            createdByUser: {
              __typename?: 'User'
              id: string
              name: string
              email: string
              phone?: string | null
            }
            mentor: {
              __typename?: 'Mentor'
              id: string
              user?: {
                __typename?: 'User'
                id: string
                name: string
                email: string
                phone?: string | null
              } | null
            }
            learner: {
              __typename?: 'Learner'
              id: string
              user?: {
                __typename?: 'User'
                id: string
                name: string
                email: string
                phone?: string | null
              } | null
            }
            formSubmission: {
              __typename?: 'FormSubmission'
              id: string
              content: string
            }
          }>
          rescheduleRequests?: Array<{
            __typename?: 'MentorSessionEventRescheduleRequest'
            id: string
            startsAt: any
            endsAt: any
            userRequestId: string
            status: UserRequestStatus
            expiresOn: any
            reason?: string | null
            userRequest: {
              __typename?: 'UserRequest'
              createdAt: any
              id: string
              recipientUserIds: Array<string>
              status: UserRequestStatus
              type: UserRequestType
              updatedAt: any
            }
          }> | null
          zoomMeetingRecordings: Array<{
            __typename?: 'ZoomRecording'
            id: string
          }>
        } | null
      }>
      mentorSessionRecapReport: {
        __typename?: 'MentorSessionRecapReport'
        id: string
        requiredRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
        mentorSessionRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
      }
      activityLogs: Array<{
        __typename?: 'ActivityLog'
        id: string
        activity: string
        updatedAt: any
        createdAt: any
        data: any
        targetIds: Array<string>
        userId?: string | null
        user?: {
          __typename?: 'User'
          id: string
          email: string
          phone?: string | null
        } | null
      }>
    }
  }
}

export type MentorSessionItemFragment = {
  __typename?: 'MentorSession'
  id: string
  status: MentorSessionStatus
  calendarEvents: Array<{
    __typename?: 'CalendarEvent'
    id: string
    startsAt: any
    endsAt: any
    mentorSessionEvent?: {
      __typename?: 'MentorSessionEvent'
      id: string
      status: MentorSessionEventStatus
    } | null
  }>
  course?: { __typename?: 'Course'; id: string; title: string } | null
  courseMembers: Array<{
    __typename?: 'CourseMember'
    id: string
    userId?: string | null
    type: CourseMemberType
  }>
  nextMentorSessionEvent?: {
    __typename?: 'MentorSessionEvent'
    id: string
    calendarEvent: {
      __typename?: 'CalendarEvent'
      id: string
      startsAt: any
      endsAt: any
    }
  } | null
}

export type MentorSessionsOfMentorQueryVariables = Exact<{
  mentorId: Scalars['ID']['input']
  courseIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
}>

export type MentorSessionsOfMentorQuery = {
  __typename?: 'Query'
  mentorSessions: Array<{
    __typename?: 'MentorSession'
    id: string
    status: MentorSessionStatus
    calendarEvents: Array<{
      __typename?: 'CalendarEvent'
      id: string
      startsAt: any
      endsAt: any
      mentorSessionEvent?: {
        __typename?: 'MentorSessionEvent'
        id: string
        status: MentorSessionEventStatus
      } | null
    }>
    course?: { __typename?: 'Course'; id: string; title: string } | null
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      userId?: string | null
      type: CourseMemberType
    }>
    nextMentorSessionEvent?: {
      __typename?: 'MentorSessionEvent'
      id: string
      calendarEvent: {
        __typename?: 'CalendarEvent'
        id: string
        startsAt: any
        endsAt: any
      }
    } | null
  }>
}

export type CancelSessionEventForMentorMutationVariables = Exact<{
  id: Scalars['ID']['input']
  cancelReason?: InputMaybe<Scalars['String']['input']>
}>

export type CancelSessionEventForMentorMutation = {
  __typename?: 'Mutation'
  cancelMentorSessionEvent: {
    __typename?: 'MentorSessionEvent'
    id: string
    status: MentorSessionEventStatus
    mentorSession: {
      __typename?: 'MentorSession'
      id: string
      status: MentorSessionStatus
      course?: { __typename?: 'Course'; id: string; title: string } | null
      courseMembers: Array<{
        __typename?: 'CourseMember'
        id: string
        userId?: string | null
        status: CourseMemberStatus
        type: CourseMemberType
        learnerId?: string | null
        mentorId?: string | null
        learningPurpose?: string | null
        learningType?: string | null
        monthToChangeJob?: number | null
        studyTimePerWeek?: number | null
        courseExpectation?: string | null
        jobExpectation?: string | null
        enrollmentDate?: any | null
        user?: {
          __typename?: 'User'
          id: string
          name: string
          email: string
          phone?: string | null
        } | null
        learner?: {
          __typename?: 'Learner'
          id: string
          codingSkill?: string | null
          discordId?: string | null
          expYears?: string | null
          industry?: string | null
          jobTitle?: string | null
          userId: string
        } | null
        mentor?: {
          __typename?: 'Mentor'
          id: string
          userId: string
          jobTitle?: string | null
          discordId?: string | null
          expYears?: string | null
          status: MentorStatus
          organization?: string | null
          city?: string | null
        } | null
        latestProgressReport?: {
          __typename?: 'CourseMemberProgressReport'
          id: string
          createdAt: any
          courseMemberId: string
          averageScore?: number | null
          overallProgress: number
          studyTimeDuringPeriod: number
          learningVelocity?: LearningVelocity | null
          weeksOfftrack?: number | null
          startSyncedAt?: any | null
          endSyncedAt: any
          currentUnit: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          }
          currentUnitPath?: {
            __typename?: 'CourseUnitPath'
            id: string
            section: { __typename?: 'CourseSection'; id: string; title: string }
            path: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
            }>
          } | null
          nextUnitPath?: {
            __typename?: 'CourseUnitPath'
            id: string
            section: { __typename?: 'CourseSection'; id: string; title: string }
            path: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
            }>
          } | null
          currentSection?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          } | null
          currentMiniCourse?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            section?: {
              __typename?: 'CourseSection'
              nextCourseSection?: {
                __typename?: 'CourseSection'
                id: string
                title: string
              } | null
            } | null
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          } | null
        } | null
      }>
      calendarEvents: Array<{
        __typename?: 'CalendarEvent'
        id: string
        name: string
        startsAt: any
        endsAt: any
        recurrence?: string | null
        origin: CalendarEventOrigin
        attendees: Array<{
          __typename?: 'UserEvent'
          id: string
          userId: string
          status: UserEventStatus
        }>
        mentorSessionEvent?: {
          __typename?: 'MentorSessionEvent'
          id: string
          status: MentorSessionEventStatus
          zoomMeetingId?: string | null
          meetingRecordings: Array<{
            __typename?: 'MeetingRecording'
            id: string
          }>
          mentorSessionRecapReport: {
            __typename?: 'MentorSessionRecapReport'
            id: string
            requiredRecapCount: {
              __typename?: 'MentorMenteeCount'
              id: string
              mentee: number
              mentor: number
            }
            mentorSessionRecapCount: {
              __typename?: 'MentorMenteeCount'
              id: string
              mentee: number
              mentor: number
            }
          }
          mentorSessionRecaps: Array<{
            __typename?: 'MentorSessionRecap'
            id: string
            createdAt: any
            type: MentorSessionRecapType
            mentorSessionEventCalendarEventId: string
            createdByUser: {
              __typename?: 'User'
              id: string
              name: string
              email: string
              phone?: string | null
            }
            mentor: {
              __typename?: 'Mentor'
              id: string
              user?: {
                __typename?: 'User'
                id: string
                name: string
                email: string
                phone?: string | null
              } | null
            }
            learner: {
              __typename?: 'Learner'
              id: string
              user?: {
                __typename?: 'User'
                id: string
                name: string
                email: string
                phone?: string | null
              } | null
            }
            formSubmission: {
              __typename?: 'FormSubmission'
              id: string
              content: string
            }
          }>
          rescheduleRequests?: Array<{
            __typename?: 'MentorSessionEventRescheduleRequest'
            id: string
            startsAt: any
            endsAt: any
            userRequestId: string
            status: UserRequestStatus
            expiresOn: any
            reason?: string | null
            userRequest: {
              __typename?: 'UserRequest'
              createdAt: any
              id: string
              recipientUserIds: Array<string>
              status: UserRequestStatus
              type: UserRequestType
              updatedAt: any
            }
          }> | null
          zoomMeetingRecordings: Array<{
            __typename?: 'ZoomRecording'
            id: string
          }>
        } | null
      }>
      mentorSessionRecapReport: {
        __typename?: 'MentorSessionRecapReport'
        id: string
        requiredRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
        mentorSessionRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
      }
      activityLogs: Array<{
        __typename?: 'ActivityLog'
        id: string
        activity: string
        updatedAt: any
        createdAt: any
        data: any
        targetIds: Array<string>
        userId?: string | null
        user?: {
          __typename?: 'User'
          id: string
          email: string
          phone?: string | null
        } | null
      }>
    }
  }
}

export type CreateMentorSessionBookingRequestResponseMutationVariables = Exact<{
  input: CreateMentorSessionBookingResponseInput
}>

export type CreateMentorSessionBookingRequestResponseMutation = {
  __typename?: 'Mutation'
  createMentorSessionBookingResponse: {
    __typename?: 'UserResponse'
    id: string
  }
}

export type MentorTimesheetQueryVariables = Exact<{
  from?: InputMaybe<Scalars['Date']['input']>
  to?: InputMaybe<Scalars['Date']['input']>
}>

export type MentorTimesheetQuery = {
  __typename?: 'Query'
  mentorTimesheet: Array<{
    __typename?: 'MentorTimesheetItem'
    absentSessions: number
    canceledSessions: number
    courseTitle: string
    finishedSessions: number
    finishedSessionsWithMentorRecap: number
    mentorId: string
    mentorEmail: string
    mentorName?: string | null
    mentorUserId: string
  }>
}

export type MentorSessionEventConnectionQueryVariables = Exact<{
  from?: InputMaybe<Scalars['Date']['input']>
  to?: InputMaybe<Scalars['Date']['input']>
  learnerId?: InputMaybe<Scalars['ID']['input']>
  mentorId?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<
    Array<MentorSessionEventStatus> | MentorSessionEventStatus
  >
}>

export type MentorSessionEventConnectionQuery = {
  __typename?: 'Query'
  mentorSessionEventConnection_deprecated: {
    __typename?: 'MentorSessionEventConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'MentorSessionEvent'
      id: string
      calendarEventId: string
      startedAt?: any | null
      status: MentorSessionEventStatus
      createdAt: any
      mentorSessionId: string
      mentorSession: {
        __typename?: 'MentorSession'
        id: string
        course?: { __typename?: 'Course'; id: string; title: string } | null
        calendarEvents: Array<{
          __typename?: 'CalendarEvent'
          id: string
          startsAt: any
          endsAt: any
          mentorSessionEvent?: {
            __typename?: 'MentorSessionEvent'
            id: string
            calendarEventId: string
            status: MentorSessionEventStatus
          } | null
        }>
        courseMembers: Array<{
          __typename?: 'CourseMember'
          id: string
          userId?: string | null
          type: CourseMemberType
          status: CourseMemberStatus
        }>
      }
      calendarEvent: {
        __typename?: 'CalendarEvent'
        id: string
        name: string
        createdAt: any
        endsAt: any
        startsAt: any
        recurrence?: string | null
        attendees: Array<{
          __typename?: 'UserEvent'
          id: string
          userId: string
          status: UserEventStatus
        }>
      }
      mentorSessionRecapReport: {
        __typename?: 'MentorSessionRecapReport'
        id: string
        requiredRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
        mentorSessionRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
      }
      rescheduleRequests?: Array<{
        __typename?: 'MentorSessionEventRescheduleRequest'
        id: string
        startsAt: any
        endsAt: any
        userRequestId: string
        status: UserRequestStatus
        expiresOn: any
        reason?: string | null
        userRequest: {
          __typename?: 'UserRequest'
          createdAt: any
          id: string
          recipientUserIds: Array<string>
          status: UserRequestStatus
          type: UserRequestType
          updatedAt: any
        }
      }> | null
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type MentorSessionEventNodeFragment = {
  __typename?: 'MentorSessionEvent'
  id: string
  calendarEventId: string
  startedAt?: any | null
  status: MentorSessionEventStatus
  createdAt: any
  mentorSessionId: string
  mentorSession: {
    __typename?: 'MentorSession'
    id: string
    course?: { __typename?: 'Course'; id: string; title: string } | null
    calendarEvents: Array<{
      __typename?: 'CalendarEvent'
      id: string
      startsAt: any
      endsAt: any
      mentorSessionEvent?: {
        __typename?: 'MentorSessionEvent'
        id: string
        calendarEventId: string
        status: MentorSessionEventStatus
      } | null
    }>
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      userId?: string | null
      type: CourseMemberType
      status: CourseMemberStatus
    }>
  }
  calendarEvent: {
    __typename?: 'CalendarEvent'
    id: string
    name: string
    createdAt: any
    endsAt: any
    startsAt: any
    recurrence?: string | null
    attendees: Array<{
      __typename?: 'UserEvent'
      id: string
      userId: string
      status: UserEventStatus
    }>
  }
  mentorSessionRecapReport: {
    __typename?: 'MentorSessionRecapReport'
    id: string
    requiredRecapCount: {
      __typename?: 'MentorMenteeCount'
      id: string
      mentee: number
      mentor: number
    }
    mentorSessionRecapCount: {
      __typename?: 'MentorMenteeCount'
      id: string
      mentee: number
      mentor: number
    }
  }
  rescheduleRequests?: Array<{
    __typename?: 'MentorSessionEventRescheduleRequest'
    id: string
    startsAt: any
    endsAt: any
    userRequestId: string
    status: UserRequestStatus
    expiresOn: any
    reason?: string | null
    userRequest: {
      __typename?: 'UserRequest'
      createdAt: any
      id: string
      recipientUserIds: Array<string>
      status: UserRequestStatus
      type: UserRequestType
      updatedAt: any
    }
  }> | null
}

export type WorkplaceDashboardQueryVariables = Exact<{ [key: string]: never }>

export type WorkplaceDashboardQuery = {
  __typename?: 'Query'
  workplaceStatistic: {
    __typename?: 'WorkplaceStatistic'
    id: string
    deals: number
    learners: number
    mentors: number
    users: number
  }
}

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input']
  fromCrossroads?: InputMaybe<Scalars['Boolean']['input']>
}>

export type RequestResetPasswordMutation = {
  __typename?: 'Mutation'
  requestResetPassword: string
}

export type ResetUserPasswordMutationVariables = Exact<{
  token: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type ResetUserPasswordMutation = {
  __typename?: 'Mutation'
  resetUserPassword: {
    __typename?: 'User'
    id: string
    name: string
    email: string
  }
}

export type InitializePasswordMutationVariables = Exact<{
  token: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type InitializePasswordMutation = {
  __typename?: 'Mutation'
  setupUserPassword: {
    __typename?: 'User'
    id: string
    name: string
    email: string
  }
}

export type SignInMutationVariables = Exact<{
  credential?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  remember?: InputMaybe<Scalars['Boolean']['input']>
}>

export type SignInMutation = { __typename?: 'Mutation'; platformSignIn: string }

export type UserCalendarViewQueryVariables = Exact<{
  between: Array<Scalars['Date']['input']> | Scalars['Date']['input']
  calendarIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
}>

export type UserCalendarViewQuery = {
  __typename?: 'Query'
  userEvents: Array<{
    __typename?: 'UserEvent'
    id: string
    userId: string
    calendarId: string
    isCurrentUserHost: boolean
    status: UserEventStatus
    eventId: string
    event: {
      __typename?: 'CalendarEvent'
      id: string
      name: string
      description?: string | null
      startsAt: any
      endsAt: any
      recurrence?: string | null
    }
  }>
}

export type CreateEventMutationVariables = Exact<{
  input: CreateCalendarEventInput
}>

export type CreateEventMutation = {
  __typename?: 'Mutation'
  createCalendarEvent: {
    __typename?: 'CalendarEvent'
    id: string
    name: string
  }
}

export type UpdateEventEventInfoFragment = {
  __typename?: 'CalendarEvent'
  id: string
  name: string
  description?: string | null
  startsAt: any
  endsAt: any
  recurrence?: string | null
  recurrenceEventId?: string | null
  attendees: Array<{
    __typename?: 'UserEvent'
    id: string
    status: UserEventStatus
    isHost: boolean
    isOptional: boolean
    userId: string
    user: { __typename?: 'User'; id: string; email: string }
  }>
}

export type UpdateUserEventFragment = {
  __typename?: 'UserEvent'
  id: string
  eventId: string
  userId: string
  calendarId: string
  status: UserEventStatus
  calendar: {
    __typename?: 'Calendar'
    id: string
    name: string
    description?: string | null
  }
  event: {
    __typename?: 'CalendarEvent'
    id: string
    name: string
    description?: string | null
    startsAt: any
    endsAt: any
    recurrence?: string | null
    recurrenceEventId?: string | null
    attendees: Array<{
      __typename?: 'UserEvent'
      id: string
      status: UserEventStatus
      isHost: boolean
      isOptional: boolean
      userId: string
      user: { __typename?: 'User'; id: string; email: string }
    }>
  }
}

export type UpdateUserEventQueryVariables = Exact<{
  eventId: Scalars['ID']['input']
}>

export type UpdateUserEventQuery = {
  __typename?: 'Query'
  userEvent?: {
    __typename?: 'UserEvent'
    id: string
    eventId: string
    userId: string
    calendarId: string
    status: UserEventStatus
    calendar: {
      __typename?: 'Calendar'
      id: string
      name: string
      description?: string | null
    }
    event: {
      __typename?: 'CalendarEvent'
      id: string
      name: string
      description?: string | null
      startsAt: any
      endsAt: any
      recurrence?: string | null
      recurrenceEventId?: string | null
      attendees: Array<{
        __typename?: 'UserEvent'
        id: string
        status: UserEventStatus
        isHost: boolean
        isOptional: boolean
        userId: string
        user: { __typename?: 'User'; id: string; email: string }
      }>
    }
  } | null
}

export type UpdateCalendarEventMutationVariables = Exact<{
  eventId: Scalars['ID']['input']
  input: UpdateCalendarEventInput
}>

export type UpdateCalendarEventMutation = {
  __typename?: 'Mutation'
  updateCalendarEvent: {
    __typename?: 'CalendarEvent'
    id: string
    name: string
    description?: string | null
    startsAt: any
    endsAt: any
    recurrence?: string | null
    recurrenceEventId?: string | null
    attendees: Array<{
      __typename?: 'UserEvent'
      id: string
      status: UserEventStatus
      isHost: boolean
      isOptional: boolean
      userId: string
      user: { __typename?: 'User'; id: string; email: string }
    }>
  }
}

export type UserCalendarSelectQueryVariables = Exact<{ [key: string]: never }>

export type UserCalendarSelectQuery = {
  __typename?: 'Query'
  userCalendars: Array<{
    __typename?: 'UserCalendar'
    calendarId: string
    userId: string
    calendar?: {
      __typename?: 'Calendar'
      id: string
      name: string
      description?: string | null
      isPublic: boolean
    } | null
  }>
}

export type DeleteCalendarEventMutationVariables = Exact<{
  id: Scalars['ID']['input']
  type?: InputMaybe<CalendarEventRecurrenceUpdateOption>
}>

export type DeleteCalendarEventMutation = {
  __typename?: 'Mutation'
  cancelCalendarEvent: boolean
}

export type UserEventGoingStatusQueryVariables = Exact<{
  eventId: Scalars['ID']['input']
}>

export type UserEventGoingStatusQuery = {
  __typename?: 'Query'
  userEvent?: {
    __typename?: 'UserEvent'
    id: string
    status: UserEventStatus
  } | null
}

export type UpdateUserEventGoingStatusMutationVariables = Exact<{
  eventId: Scalars['ID']['input']
  status: UserEventStatus
}>

export type UpdateUserEventGoingStatusMutation = {
  __typename?: 'Mutation'
  updateUserEvent: {
    __typename?: 'UserEvent'
    id: string
    status: UserEventStatus
  }
}

export type UserEventQuickViewFragment = {
  __typename?: 'UserEvent'
  id: string
  eventId: string
  userId: string
  calendarId: string
  status: UserEventStatus
  calendar: {
    __typename?: 'Calendar'
    id: string
    name: string
    description?: string | null
  }
  event: {
    __typename?: 'CalendarEvent'
    id: string
    name: string
    description?: string | null
    startsAt: any
    endsAt: any
    recurrence?: string | null
    recurrenceEventId?: string | null
    attendees: Array<{
      __typename?: 'UserEvent'
      id: string
      status: UserEventStatus
      isHost: boolean
      isOptional: boolean
      userId: string
      user: { __typename?: 'User'; id: string; email: string }
    }>
  }
}

export type UserEventQuickViewQueryVariables = Exact<{
  eventId: Scalars['ID']['input']
}>

export type UserEventQuickViewQuery = {
  __typename?: 'Query'
  userEvent?: {
    __typename?: 'UserEvent'
    id: string
    eventId: string
    userId: string
    calendarId: string
    status: UserEventStatus
    calendar: {
      __typename?: 'Calendar'
      id: string
      name: string
      description?: string | null
    }
    event: {
      __typename?: 'CalendarEvent'
      id: string
      name: string
      description?: string | null
      startsAt: any
      endsAt: any
      recurrence?: string | null
      recurrenceEventId?: string | null
      attendees: Array<{
        __typename?: 'UserEvent'
        id: string
        status: UserEventStatus
        isHost: boolean
        isOptional: boolean
        userId: string
        user: { __typename?: 'User'; id: string; email: string }
      }>
    }
  } | null
}

export type CourseListConnectionQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
}>

export type CourseListConnectionQuery = {
  __typename?: 'Query'
  courseConnection: {
    __typename?: 'CourseConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'Course'
      id: string
      title: string
      description?: string | null
      access: CourseAccess
      lwCourseId?: string | null
      lwCourse?: {
        __typename?: 'LwCourse'
        id: string
        title: string
        courseImage?: string | null
      } | null
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type CourseViewUnitFragment = {
  __typename?: 'CourseUnit'
  id: string
  title: string
  order: number
  parentUnitId?: string | null
  deletedAt?: any | null
  lwLearningActivityId?: string | null
  lwSectionId?: string | null
  lwCourseId?: string | null
  lwSection?: {
    __typename?: 'LwCourseSection'
    id: string
    title: string
    description?: string | null
  } | null
}

export type CourseViewWithContentsFragment = {
  __typename?: 'Course'
  id: string
  title: string
  description?: string | null
  access: CourseAccess
  updatedAt: any
  lwCourseId?: string | null
  sections: Array<{
    __typename?: 'CourseSection'
    id: string
    title: string
    description?: string | null
    deletedAt?: any | null
    order: number
    lwCourseId?: string | null
    lwSectionId?: string | null
    units: Array<{
      __typename?: 'CourseUnit'
      id: string
      title: string
      order: number
      parentUnitId?: string | null
      deletedAt?: any | null
      lwLearningActivityId?: string | null
      lwSectionId?: string | null
      lwCourseId?: string | null
      children?: Array<{
        __typename?: 'CourseUnit'
        id: string
        title: string
        order: number
        parentUnitId?: string | null
        deletedAt?: any | null
        lwLearningActivityId?: string | null
        lwSectionId?: string | null
        lwCourseId?: string | null
        children?: Array<{
          __typename?: 'CourseUnit'
          id: string
          title: string
          order: number
          parentUnitId?: string | null
          deletedAt?: any | null
          lwLearningActivityId?: string | null
          lwSectionId?: string | null
          lwCourseId?: string | null
          children?: Array<{
            __typename?: 'CourseUnit'
            id: string
            title: string
            order: number
            parentUnitId?: string | null
            deletedAt?: any | null
            lwLearningActivityId?: string | null
            lwSectionId?: string | null
            lwCourseId?: string | null
            lwSection?: {
              __typename?: 'LwCourseSection'
              id: string
              title: string
              description?: string | null
            } | null
          }> | null
          lwSection?: {
            __typename?: 'LwCourseSection'
            id: string
            title: string
            description?: string | null
          } | null
        }> | null
        lwSection?: {
          __typename?: 'LwCourseSection'
          id: string
          title: string
          description?: string | null
        } | null
      }> | null
      lwSection?: {
        __typename?: 'LwCourseSection'
        id: string
        title: string
        description?: string | null
      } | null
    }>
  }>
}

export type CourseViewWithContentsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CourseViewWithContentsQuery = {
  __typename?: 'Query'
  course?: {
    __typename?: 'Course'
    id: string
    title: string
    description?: string | null
    access: CourseAccess
    updatedAt: any
    lwCourseId?: string | null
    sections: Array<{
      __typename?: 'CourseSection'
      id: string
      title: string
      description?: string | null
      deletedAt?: any | null
      order: number
      lwCourseId?: string | null
      lwSectionId?: string | null
      units: Array<{
        __typename?: 'CourseUnit'
        id: string
        title: string
        order: number
        parentUnitId?: string | null
        deletedAt?: any | null
        lwLearningActivityId?: string | null
        lwSectionId?: string | null
        lwCourseId?: string | null
        children?: Array<{
          __typename?: 'CourseUnit'
          id: string
          title: string
          order: number
          parentUnitId?: string | null
          deletedAt?: any | null
          lwLearningActivityId?: string | null
          lwSectionId?: string | null
          lwCourseId?: string | null
          children?: Array<{
            __typename?: 'CourseUnit'
            id: string
            title: string
            order: number
            parentUnitId?: string | null
            deletedAt?: any | null
            lwLearningActivityId?: string | null
            lwSectionId?: string | null
            lwCourseId?: string | null
            children?: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
              order: number
              parentUnitId?: string | null
              deletedAt?: any | null
              lwLearningActivityId?: string | null
              lwSectionId?: string | null
              lwCourseId?: string | null
              lwSection?: {
                __typename?: 'LwCourseSection'
                id: string
                title: string
                description?: string | null
              } | null
            }> | null
            lwSection?: {
              __typename?: 'LwCourseSection'
              id: string
              title: string
              description?: string | null
            } | null
          }> | null
          lwSection?: {
            __typename?: 'LwCourseSection'
            id: string
            title: string
            description?: string | null
          } | null
        }> | null
        lwSection?: {
          __typename?: 'LwCourseSection'
          id: string
          title: string
          description?: string | null
        } | null
      }>
    }>
  } | null
}

export type CourseMemberViewFragment = {
  __typename?: 'CourseMember'
  id: string
  courseId: string
  learnerId?: string | null
  user?: {
    __typename?: 'User'
    id: string
    email: string
    name: string
    roles: Array<UserRole>
    status: UserStatus
  } | null
  learner?: {
    __typename?: 'Learner'
    id: string
    codingSkill?: string | null
    industry?: string | null
    jobTitle?: string | null
    lwUser?: {
      __typename?: 'LwUser'
      id: string
      email: string
      tags: Array<string>
    } | null
  } | null
}

export type CourseMemberViewQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CourseMemberViewQuery = {
  __typename?: 'Query'
  courseMember?: {
    __typename?: 'CourseMember'
    id: string
    courseId: string
    learnerId?: string | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      name: string
      roles: Array<UserRole>
      status: UserStatus
    } | null
    learner?: {
      __typename?: 'Learner'
      id: string
      codingSkill?: string | null
      industry?: string | null
      jobTitle?: string | null
      lwUser?: {
        __typename?: 'LwUser'
        id: string
        email: string
        tags: Array<string>
      } | null
    } | null
  } | null
}

export type CourseMemberListItemFragment = {
  __typename?: 'CourseMember'
  id: string
  courseId: string
  status: CourseMemberStatus
  type: CourseMemberType
  userId?: string | null
  learnerId?: string | null
  mentorId?: string | null
  createdAt: any
  learner?: {
    __typename?: 'Learner'
    id: string
    userId: string
    lwUser?: { __typename?: 'LwUser'; id: string; email: string } | null
  } | null
  mentor?: {
    __typename?: 'Mentor'
    id: string
    userId: string
    lwUserId?: string | null
  } | null
}

export type CourseStaffListItemFragment = {
  __typename?: 'CourseStaff'
  id: string
  courseId: string
  userId: string
  status: CourseStaffStatus
  createdAt: any
}

export type CourseMemberConnectionQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>
  courseId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<CourseMemberStatus>
  type?: InputMaybe<CourseMemberType>
}>

export type CourseMemberConnectionQuery = {
  __typename?: 'Query'
  courseMemberConnection: {
    __typename?: 'CourseMemberConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'CourseMember'
      id: string
      courseId: string
      status: CourseMemberStatus
      type: CourseMemberType
      userId?: string | null
      learnerId?: string | null
      mentorId?: string | null
      createdAt: any
      learner?: {
        __typename?: 'Learner'
        id: string
        userId: string
        lwUser?: { __typename?: 'LwUser'; id: string; email: string } | null
      } | null
      mentor?: {
        __typename?: 'Mentor'
        id: string
        userId: string
        lwUserId?: string | null
      } | null
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type CourseStaffConnectionQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>
  courseId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<CourseStaffStatus>
}>

export type CourseStaffConnectionQuery = {
  __typename?: 'Query'
  courseStaffConnection: {
    __typename?: 'CourseStaffConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'CourseStaff'
      id: string
      courseId: string
      userId: string
      status: CourseStaffStatus
      createdAt: any
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type CreateCourseStaffMutationVariables = Exact<{
  courseId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}>

export type CreateCourseStaffMutation = {
  __typename?: 'Mutation'
  createCourseStaff: {
    __typename?: 'CourseStaff'
    id: string
    courseId: string
    userId: string
    status: CourseStaffStatus
    createdAt: any
  }
}

export type DeleteCourseStaffMutationVariables = Exact<{
  courseStaffId: Scalars['ID']['input']
}>

export type DeleteCourseStaffMutation = {
  __typename?: 'Mutation'
  hardDeleteCourseStaff: { __typename?: 'CourseStaff'; id: string }
}

export type UpdateCourseMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateCourseInput
}>

export type UpdateCourseMutation = {
  __typename?: 'Mutation'
  updateCourse: {
    __typename?: 'Course'
    id: string
    title: string
    description?: string | null
    totalAllowedTASessions?: number | null
    access: CourseAccess
    updatedAt: any
    lwCourseId?: string | null
    zohoProductId?: string | null
    discordServerId?: string | null
  }
}

export type DeleteCourseMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteCourseMutation = {
  __typename?: 'Mutation'
  deleteCourse: boolean
}

export type CourseTotalAllowedTaSessionsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CourseTotalAllowedTaSessionsQuery = {
  __typename?: 'Query'
  course?: {
    __typename?: 'Course'
    totalAllowedTASessions?: number | null
  } | null
}

export type CourseViewFragment = {
  __typename?: 'Course'
  id: string
  title: string
  description?: string | null
  totalAllowedTASessions?: number | null
  access: CourseAccess
  updatedAt: any
  lwCourseId?: string | null
  zohoProductId?: string | null
  discordServerId?: string | null
}

export type CourseViewQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CourseViewQuery = {
  __typename?: 'Query'
  course?: {
    __typename?: 'Course'
    id: string
    title: string
    description?: string | null
    totalAllowedTASessions?: number | null
    access: CourseAccess
    updatedAt: any
    lwCourseId?: string | null
    zohoProductId?: string | null
    discordServerId?: string | null
  } | null
  learnerConnection: {
    __typename?: 'CourseMemberConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
  mentorConnection: {
    __typename?: 'CourseMemberConnection'
    totalCount: number
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type CreateCourseMutationVariables = Exact<{
  input: CreateCourseInput
}>

export type CreateCourseMutation = {
  __typename?: 'Mutation'
  createCourse: { __typename?: 'Course'; id: string; title: string }
}

export type LwCourseContentsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type LwCourseContentsQuery = {
  __typename?: 'Query'
  lwCourse?: {
    __typename?: 'LwCourse'
    id: string
    title: string
    sections: Array<{
      __typename?: 'LwCourseSection'
      id: string
      title: string
      description?: string | null
      access: LwCourseSectionAccess
      learningUnits: Array<{
        __typename?: 'LwLearningUnit'
        id: string
        subtitle: string
        type: string
        icon: string
      }>
    }>
  } | null
}

export type UpdateCourseContentsMutationVariables = Exact<{
  id: Scalars['ID']['input']
  sections: Array<CourseSectionInput> | CourseSectionInput
  deletedSectionIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  deletedUnitIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
}>

export type UpdateCourseContentsMutation = {
  __typename?: 'Mutation'
  updateCourseContents: {
    __typename?: 'Course'
    id: string
    title: string
    description?: string | null
    access: CourseAccess
    updatedAt: any
    lwCourseId?: string | null
    sections: Array<{
      __typename?: 'CourseSection'
      id: string
      title: string
      description?: string | null
      deletedAt?: any | null
      order: number
      lwCourseId?: string | null
      lwSectionId?: string | null
      units: Array<{
        __typename?: 'CourseUnit'
        id: string
        title: string
        order: number
        parentUnitId?: string | null
        deletedAt?: any | null
        lwLearningActivityId?: string | null
        lwSectionId?: string | null
        lwCourseId?: string | null
        children?: Array<{
          __typename?: 'CourseUnit'
          id: string
          title: string
          order: number
          parentUnitId?: string | null
          deletedAt?: any | null
          lwLearningActivityId?: string | null
          lwSectionId?: string | null
          lwCourseId?: string | null
          children?: Array<{
            __typename?: 'CourseUnit'
            id: string
            title: string
            order: number
            parentUnitId?: string | null
            deletedAt?: any | null
            lwLearningActivityId?: string | null
            lwSectionId?: string | null
            lwCourseId?: string | null
            children?: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
              order: number
              parentUnitId?: string | null
              deletedAt?: any | null
              lwLearningActivityId?: string | null
              lwSectionId?: string | null
              lwCourseId?: string | null
              lwSection?: {
                __typename?: 'LwCourseSection'
                id: string
                title: string
                description?: string | null
              } | null
            }> | null
            lwSection?: {
              __typename?: 'LwCourseSection'
              id: string
              title: string
              description?: string | null
            } | null
          }> | null
          lwSection?: {
            __typename?: 'LwCourseSection'
            id: string
            title: string
            description?: string | null
          } | null
        }> | null
        lwSection?: {
          __typename?: 'LwCourseSection'
          id: string
          title: string
          description?: string | null
        } | null
      }>
    }>
  }
}

export type CourseSelectItemFragment = {
  __typename?: 'Course'
  id: string
  title: string
  description?: string | null
  lwCourseId?: string | null
  access: CourseAccess
  totalAllowedTASessions?: number | null
}

export type CourseSelectQueryVariables = Exact<{ [key: string]: never }>

export type CourseSelectQuery = {
  __typename?: 'Query'
  courseConnection: {
    __typename?: 'CourseConnection'
    nodes?: Array<{
      __typename?: 'Course'
      id: string
      title: string
      description?: string | null
      lwCourseId?: string | null
      access: CourseAccess
      totalAllowedTASessions?: number | null
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type LwCourseSelectQueryVariables = Exact<{ [key: string]: never }>

export type LwCourseSelectQuery = {
  __typename?: 'Query'
  lwCourses: Array<{
    __typename?: 'LwCourse'
    id: string
    title: string
    description?: string | null
    access: LwCourseAccess
  }>
}

export type ZohoProductSelectQueryVariables = Exact<{ [key: string]: never }>

export type ZohoProductSelectQuery = {
  __typename?: 'Query'
  zohoProducts: Array<{
    __typename?: 'ZohoProduct'
    id: string
    productName: string
  }>
}

export type DeveloperActivityLogConnectionFragment = {
  __typename?: 'ActivityLog'
  id: string
  createdAt: any
  data: any
  targetIds: Array<string>
  userId?: string | null
  activity: string
}

export type DeveloperActivityLogConnectionQueryVariables = Exact<{
  activities?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >
  searchText?: InputMaybe<Scalars['String']['input']>
  performedBy?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
  from?: InputMaybe<Scalars['Date']['input']>
  to?: InputMaybe<Scalars['Date']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}>

export type DeveloperActivityLogConnectionQuery = {
  __typename?: 'Query'
  activityLogConnection: {
    __typename?: 'ActivityLogConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'ActivityLog'
      id: string
      createdAt: any
      data: any
      targetIds: Array<string>
      userId?: string | null
      activity: string
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type SyncAllCourseMemberProgressFromLwMutationVariables = Exact<{
  [key: string]: never
}>

export type SyncAllCourseMemberProgressFromLwMutation = {
  __typename?: 'Mutation'
  syncAllCourseMemberProgressFromLw: any
}

export type NormalizeAllUserEmailsMutationVariables = Exact<{
  [key: string]: never
}>

export type NormalizeAllUserEmailsMutation = {
  __typename?: 'Mutation'
  normalizeAllUserEmails: any
}

export type UpdateFullTextSearchForAllUsersMutationVariables = Exact<{
  [key: string]: never
}>

export type UpdateFullTextSearchForAllUsersMutation = {
  __typename?: 'Mutation'
  updateFullTextSearchForAllUsers: any
}

export type EnsureLearnerLwUserTagsMutationVariables = Exact<{
  [key: string]: never
}>

export type EnsureLearnerLwUserTagsMutation = {
  __typename?: 'Mutation'
  message: string
}

export type UpdateFullTextSearchForAllBookingRequestsMutationVariables = Exact<{
  [key: string]: never
}>

export type UpdateFullTextSearchForAllBookingRequestsMutation = {
  __typename?: 'Mutation'
  updateFullTextSearchForAllBookingRequests: any
}

export type CheckExpiredMentorSessionBookingRequestsMutationVariables = Exact<{
  [key: string]: never
}>

export type CheckExpiredMentorSessionBookingRequestsMutation = {
  __typename?: 'Mutation'
  checkExpiredBookingRequests: number
}

export type NotifyAboutReturningLearnersMutationVariables = Exact<{
  [key: string]: never
}>

export type NotifyAboutReturningLearnersMutation = {
  __typename?: 'Mutation'
  notifyAboutReturningPostponedCourseMembers: boolean
}

export type FlattenAllCourseUnitsMutationVariables = Exact<{
  [key: string]: never
}>

export type FlattenAllCourseUnitsMutation = {
  __typename?: 'Mutation'
  flattenAllCourseUnits: any
}

export type UpdateTargetIdsOfAllActivitiesMutationVariables = Exact<{
  [key: string]: never
}>

export type UpdateTargetIdsOfAllActivitiesMutation = {
  __typename?: 'Mutation'
  updateTargetIdsOfAllRecapActivities: any
  updateTargetIdsOfAllRescheduleRequestActivities: any
}

export type SyncAllRecordingsFromVsdkToDbMutationVariables = Exact<{
  [key: string]: never
}>

export type SyncAllRecordingsFromVsdkToDbMutation = {
  __typename?: 'Mutation'
  syncAllRecordingsFromVSDKToDb: any
}

export type UpdateAllChatChannelsTypeToMentorSessionMutationVariables = Exact<{
  [key: string]: never
}>

export type UpdateAllChatChannelsTypeToMentorSessionMutation = {
  __typename?: 'Mutation'
  updateAllChatChannelsTypeToMentorSession: any
}

export type GenerateSecretSantaPairsMutationVariables = Exact<{
  [key: string]: never
}>

export type GenerateSecretSantaPairsMutation = {
  __typename?: 'Mutation'
  generateSecretSantaPairs: any
}

export type SyncSchoolEventsMutationVariables = Exact<{ [key: string]: never }>

export type SyncSchoolEventsMutation = {
  __typename?: 'Mutation'
  syncSchoolEvents: any
}

export type SyncAssignedLsStaffMutationVariables = Exact<{
  [key: string]: never
}>

export type SyncAssignedLsStaffMutation = {
  __typename?: 'Mutation'
  syncAssignedLSStaff: boolean
}

export type AppTokenListQueryVariables = Exact<{ [key: string]: never }>

export type AppTokenListQuery = {
  __typename?: 'Query'
  appTokens: Array<{
    __typename?: 'AppToken'
    id: string
    createdAt: any
    description: string
    expires?: any | null
    lastUsed?: any | null
    permissions: Array<Permission>
  }>
}

export type DeleteAppTokenMutationVariables = Exact<{
  idOrToken: Scalars['String']['input']
}>

export type DeleteAppTokenMutation = {
  __typename?: 'Mutation'
  deleteAppToken: boolean
}

export type CreateAppTokenMutationVariables = Exact<{
  input: CreateAppTokenInput
}>

export type CreateAppTokenMutation = {
  __typename?: 'Mutation'
  createAppToken: {
    __typename?: 'AppToken'
    id: string
    description: string
    token: string
    permissions: Array<Permission>
    expires?: any | null
  }
}

export type UpdateAppTokenMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateAppTokenInput
}>

export type UpdateAppTokenMutation = {
  __typename?: 'Mutation'
  updateAppToken: {
    __typename?: 'AppToken'
    id: string
    description: string
    token: string
    permissions: Array<Permission>
    expires?: any | null
  }
}

export type DealListNodeFragment = {
  __typename?: 'Deal'
  id: string
  classInterested?: string | null
  wonAt?: any | null
  createdAt: any
  personEmail?: string | null
  personName: string
  personPhone?: string | null
  subject?: string | null
  value?: number | null
  enrollmentDate?: any | null
  enrollment?: {
    __typename?: 'DealEnrollment'
    dealId: string
    courseMemberId?: string | null
    createdAt: any
  } | null
}

export type DealListConnectionQueryVariables = Exact<{
  status?: InputMaybe<PipedriveDealStatus>
  searchText?: InputMaybe<Scalars['String']['input']>
  limit?: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
}>

export type DealListConnectionQuery = {
  __typename?: 'Query'
  dealConnection: {
    __typename?: 'DealConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'Deal'
      id: string
      classInterested?: string | null
      wonAt?: any | null
      createdAt: any
      personEmail?: string | null
      personName: string
      personPhone?: string | null
      subject?: string | null
      value?: number | null
      enrollmentDate?: any | null
      enrollment?: {
        __typename?: 'DealEnrollment'
        dealId: string
        courseMemberId?: string | null
        createdAt: any
      } | null
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type DealViewFragment = {
  __typename?: 'Deal'
  id: string
  address?: string | null
  courseExpectation?: string | null
  dateOfBirth?: any | null
  enrollmentDate?: any | null
  gender?: Gender | null
  learningPurpose?: string | null
  linkedInUrl?: string | null
  nationalIdNumber?: string | null
  resumeUrl?: string | null
  newJobExpectation?: string | null
  organization?: string | null
  studyingCodingCoursesBefore?: string | null
  studyTimePerWeek?: number | null
  workingStatus?: string | null
  notes?: string | null
  classInterested?: string | null
  codingSkill?: string | null
  createdAt: any
  industry?: string | null
  jobTitle?: string | null
  leadForm?: string | null
  location?: string | null
  lookingForJob?: string | null
  ownerName?: string | null
  paymentType?: string | null
  personEmail?: string | null
  personName: string
  personPhone?: string | null
  source?: string | null
  status: PipedriveDealStatus
  subject?: string | null
  testScoreResult?: number | null
  updatedAt: any
  value?: number | null
  wonAt?: any | null
  yearsOfExp?: string | null
  user?: {
    __typename?: 'User'
    id: string
    email: string
    name: string
    status: UserStatus
  } | null
  learner?: {
    __typename?: 'Learner'
    id: string
    codingSkill?: string | null
    industry?: string | null
    jobTitle?: string | null
    userId: string
    learnerCode: number
    user?: {
      __typename?: 'User'
      id: string
      name: string
      email: string
    } | null
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      courseId: string
      status: CourseMemberStatus
    }>
  } | null
  enrollment?: {
    __typename?: 'DealEnrollment'
    dealId: string
    courseMemberId?: string | null
    createdAt: any
  } | null
}

export type DealViewQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DealViewQuery = {
  __typename?: 'Query'
  deal?: {
    __typename?: 'Deal'
    id: string
    address?: string | null
    courseExpectation?: string | null
    dateOfBirth?: any | null
    enrollmentDate?: any | null
    gender?: Gender | null
    learningPurpose?: string | null
    linkedInUrl?: string | null
    nationalIdNumber?: string | null
    resumeUrl?: string | null
    newJobExpectation?: string | null
    organization?: string | null
    studyingCodingCoursesBefore?: string | null
    studyTimePerWeek?: number | null
    workingStatus?: string | null
    notes?: string | null
    classInterested?: string | null
    codingSkill?: string | null
    createdAt: any
    industry?: string | null
    jobTitle?: string | null
    leadForm?: string | null
    location?: string | null
    lookingForJob?: string | null
    ownerName?: string | null
    paymentType?: string | null
    personEmail?: string | null
    personName: string
    personPhone?: string | null
    source?: string | null
    status: PipedriveDealStatus
    subject?: string | null
    testScoreResult?: number | null
    updatedAt: any
    value?: number | null
    wonAt?: any | null
    yearsOfExp?: string | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      name: string
      status: UserStatus
    } | null
    learner?: {
      __typename?: 'Learner'
      id: string
      codingSkill?: string | null
      industry?: string | null
      jobTitle?: string | null
      userId: string
      learnerCode: number
      user?: {
        __typename?: 'User'
        id: string
        name: string
        email: string
      } | null
      courseMembers: Array<{
        __typename?: 'CourseMember'
        id: string
        courseId: string
        status: CourseMemberStatus
      }>
    } | null
    enrollment?: {
      __typename?: 'DealEnrollment'
      dealId: string
      courseMemberId?: string | null
      createdAt: any
    } | null
  } | null
}

export type CreateLearnerToEnrollDealMutationVariables = Exact<{
  input: CreateLearnerInput
}>

export type CreateLearnerToEnrollDealMutation = {
  __typename?: 'Mutation'
  createLearner: {
    __typename?: 'Learner'
    id: string
    userId: string
    learnerCode: number
    user?: { __typename?: 'User'; name: string; id: string } | null
  }
}

export type EnrollLearnerToCourseMutationVariables = Exact<{
  input: EnrollLearnerToCourseInput
}>

export type EnrollLearnerToCourseMutation = {
  __typename?: 'Mutation'
  enrollLearner: {
    __typename?: 'CourseMember'
    id: string
    learnerId?: string | null
    status: CourseMemberStatus
    type: CourseMemberType
  }
}

export type MarkDealAsEnrolledMutationVariables = Exact<{
  input: CreateDealEnrollmentInput
}>

export type MarkDealAsEnrolledMutation = {
  __typename?: 'Mutation'
  createDealEnrollment: {
    __typename?: 'DealEnrollment'
    dealId: string
    courseMemberId?: string | null
  }
}

export type LearnerProfileByUserIdQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type LearnerProfileByUserIdQuery = {
  __typename?: 'Query'
  learner?: {
    __typename?: 'Learner'
    id: string
    codingSkill?: string | null
    industry?: string | null
    jobTitle?: string | null
    userId: string
    learnerCode: number
    user?: {
      __typename?: 'User'
      id: string
      name: string
      email: string
    } | null
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      courseId: string
      status: CourseMemberStatus
    }>
  } | null
}

export type UserQueryVariables = Exact<{
  email: Scalars['String']['input']
}>

export type UserQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    id: string
    email: string
    name: string
    roles: Array<UserRole>
  } | null
}

export type LearnerMemberQueryVariables = Exact<{
  learnerId: Scalars['ID']['input']
}>

export type LearnerMemberQuery = {
  __typename?: 'Query'
  learner?: {
    __typename?: 'Learner'
    id: string
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      course?: { __typename?: 'Course'; id: string } | null
    }>
  } | null
}

export type CourseMemberNodeFragment = {
  __typename?: 'CourseMember'
  id: string
  status: CourseMemberStatus
  type: CourseMemberType
  enrollmentDate?: any | null
  createdAt: any
  course?: { __typename?: 'Course'; id: string; title: string } | null
  learner?: {
    __typename?: 'Learner'
    id: string
    lwUserId?: string | null
    user?: {
      __typename?: 'User'
      id: string
      name: string
      email: string
    } | null
  } | null
  mentor?: {
    __typename?: 'Mentor'
    id: string
    lwUserId?: string | null
    user?: {
      __typename?: 'User'
      id: string
      name: string
      email: string
    } | null
  } | null
}

export type PendingCourseMemberConnectionQueryVariables = Exact<{
  type?: InputMaybe<CourseMemberType>
  courseId?: InputMaybe<Scalars['ID']['input']>
  enrollmentDate?: InputMaybe<Scalars['Date']['input']>
  limit?: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
}>

export type PendingCourseMemberConnectionQuery = {
  __typename?: 'Query'
  courseMemberConnection: {
    __typename?: 'CourseMemberConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'CourseMember'
      id: string
      status: CourseMemberStatus
      type: CourseMemberType
      enrollmentDate?: any | null
      createdAt: any
      course?: { __typename?: 'Course'; id: string; title: string } | null
      learner?: {
        __typename?: 'Learner'
        id: string
        lwUserId?: string | null
        user?: {
          __typename?: 'User'
          id: string
          name: string
          email: string
        } | null
      } | null
      mentor?: {
        __typename?: 'Mentor'
        id: string
        lwUserId?: string | null
        user?: {
          __typename?: 'User'
          id: string
          name: string
          email: string
        } | null
      } | null
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type PendingMemberStatisticsQueryVariables = Exact<{
  [key: string]: never
}>

export type PendingMemberStatisticsQuery = {
  __typename?: 'Query'
  workplaceStatistic: {
    __typename?: 'WorkplaceStatistic'
    id: string
    pendingDSMembers: number
    pendingWebMembers: number
    pendingLearners: number
    pendingMentors: number
  }
}

export type EnrollToLwMutationVariables = Exact<{
  courseMemberId: Scalars['String']['input']
}>

export type EnrollToLwMutation = {
  __typename?: 'Mutation'
  enrollPendingMember: {
    __typename?: 'CourseMember'
    id: string
    status: CourseMemberStatus
    type: CourseMemberType
    enrollmentDate?: any | null
    createdAt: any
    course?: { __typename?: 'Course'; id: string; title: string } | null
    learner?: {
      __typename?: 'Learner'
      id: string
      lwUserId?: string | null
      user?: {
        __typename?: 'User'
        id: string
        name: string
        email: string
      } | null
    } | null
    mentor?: {
      __typename?: 'Mentor'
      id: string
      lwUserId?: string | null
      user?: {
        __typename?: 'User'
        id: string
        name: string
        email: string
      } | null
    } | null
  }
}

export type LearnerListCourseMemberNodeFragment = {
  __typename?: 'CourseMember'
  id: string
  enrollmentDate?: any | null
  status: CourseMemberStatus
  course?: { __typename?: 'Course'; id: string; title: string } | null
  latestProgressReport?: {
    __typename?: 'CourseMemberProgressReport'
    id: string
    createdAt: any
    courseMemberId: string
    averageScore?: number | null
    overallProgress: number
    studyTimeDuringPeriod: number
    learningVelocity?: LearningVelocity | null
    weeksOfftrack?: number | null
    startSyncedAt?: any | null
    endSyncedAt: any
    currentUnit: {
      __typename?: 'CourseUnit'
      id: string
      title: string
      nextCourseUnit?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
      } | null
    }
    currentUnitPath?: {
      __typename?: 'CourseUnitPath'
      id: string
      section: { __typename?: 'CourseSection'; id: string; title: string }
      path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
    } | null
    nextUnitPath?: {
      __typename?: 'CourseUnitPath'
      id: string
      section: { __typename?: 'CourseSection'; id: string; title: string }
      path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
    } | null
    currentSection?: {
      __typename?: 'CourseUnit'
      id: string
      title: string
      nextCourseUnit?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
      } | null
    } | null
    currentMiniCourse?: {
      __typename?: 'CourseUnit'
      id: string
      title: string
      section?: {
        __typename?: 'CourseSection'
        nextCourseSection?: {
          __typename?: 'CourseSection'
          id: string
          title: string
        } | null
      } | null
      nextCourseUnit?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
      } | null
    } | null
  } | null
}

export type LearnerListNodeFragment = {
  __typename?: 'Learner'
  id: string
  codingSkill?: string | null
  createdAt: any
  discordId?: string | null
  expYears?: string | null
  industry?: string | null
  jobTitle?: string | null
  userId: string
  learnerCode: number
  courseMembers: Array<{
    __typename?: 'CourseMember'
    id: string
    enrollmentDate?: any | null
    status: CourseMemberStatus
    course?: { __typename?: 'Course'; id: string; title: string } | null
    latestProgressReport?: {
      __typename?: 'CourseMemberProgressReport'
      id: string
      createdAt: any
      courseMemberId: string
      averageScore?: number | null
      overallProgress: number
      studyTimeDuringPeriod: number
      learningVelocity?: LearningVelocity | null
      weeksOfftrack?: number | null
      startSyncedAt?: any | null
      endSyncedAt: any
      currentUnit: {
        __typename?: 'CourseUnit'
        id: string
        title: string
        nextCourseUnit?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
        } | null
      }
      currentUnitPath?: {
        __typename?: 'CourseUnitPath'
        id: string
        section: { __typename?: 'CourseSection'; id: string; title: string }
        path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
      } | null
      nextUnitPath?: {
        __typename?: 'CourseUnitPath'
        id: string
        section: { __typename?: 'CourseSection'; id: string; title: string }
        path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
      } | null
      currentSection?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
        nextCourseUnit?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
        } | null
      } | null
      currentMiniCourse?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
        section?: {
          __typename?: 'CourseSection'
          nextCourseSection?: {
            __typename?: 'CourseSection'
            id: string
            title: string
          } | null
        } | null
        nextCourseUnit?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
        } | null
      } | null
    } | null
  }>
  user?: {
    __typename?: 'User'
    id: string
    email: string
    name: string
    status: UserStatus
  } | null
}

export type LearnerConnectionQueryVariables = Exact<{
  first?: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  userStatus?: InputMaybe<UserStatus>
  courseMemberStatus?: InputMaybe<
    Array<CourseMemberStatus> | CourseMemberStatus
  >
  courseIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  learningVelocity?: InputMaybe<LearningVelocity>
  enrollmentDate?: InputMaybe<Scalars['Date']['input']>
}>

export type LearnerConnectionQuery = {
  __typename?: 'Query'
  learnerConnection: {
    __typename?: 'LearnerConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'Learner'
      id: string
      codingSkill?: string | null
      createdAt: any
      discordId?: string | null
      expYears?: string | null
      industry?: string | null
      jobTitle?: string | null
      userId: string
      learnerCode: number
      courseMembers: Array<{
        __typename?: 'CourseMember'
        id: string
        enrollmentDate?: any | null
        status: CourseMemberStatus
        course?: { __typename?: 'Course'; id: string; title: string } | null
        latestProgressReport?: {
          __typename?: 'CourseMemberProgressReport'
          id: string
          createdAt: any
          courseMemberId: string
          averageScore?: number | null
          overallProgress: number
          studyTimeDuringPeriod: number
          learningVelocity?: LearningVelocity | null
          weeksOfftrack?: number | null
          startSyncedAt?: any | null
          endSyncedAt: any
          currentUnit: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          }
          currentUnitPath?: {
            __typename?: 'CourseUnitPath'
            id: string
            section: { __typename?: 'CourseSection'; id: string; title: string }
            path: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
            }>
          } | null
          nextUnitPath?: {
            __typename?: 'CourseUnitPath'
            id: string
            section: { __typename?: 'CourseSection'; id: string; title: string }
            path: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
            }>
          } | null
          currentSection?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          } | null
          currentMiniCourse?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            section?: {
              __typename?: 'CourseSection'
              nextCourseSection?: {
                __typename?: 'CourseSection'
                id: string
                title: string
              } | null
            } | null
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          } | null
        } | null
      }>
      user?: {
        __typename?: 'User'
        id: string
        email: string
        name: string
        status: UserStatus
      } | null
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type EnrollmentBatchesQueryVariables = Exact<{ [key: string]: never }>

export type EnrollmentBatchesQuery = {
  __typename?: 'Query'
  getAllEnrollmentBatches?: Array<{
    __typename?: 'EnrollmentBatch'
    id: string
    date: any
  }> | null
}

export type LearnerViewNodeFragment = {
  __typename?: 'Learner'
  id: string
  codingSkill?: string | null
  industry?: string | null
  jobTitle?: string | null
  userId: string
  learnerCode: number
  user?: { __typename?: 'User'; id: string; name: string; email: string } | null
  courseMembers: Array<{
    __typename?: 'CourseMember'
    id: string
    courseId: string
    status: CourseMemberStatus
  }>
}

export type LearnerViewQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type LearnerViewQuery = {
  __typename?: 'Query'
  learner?: {
    __typename?: 'Learner'
    id: string
    codingSkill?: string | null
    industry?: string | null
    jobTitle?: string | null
    userId: string
    learnerCode: number
    user?: {
      __typename?: 'User'
      id: string
      name: string
      email: string
    } | null
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      courseId: string
      status: CourseMemberStatus
    }>
  } | null
}

export type LearnerProfileCourseMemberFragment = {
  __typename?: 'CourseMember'
  id: string
  learningType?: string | null
  enrollmentDate?: any | null
  paymentType?: string | null
  learningPurpose?: string | null
  courseExpectation?: string | null
  studyTimePerWeek?: number | null
  monthToChangeJob?: number | null
  jobExpectation?: string | null
  reasonToSwitchCareer?: string | null
  source?: string | null
  status: CourseMemberStatus
  remainingMentorSessionEvents?: number | null
  redeemedMentorSessionEvents?: number | null
  totalAllowedTASessions?: number | null
  totalMentorSessionEvents?: number | null
  course?: { __typename?: 'Course'; id: string; title: string } | null
  latestProgressReport?: {
    __typename?: 'CourseMemberProgressReport'
    id: string
    createdAt: any
    courseMemberId: string
    averageScore?: number | null
    overallProgress: number
    studyTimeDuringPeriod: number
    learningVelocity?: LearningVelocity | null
    weeksOfftrack?: number | null
    startSyncedAt?: any | null
    endSyncedAt: any
    currentUnit: {
      __typename?: 'CourseUnit'
      id: string
      title: string
      nextCourseUnit?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
      } | null
    }
    currentUnitPath?: {
      __typename?: 'CourseUnitPath'
      id: string
      section: { __typename?: 'CourseSection'; id: string; title: string }
      path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
    } | null
    nextUnitPath?: {
      __typename?: 'CourseUnitPath'
      id: string
      section: { __typename?: 'CourseSection'; id: string; title: string }
      path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
    } | null
    currentSection?: {
      __typename?: 'CourseUnit'
      id: string
      title: string
      nextCourseUnit?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
      } | null
    } | null
    currentMiniCourse?: {
      __typename?: 'CourseUnit'
      id: string
      title: string
      section?: {
        __typename?: 'CourseSection'
        nextCourseSection?: {
          __typename?: 'CourseSection'
          id: string
          title: string
        } | null
      } | null
      nextCourseUnit?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
      } | null
    } | null
  } | null
}

export type LearnerProfileFragment = {
  __typename?: 'Learner'
  id: string
  address?: string | null
  createdAt: any
  dateOfBirth?: any | null
  discordId?: string | null
  englishLevel?: string | null
  expYears?: string | null
  gender?: Gender | null
  industry?: string | null
  jobTitle?: string | null
  learnerCode: number
  linkedinURL?: string | null
  nationalIdIssuedDate?: any | null
  nationalIdIssuedPlace?: string | null
  nationalIdNumber?: string | null
  notes?: string | null
  organization?: string | null
  priorCodingCourses?: string | null
  resumeURL?: string | null
  secondaryEmail?: string | null
  secondaryPhoneNumber?: string | null
  workingStatus?: string | null
  userId: string
  lwUserId?: string | null
  lwUser?: {
    __typename?: 'LwUser'
    id: string
    email: string
    username: string
  } | null
  user?: {
    __typename?: 'User'
    id: string
    email: string
    name: string
    phone?: string | null
  } | null
  courseMembers: Array<{
    __typename?: 'CourseMember'
    id: string
    learningType?: string | null
    enrollmentDate?: any | null
    paymentType?: string | null
    learningPurpose?: string | null
    courseExpectation?: string | null
    studyTimePerWeek?: number | null
    monthToChangeJob?: number | null
    jobExpectation?: string | null
    reasonToSwitchCareer?: string | null
    source?: string | null
    status: CourseMemberStatus
    remainingMentorSessionEvents?: number | null
    redeemedMentorSessionEvents?: number | null
    totalAllowedTASessions?: number | null
    totalMentorSessionEvents?: number | null
    course?: { __typename?: 'Course'; id: string; title: string } | null
    latestProgressReport?: {
      __typename?: 'CourseMemberProgressReport'
      id: string
      createdAt: any
      courseMemberId: string
      averageScore?: number | null
      overallProgress: number
      studyTimeDuringPeriod: number
      learningVelocity?: LearningVelocity | null
      weeksOfftrack?: number | null
      startSyncedAt?: any | null
      endSyncedAt: any
      currentUnit: {
        __typename?: 'CourseUnit'
        id: string
        title: string
        nextCourseUnit?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
        } | null
      }
      currentUnitPath?: {
        __typename?: 'CourseUnitPath'
        id: string
        section: { __typename?: 'CourseSection'; id: string; title: string }
        path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
      } | null
      nextUnitPath?: {
        __typename?: 'CourseUnitPath'
        id: string
        section: { __typename?: 'CourseSection'; id: string; title: string }
        path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
      } | null
      currentSection?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
        nextCourseUnit?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
        } | null
      } | null
      currentMiniCourse?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
        section?: {
          __typename?: 'CourseSection'
          nextCourseSection?: {
            __typename?: 'CourseSection'
            id: string
            title: string
          } | null
        } | null
        nextCourseUnit?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
        } | null
      } | null
    } | null
  }>
}

export type LearnerProfileQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type LearnerProfileQuery = {
  __typename?: 'Query'
  learner?: {
    __typename?: 'Learner'
    id: string
    address?: string | null
    createdAt: any
    dateOfBirth?: any | null
    discordId?: string | null
    englishLevel?: string | null
    expYears?: string | null
    gender?: Gender | null
    industry?: string | null
    jobTitle?: string | null
    learnerCode: number
    linkedinURL?: string | null
    nationalIdIssuedDate?: any | null
    nationalIdIssuedPlace?: string | null
    nationalIdNumber?: string | null
    notes?: string | null
    organization?: string | null
    priorCodingCourses?: string | null
    resumeURL?: string | null
    secondaryEmail?: string | null
    secondaryPhoneNumber?: string | null
    workingStatus?: string | null
    userId: string
    lwUserId?: string | null
    lwUser?: {
      __typename?: 'LwUser'
      id: string
      email: string
      username: string
    } | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      name: string
      phone?: string | null
    } | null
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      learningType?: string | null
      enrollmentDate?: any | null
      paymentType?: string | null
      learningPurpose?: string | null
      courseExpectation?: string | null
      studyTimePerWeek?: number | null
      monthToChangeJob?: number | null
      jobExpectation?: string | null
      reasonToSwitchCareer?: string | null
      source?: string | null
      status: CourseMemberStatus
      remainingMentorSessionEvents?: number | null
      redeemedMentorSessionEvents?: number | null
      totalAllowedTASessions?: number | null
      totalMentorSessionEvents?: number | null
      course?: { __typename?: 'Course'; id: string; title: string } | null
      latestProgressReport?: {
        __typename?: 'CourseMemberProgressReport'
        id: string
        createdAt: any
        courseMemberId: string
        averageScore?: number | null
        overallProgress: number
        studyTimeDuringPeriod: number
        learningVelocity?: LearningVelocity | null
        weeksOfftrack?: number | null
        startSyncedAt?: any | null
        endSyncedAt: any
        currentUnit: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        }
        currentUnitPath?: {
          __typename?: 'CourseUnitPath'
          id: string
          section: { __typename?: 'CourseSection'; id: string; title: string }
          path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
        } | null
        nextUnitPath?: {
          __typename?: 'CourseUnitPath'
          id: string
          section: { __typename?: 'CourseSection'; id: string; title: string }
          path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
        } | null
        currentSection?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        } | null
        currentMiniCourse?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          section?: {
            __typename?: 'CourseSection'
            nextCourseSection?: {
              __typename?: 'CourseSection'
              id: string
              title: string
            } | null
          } | null
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        } | null
      } | null
    }>
  } | null
}

export type LearnerCourseConnectionQueryVariables = Exact<{
  learnerId: Scalars['ID']['input']
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type LearnerCourseConnectionQuery = {
  __typename?: 'Query'
  courseMemberConnection: {
    __typename?: 'CourseMemberConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'CourseMember'
      id: string
      type: CourseMemberType
      status: CourseMemberStatus
      learningType?: string | null
      enrollmentDate?: any | null
      paymentType?: string | null
      learningPurpose?: string | null
      courseExpectation?: string | null
      studyTimePerWeek?: number | null
      monthToChangeJob?: number | null
      jobExpectation?: string | null
      courseId: string
      createdAt: any
      user?: {
        __typename?: 'User'
        id: string
        email: string
        name: string
        phone?: string | null
      } | null
      learner?: {
        __typename?: 'Learner'
        id: string
        address?: string | null
        createdAt: any
        dateOfBirth?: any | null
        discordId?: string | null
        englishLevel?: string | null
        expYears?: string | null
        gender?: Gender | null
        industry?: string | null
        jobTitle?: string | null
        learnerCode: number
        linkedinURL?: string | null
        nationalIdIssuedDate?: any | null
        nationalIdIssuedPlace?: string | null
        nationalIdNumber?: string | null
        notes?: string | null
        organization?: string | null
        priorCodingCourses?: string | null
        resumeURL?: string | null
        secondaryEmail?: string | null
        secondaryPhoneNumber?: string | null
        workingStatus?: string | null
        userId: string
        lwUserId?: string | null
        lwUser?: {
          __typename?: 'LwUser'
          id: string
          email: string
          username: string
        } | null
        user?: {
          __typename?: 'User'
          id: string
          email: string
          name: string
          phone?: string | null
        } | null
        courseMembers: Array<{
          __typename?: 'CourseMember'
          id: string
          learningType?: string | null
          enrollmentDate?: any | null
          paymentType?: string | null
          learningPurpose?: string | null
          courseExpectation?: string | null
          studyTimePerWeek?: number | null
          monthToChangeJob?: number | null
          jobExpectation?: string | null
          reasonToSwitchCareer?: string | null
          source?: string | null
          status: CourseMemberStatus
          remainingMentorSessionEvents?: number | null
          redeemedMentorSessionEvents?: number | null
          totalAllowedTASessions?: number | null
          totalMentorSessionEvents?: number | null
          course?: { __typename?: 'Course'; id: string; title: string } | null
          latestProgressReport?: {
            __typename?: 'CourseMemberProgressReport'
            id: string
            createdAt: any
            courseMemberId: string
            averageScore?: number | null
            overallProgress: number
            studyTimeDuringPeriod: number
            learningVelocity?: LearningVelocity | null
            weeksOfftrack?: number | null
            startSyncedAt?: any | null
            endSyncedAt: any
            currentUnit: {
              __typename?: 'CourseUnit'
              id: string
              title: string
              nextCourseUnit?: {
                __typename?: 'CourseUnit'
                id: string
                title: string
              } | null
            }
            currentUnitPath?: {
              __typename?: 'CourseUnitPath'
              id: string
              section: {
                __typename?: 'CourseSection'
                id: string
                title: string
              }
              path: Array<{
                __typename?: 'CourseUnit'
                id: string
                title: string
              }>
            } | null
            nextUnitPath?: {
              __typename?: 'CourseUnitPath'
              id: string
              section: {
                __typename?: 'CourseSection'
                id: string
                title: string
              }
              path: Array<{
                __typename?: 'CourseUnit'
                id: string
                title: string
              }>
            } | null
            currentSection?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
              nextCourseUnit?: {
                __typename?: 'CourseUnit'
                id: string
                title: string
              } | null
            } | null
            currentMiniCourse?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
              section?: {
                __typename?: 'CourseSection'
                nextCourseSection?: {
                  __typename?: 'CourseSection'
                  id: string
                  title: string
                } | null
              } | null
              nextCourseUnit?: {
                __typename?: 'CourseUnit'
                id: string
                title: string
              } | null
            } | null
          } | null
        }>
      } | null
      course?: {
        __typename?: 'Course'
        id: string
        title: string
        description?: string | null
        lwCourseId?: string | null
      } | null
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type UpdateLearnerMemberMutationVariables = Exact<{
  courseMemberId: Scalars['ID']['input']
  updates: UpdateCourseMemberInput
}>

export type UpdateLearnerMemberMutation = {
  __typename?: 'Mutation'
  updateCourseMember: {
    __typename?: 'CourseMember'
    id: string
    learningType?: string | null
    enrollmentDate?: any | null
    paymentType?: string | null
    learningPurpose?: string | null
    courseExpectation?: string | null
    studyTimePerWeek?: number | null
    monthToChangeJob?: number | null
    jobExpectation?: string | null
    reasonToSwitchCareer?: string | null
    source?: string | null
    status: CourseMemberStatus
    remainingMentorSessionEvents?: number | null
    redeemedMentorSessionEvents?: number | null
    totalAllowedTASessions?: number | null
    totalMentorSessionEvents?: number | null
    course?: { __typename?: 'Course'; id: string; title: string } | null
    latestProgressReport?: {
      __typename?: 'CourseMemberProgressReport'
      id: string
      createdAt: any
      courseMemberId: string
      averageScore?: number | null
      overallProgress: number
      studyTimeDuringPeriod: number
      learningVelocity?: LearningVelocity | null
      weeksOfftrack?: number | null
      startSyncedAt?: any | null
      endSyncedAt: any
      currentUnit: {
        __typename?: 'CourseUnit'
        id: string
        title: string
        nextCourseUnit?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
        } | null
      }
      currentUnitPath?: {
        __typename?: 'CourseUnitPath'
        id: string
        section: { __typename?: 'CourseSection'; id: string; title: string }
        path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
      } | null
      nextUnitPath?: {
        __typename?: 'CourseUnitPath'
        id: string
        section: { __typename?: 'CourseSection'; id: string; title: string }
        path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
      } | null
      currentSection?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
        nextCourseUnit?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
        } | null
      } | null
      currentMiniCourse?: {
        __typename?: 'CourseUnit'
        id: string
        title: string
        section?: {
          __typename?: 'CourseSection'
          nextCourseSection?: {
            __typename?: 'CourseSection'
            id: string
            title: string
          } | null
        } | null
        nextCourseUnit?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
        } | null
      } | null
    } | null
  }
}

export type UpdateLearnerLwUserMutationVariables = Exact<{
  learnerId: Scalars['ID']['input']
  email: Scalars['String']['input']
}>

export type UpdateLearnerLwUserMutation = {
  __typename?: 'Mutation'
  updateLwUser: { __typename?: 'Learner'; id: string }
}

export type UpdateLearnerProfileMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateLearnerInput
}>

export type UpdateLearnerProfileMutation = {
  __typename?: 'Mutation'
  updateLearner: {
    __typename?: 'Learner'
    id: string
    address?: string | null
    createdAt: any
    dateOfBirth?: any | null
    discordId?: string | null
    englishLevel?: string | null
    expYears?: string | null
    gender?: Gender | null
    industry?: string | null
    jobTitle?: string | null
    learnerCode: number
    linkedinURL?: string | null
    nationalIdIssuedDate?: any | null
    nationalIdIssuedPlace?: string | null
    nationalIdNumber?: string | null
    notes?: string | null
    organization?: string | null
    priorCodingCourses?: string | null
    resumeURL?: string | null
    secondaryEmail?: string | null
    secondaryPhoneNumber?: string | null
    workingStatus?: string | null
    userId: string
    lwUserId?: string | null
    lwUser?: {
      __typename?: 'LwUser'
      id: string
      email: string
      username: string
    } | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      name: string
      phone?: string | null
    } | null
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      learningType?: string | null
      enrollmentDate?: any | null
      paymentType?: string | null
      learningPurpose?: string | null
      courseExpectation?: string | null
      studyTimePerWeek?: number | null
      monthToChangeJob?: number | null
      jobExpectation?: string | null
      reasonToSwitchCareer?: string | null
      source?: string | null
      status: CourseMemberStatus
      remainingMentorSessionEvents?: number | null
      redeemedMentorSessionEvents?: number | null
      totalAllowedTASessions?: number | null
      totalMentorSessionEvents?: number | null
      course?: { __typename?: 'Course'; id: string; title: string } | null
      latestProgressReport?: {
        __typename?: 'CourseMemberProgressReport'
        id: string
        createdAt: any
        courseMemberId: string
        averageScore?: number | null
        overallProgress: number
        studyTimeDuringPeriod: number
        learningVelocity?: LearningVelocity | null
        weeksOfftrack?: number | null
        startSyncedAt?: any | null
        endSyncedAt: any
        currentUnit: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        }
        currentUnitPath?: {
          __typename?: 'CourseUnitPath'
          id: string
          section: { __typename?: 'CourseSection'; id: string; title: string }
          path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
        } | null
        nextUnitPath?: {
          __typename?: 'CourseUnitPath'
          id: string
          section: { __typename?: 'CourseSection'; id: string; title: string }
          path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
        } | null
        currentSection?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        } | null
        currentMiniCourse?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          section?: {
            __typename?: 'CourseSection'
            nextCourseSection?: {
              __typename?: 'CourseSection'
              id: string
              title: string
            } | null
          } | null
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        } | null
      } | null
    }>
  }
}

export type UpdateLearnerCodeMutationVariables = Exact<{
  id: Scalars['ID']['input']
  code: Scalars['Int']['input']
}>

export type UpdateLearnerCodeMutation = {
  __typename?: 'Mutation'
  updateLearnerCode: {
    __typename?: 'Learner'
    id: string
    address?: string | null
    createdAt: any
    dateOfBirth?: any | null
    discordId?: string | null
    englishLevel?: string | null
    expYears?: string | null
    gender?: Gender | null
    industry?: string | null
    jobTitle?: string | null
    learnerCode: number
    linkedinURL?: string | null
    nationalIdIssuedDate?: any | null
    nationalIdIssuedPlace?: string | null
    nationalIdNumber?: string | null
    notes?: string | null
    organization?: string | null
    priorCodingCourses?: string | null
    resumeURL?: string | null
    secondaryEmail?: string | null
    secondaryPhoneNumber?: string | null
    workingStatus?: string | null
    userId: string
    lwUserId?: string | null
    lwUser?: {
      __typename?: 'LwUser'
      id: string
      email: string
      username: string
    } | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      name: string
      phone?: string | null
    } | null
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      learningType?: string | null
      enrollmentDate?: any | null
      paymentType?: string | null
      learningPurpose?: string | null
      courseExpectation?: string | null
      studyTimePerWeek?: number | null
      monthToChangeJob?: number | null
      jobExpectation?: string | null
      reasonToSwitchCareer?: string | null
      source?: string | null
      status: CourseMemberStatus
      remainingMentorSessionEvents?: number | null
      redeemedMentorSessionEvents?: number | null
      totalAllowedTASessions?: number | null
      totalMentorSessionEvents?: number | null
      course?: { __typename?: 'Course'; id: string; title: string } | null
      latestProgressReport?: {
        __typename?: 'CourseMemberProgressReport'
        id: string
        createdAt: any
        courseMemberId: string
        averageScore?: number | null
        overallProgress: number
        studyTimeDuringPeriod: number
        learningVelocity?: LearningVelocity | null
        weeksOfftrack?: number | null
        startSyncedAt?: any | null
        endSyncedAt: any
        currentUnit: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        }
        currentUnitPath?: {
          __typename?: 'CourseUnitPath'
          id: string
          section: { __typename?: 'CourseSection'; id: string; title: string }
          path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
        } | null
        nextUnitPath?: {
          __typename?: 'CourseUnitPath'
          id: string
          section: { __typename?: 'CourseSection'; id: string; title: string }
          path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
        } | null
        currentSection?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        } | null
        currentMiniCourse?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          section?: {
            __typename?: 'CourseSection'
            nextCourseSection?: {
              __typename?: 'CourseSection'
              id: string
              title: string
            } | null
          } | null
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        } | null
      } | null
    }>
  }
}

export type LatestCourseMemberMentorSessionQueryVariables = Exact<{
  courseMemberId: Scalars['ID']['input']
}>

export type LatestCourseMemberMentorSessionQuery = {
  __typename?: 'Query'
  latestCourseMemberMentorSession?: {
    __typename?: 'MentorSession'
    id: string
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      type: CourseMemberType
      userId?: string | null
    }>
    calendarEvents: Array<{
      __typename?: 'CalendarEvent'
      recurrence?: string | null
    }>
  } | null
}

export type DropCourseMemberMutationVariables = Exact<{
  courseMemberId: Scalars['String']['input']
  data: DropCourseMemberInput
}>

export type DropCourseMemberMutation = {
  __typename?: 'Mutation'
  dropCourseMember: { __typename?: 'CourseMember'; id: string }
}

export type PostponeCourseMemberMutationVariables = Exact<{
  courseMemberId: Scalars['String']['input']
  data: PostponeCourseMemberInput
}>

export type PostponeCourseMemberMutation = {
  __typename?: 'Mutation'
  postponeCourseMember: { __typename?: 'CourseMember'; id: string }
}

export type GraduateCourseMemberMutationVariables = Exact<{
  courseMemberId: Scalars['String']['input']
  data: GraduateCourseMemberInput
}>

export type GraduateCourseMemberMutation = {
  __typename?: 'Mutation'
  graduateCourseMember: { __typename?: 'CourseMember'; id: string }
}

export type ReactivateCourseMemberMutationVariables = Exact<{
  courseMemberId: Scalars['ID']['input']
}>

export type ReactivateCourseMemberMutation = {
  __typename?: 'Mutation'
  reactivateCourseMember: { __typename?: 'CourseMember'; id: string }
}

export type DropStatusActivityLogFragment = {
  __typename?: 'CourseMemberDropStatusActivityLog'
  createdAt: any
  id: string
  performUserId?: string | null
  dropReason?: string | null
  updatedAt: any
}

export type GraduateStatusActivityLogFragment = {
  __typename?: 'CourseMemberGraduateStatusActivityLog'
  createdAt: any
  id: string
  performUserId?: string | null
  updatedAt: any
}

export type PostponeStatusActivityLogFragment = {
  __typename?: 'CourseMemberPostponeStatusActivityLog'
  createdAt: any
  id: string
  performUserId?: string | null
  postponeReason?: string | null
  returnDate?: any | null
  updatedAt: any
}

export type GetCourseMemberDropActivityLogQueryVariables = Exact<{
  courseMemberId: Scalars['ID']['input']
}>

export type GetCourseMemberDropActivityLogQuery = {
  __typename?: 'Query'
  getCourseMemberDropActivityLog: {
    __typename?: 'CourseMemberDropStatusActivityLog'
    createdAt: any
    id: string
    performUserId?: string | null
    dropReason?: string | null
    updatedAt: any
  }
}

export type GetCourseMemberGraduateActivityLogQueryVariables = Exact<{
  courseMemberId: Scalars['ID']['input']
}>

export type GetCourseMemberGraduateActivityLogQuery = {
  __typename?: 'Query'
  getCourseMemberGraduateActivityLog: {
    __typename?: 'CourseMemberGraduateStatusActivityLog'
    createdAt: any
    id: string
    performUserId?: string | null
    updatedAt: any
  }
}

export type GetCourseMemberPostponeActivityLogQueryVariables = Exact<{
  courseMemberId: Scalars['ID']['input']
}>

export type GetCourseMemberPostponeActivityLogQuery = {
  __typename?: 'Query'
  getCourseMemberPostponeActivityLog: {
    __typename?: 'CourseMemberPostponeStatusActivityLog'
    createdAt: any
    id: string
    performUserId?: string | null
    postponeReason?: string | null
    returnDate?: any | null
    updatedAt: any
  }
}

export type UpdateDropActivityLogMutationVariables = Exact<{
  activityLogId: Scalars['ID']['input']
  data: DropCourseMemberInput
}>

export type UpdateDropActivityLogMutation = {
  __typename?: 'Mutation'
  updateDropActivityLog: { __typename?: 'ActivityLog'; id: string }
}

export type UpdateGraduateActivityLogMutationVariables = Exact<{
  activityLogId: Scalars['ID']['input']
  data: GraduateCourseMemberInput
}>

export type UpdateGraduateActivityLogMutation = {
  __typename?: 'Mutation'
  updateGraduateActivityLog: { __typename?: 'ActivityLog'; id: string }
}

export type UpdatePostponeActivityLogMutationVariables = Exact<{
  activityLogId: Scalars['ID']['input']
  data: PostponeCourseMemberInput
}>

export type UpdatePostponeActivityLogMutation = {
  __typename?: 'Mutation'
  updatePostponeActivityLog: { __typename?: 'ActivityLog'; id: string }
}

export type CourseDurationOptionsQueryVariables = Exact<{
  courseId: Scalars['ID']['input']
}>

export type CourseDurationOptionsQuery = {
  __typename?: 'Query'
  courseDurationOptions: Array<{
    __typename?: 'CourseDurationOption'
    id: string
    label: string
    description: string
    value: number
  }>
}

export type LearnerProfileViewQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type LearnerProfileViewQuery = {
  __typename?: 'Query'
  learner?: {
    __typename?: 'Learner'
    id: string
    codingSkill?: string | null
    industry?: string | null
    jobTitle?: string | null
    userId: string
    lwUser?: {
      __typename?: 'LwUser'
      id: string
      email: string
      tags: Array<string>
    } | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      name: string
    } | null
  } | null
}

export type LinkLearnerToLearnWorldsMutationVariables = Exact<{
  input: LinkLearnerToLwUserInput
}>

export type LinkLearnerToLearnWorldsMutation = {
  __typename?: 'Mutation'
  linkLearnerToLwUser: {
    __typename?: 'Learner'
    id: string
    lwUserId?: string | null
    lwUser?: { __typename?: 'LwUser'; id: string; email: string } | null
  }
}

export type CreateMentorSessionEventRescheduleRequestMutationVariables = Exact<{
  input: RescheduleMentorSessionEventInput
}>

export type CreateMentorSessionEventRescheduleRequestMutation = {
  __typename?: 'Mutation'
  createMentorSessionEventRescheduleRequest: {
    __typename?: 'MentorSessionEventRescheduleRequest'
    userRequestId: string
    userRequest: {
      __typename?: 'UserRequest'
      id: string
      status: UserRequestStatus
    }
  }
}

export type MentorSessionListFragment = {
  __typename?: 'MentorSession'
  id: string
  status: MentorSessionStatus
  isFinished: boolean
  createdAt: any
  calendarEvents: Array<{
    __typename?: 'CalendarEvent'
    id: string
    startsAt: any
    endsAt: any
    recurrence?: string | null
    mentorSessionEvent?: {
      __typename?: 'MentorSessionEvent'
      status: MentorSessionEventStatus
    } | null
  }>
  course?: { __typename?: 'Course'; id: string; title: string } | null
  courseMembers: Array<{
    __typename?: 'CourseMember'
    id: string
    userId?: string | null
    type: CourseMemberType
  }>
}

export type MentorSessionListQueryVariables = Exact<{
  mentorId?: InputMaybe<Scalars['ID']['input']>
  learnerId?: InputMaybe<Scalars['ID']['input']>
  courseMemberId?: InputMaybe<Scalars['ID']['input']>
}>

export type MentorSessionListQuery = {
  __typename?: 'Query'
  mentorSessions: Array<{
    __typename?: 'MentorSession'
    id: string
    status: MentorSessionStatus
    isFinished: boolean
    createdAt: any
    calendarEvents: Array<{
      __typename?: 'CalendarEvent'
      id: string
      startsAt: any
      endsAt: any
      recurrence?: string | null
      mentorSessionEvent?: {
        __typename?: 'MentorSessionEvent'
        status: MentorSessionEventStatus
      } | null
    }>
    course?: { __typename?: 'Course'; id: string; title: string } | null
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      userId?: string | null
      type: CourseMemberType
    }>
  }>
}

export type MentorSessionConnectionQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  courseIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  mentorIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  learnerIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
  statuses?: InputMaybe<Array<MentorSessionStatus> | MentorSessionStatus>
  enrollmentDate?: InputMaybe<Scalars['Date']['input']>
  startDate?: InputMaybe<Scalars['Date']['input']>
}>

export type MentorSessionConnectionQuery = {
  __typename?: 'Query'
  mentorSessionConnection: {
    __typename?: 'MentorSessionConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'MentorSession'
      id: string
      status: MentorSessionStatus
      isFinished: boolean
      createdAt: any
      calendarEvents: Array<{
        __typename?: 'CalendarEvent'
        id: string
        startsAt: any
        endsAt: any
        recurrence?: string | null
        mentorSessionEvent?: {
          __typename?: 'MentorSessionEvent'
          status: MentorSessionEventStatus
        } | null
      }>
      course?: { __typename?: 'Course'; id: string; title: string } | null
      courseMembers: Array<{
        __typename?: 'CourseMember'
        id: string
        userId?: string | null
        type: CourseMemberType
      }>
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type BookMentorSessionLearnerFragment = {
  __typename?: 'Learner'
  id: string
  userId: string
  learnerCode: number
  discordId?: string | null
  metadata?: string | null
  codingSkill?: string | null
  notes?: string | null
  gender?: Gender | null
  dateOfBirth?: any | null
  address?: string | null
  englishLevel?: string | null
  user?: {
    __typename?: 'User'
    id: string
    email: string
    phone?: string | null
    name: string
  } | null
  mentorSessionAvailabilitySchedules: Array<{
    __typename?: 'MentorSessionAvailabilitySchedule'
    id: string
    dayOfWeek: number
    start: any
    end: any
    targetId: string
    targetType?: MentorSessionAvailabilityScheduleTargetType | null
  }>
}

export type BookMentorSessionMentorFragment = {
  __typename?: 'Mentor'
  id: string
  userId: string
  discordId?: string | null
  gender?: Gender | null
  jobTitle?: string | null
  organization?: string | null
  address?: string | null
  status: MentorStatus
  capacity?: number | null
  dateOfBirth?: any | null
  user?: {
    __typename?: 'User'
    id: string
    email: string
    phone?: string | null
    name: string
  } | null
  mentorSessionAvailabilitySchedules: Array<{
    __typename?: 'MentorSessionAvailabilitySchedule'
    id: string
    dayOfWeek: number
    start: any
    end: any
    targetId: string
    targetType?: MentorSessionAvailabilityScheduleTargetType | null
  }>
}

export type BookMentorSessionLearnerMemberFragment = {
  __typename?: 'CourseMember'
  id: string
  enrollmentDate?: any | null
  courseExpectation?: string | null
  learningPurpose?: string | null
  jobExpectation?: string | null
  redeemedMentorSessionEvents?: number | null
  course?: { __typename?: 'Course'; id: string; title: string } | null
  learner?: {
    __typename?: 'Learner'
    id: string
    userId: string
    learnerCode: number
    discordId?: string | null
    metadata?: string | null
    codingSkill?: string | null
    notes?: string | null
    gender?: Gender | null
    dateOfBirth?: any | null
    address?: string | null
    englishLevel?: string | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      phone?: string | null
      name: string
    } | null
    mentorSessionAvailabilitySchedules: Array<{
      __typename?: 'MentorSessionAvailabilitySchedule'
      id: string
      dayOfWeek: number
      start: any
      end: any
      targetId: string
      targetType?: MentorSessionAvailabilityScheduleTargetType | null
    }>
  } | null
}

export type BookMentorSessionMentorMemberFragment = {
  __typename?: 'SuitableCourseMember'
  id: string
  score: number
  enrollmentDate?: any | null
  courseExpectation?: string | null
  learningPurpose?: string | null
  jobExpectation?: string | null
  bookedSessionsAndPendingRequests: number
  course?: { __typename?: 'Course'; id: string; title: string } | null
  mentor?: {
    __typename?: 'Mentor'
    id: string
    userId: string
    discordId?: string | null
    gender?: Gender | null
    jobTitle?: string | null
    organization?: string | null
    address?: string | null
    status: MentorStatus
    capacity?: number | null
    dateOfBirth?: any | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      phone?: string | null
      name: string
    } | null
    mentorSessionAvailabilitySchedules: Array<{
      __typename?: 'MentorSessionAvailabilitySchedule'
      id: string
      dayOfWeek: number
      start: any
      end: any
      targetId: string
      targetType?: MentorSessionAvailabilityScheduleTargetType | null
    }>
  } | null
}

export type SuggestionItemFragment = {
  __typename?: 'MentorSessionTimeSuggestion'
  id: string
  start: any
  end: any
  notes?: string | null
  score: number
}

export type LearnersWithNoSessionsQueryVariables = Exact<{
  courseId?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<CourseMemberType>
}>

export type LearnersWithNoSessionsQuery = {
  __typename?: 'Query'
  courseMembersWithNoSessions: Array<{
    __typename?: 'CourseMember'
    id: string
    enrollmentDate?: any | null
    courseExpectation?: string | null
    learningPurpose?: string | null
    jobExpectation?: string | null
    redeemedMentorSessionEvents?: number | null
    course?: { __typename?: 'Course'; id: string; title: string } | null
    learner?: {
      __typename?: 'Learner'
      id: string
      userId: string
      learnerCode: number
      discordId?: string | null
      metadata?: string | null
      codingSkill?: string | null
      notes?: string | null
      gender?: Gender | null
      dateOfBirth?: any | null
      address?: string | null
      englishLevel?: string | null
      user?: {
        __typename?: 'User'
        id: string
        email: string
        phone?: string | null
        name: string
      } | null
      mentorSessionAvailabilitySchedules: Array<{
        __typename?: 'MentorSessionAvailabilitySchedule'
        id: string
        dayOfWeek: number
        start: any
        end: any
        targetId: string
        targetType?: MentorSessionAvailabilityScheduleTargetType | null
      }>
    } | null
  }>
}

export type LearnersAvailableForBookingRequestQueryVariables = Exact<{
  courseId?: InputMaybe<Scalars['ID']['input']>
}>

export type LearnersAvailableForBookingRequestQuery = {
  __typename?: 'Query'
  learnerMembersAvailableForBookingRequest: Array<{
    __typename?: 'CourseMember'
    id: string
    enrollmentDate?: any | null
    courseExpectation?: string | null
    learningPurpose?: string | null
    jobExpectation?: string | null
    redeemedMentorSessionEvents?: number | null
    course?: { __typename?: 'Course'; id: string; title: string } | null
    learner?: {
      __typename?: 'Learner'
      id: string
      userId: string
      learnerCode: number
      discordId?: string | null
      metadata?: string | null
      codingSkill?: string | null
      notes?: string | null
      gender?: Gender | null
      dateOfBirth?: any | null
      address?: string | null
      englishLevel?: string | null
      user?: {
        __typename?: 'User'
        id: string
        email: string
        phone?: string | null
        name: string
      } | null
      mentorSessionAvailabilitySchedules: Array<{
        __typename?: 'MentorSessionAvailabilitySchedule'
        id: string
        dayOfWeek: number
        start: any
        end: any
        targetId: string
        targetType?: MentorSessionAvailabilityScheduleTargetType | null
      }>
    } | null
  }>
}

export type BookMentorReviewLearnerQueryVariables = Exact<{
  courseMemberId: Scalars['ID']['input']
}>

export type BookMentorReviewLearnerQuery = {
  __typename?: 'Query'
  courseMember?: {
    __typename?: 'CourseMember'
    id: string
    enrollmentDate?: any | null
    courseExpectation?: string | null
    learningPurpose?: string | null
    jobExpectation?: string | null
    redeemedMentorSessionEvents?: number | null
    course?: { __typename?: 'Course'; id: string; title: string } | null
    learner?: {
      __typename?: 'Learner'
      id: string
      userId: string
      learnerCode: number
      discordId?: string | null
      metadata?: string | null
      codingSkill?: string | null
      notes?: string | null
      gender?: Gender | null
      dateOfBirth?: any | null
      address?: string | null
      englishLevel?: string | null
      user?: {
        __typename?: 'User'
        id: string
        email: string
        phone?: string | null
        name: string
      } | null
      mentorSessionAvailabilitySchedules: Array<{
        __typename?: 'MentorSessionAvailabilitySchedule'
        id: string
        dayOfWeek: number
        start: any
        end: any
        targetId: string
        targetType?: MentorSessionAvailabilityScheduleTargetType | null
      }>
    } | null
  } | null
}

export type SuitableMentorMembersQueryVariables = Exact<{
  courseId: Scalars['ID']['input']
  learnerCourseMemberId: Scalars['ID']['input']
}>

export type SuitableMentorMembersQuery = {
  __typename?: 'Query'
  suitableMentorsForMentorSession: Array<{
    __typename?: 'SuitableCourseMember'
    id: string
    score: number
    enrollmentDate?: any | null
    courseExpectation?: string | null
    learningPurpose?: string | null
    jobExpectation?: string | null
    bookedSessionsAndPendingRequests: number
    course?: { __typename?: 'Course'; id: string; title: string } | null
    mentor?: {
      __typename?: 'Mentor'
      id: string
      userId: string
      discordId?: string | null
      gender?: Gender | null
      jobTitle?: string | null
      organization?: string | null
      address?: string | null
      status: MentorStatus
      capacity?: number | null
      dateOfBirth?: any | null
      user?: {
        __typename?: 'User'
        id: string
        email: string
        phone?: string | null
        name: string
      } | null
      mentorSessionAvailabilitySchedules: Array<{
        __typename?: 'MentorSessionAvailabilitySchedule'
        id: string
        dayOfWeek: number
        start: any
        end: any
        targetId: string
        targetType?: MentorSessionAvailabilityScheduleTargetType | null
      }>
    } | null
  }>
}

export type MentorSessionTimeSuggestionsQueryVariables = Exact<{
  courseId: Scalars['ID']['input']
  learnerCourseMemberId: Scalars['String']['input']
  mentorCourseMemberId: Scalars['String']['input']
  durationInMinutes: Scalars['Int']['input']
  endsAfterMonths?: InputMaybe<Scalars['Int']['input']>
  intervalsFrom?: InputMaybe<Scalars['Date']['input']>
}>

export type MentorSessionTimeSuggestionsQuery = {
  __typename?: 'Query'
  mentorSessionTimeSuggestions: Array<{
    __typename?: 'MentorSessionTimeSuggestion'
    id: string
    start: any
    end: any
    notes?: string | null
    score: number
  }>
}

export type UpdateMentorSessionsStatusBatchMutationVariables = Exact<{
  [key: string]: never
}>

export type UpdateMentorSessionsStatusBatchMutation = {
  __typename?: 'Mutation'
  updateMentorSessionsStatusBatch: any
}

export type BookingRequestActivityLogItemFragment = {
  __typename?: 'ActivityLog'
  id: string
  createdAt: any
  activity: string
  data: any
  userId?: string | null
  user?: {
    __typename?: 'User'
    id: string
    mentor?: { __typename?: 'Mentor'; id: string } | null
    learner?: { __typename?: 'Learner'; id: string } | null
  } | null
}

export type BookingRequestViewFragment = {
  __typename?: 'MentorSessionBookingRequest'
  id: string
  userRequestId: string
  courseId: string
  endsAt: any
  expiresOn: any
  learnerIds: Array<string>
  mentorIds: Array<string>
  recurrence?: string | null
  startsAt: any
  status: UserRequestStatus
  course: {
    __typename?: 'Course'
    id: string
    title: string
    description?: string | null
    totalAllowedTASessions?: number | null
    access: CourseAccess
    updatedAt: any
    lwCourseId?: string | null
    zohoProductId?: string | null
    discordServerId?: string | null
  }
  learners: Array<{
    __typename?: 'Learner'
    id: string
    user?: { __typename?: 'User'; name: string; id: string } | null
  }>
  mentors: Array<{
    __typename?: 'Mentor'
    id: string
    user?: { __typename?: 'User'; name: string; id: string } | null
  }>
  responses: Array<{
    __typename?: 'UserResponse'
    id: string
    action: UserResponseAction
    content?: string | null
    createdAt: any
  }>
  activityLogs: Array<{
    __typename?: 'ActivityLog'
    id: string
    createdAt: any
    activity: string
    data: any
    userId?: string | null
    user?: {
      __typename?: 'User'
      id: string
      mentor?: { __typename?: 'Mentor'; id: string } | null
      learner?: { __typename?: 'Learner'; id: string } | null
    } | null
  }>
}

export type BookingRequestViewQueryVariables = Exact<{
  userRequestId: Scalars['ID']['input']
}>

export type BookingRequestViewQuery = {
  __typename?: 'Query'
  mentorSessionBookingRequest?: {
    __typename?: 'MentorSessionBookingRequest'
    id: string
    userRequestId: string
    courseId: string
    endsAt: any
    expiresOn: any
    learnerIds: Array<string>
    mentorIds: Array<string>
    recurrence?: string | null
    startsAt: any
    status: UserRequestStatus
    course: {
      __typename?: 'Course'
      id: string
      title: string
      description?: string | null
      totalAllowedTASessions?: number | null
      access: CourseAccess
      updatedAt: any
      lwCourseId?: string | null
      zohoProductId?: string | null
      discordServerId?: string | null
    }
    learners: Array<{
      __typename?: 'Learner'
      id: string
      user?: { __typename?: 'User'; name: string; id: string } | null
    }>
    mentors: Array<{
      __typename?: 'Mentor'
      id: string
      user?: { __typename?: 'User'; name: string; id: string } | null
    }>
    responses: Array<{
      __typename?: 'UserResponse'
      id: string
      action: UserResponseAction
      content?: string | null
      createdAt: any
    }>
    activityLogs: Array<{
      __typename?: 'ActivityLog'
      id: string
      createdAt: any
      activity: string
      data: any
      userId?: string | null
      user?: {
        __typename?: 'User'
        id: string
        mentor?: { __typename?: 'Mentor'; id: string } | null
        learner?: { __typename?: 'Learner'; id: string } | null
      } | null
    }>
  } | null
}

export type CancelBookingRequestMutationVariables = Exact<{
  userRequestId: Scalars['ID']['input']
  reason?: InputMaybe<Scalars['String']['input']>
}>

export type CancelBookingRequestMutation = {
  __typename?: 'Mutation'
  cancelPendingMentorSessionBookingRequest: {
    __typename?: 'MentorSessionBookingRequest'
    id: string
    userRequestId: string
    courseId: string
    endsAt: any
    expiresOn: any
    learnerIds: Array<string>
    mentorIds: Array<string>
    recurrence?: string | null
    startsAt: any
    status: UserRequestStatus
    course: {
      __typename?: 'Course'
      id: string
      title: string
      description?: string | null
      totalAllowedTASessions?: number | null
      access: CourseAccess
      updatedAt: any
      lwCourseId?: string | null
      zohoProductId?: string | null
      discordServerId?: string | null
    }
    learners: Array<{
      __typename?: 'Learner'
      id: string
      user?: { __typename?: 'User'; name: string; id: string } | null
    }>
    mentors: Array<{
      __typename?: 'Mentor'
      id: string
      user?: { __typename?: 'User'; name: string; id: string } | null
    }>
    responses: Array<{
      __typename?: 'UserResponse'
      id: string
      action: UserResponseAction
      content?: string | null
      createdAt: any
    }>
    activityLogs: Array<{
      __typename?: 'ActivityLog'
      id: string
      createdAt: any
      activity: string
      data: any
      userId?: string | null
      user?: {
        __typename?: 'User'
        id: string
        mentor?: { __typename?: 'Mentor'; id: string } | null
        learner?: { __typename?: 'Learner'; id: string } | null
      } | null
    }>
  }
}

export type CreateMentorSessionMutationVariables = Exact<{
  input: CreateMentorSessionInput
}>

export type CreateMentorSessionMutation = {
  __typename?: 'Mutation'
  createMentorSession: {
    __typename?: 'MentorSession'
    id: string
    courseId: string
  }
}

export type CreateMentorSessionMembersQueryVariables = Exact<{
  courseId: Scalars['ID']['input']
}>

export type CreateMentorSessionMembersQuery = {
  __typename?: 'Query'
  course?: {
    __typename?: 'Course'
    id: string
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      userId?: string | null
      type: CourseMemberType
      status: CourseMemberStatus
      mentorSessions?: Array<{
        __typename?: 'MentorSession'
        id: string
      }> | null
    }>
  } | null
}

export type ConflictingUserEventsQueryVariables = Exact<{
  userId: Scalars['ID']['input']
  from: Scalars['Date']['input']
  to: Scalars['Date']['input']
}>

export type ConflictingUserEventsQuery = {
  __typename?: 'Query'
  userEvents: Array<{
    __typename?: 'UserEvent'
    id: string
    status: UserEventStatus
  }>
}

export type BookingMemberSessionsQueryVariables = Exact<{
  courseIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  learnerId?: InputMaybe<Scalars['ID']['input']>
  mentorId?: InputMaybe<Scalars['ID']['input']>
}>

export type BookingMemberSessionsQuery = {
  __typename?: 'Query'
  mentorSessions: Array<{
    __typename?: 'MentorSession'
    id: string
    isFinished: boolean
  }>
}

export type FindAvailableMentorMembersQueryVariables = Exact<{
  courseId: Scalars['ID']['input']
  intervals: Array<TimeInterval> | TimeInterval
}>

export type FindAvailableMentorMembersQuery = {
  __typename?: 'Query'
  availableMentorCourseMembers: Array<{
    __typename?: 'CourseMember'
    id: string
    userId?: string | null
    type: CourseMemberType
    mentorId?: string | null
    status: CourseMemberStatus
    mentorSessions?: Array<{
      __typename?: 'MentorSession'
      id: string
      isFinished: boolean
    }> | null
  }>
}

export type CreateMentorSessionBookingRequestMutationVariables = Exact<{
  input: CreateMentorSessionBookingRequestInput
}>

export type CreateMentorSessionBookingRequestMutation = {
  __typename?: 'Mutation'
  createMentorSessionBookingRequest: {
    __typename?: 'MentorSessionBookingRequest'
    id: string
    courseId: string
  }
}

export type CreateMentorSessionBookingRequestMembersQueryVariables = Exact<{
  courseId: Scalars['ID']['input']
}>

export type CreateMentorSessionBookingRequestMembersQuery = {
  __typename?: 'Query'
  course?: {
    __typename?: 'Course'
    id: string
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      userId?: string | null
      type: CourseMemberType
      status: CourseMemberStatus
      mentorSessions?: Array<{
        __typename?: 'MentorSession'
        id: string
      }> | null
    }>
  } | null
}

export type MentorSessionBookingRequestLearnerFragment = {
  __typename?: 'Learner'
  id: string
  userId: string
  learnerCode: number
  discordId?: string | null
  metadata?: string | null
  codingSkill?: string | null
  notes?: string | null
  gender?: Gender | null
  dateOfBirth?: any | null
  address?: string | null
  englishLevel?: string | null
  user?: {
    __typename?: 'User'
    id: string
    email: string
    phone?: string | null
    name: string
  } | null
  mentorSessionAvailabilitySchedules: Array<{
    __typename?: 'MentorSessionAvailabilitySchedule'
    id: string
    dayOfWeek: number
    start: any
    end: any
    targetId: string
    targetType?: MentorSessionAvailabilityScheduleTargetType | null
  }>
}

export type MentorSessionBookingRequestMentorFragment = {
  __typename?: 'Mentor'
  id: string
  userId: string
  discordId?: string | null
  gender?: Gender | null
  jobTitle?: string | null
  organization?: string | null
  address?: string | null
  status: MentorStatus
  capacity?: number | null
  dateOfBirth?: any | null
  user?: {
    __typename?: 'User'
    id: string
    email: string
    phone?: string | null
    name: string
  } | null
  mentorSessionAvailabilitySchedules: Array<{
    __typename?: 'MentorSessionAvailabilitySchedule'
    id: string
    dayOfWeek: number
    start: any
    end: any
    targetId: string
    targetType?: MentorSessionAvailabilityScheduleTargetType | null
  }>
}

export type MentorSessionBookingRequestMentorMemberFragment = {
  __typename?: 'SuitableCourseMember'
  id: string
  score: number
  enrollmentDate?: any | null
  courseExpectation?: string | null
  learningPurpose?: string | null
  jobExpectation?: string | null
  bookedSessionsAndPendingRequests: number
  course?: { __typename?: 'Course'; id: string; title: string } | null
  mentor?: {
    __typename?: 'Mentor'
    id: string
    userId: string
    discordId?: string | null
    gender?: Gender | null
    jobTitle?: string | null
    organization?: string | null
    address?: string | null
    status: MentorStatus
    capacity?: number | null
    dateOfBirth?: any | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      phone?: string | null
      name: string
    } | null
    mentorSessionAvailabilitySchedules: Array<{
      __typename?: 'MentorSessionAvailabilitySchedule'
      id: string
      dayOfWeek: number
      start: any
      end: any
      targetId: string
      targetType?: MentorSessionAvailabilityScheduleTargetType | null
    }>
  } | null
}

export type MentorSessionBookingRequestLearnerMemberFragment = {
  __typename?: 'CourseMember'
  id: string
  enrollmentDate?: any | null
  courseExpectation?: string | null
  learningPurpose?: string | null
  jobExpectation?: string | null
  course?: { __typename?: 'Course'; id: string; title: string } | null
  learner?: {
    __typename?: 'Learner'
    id: string
    userId: string
    learnerCode: number
    discordId?: string | null
    metadata?: string | null
    codingSkill?: string | null
    notes?: string | null
    gender?: Gender | null
    dateOfBirth?: any | null
    address?: string | null
    englishLevel?: string | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      phone?: string | null
      name: string
    } | null
    mentorSessionAvailabilitySchedules: Array<{
      __typename?: 'MentorSessionAvailabilitySchedule'
      id: string
      dayOfWeek: number
      start: any
      end: any
      targetId: string
      targetType?: MentorSessionAvailabilityScheduleTargetType | null
    }>
  } | null
}

export type CancelMentorSessionMutationVariables = Exact<{
  args: CancelMentorSessionInput
}>

export type CancelMentorSessionMutation = {
  __typename?: 'Mutation'
  cancelMentorSession: {
    __typename?: 'MentorSession'
    id: string
    status: MentorSessionStatus
    course?: { __typename?: 'Course'; id: string; title: string } | null
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      userId?: string | null
      status: CourseMemberStatus
      type: CourseMemberType
      learnerId?: string | null
      mentorId?: string | null
      learningPurpose?: string | null
      learningType?: string | null
      monthToChangeJob?: number | null
      studyTimePerWeek?: number | null
      courseExpectation?: string | null
      jobExpectation?: string | null
      enrollmentDate?: any | null
      user?: {
        __typename?: 'User'
        id: string
        name: string
        email: string
        phone?: string | null
      } | null
      learner?: {
        __typename?: 'Learner'
        id: string
        codingSkill?: string | null
        discordId?: string | null
        expYears?: string | null
        industry?: string | null
        jobTitle?: string | null
        userId: string
      } | null
      mentor?: {
        __typename?: 'Mentor'
        id: string
        userId: string
        jobTitle?: string | null
        discordId?: string | null
        expYears?: string | null
        status: MentorStatus
        organization?: string | null
        city?: string | null
      } | null
      latestProgressReport?: {
        __typename?: 'CourseMemberProgressReport'
        id: string
        createdAt: any
        courseMemberId: string
        averageScore?: number | null
        overallProgress: number
        studyTimeDuringPeriod: number
        learningVelocity?: LearningVelocity | null
        weeksOfftrack?: number | null
        startSyncedAt?: any | null
        endSyncedAt: any
        currentUnit: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        }
        currentUnitPath?: {
          __typename?: 'CourseUnitPath'
          id: string
          section: { __typename?: 'CourseSection'; id: string; title: string }
          path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
        } | null
        nextUnitPath?: {
          __typename?: 'CourseUnitPath'
          id: string
          section: { __typename?: 'CourseSection'; id: string; title: string }
          path: Array<{ __typename?: 'CourseUnit'; id: string; title: string }>
        } | null
        currentSection?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        } | null
        currentMiniCourse?: {
          __typename?: 'CourseUnit'
          id: string
          title: string
          section?: {
            __typename?: 'CourseSection'
            nextCourseSection?: {
              __typename?: 'CourseSection'
              id: string
              title: string
            } | null
          } | null
          nextCourseUnit?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
          } | null
        } | null
      } | null
    }>
    calendarEvents: Array<{
      __typename?: 'CalendarEvent'
      id: string
      name: string
      startsAt: any
      endsAt: any
      recurrence?: string | null
      origin: CalendarEventOrigin
      attendees: Array<{
        __typename?: 'UserEvent'
        id: string
        userId: string
        status: UserEventStatus
      }>
      mentorSessionEvent?: {
        __typename?: 'MentorSessionEvent'
        id: string
        status: MentorSessionEventStatus
        zoomMeetingId?: string | null
        meetingRecordings: Array<{
          __typename?: 'MeetingRecording'
          id: string
        }>
        mentorSessionRecapReport: {
          __typename?: 'MentorSessionRecapReport'
          id: string
          requiredRecapCount: {
            __typename?: 'MentorMenteeCount'
            id: string
            mentee: number
            mentor: number
          }
          mentorSessionRecapCount: {
            __typename?: 'MentorMenteeCount'
            id: string
            mentee: number
            mentor: number
          }
        }
        mentorSessionRecaps: Array<{
          __typename?: 'MentorSessionRecap'
          id: string
          createdAt: any
          type: MentorSessionRecapType
          mentorSessionEventCalendarEventId: string
          createdByUser: {
            __typename?: 'User'
            id: string
            name: string
            email: string
            phone?: string | null
          }
          mentor: {
            __typename?: 'Mentor'
            id: string
            user?: {
              __typename?: 'User'
              id: string
              name: string
              email: string
              phone?: string | null
            } | null
          }
          learner: {
            __typename?: 'Learner'
            id: string
            user?: {
              __typename?: 'User'
              id: string
              name: string
              email: string
              phone?: string | null
            } | null
          }
          formSubmission: {
            __typename?: 'FormSubmission'
            id: string
            content: string
          }
        }>
        rescheduleRequests?: Array<{
          __typename?: 'MentorSessionEventRescheduleRequest'
          id: string
          startsAt: any
          endsAt: any
          userRequestId: string
          status: UserRequestStatus
          expiresOn: any
          reason?: string | null
          userRequest: {
            __typename?: 'UserRequest'
            createdAt: any
            id: string
            recipientUserIds: Array<string>
            status: UserRequestStatus
            type: UserRequestType
            updatedAt: any
          }
        }> | null
        zoomMeetingRecordings: Array<{
          __typename?: 'ZoomRecording'
          id: string
        }>
      } | null
    }>
    mentorSessionRecapReport: {
      __typename?: 'MentorSessionRecapReport'
      id: string
      requiredRecapCount: {
        __typename?: 'MentorMenteeCount'
        id: string
        mentee: number
        mentor: number
      }
      mentorSessionRecapCount: {
        __typename?: 'MentorMenteeCount'
        id: string
        mentee: number
        mentor: number
      }
    }
    activityLogs: Array<{
      __typename?: 'ActivityLog'
      id: string
      activity: string
      updatedAt: any
      createdAt: any
      data: any
      targetIds: Array<string>
      userId?: string | null
      user?: {
        __typename?: 'User'
        id: string
        email: string
        phone?: string | null
      } | null
    }>
  }
}

export type CancelSessionEventForStaffMutationVariables = Exact<{
  id: Scalars['ID']['input']
  canceledByUserId: Scalars['ID']['input']
  cancelReason?: InputMaybe<Scalars['String']['input']>
  createMakeUpEvent?: InputMaybe<Scalars['Boolean']['input']>
}>

export type CancelSessionEventForStaffMutation = {
  __typename?: 'Mutation'
  cancelMentorSessionEvent: {
    __typename?: 'MentorSessionEvent'
    id: string
    status: MentorSessionEventStatus
    mentorSession: {
      __typename?: 'MentorSession'
      id: string
      status: MentorSessionStatus
      course?: { __typename?: 'Course'; id: string; title: string } | null
      courseMembers: Array<{
        __typename?: 'CourseMember'
        id: string
        userId?: string | null
        status: CourseMemberStatus
        type: CourseMemberType
        learnerId?: string | null
        mentorId?: string | null
        learningPurpose?: string | null
        learningType?: string | null
        monthToChangeJob?: number | null
        studyTimePerWeek?: number | null
        courseExpectation?: string | null
        jobExpectation?: string | null
        enrollmentDate?: any | null
        user?: {
          __typename?: 'User'
          id: string
          name: string
          email: string
          phone?: string | null
        } | null
        learner?: {
          __typename?: 'Learner'
          id: string
          codingSkill?: string | null
          discordId?: string | null
          expYears?: string | null
          industry?: string | null
          jobTitle?: string | null
          userId: string
        } | null
        mentor?: {
          __typename?: 'Mentor'
          id: string
          userId: string
          jobTitle?: string | null
          discordId?: string | null
          expYears?: string | null
          status: MentorStatus
          organization?: string | null
          city?: string | null
        } | null
        latestProgressReport?: {
          __typename?: 'CourseMemberProgressReport'
          id: string
          createdAt: any
          courseMemberId: string
          averageScore?: number | null
          overallProgress: number
          studyTimeDuringPeriod: number
          learningVelocity?: LearningVelocity | null
          weeksOfftrack?: number | null
          startSyncedAt?: any | null
          endSyncedAt: any
          currentUnit: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          }
          currentUnitPath?: {
            __typename?: 'CourseUnitPath'
            id: string
            section: { __typename?: 'CourseSection'; id: string; title: string }
            path: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
            }>
          } | null
          nextUnitPath?: {
            __typename?: 'CourseUnitPath'
            id: string
            section: { __typename?: 'CourseSection'; id: string; title: string }
            path: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
            }>
          } | null
          currentSection?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          } | null
          currentMiniCourse?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            section?: {
              __typename?: 'CourseSection'
              nextCourseSection?: {
                __typename?: 'CourseSection'
                id: string
                title: string
              } | null
            } | null
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          } | null
        } | null
      }>
      calendarEvents: Array<{
        __typename?: 'CalendarEvent'
        id: string
        name: string
        startsAt: any
        endsAt: any
        recurrence?: string | null
        origin: CalendarEventOrigin
        attendees: Array<{
          __typename?: 'UserEvent'
          id: string
          userId: string
          status: UserEventStatus
        }>
        mentorSessionEvent?: {
          __typename?: 'MentorSessionEvent'
          id: string
          status: MentorSessionEventStatus
          zoomMeetingId?: string | null
          meetingRecordings: Array<{
            __typename?: 'MeetingRecording'
            id: string
          }>
          mentorSessionRecapReport: {
            __typename?: 'MentorSessionRecapReport'
            id: string
            requiredRecapCount: {
              __typename?: 'MentorMenteeCount'
              id: string
              mentee: number
              mentor: number
            }
            mentorSessionRecapCount: {
              __typename?: 'MentorMenteeCount'
              id: string
              mentee: number
              mentor: number
            }
          }
          mentorSessionRecaps: Array<{
            __typename?: 'MentorSessionRecap'
            id: string
            createdAt: any
            type: MentorSessionRecapType
            mentorSessionEventCalendarEventId: string
            createdByUser: {
              __typename?: 'User'
              id: string
              name: string
              email: string
              phone?: string | null
            }
            mentor: {
              __typename?: 'Mentor'
              id: string
              user?: {
                __typename?: 'User'
                id: string
                name: string
                email: string
                phone?: string | null
              } | null
            }
            learner: {
              __typename?: 'Learner'
              id: string
              user?: {
                __typename?: 'User'
                id: string
                name: string
                email: string
                phone?: string | null
              } | null
            }
            formSubmission: {
              __typename?: 'FormSubmission'
              id: string
              content: string
            }
          }>
          rescheduleRequests?: Array<{
            __typename?: 'MentorSessionEventRescheduleRequest'
            id: string
            startsAt: any
            endsAt: any
            userRequestId: string
            status: UserRequestStatus
            expiresOn: any
            reason?: string | null
            userRequest: {
              __typename?: 'UserRequest'
              createdAt: any
              id: string
              recipientUserIds: Array<string>
              status: UserRequestStatus
              type: UserRequestType
              updatedAt: any
            }
          }> | null
          zoomMeetingRecordings: Array<{
            __typename?: 'ZoomRecording'
            id: string
          }>
        } | null
      }>
      mentorSessionRecapReport: {
        __typename?: 'MentorSessionRecapReport'
        id: string
        requiredRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
        mentorSessionRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
      }
      activityLogs: Array<{
        __typename?: 'ActivityLog'
        id: string
        activity: string
        updatedAt: any
        createdAt: any
        data: any
        targetIds: Array<string>
        userId?: string | null
        user?: {
          __typename?: 'User'
          id: string
          email: string
          phone?: string | null
        } | null
      }>
    }
  }
}

export type CreateMentorSessionEventMutationVariables = Exact<{
  input: CreateMentorSessionEventInput
}>

export type CreateMentorSessionEventMutation = {
  __typename?: 'Mutation'
  createMentorSessionEvent: { __typename?: 'MentorSessionEvent'; id: string }
}

export type ExtendMentorSessionMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: ExtendMentorSessionInput
}>

export type ExtendMentorSessionMutation = {
  __typename?: 'Mutation'
  extendMentorSession: Array<{ __typename?: 'MentorSessionEvent'; id: string }>
}

export type MarkEventAsFinishedMutationVariables = Exact<{
  calendarEventId: Scalars['ID']['input']
}>

export type MarkEventAsFinishedMutation = {
  __typename?: 'Mutation'
  markEventAsFinished: {
    __typename?: 'MentorSessionEvent'
    id: string
    status: MentorSessionEventStatus
  }
}

export type RescheduleMentorSessionEventMutationVariables = Exact<{
  input: RescheduleMentorSessionEventInput
}>

export type RescheduleMentorSessionEventMutation = {
  __typename?: 'Mutation'
  rescheduleMentorSessionEvent: {
    __typename?: 'MentorSessionEvent'
    calendarEventId: string
    calendarEvent: {
      __typename?: 'CalendarEvent'
      id: string
      startsAt: any
      endsAt: any
    }
    mentorSession: {
      __typename?: 'MentorSession'
      id: string
      status: MentorSessionStatus
      course?: { __typename?: 'Course'; id: string; title: string } | null
      courseMembers: Array<{
        __typename?: 'CourseMember'
        id: string
        userId?: string | null
        status: CourseMemberStatus
        type: CourseMemberType
        learnerId?: string | null
        mentorId?: string | null
        learningPurpose?: string | null
        learningType?: string | null
        monthToChangeJob?: number | null
        studyTimePerWeek?: number | null
        courseExpectation?: string | null
        jobExpectation?: string | null
        enrollmentDate?: any | null
        user?: {
          __typename?: 'User'
          id: string
          name: string
          email: string
          phone?: string | null
        } | null
        learner?: {
          __typename?: 'Learner'
          id: string
          codingSkill?: string | null
          discordId?: string | null
          expYears?: string | null
          industry?: string | null
          jobTitle?: string | null
          userId: string
        } | null
        mentor?: {
          __typename?: 'Mentor'
          id: string
          userId: string
          jobTitle?: string | null
          discordId?: string | null
          expYears?: string | null
          status: MentorStatus
          organization?: string | null
          city?: string | null
        } | null
        latestProgressReport?: {
          __typename?: 'CourseMemberProgressReport'
          id: string
          createdAt: any
          courseMemberId: string
          averageScore?: number | null
          overallProgress: number
          studyTimeDuringPeriod: number
          learningVelocity?: LearningVelocity | null
          weeksOfftrack?: number | null
          startSyncedAt?: any | null
          endSyncedAt: any
          currentUnit: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          }
          currentUnitPath?: {
            __typename?: 'CourseUnitPath'
            id: string
            section: { __typename?: 'CourseSection'; id: string; title: string }
            path: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
            }>
          } | null
          nextUnitPath?: {
            __typename?: 'CourseUnitPath'
            id: string
            section: { __typename?: 'CourseSection'; id: string; title: string }
            path: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
            }>
          } | null
          currentSection?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          } | null
          currentMiniCourse?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            section?: {
              __typename?: 'CourseSection'
              nextCourseSection?: {
                __typename?: 'CourseSection'
                id: string
                title: string
              } | null
            } | null
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          } | null
        } | null
      }>
      calendarEvents: Array<{
        __typename?: 'CalendarEvent'
        id: string
        name: string
        startsAt: any
        endsAt: any
        recurrence?: string | null
        origin: CalendarEventOrigin
        attendees: Array<{
          __typename?: 'UserEvent'
          id: string
          userId: string
          status: UserEventStatus
        }>
        mentorSessionEvent?: {
          __typename?: 'MentorSessionEvent'
          id: string
          status: MentorSessionEventStatus
          zoomMeetingId?: string | null
          meetingRecordings: Array<{
            __typename?: 'MeetingRecording'
            id: string
          }>
          mentorSessionRecapReport: {
            __typename?: 'MentorSessionRecapReport'
            id: string
            requiredRecapCount: {
              __typename?: 'MentorMenteeCount'
              id: string
              mentee: number
              mentor: number
            }
            mentorSessionRecapCount: {
              __typename?: 'MentorMenteeCount'
              id: string
              mentee: number
              mentor: number
            }
          }
          mentorSessionRecaps: Array<{
            __typename?: 'MentorSessionRecap'
            id: string
            createdAt: any
            type: MentorSessionRecapType
            mentorSessionEventCalendarEventId: string
            createdByUser: {
              __typename?: 'User'
              id: string
              name: string
              email: string
              phone?: string | null
            }
            mentor: {
              __typename?: 'Mentor'
              id: string
              user?: {
                __typename?: 'User'
                id: string
                name: string
                email: string
                phone?: string | null
              } | null
            }
            learner: {
              __typename?: 'Learner'
              id: string
              user?: {
                __typename?: 'User'
                id: string
                name: string
                email: string
                phone?: string | null
              } | null
            }
            formSubmission: {
              __typename?: 'FormSubmission'
              id: string
              content: string
            }
          }>
          rescheduleRequests?: Array<{
            __typename?: 'MentorSessionEventRescheduleRequest'
            id: string
            startsAt: any
            endsAt: any
            userRequestId: string
            status: UserRequestStatus
            expiresOn: any
            reason?: string | null
            userRequest: {
              __typename?: 'UserRequest'
              createdAt: any
              id: string
              recipientUserIds: Array<string>
              status: UserRequestStatus
              type: UserRequestType
              updatedAt: any
            }
          }> | null
          zoomMeetingRecordings: Array<{
            __typename?: 'ZoomRecording'
            id: string
          }>
        } | null
      }>
      mentorSessionRecapReport: {
        __typename?: 'MentorSessionRecapReport'
        id: string
        requiredRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
        mentorSessionRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
      }
      activityLogs: Array<{
        __typename?: 'ActivityLog'
        id: string
        activity: string
        updatedAt: any
        createdAt: any
        data: any
        targetIds: Array<string>
        userId?: string | null
        user?: {
          __typename?: 'User'
          id: string
          email: string
          phone?: string | null
        } | null
      }>
    }
  }
}

export type RescheduleMentorSessionEventBatchMutationVariables = Exact<{
  input: RescheduleMentorSessionEventBatchInput
}>

export type RescheduleMentorSessionEventBatchMutation = {
  __typename?: 'Mutation'
  rescheduleMentorSessionEventBatch: Array<{
    __typename?: 'MentorSessionEvent'
    calendarEventId: string
    calendarEvent: {
      __typename?: 'CalendarEvent'
      id: string
      startsAt: any
      endsAt: any
    }
    mentorSession: {
      __typename?: 'MentorSession'
      id: string
      status: MentorSessionStatus
      course?: { __typename?: 'Course'; id: string; title: string } | null
      courseMembers: Array<{
        __typename?: 'CourseMember'
        id: string
        userId?: string | null
        status: CourseMemberStatus
        type: CourseMemberType
        learnerId?: string | null
        mentorId?: string | null
        learningPurpose?: string | null
        learningType?: string | null
        monthToChangeJob?: number | null
        studyTimePerWeek?: number | null
        courseExpectation?: string | null
        jobExpectation?: string | null
        enrollmentDate?: any | null
        user?: {
          __typename?: 'User'
          id: string
          name: string
          email: string
          phone?: string | null
        } | null
        learner?: {
          __typename?: 'Learner'
          id: string
          codingSkill?: string | null
          discordId?: string | null
          expYears?: string | null
          industry?: string | null
          jobTitle?: string | null
          userId: string
        } | null
        mentor?: {
          __typename?: 'Mentor'
          id: string
          userId: string
          jobTitle?: string | null
          discordId?: string | null
          expYears?: string | null
          status: MentorStatus
          organization?: string | null
          city?: string | null
        } | null
        latestProgressReport?: {
          __typename?: 'CourseMemberProgressReport'
          id: string
          createdAt: any
          courseMemberId: string
          averageScore?: number | null
          overallProgress: number
          studyTimeDuringPeriod: number
          learningVelocity?: LearningVelocity | null
          weeksOfftrack?: number | null
          startSyncedAt?: any | null
          endSyncedAt: any
          currentUnit: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          }
          currentUnitPath?: {
            __typename?: 'CourseUnitPath'
            id: string
            section: { __typename?: 'CourseSection'; id: string; title: string }
            path: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
            }>
          } | null
          nextUnitPath?: {
            __typename?: 'CourseUnitPath'
            id: string
            section: { __typename?: 'CourseSection'; id: string; title: string }
            path: Array<{
              __typename?: 'CourseUnit'
              id: string
              title: string
            }>
          } | null
          currentSection?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          } | null
          currentMiniCourse?: {
            __typename?: 'CourseUnit'
            id: string
            title: string
            section?: {
              __typename?: 'CourseSection'
              nextCourseSection?: {
                __typename?: 'CourseSection'
                id: string
                title: string
              } | null
            } | null
            nextCourseUnit?: {
              __typename?: 'CourseUnit'
              id: string
              title: string
            } | null
          } | null
        } | null
      }>
      calendarEvents: Array<{
        __typename?: 'CalendarEvent'
        id: string
        name: string
        startsAt: any
        endsAt: any
        recurrence?: string | null
        origin: CalendarEventOrigin
        attendees: Array<{
          __typename?: 'UserEvent'
          id: string
          userId: string
          status: UserEventStatus
        }>
        mentorSessionEvent?: {
          __typename?: 'MentorSessionEvent'
          id: string
          status: MentorSessionEventStatus
          zoomMeetingId?: string | null
          meetingRecordings: Array<{
            __typename?: 'MeetingRecording'
            id: string
          }>
          mentorSessionRecapReport: {
            __typename?: 'MentorSessionRecapReport'
            id: string
            requiredRecapCount: {
              __typename?: 'MentorMenteeCount'
              id: string
              mentee: number
              mentor: number
            }
            mentorSessionRecapCount: {
              __typename?: 'MentorMenteeCount'
              id: string
              mentee: number
              mentor: number
            }
          }
          mentorSessionRecaps: Array<{
            __typename?: 'MentorSessionRecap'
            id: string
            createdAt: any
            type: MentorSessionRecapType
            mentorSessionEventCalendarEventId: string
            createdByUser: {
              __typename?: 'User'
              id: string
              name: string
              email: string
              phone?: string | null
            }
            mentor: {
              __typename?: 'Mentor'
              id: string
              user?: {
                __typename?: 'User'
                id: string
                name: string
                email: string
                phone?: string | null
              } | null
            }
            learner: {
              __typename?: 'Learner'
              id: string
              user?: {
                __typename?: 'User'
                id: string
                name: string
                email: string
                phone?: string | null
              } | null
            }
            formSubmission: {
              __typename?: 'FormSubmission'
              id: string
              content: string
            }
          }>
          rescheduleRequests?: Array<{
            __typename?: 'MentorSessionEventRescheduleRequest'
            id: string
            startsAt: any
            endsAt: any
            userRequestId: string
            status: UserRequestStatus
            expiresOn: any
            reason?: string | null
            userRequest: {
              __typename?: 'UserRequest'
              createdAt: any
              id: string
              recipientUserIds: Array<string>
              status: UserRequestStatus
              type: UserRequestType
              updatedAt: any
            }
          }> | null
          zoomMeetingRecordings: Array<{
            __typename?: 'ZoomRecording'
            id: string
          }>
        } | null
      }>
      mentorSessionRecapReport: {
        __typename?: 'MentorSessionRecapReport'
        id: string
        requiredRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
        mentorSessionRecapCount: {
          __typename?: 'MentorMenteeCount'
          id: string
          mentee: number
          mentor: number
        }
      }
      activityLogs: Array<{
        __typename?: 'ActivityLog'
        id: string
        activity: string
        updatedAt: any
        createdAt: any
        data: any
        targetIds: Array<string>
        userId?: string | null
        user?: {
          __typename?: 'User'
          id: string
          email: string
          phone?: string | null
        } | null
      }>
    }
  }>
}

export type RequestLearnerInfoFragment = {
  __typename?: 'CourseMember'
  id: string
  learningPurpose?: string | null
  jobExpectation?: string | null
  courseExpectation?: string | null
  studyTimePerWeek?: number | null
  monthToChangeJob?: number | null
}

export type MentorSessionBookingRequestNodeFragment = {
  __typename?: 'MentorSessionBookingRequest'
  id: string
  userRequestId: string
  courseId: string
  expiresOn: any
  learnerIds: Array<string>
  mentorIds: Array<string>
  startsAt: any
  endsAt: any
  status: UserRequestStatus
  recurrence?: string | null
  userRequest: { __typename?: 'UserRequest'; id: string; createdAt: any }
  course: { __typename?: 'Course'; id: string; title: string }
  learners: Array<{
    __typename?: 'Learner'
    id: string
    user?: { __typename?: 'User'; id: string; name: string } | null
    courseMembers: Array<{
      __typename?: 'CourseMember'
      id: string
      learningPurpose?: string | null
      jobExpectation?: string | null
      courseExpectation?: string | null
      studyTimePerWeek?: number | null
      monthToChangeJob?: number | null
    }>
  }>
  mentors: Array<{
    __typename?: 'Mentor'
    id: string
    user?: { __typename?: 'User'; id: string; name: string } | null
  }>
  activityLogs: Array<{
    __typename?: 'ActivityLog'
    id: string
    createdAt: any
    activity: string
    data: any
    userId?: string | null
  }>
}

export type MentorSessionBookingRequestConnectionNodeFragment = {
  __typename?: 'MentorSessionBookingRequest'
  id: string
  userRequestId: string
  courseId: string
  expiresOn: any
  learnerIds: Array<string>
  mentorIds: Array<string>
  startsAt: any
  endsAt: any
  status: UserRequestStatus
  userRequest: { __typename?: 'UserRequest'; id: string; createdAt: any }
  course: { __typename?: 'Course'; id: string; title: string }
  learners: Array<{
    __typename?: 'Learner'
    id: string
    user?: { __typename?: 'User'; id: string; name: string } | null
  }>
  mentors: Array<{
    __typename?: 'Mentor'
    id: string
    user?: { __typename?: 'User'; id: string; name: string } | null
  }>
}

export type MentorSessionBookingRequestConnectionQueryVariables = Exact<{
  first?: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  courseId?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<Array<UserRequestStatus> | UserRequestStatus>
  mentorIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type MentorSessionBookingRequestConnectionQuery = {
  __typename?: 'Query'
  mentorSessionBookingRequestConnection: {
    __typename?: 'MentorSessionBookingRequestConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'MentorSessionBookingRequest'
      id: string
      userRequestId: string
      courseId: string
      expiresOn: any
      learnerIds: Array<string>
      mentorIds: Array<string>
      startsAt: any
      endsAt: any
      status: UserRequestStatus
      userRequest: { __typename?: 'UserRequest'; id: string; createdAt: any }
      course: { __typename?: 'Course'; id: string; title: string }
      learners: Array<{
        __typename?: 'Learner'
        id: string
        user?: { __typename?: 'User'; id: string; name: string } | null
      }>
      mentors: Array<{
        __typename?: 'Mentor'
        id: string
        user?: { __typename?: 'User'; id: string; name: string } | null
      }>
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type MentorSessionBookingRequestsQueryVariables = Exact<{
  courseId?: InputMaybe<Scalars['ID']['input']>
  start?: InputMaybe<Scalars['Date']['input']>
  end?: InputMaybe<Scalars['Date']['input']>
  learnerIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
  mentorIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  status?: InputMaybe<Array<UserRequestStatus> | UserRequestStatus>
}>

export type MentorSessionBookingRequestsQuery = {
  __typename?: 'Query'
  mentorSessionBookingRequests: Array<{
    __typename?: 'MentorSessionBookingRequest'
    id: string
    userRequestId: string
    courseId: string
    expiresOn: any
    learnerIds: Array<string>
    mentorIds: Array<string>
    startsAt: any
    endsAt: any
    status: UserRequestStatus
    recurrence?: string | null
    userRequest: { __typename?: 'UserRequest'; id: string; createdAt: any }
    course: { __typename?: 'Course'; id: string; title: string }
    learners: Array<{
      __typename?: 'Learner'
      id: string
      user?: { __typename?: 'User'; id: string; name: string } | null
      courseMembers: Array<{
        __typename?: 'CourseMember'
        id: string
        learningPurpose?: string | null
        jobExpectation?: string | null
        courseExpectation?: string | null
        studyTimePerWeek?: number | null
        monthToChangeJob?: number | null
      }>
    }>
    mentors: Array<{
      __typename?: 'Mentor'
      id: string
      user?: { __typename?: 'User'; id: string; name: string } | null
    }>
    activityLogs: Array<{
      __typename?: 'ActivityLog'
      id: string
      createdAt: any
      activity: string
      data: any
      userId?: string | null
    }>
  }>
}

export type CreateMentorMutationVariables = Exact<{
  input: CreateMentorInput
}>

export type CreateMentorMutation = {
  __typename?: 'Mutation'
  createMentor: {
    __typename?: 'Mentor'
    id: string
    createdAt: any
    user?: {
      __typename?: 'User'
      id: string
      name: string
      email: string
      roles: Array<UserRole>
      status: UserStatus
    } | null
  }
}

export type MentorListNodeFragment = {
  __typename?: 'Mentor'
  id: string
  createdAt: any
  type: MentorType
  status: MentorStatus
  userId: string
  discordId?: string | null
  courseMembers?: Array<{
    __typename?: 'CourseMember'
    id: string
    course?: { __typename?: 'Course'; id: string; title: string } | null
  }> | null
  user?: { __typename?: 'User'; id: string; email: string; name: string } | null
}

export type MentorConnectionQueryVariables = Exact<{
  first?: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  types?: InputMaybe<Array<MentorType> | MentorType>
  statuses?: InputMaybe<Array<MentorStatus> | MentorStatus>
  courseIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type MentorConnectionQuery = {
  __typename?: 'Query'
  mentorConnection: {
    __typename?: 'MentorConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'Mentor'
      id: string
      createdAt: any
      type: MentorType
      status: MentorStatus
      userId: string
      discordId?: string | null
      courseMembers?: Array<{
        __typename?: 'CourseMember'
        id: string
        course?: { __typename?: 'Course'; id: string; title: string } | null
      }> | null
      user?: {
        __typename?: 'User'
        id: string
        email: string
        name: string
      } | null
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type MentorManagementStatsQueryVariables = Exact<{
  [key: string]: never
}>

export type MentorManagementStatsQuery = {
  __typename?: 'Query'
  mentorConnection: { __typename?: 'MentorConnection'; totalCount: number }
}

export type MentorViewNodeFragment = {
  __typename?: 'Mentor'
  id: string
  address?: string | null
  dateOfBirth?: any | null
  expYears?: string | null
  userId: string
  gender?: Gender | null
  hourlyRate?: number | null
  jobTitle?: string | null
  lwUserId?: string | null
  nationalIdIssuedDate?: any | null
  nationalIdIssuedPlace?: string | null
  nationalIdNumber?: string | null
  oldNationalIdNumber?: string | null
  pitCode?: string | null
  status: MentorStatus
  bankAccountNumber?: string | null
  type: MentorType
  updatedAt: any
  bankBranch?: string | null
  bankName?: string | null
  beneficiary?: string | null
  capacity?: number | null
  city?: string | null
  organization?: string | null
  createdAt: any
  discordId?: string | null
  user?: {
    __typename?: 'User'
    id: string
    email: string
    name: string
    phone?: string | null
  } | null
}

export type MentorViewQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type MentorViewQuery = {
  __typename?: 'Query'
  mentor?: {
    __typename?: 'Mentor'
    id: string
    address?: string | null
    dateOfBirth?: any | null
    expYears?: string | null
    userId: string
    gender?: Gender | null
    hourlyRate?: number | null
    jobTitle?: string | null
    lwUserId?: string | null
    nationalIdIssuedDate?: any | null
    nationalIdIssuedPlace?: string | null
    nationalIdNumber?: string | null
    oldNationalIdNumber?: string | null
    pitCode?: string | null
    status: MentorStatus
    bankAccountNumber?: string | null
    type: MentorType
    updatedAt: any
    bankBranch?: string | null
    bankName?: string | null
    beneficiary?: string | null
    capacity?: number | null
    city?: string | null
    organization?: string | null
    createdAt: any
    discordId?: string | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      name: string
      phone?: string | null
    } | null
  } | null
}

export type MentorProfileFragment = {
  __typename?: 'Mentor'
  id: string
  address?: string | null
  expYears?: string | null
  hourlyRate?: number | null
  jobTitle?: string | null
  lwUserId?: string | null
  nationalIdIssuedDate?: any | null
  nationalIdIssuedPlace?: string | null
  nationalIdNumber?: string | null
  oldNationalIdNumber?: string | null
  pitCode?: string | null
  status: MentorStatus
  bankAccountNumber?: string | null
  type: MentorType
  updatedAt: any
  bankBranch?: string | null
  bankName?: string | null
  beneficiary?: string | null
  capacity?: number | null
  city?: string | null
  organization?: string | null
  createdAt: any
  discordId?: string | null
  dateOfBirth?: any | null
  gender?: Gender | null
  userId: string
  secondaryEmail?: string | null
  user?: {
    __typename?: 'User'
    id: string
    email: string
    name: string
    phone?: string | null
  } | null
}

export type MentorProfileQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type MentorProfileQuery = {
  __typename?: 'Query'
  mentor?: {
    __typename?: 'Mentor'
    id: string
    address?: string | null
    expYears?: string | null
    hourlyRate?: number | null
    jobTitle?: string | null
    lwUserId?: string | null
    nationalIdIssuedDate?: any | null
    nationalIdIssuedPlace?: string | null
    nationalIdNumber?: string | null
    oldNationalIdNumber?: string | null
    pitCode?: string | null
    status: MentorStatus
    bankAccountNumber?: string | null
    type: MentorType
    updatedAt: any
    bankBranch?: string | null
    bankName?: string | null
    beneficiary?: string | null
    capacity?: number | null
    city?: string | null
    organization?: string | null
    createdAt: any
    discordId?: string | null
    dateOfBirth?: any | null
    gender?: Gender | null
    userId: string
    secondaryEmail?: string | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      name: string
      phone?: string | null
    } | null
  } | null
}

export type MentorCourseConnectionQueryVariables = Exact<{
  mentorId: Scalars['ID']['input']
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type MentorCourseConnectionQuery = {
  __typename?: 'Query'
  courseMemberConnection: {
    __typename?: 'CourseMemberConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'CourseMember'
      id: string
      type: CourseMemberType
      status: CourseMemberStatus
      courseId: string
      createdAt: any
      user?: {
        __typename?: 'User'
        id: string
        email: string
        name: string
        phone?: string | null
      } | null
      mentor?: {
        __typename?: 'Mentor'
        id: string
        address?: string | null
        expYears?: string | null
        hourlyRate?: number | null
        jobTitle?: string | null
        lwUserId?: string | null
        nationalIdIssuedDate?: any | null
        nationalIdIssuedPlace?: string | null
        nationalIdNumber?: string | null
        oldNationalIdNumber?: string | null
        pitCode?: string | null
        status: MentorStatus
        bankAccountNumber?: string | null
        type: MentorType
        updatedAt: any
        bankBranch?: string | null
        bankName?: string | null
        beneficiary?: string | null
        capacity?: number | null
        city?: string | null
        organization?: string | null
        createdAt: any
        discordId?: string | null
        dateOfBirth?: any | null
        gender?: Gender | null
        userId: string
        secondaryEmail?: string | null
        user?: {
          __typename?: 'User'
          id: string
          email: string
          name: string
          phone?: string | null
        } | null
      } | null
      course?: {
        __typename?: 'Course'
        id: string
        title: string
        description?: string | null
        lwCourseId?: string | null
      } | null
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type AddMentorToCourseMutationVariables = Exact<{
  mentorId: Scalars['ID']['input']
  courseId: Scalars['ID']['input']
}>

export type AddMentorToCourseMutation = {
  __typename?: 'Mutation'
  addMentorToCourse: {
    __typename?: 'CourseMember'
    id: string
    createdAt: any
    courseId: string
    status: CourseMemberStatus
    mentor?: {
      __typename?: 'Mentor'
      id: string
      lwUserId?: string | null
    } | null
  }
}

export type UpdateMentorProfileMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateMentorInput
}>

export type UpdateMentorProfileMutation = {
  __typename?: 'Mutation'
  updateMentor: {
    __typename?: 'Mentor'
    id: string
    address?: string | null
    expYears?: string | null
    hourlyRate?: number | null
    jobTitle?: string | null
    lwUserId?: string | null
    nationalIdIssuedDate?: any | null
    nationalIdIssuedPlace?: string | null
    nationalIdNumber?: string | null
    oldNationalIdNumber?: string | null
    pitCode?: string | null
    status: MentorStatus
    bankAccountNumber?: string | null
    type: MentorType
    updatedAt: any
    bankBranch?: string | null
    bankName?: string | null
    beneficiary?: string | null
    capacity?: number | null
    city?: string | null
    organization?: string | null
    createdAt: any
    discordId?: string | null
    dateOfBirth?: any | null
    gender?: Gender | null
    userId: string
    secondaryEmail?: string | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      name: string
      phone?: string | null
    } | null
  }
}

export type OngoingMentorSessionCountQueryVariables = Exact<{
  mentorId: Scalars['ID']['input']
}>

export type OngoingMentorSessionCountQuery = {
  __typename?: 'Query'
  mentorSessionConnection: {
    __typename?: 'MentorSessionConnection'
    totalCount: number
  }
  mentorSessionBookingRequestConnection: {
    __typename?: 'MentorSessionBookingRequestConnection'
    totalCount: number
  }
}

export type SalesDealListQueryVariables = Exact<{
  status?: InputMaybe<PipedriveDealStatus>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
}>

export type SalesDealListQuery = {
  __typename?: 'Query'
  dealConnection: {
    __typename?: 'DealConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'Deal'
      id: string
      classInterested?: string | null
      codingSkill?: string | null
      createdAt: any
      industry?: string | null
      jobTitle?: string | null
      leadForm?: string | null
      location?: string | null
      lookingForJob?: string | null
      ownerName?: string | null
      paymentType?: string | null
      personEmail?: string | null
      personName: string
      personPhone?: string | null
      source?: string | null
      status: PipedriveDealStatus
      subject?: string | null
      testScoreResult?: number | null
      updatedAt: any
      value?: number | null
      wonAt?: any | null
      yearsOfExp?: string | null
      user?: {
        __typename?: 'User'
        id: string
        email: string
        name: string
        status: UserStatus
      } | null
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type SyncDealsFromPipeDriveMutationVariables = Exact<{
  [key: string]: never
}>

export type SyncDealsFromPipeDriveMutation = {
  __typename?: 'Mutation'
  syncDealsFromPipeDrive: any
}

export type SalesDealViewQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SalesDealViewQuery = {
  __typename?: 'Query'
  deal?: {
    __typename?: 'Deal'
    id: string
    classInterested?: string | null
    codingSkill?: string | null
    createdAt: any
    industry?: string | null
    jobTitle?: string | null
    leadForm?: string | null
    location?: string | null
    lookingForJob?: string | null
    ownerName?: string | null
    paymentType?: string | null
    personEmail?: string | null
    personName: string
    personPhone?: string | null
    source?: string | null
    status: PipedriveDealStatus
    subject?: string | null
    testScoreResult?: number | null
    updatedAt: any
    value?: number | null
    wonAt?: any | null
    yearsOfExp?: string | null
    user?: {
      __typename?: 'User'
      id: string
      email: string
      name: string
      status: UserStatus
    } | null
  } | null
}

export type SalesDealFragment = {
  __typename?: 'Deal'
  id: string
  classInterested?: string | null
  codingSkill?: string | null
  createdAt: any
  industry?: string | null
  jobTitle?: string | null
  leadForm?: string | null
  location?: string | null
  lookingForJob?: string | null
  ownerName?: string | null
  paymentType?: string | null
  personEmail?: string | null
  personName: string
  personPhone?: string | null
  source?: string | null
  status: PipedriveDealStatus
  subject?: string | null
  testScoreResult?: number | null
  updatedAt: any
  value?: number | null
  wonAt?: any | null
  yearsOfExp?: string | null
  user?: {
    __typename?: 'User'
    id: string
    email: string
    name: string
    status: UserStatus
  } | null
}

export type DisconnectDiscordMutationVariables = Exact<{ [key: string]: never }>

export type DisconnectDiscordMutation = {
  __typename?: 'Mutation'
  disconnectDiscord: { __typename?: 'AuthUser'; id: string }
}

export type UpdateUserPasswordMutationVariables = Exact<{
  currentPassword: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}>

export type UpdateUserPasswordMutation = {
  __typename?: 'Mutation'
  updateUserPassword: { __typename?: 'User'; id: string }
}

export type UpdateUserAvatarMutationVariables = Exact<{
  file?: InputMaybe<Scalars['Upload']['input']>
}>

export type UpdateUserAvatarMutation = {
  __typename?: 'Mutation'
  updateUserAvatar: {
    __typename?: 'User'
    id: string
    avatarFileId?: string | null
    avatar?: {
      __typename?: 'CloudFile'
      id: string
      signedUrl?: string | null
      blurhash?: string | null
    } | null
  }
}

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser: {
    __typename?: 'CreateUserOutput'
    id: string
    name: string
    email: string
    isExists: boolean
    phone?: string | null
  }
}

export type UserConnectionQueryVariables = Exact<{
  first?: Scalars['Int']['input']
  after?: InputMaybe<Scalars['String']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  roles?: InputMaybe<Array<UserRole> | UserRole>
  status?: InputMaybe<UserStatus>
}>

export type UserConnectionQuery = {
  __typename?: 'Query'
  userConnection: {
    __typename?: 'UserConnection'
    totalCount: number
    nodes?: Array<{
      __typename?: 'User'
      id: string
      email: string
      name: string
      status: UserStatus
      roles: Array<UserRole>
    }> | null
    pageInfo: {
      __typename?: 'ConnectionPageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type UserViewFragment = {
  __typename?: 'User'
  id: string
  name: string
  email: string
  roles: Array<UserRole>
  status: UserStatus
  phone?: string | null
  discordId?: string | null
  slackId?: string | null
  zoomUserEmail?: string | null
  mentor?: { __typename?: 'Mentor'; id: string } | null
  learner?: { __typename?: 'Learner'; id: string } | null
}

export type UserViewQueryVariables = Exact<{
  id: Scalars['ID']['input']
  email: Scalars['String']['input']
}>

export type UserViewQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    id: string
    name: string
    email: string
    roles: Array<UserRole>
    status: UserStatus
    phone?: string | null
    discordId?: string | null
    slackId?: string | null
    zoomUserEmail?: string | null
    mentor?: { __typename?: 'Mentor'; id: string } | null
    learner?: { __typename?: 'Learner'; id: string } | null
  } | null
}

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser?: {
    __typename?: 'User'
    id: string
    name: string
    email: string
    roles: Array<UserRole>
    status: UserStatus
    phone?: string | null
    discordId?: string | null
    slackId?: string | null
    zoomUserEmail?: string | null
    mentor?: { __typename?: 'Mentor'; id: string } | null
    learner?: { __typename?: 'Learner'; id: string } | null
  } | null
}

export type SendResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type SendResetPasswordMutation = {
  __typename?: 'Mutation'
  sendResetPassword: string
}

export type LatestLearnerMentorSessionsQueryVariables = Exact<{
  learnerId: Scalars['ID']['input']
}>

export type LatestLearnerMentorSessionsQuery = {
  __typename?: 'Query'
  latestLearnerMentorSessions?: Array<{
    __typename?: 'MentorSession'
    id: string
    course?: { __typename?: 'Course'; id: string; title: string } | null
  }> | null
}

export const ConnectionPageInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const CourseMemberItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseMemberItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lwUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MyMentorSchedulesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyMentorSchedules' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionAvailabilitySchedule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MyLearnerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyLearner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyMentorSchedules' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatarFileId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blurhash' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyMentorSchedules' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionAvailabilitySchedule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MyMentorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyMentor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingBookingRequestCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const CourseMemberSelectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseMemberSelect' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const UserSelectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSelect' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const RecapLearnerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecapLearner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const RecapMentorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecapMentor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const LearnerMemberProgressReportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionRecapItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionRecapItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionRecap' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEventCalendarEventId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const UserRequestItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const RescheduleRequestItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionEventItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionEventItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionRecapItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RescheduleRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zoomMeetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionRecapItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionRecap' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEventCalendarEventId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionActivityLogItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codingSkill' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestProgressReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'LearnerMemberProgressReport',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningPurpose' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthToChangeJob' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studyTimePerWeek' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionEventItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionRecapItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionRecap' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEventCalendarEventId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionEventItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionRecapItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RescheduleRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zoomMeetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionEventRescheduleRequestActivityLogItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'MentorSessionEventRescheduleRequestActivityLogItem',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextMentorSessionEvent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endsAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionEventNodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionEventNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'course' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEvents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorSessionEvent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'calendarEventId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMembers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorSessionId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RescheduleRequestItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const UpdateEventEventInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateEventEventInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrenceEventId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isHost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOptional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const UpdateUserEventFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateUserEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'event' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UpdateEventEventInfo' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateEventEventInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrenceEventId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isHost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOptional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const UserEventQuickViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserEventQuickView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'event' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurrenceEventId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isHost' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isOptional' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const CourseViewUnitFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseViewUnit' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseUnit' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentUnitId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwLearningActivityId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lwSectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const CourseViewWithContentsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseViewWithContents' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwSectionId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'units' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CourseViewUnit' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CourseViewUnit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'children' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CourseViewUnit',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'children' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'CourseViewUnit',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseViewUnit' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseUnit' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentUnitId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwLearningActivityId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lwSectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const CourseMemberViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseMemberView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lwUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const CourseMemberListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseMemberListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lwUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const CourseStaffListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseStaffListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseStaff' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const CourseSelectItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseSelectItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const DeveloperActivityLogConnectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeveloperActivityLogConnection' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const DealListNodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DealListNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Deal' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'classInterested' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wonAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dealId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const LearnerViewNodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerViewNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const DealViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DealView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Deal' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedInUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newJobExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyingCodingCoursesBefore' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'studyTimePerWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'classInterested' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookingForJob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'testScoreResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wonAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yearsOfExp' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerViewNode' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dealId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerViewNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const CourseMemberNodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseMemberNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const LearnerListCourseMemberNodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerListCourseMemberNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestProgressReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const LearnerListNodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerListNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerListCourseMemberNode' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerListCourseMemberNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestProgressReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const LearnerProfileCourseMemberFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studyTimePerWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthToChangeJob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reasonToSwitchCareer' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestProgressReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemedMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalMentorSessionEvents' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const LearnerProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'englishLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinURL' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedPlace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priorCodingCourses' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPhoneNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'workingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studyTimePerWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthToChangeJob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reasonToSwitchCareer' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestProgressReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemedMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalMentorSessionEvents' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const DropStatusActivityLogFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DropStatusActivityLog' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberDropStatusActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'performUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dropReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GraduateStatusActivityLogFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduateStatusActivityLog' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberGraduateStatusActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'performUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const PostponeStatusActivityLogFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostponeStatusActivityLog' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberPostponeStatusActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'performUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postponeReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFinished' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const BookMentorSessionLearnerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionLearner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'englishLevel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const BookMentorSessionLearnerMemberFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionLearnerMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BookMentorSessionLearner' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemedMentorSessionEvents' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionLearner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'englishLevel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const BookMentorSessionMentorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionMentor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'capacity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const BookMentorSessionMentorMemberFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionMentorMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SuitableCourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BookMentorSessionMentor' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bookedSessionsAndPendingRequests' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionMentor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'capacity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const SuggestionItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SuggestionItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionTimeSuggestion' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const CourseViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zohoProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordServerId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const BookingRequestActivityLogItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingRequestActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const BookingRequestViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingRequestView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionBookingRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseView' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learners' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BookingRequestActivityLogItem',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zohoProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordServerId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingRequestActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionBookingRequestMentorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionBookingRequestMentor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'capacity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionBookingRequestMentorMemberFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionBookingRequestMentorMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SuitableCourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MentorSessionBookingRequestMentor',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bookedSessionsAndPendingRequests' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionBookingRequestMentor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'capacity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionBookingRequestLearnerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionBookingRequestLearner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'englishLevel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionBookingRequestLearnerMemberFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionBookingRequestLearnerMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MentorSessionBookingRequestLearner',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionBookingRequestLearner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'englishLevel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const RequestLearnerInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestLearnerInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studyTimePerWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthToChangeJob' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionBookingRequestNodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionBookingRequestNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionBookingRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learners' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMembers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestLearnerInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestLearnerInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studyTimePerWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthToChangeJob' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorSessionBookingRequestConnectionNodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'MentorSessionBookingRequestConnectionNode',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionBookingRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learners' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorListNodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorListNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'course' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorViewNodeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorViewNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hourlyRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedPlace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oldNationalIdNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pitCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankAccountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankBranch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beneficiary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'capacity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentorProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hourlyRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedPlace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oldNationalIdNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pitCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankAccountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankBranch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beneficiary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'capacity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const SalesDealFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesDeal' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Deal' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'classInterested' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookingForJob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'testScoreResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wonAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yearsOfExp' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const UserViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slackId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomUserEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const AppAuthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppAuth' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatarFileId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPassword' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useAppAuthQuery(
  options?: Omit<Urql.UseQueryArgs<AppAuthQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AppAuthQuery, AppAuthQueryVariables>({
    query: AppAuthDocument,
    ...options,
  })
}
export const ActivityTypesFilterListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ActivityTypesFilterList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useActivityTypesFilterListQuery(
  options?: Omit<
    Urql.UseQueryArgs<ActivityTypesFilterListQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ActivityTypesFilterListQuery,
    ActivityTypesFilterListQueryVariables
  >({ query: ActivityTypesFilterListDocument, ...options })
}
export const CourseMemberListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CourseMemberList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CourseMemberStatus' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CourseMemberType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseMemberItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseMemberItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lwUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCourseMemberListQuery(
  options?: Omit<Urql.UseQueryArgs<CourseMemberListQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CourseMemberListQuery, CourseMemberListQueryVariables>({
    query: CourseMemberListDocument,
    ...options,
  })
}
export const CoursesFilterListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CoursesFilterList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalAllowedTASessions' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCoursesFilterListQuery(
  options?: Omit<Urql.UseQueryArgs<CoursesFilterListQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CoursesFilterListQuery, CoursesFilterListQueryVariables>(
    { query: CoursesFilterListDocument, ...options },
  )
}
export const MyLearnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyLearner' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myLearner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyLearner' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyLearner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyMentorSchedules' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatarFileId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blurhash' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyMentorSchedules' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionAvailabilitySchedule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMyLearnerQuery(
  options?: Omit<Urql.UseQueryArgs<MyLearnerQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MyLearnerQuery, MyLearnerQueryVariables>({
    query: MyLearnerDocument,
    ...options,
  })
}
export const LearnersFilterListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnersFilterList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '20' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learnerConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnersFilterListQuery(
  options?: Omit<Urql.UseQueryArgs<LearnersFilterListQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    LearnersFilterListQuery,
    LearnersFilterListQueryVariables
  >({ query: LearnersFilterListDocument, ...options })
}
export const LwSingleSignOnDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LwSingleSignOn' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'lwSSO' } }],
      },
    },
  ],
} as unknown as DocumentNode

export function useLwSingleSignOnMutation() {
  return Urql.useMutation<
    LwSingleSignOnMutation,
    LwSingleSignOnMutationVariables
  >(LwSingleSignOnDocument)
}
export const MyMentorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyMentor' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myMentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyMentor' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyMentor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingBookingRequestCount' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMyMentorQuery(
  options?: Omit<Urql.UseQueryArgs<MyMentorQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MyMentorQuery, MyMentorQueryVariables>({
    query: MyMentorDocument,
    ...options,
  })
}
export const MentorsFilterListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorsFilterList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '20' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorsFilterListQuery(
  options?: Omit<Urql.UseQueryArgs<MentorsFilterListQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MentorsFilterListQuery, MentorsFilterListQueryVariables>(
    { query: MentorsFilterListDocument, ...options },
  )
}
export const UsersFilterListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UsersFilterList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roles' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'UserRole' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserStatus' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roles' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'roles' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '10000' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learner' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUsersFilterListQuery(
  options?: Omit<Urql.UseQueryArgs<UsersFilterListQueryVariables>, 'query'>,
) {
  return Urql.useQuery<UsersFilterListQuery, UsersFilterListQueryVariables>({
    query: UsersFilterListDocument,
    ...options,
  })
}
export const MyMentorSchedulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyMentorSchedules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'targetId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'targetId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyMentorSchedules' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyMentorSchedules' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionAvailabilitySchedule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMyMentorSchedulesQuery(
  options: Omit<Urql.UseQueryArgs<MyMentorSchedulesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MyMentorSchedulesQuery, MyMentorSchedulesQueryVariables>(
    { query: MyMentorSchedulesDocument, ...options },
  )
}
export const UpdateMyMentorSchedulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMyMentorSchedules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'targetId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'schedules' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'MentorSessionAvailabilityInput',
                  },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateMentorSessionAvailabilitySchedules',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'schedules' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'schedules' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'targetId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MyMentorSchedules' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MyMentorSchedules' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionAvailabilitySchedule' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateMyMentorSchedulesMutation() {
  return Urql.useMutation<
    UpdateMyMentorSchedulesMutation,
    UpdateMyMentorSchedulesMutationVariables
  >(UpdateMyMentorSchedulesDocument)
}
export const CustomFieldsSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomFieldsSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'targetGroup' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomFieldTargetGroup' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'targetGroup' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetGroup' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCustomFieldsSettingsQuery(
  options: Omit<Urql.UseQueryArgs<CustomFieldsSettingsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    CustomFieldsSettingsQuery,
    CustomFieldsSettingsQueryVariables
  >({ query: CustomFieldsSettingsDocument, ...options })
}
export const CreateCustomFieldDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCustomField' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCustomFieldInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCustomField' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetGroup' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateCustomFieldMutation() {
  return Urql.useMutation<
    CreateCustomFieldMutation,
    CreateCustomFieldMutationVariables
  >(CreateCustomFieldDocument)
}
export const UpdateCustomFieldDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomField' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCustomFieldInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomField' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetGroup' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateCustomFieldMutation() {
  return Urql.useMutation<
    UpdateCustomFieldMutation,
    UpdateCustomFieldMutationVariables
  >(UpdateCustomFieldDocument)
}
export const DeleteCustomFieldDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCustomField' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomField' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useDeleteCustomFieldMutation() {
  return Urql.useMutation<
    DeleteCustomFieldMutation,
    DeleteCustomFieldMutationVariables
  >(DeleteCustomFieldDocument)
}
export const AvailabilitySchedulesViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AvailabilitySchedulesView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'targetId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'targetIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'targetId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'targetIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useAvailabilitySchedulesViewQuery(
  options?: Omit<
    Urql.UseQueryArgs<AvailabilitySchedulesViewQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    AvailabilitySchedulesViewQuery,
    AvailabilitySchedulesViewQueryVariables
  >({ query: AvailabilitySchedulesViewDocument, ...options })
}
export const UserAvatarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserAvatar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blurhash' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUserAvatarQuery(
  options: Omit<Urql.UseQueryArgs<UserAvatarQueryVariables>, 'query'>,
) {
  return Urql.useQuery<UserAvatarQuery, UserAvatarQueryVariables>({
    query: UserAvatarDocument,
    ...options,
  })
}
export const UserDisplayNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserDisplayName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUserDisplayNameQuery(
  options: Omit<Urql.UseQueryArgs<UserDisplayNameQueryVariables>, 'query'>,
) {
  return Urql.useQuery<UserDisplayNameQuery, UserDisplayNameQueryVariables>({
    query: UserDisplayNameDocument,
    ...options,
  })
}
export const CourseMemberSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CourseMemberSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMemberConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CourseMemberSelect' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseMemberSelect' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCourseMemberSelectQuery(
  options?: Omit<Urql.UseQueryArgs<CourseMemberSelectQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    CourseMemberSelectQuery,
    CourseMemberSelectQueryVariables
  >({ query: CourseMemberSelectDocument, ...options })
}
export const UserAutocompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserAutocomplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'excludedUserIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '20' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'excludedUserIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'excludedUserIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUserAutocompleteQuery(
  options?: Omit<Urql.UseQueryArgs<UserAutocompleteQueryVariables>, 'query'>,
) {
  return Urql.useQuery<UserAutocompleteQuery, UserAutocompleteQueryVariables>({
    query: UserAutocompleteDocument,
    ...options,
  })
}
export const UserSelectConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserSelectConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '20' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includedUserIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includedUserIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includedUserIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserSelect' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSelect' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUserSelectConnectionQuery(
  options?: Omit<
    Urql.UseQueryArgs<UserSelectConnectionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    UserSelectConnectionQuery,
    UserSelectConnectionQueryVariables
  >({ query: UserSelectConnectionDocument, ...options })
}
export const GetEventMeetingUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetEventMeetingUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEventMeetingUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventId' },
                },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'joinEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useGetEventMeetingUrlMutation() {
  return Urql.useMutation<
    GetEventMeetingUrlMutation,
    GetEventMeetingUrlMutationVariables
  >(GetEventMeetingUrlDocument)
}
export const MentorSessionEventViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorSessionEventView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEventId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attendees' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecaps' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learnerId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdByUserId' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'mentorSessionEventCalendarEventId',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requiredRecapCount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mentee' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mentor' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'mentorSessionRecapCount',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mentee' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mentor' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorSessionEventViewQuery(
  options: Omit<
    Urql.UseQueryArgs<MentorSessionEventViewQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MentorSessionEventViewQuery,
    MentorSessionEventViewQueryVariables
  >({ query: MentorSessionEventViewDocument, ...options })
}
export const LearnerMentorSessionRecapsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnerMentorSessionRecaps' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorSessionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorSessionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorSessionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'MENTOR_RECAP' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdByUserId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formSubmission' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorSession' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'course' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnerMentorSessionRecapsQuery(
  options: Omit<
    Urql.UseQueryArgs<LearnerMentorSessionRecapsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LearnerMentorSessionRecapsQuery,
    LearnerMentorSessionRecapsQueryVariables
  >({ query: LearnerMentorSessionRecapsDocument, ...options })
}
export const LearnerMemberProgressReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCourseMemberProgressReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnerMemberProgressReportQuery(
  options: Omit<
    Urql.UseQueryArgs<LearnerMemberProgressReportQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LearnerMemberProgressReportQuery,
    LearnerMemberProgressReportQueryVariables
  >({ query: LearnerMemberProgressReportDocument, ...options })
}
export const CreateLearnerMemberProgressReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLearnerMemberProgressReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCourseMemberProgressReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateLearnerMemberProgressReportMutation() {
  return Urql.useMutation<
    CreateLearnerMemberProgressReportMutation,
    CreateLearnerMemberProgressReportMutationVariables
  >(CreateLearnerMemberProgressReportDocument)
}
export const MentorMentorSessionRecapsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorMentorSessionRecaps' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorSessionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorSessionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorSessionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'MENTEE_RECAP' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdByUserId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formSubmission' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorSession' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'course' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorMentorSessionRecapsQuery(
  options: Omit<
    Urql.UseQueryArgs<MentorMentorSessionRecapsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MentorMentorSessionRecapsQuery,
    MentorMentorSessionRecapsQueryVariables
  >({ query: MentorMentorSessionRecapsDocument, ...options })
}
export const MentorSessionRecapListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorSessionRecapList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorSessionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'MentorSessionRecapType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorSessionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorSessionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RecapLearner' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RecapMentor' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdByUserId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formSubmission' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorSession' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'course' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecapLearner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecapMentor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorSessionRecapListQuery(
  options?: Omit<
    Urql.UseQueryArgs<MentorSessionRecapListQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MentorSessionRecapListQuery,
    MentorSessionRecapListQueryVariables
  >({ query: MentorSessionRecapListDocument, ...options })
}
export const ViewMentorSessionRecapDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ViewMentorSessionRecap' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recapId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecap' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'recapId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdByUserId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formSubmissionId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formSubmission' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'mentorSessionEventCalendarEventId',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendarEvent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startsAt' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useViewMentorSessionRecapQuery(
  options: Omit<
    Urql.UseQueryArgs<ViewMentorSessionRecapQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ViewMentorSessionRecapQuery,
    ViewMentorSessionRecapQueryVariables
  >({ query: ViewMentorSessionRecapDocument, ...options })
}
export const MentorSessionViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorSessionView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionRecapItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionRecap' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEventCalendarEventId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionEventItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionRecapItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RescheduleRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zoomMeetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codingSkill' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestProgressReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'LearnerMemberProgressReport',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningPurpose' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthToChangeJob' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studyTimePerWeek' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionEventItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorSessionViewQuery(
  options: Omit<Urql.UseQueryArgs<MentorSessionViewQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MentorSessionViewQuery, MentorSessionViewQueryVariables>(
    { query: MentorSessionViewDocument, ...options },
  )
}
export const DeleteMentorSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMentorSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteMentorSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useDeleteMentorSessionMutation() {
  return Urql.useMutation<
    DeleteMentorSessionMutation,
    DeleteMentorSessionMutationVariables
  >(DeleteMentorSessionDocument)
}
export const CanceledMentorSessionEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CanceledMentorSessionEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endsAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCanceledMentorSessionEventQuery(
  options: Omit<
    Urql.UseQueryArgs<CanceledMentorSessionEventQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    CanceledMentorSessionEventQuery,
    CanceledMentorSessionEventQueryVariables
  >({ query: CanceledMentorSessionEventDocument, ...options })
}
export const MentorSessionEventRescheduleRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userRequestId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'mentorSessionEventRescheduleRequest',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userRequestId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userRequestId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userRequestId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipientUserIds' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEventId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calendarEvent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startsAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endsAt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorSessionId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorSession' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'course' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityLogs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value:
                            'MentorSessionEventRescheduleRequestActivityLogItem',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'MentorSessionEventRescheduleRequestActivityLogItem',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorSessionEventRescheduleRequestQuery(
  options: Omit<
    Urql.UseQueryArgs<MentorSessionEventRescheduleRequestQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MentorSessionEventRescheduleRequestQuery,
    MentorSessionEventRescheduleRequestQueryVariables
  >({ query: MentorSessionEventRescheduleRequestDocument, ...options })
}
export const CancelMentorSessionEventRescheduleRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'CancelMentorSessionEventRescheduleRequest',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userRequestId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CancelMentorSessionEventRescheduleRequestInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'cancelMentorSessionEventRescheduleRequest',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userRequestId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userRequestId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCancelMentorSessionEventRescheduleRequestMutation() {
  return Urql.useMutation<
    CancelMentorSessionEventRescheduleRequestMutation,
    CancelMentorSessionEventRescheduleRequestMutationVariables
  >(CancelMentorSessionEventRescheduleRequestDocument)
}
export const ResponseMentorSessionEventRescheduleRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'ResponseMentorSessionEventRescheduleRequest',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateMentorSessionEventRescheduleResponseInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'createMentorSessionEventRescheduleResponse',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionEventItem' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionId' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionRecapItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionRecap' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEventCalendarEventId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionEventItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionRecapItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RescheduleRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zoomMeetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useResponseMentorSessionEventRescheduleRequestMutation() {
  return Urql.useMutation<
    ResponseMentorSessionEventRescheduleRequestMutation,
    ResponseMentorSessionEventRescheduleRequestMutationVariables
  >(ResponseMentorSessionEventRescheduleRequestDocument)
}
export const CreateMentorSessionRecapForMentorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMentorSessionRecapForMentor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMentorSessionRecapInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMentorSessionRecap' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'formSubmissionId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorSessionRecaps' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateMentorSessionRecapForMentorMutation() {
  return Urql.useMutation<
    CreateMentorSessionRecapForMentorMutation,
    CreateMentorSessionRecapForMentorMutationVariables
  >(CreateMentorSessionRecapForMentorDocument)
}
export const LearnerLatestLwSsoActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnerLatestLwSSOActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestLearnerLwSSOActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnerLatestLwSsoActivityQuery(
  options: Omit<
    Urql.UseQueryArgs<LearnerLatestLwSsoActivityQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LearnerLatestLwSsoActivityQuery,
    LearnerLatestLwSsoActivityQueryVariables
  >({ query: LearnerLatestLwSsoActivityDocument, ...options })
}
export const CancelSessionEventForLearnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelSessionEventForLearner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cancelReason' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelMentorSessionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cancelReason' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cancelReason' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSession' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionView' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionRecapItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionRecap' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEventCalendarEventId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionEventItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionRecapItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RescheduleRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zoomMeetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codingSkill' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestProgressReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'LearnerMemberProgressReport',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningPurpose' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthToChangeJob' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studyTimePerWeek' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionEventItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCancelSessionEventForLearnerMutation() {
  return Urql.useMutation<
    CancelSessionEventForLearnerMutation,
    CancelSessionEventForLearnerMutationVariables
  >(CancelSessionEventForLearnerDocument)
}
export const MentorSessionsOfMentorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorSessionsOfMentor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextMentorSessionEvent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endsAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorSessionsOfMentorQuery(
  options: Omit<
    Urql.UseQueryArgs<MentorSessionsOfMentorQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MentorSessionsOfMentorQuery,
    MentorSessionsOfMentorQueryVariables
  >({ query: MentorSessionsOfMentorDocument, ...options })
}
export const CancelSessionEventForMentorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelSessionEventForMentor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cancelReason' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelMentorSessionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cancelReason' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cancelReason' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSession' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionView' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionRecapItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionRecap' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEventCalendarEventId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionEventItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionRecapItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RescheduleRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zoomMeetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codingSkill' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestProgressReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'LearnerMemberProgressReport',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningPurpose' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthToChangeJob' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studyTimePerWeek' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionEventItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCancelSessionEventForMentorMutation() {
  return Urql.useMutation<
    CancelSessionEventForMentorMutation,
    CancelSessionEventForMentorMutationVariables
  >(CancelSessionEventForMentorDocument)
}
export const CreateMentorSessionBookingRequestResponseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'CreateMentorSessionBookingRequestResponse',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateMentorSessionBookingResponseInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMentorSessionBookingResponse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateMentorSessionBookingRequestResponseMutation() {
  return Urql.useMutation<
    CreateMentorSessionBookingRequestResponseMutation,
    CreateMentorSessionBookingRequestResponseMutationVariables
  >(CreateMentorSessionBookingRequestResponseDocument)
}
export const MentorTimesheetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorTimesheet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorTimesheet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'from' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'to' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'absentSessions' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'canceledSessions' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'courseTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finishedSessions' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'finishedSessionsWithMentorRecap',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorUserId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorTimesheetQuery(
  options?: Omit<Urql.UseQueryArgs<MentorTimesheetQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MentorTimesheetQuery, MentorTimesheetQueryVariables>({
    query: MentorTimesheetDocument,
    ...options,
  })
}
export const MentorSessionEventConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorSessionEventConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MentorSessionEventStatus' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'mentorSessionEventConnection_deprecated',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'from' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'to' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionEventNode' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionEventNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarEventId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'course' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEvents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorSessionEvent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'calendarEventId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMembers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorSessionId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RescheduleRequestItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorSessionEventConnectionQuery(
  options?: Omit<
    Urql.UseQueryArgs<MentorSessionEventConnectionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MentorSessionEventConnectionQuery,
    MentorSessionEventConnectionQueryVariables
  >({ query: MentorSessionEventConnectionDocument, ...options })
}
export const WorkplaceDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WorkplaceDashboard' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workplaceStatistic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deals' } },
                { kind: 'Field', name: { kind: 'Name', value: 'learners' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mentors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useWorkplaceDashboardQuery(
  options?: Omit<Urql.UseQueryArgs<WorkplaceDashboardQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    WorkplaceDashboardQuery,
    WorkplaceDashboardQueryVariables
  >({ query: WorkplaceDashboardDocument, ...options })
}
export const RequestResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromCrossroads' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestResetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromCrossroads' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fromCrossroads' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useRequestResetPasswordMutation() {
  return Urql.useMutation<
    RequestResetPasswordMutation,
    RequestResetPasswordMutationVariables
  >(RequestResetPasswordDocument)
}
export const ResetUserPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetUserPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetUserPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useResetUserPasswordMutation() {
  return Urql.useMutation<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >(ResetUserPasswordDocument)
}
export const InitializePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'initializePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setupUserPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useInitializePasswordMutation() {
  return Urql.useMutation<
    InitializePasswordMutation,
    InitializePasswordMutationVariables
  >(InitializePasswordDocument)
}
export const SignInDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SignIn' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'credential' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'remember' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'platformSignIn' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'credential' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'credential' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'remember' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'remember' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useSignInMutation() {
  return Urql.useMutation<SignInMutation, SignInMutationVariables>(
    SignInDocument,
  )
}
export const UserCalendarViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserCalendarView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'between' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Date' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendarIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'between' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'between' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'calendarIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'calendarIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'calendarId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCurrentUserHost' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurrence' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUserCalendarViewQuery(
  options: Omit<Urql.UseQueryArgs<UserCalendarViewQueryVariables>, 'query'>,
) {
  return Urql.useQuery<UserCalendarViewQuery, UserCalendarViewQueryVariables>({
    query: UserCalendarViewDocument,
    ...options,
  })
}
export const CreateEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCalendarEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCalendarEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateEventMutation() {
  return Urql.useMutation<CreateEventMutation, CreateEventMutationVariables>(
    CreateEventDocument,
  )
}
export const UpdateUserEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateUserEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UpdateUserEvent' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateEventEventInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrenceEventId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isHost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOptional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateUserEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'event' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UpdateEventEventInfo' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateUserEventQuery(
  options: Omit<Urql.UseQueryArgs<UpdateUserEventQueryVariables>, 'query'>,
) {
  return Urql.useQuery<UpdateUserEventQuery, UpdateUserEventQueryVariables>({
    query: UpdateUserEventDocument,
    ...options,
  })
}
export const UpdateCalendarEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCalendarEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCalendarEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCalendarEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UpdateEventEventInfo' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateEventEventInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CalendarEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrenceEventId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attendees' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isHost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOptional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateCalendarEventMutation() {
  return Urql.useMutation<
    UpdateCalendarEventMutation,
    UpdateCalendarEventMutationVariables
  >(UpdateCalendarEventDocument)
}
export const UserCalendarSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserCalendarSelect' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userCalendars' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'calendarId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isPublic' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUserCalendarSelectQuery(
  options?: Omit<Urql.UseQueryArgs<UserCalendarSelectQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    UserCalendarSelectQuery,
    UserCalendarSelectQueryVariables
  >({ query: UserCalendarSelectDocument, ...options })
}
export const DeleteCalendarEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCalendarEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'CalendarEventRecurrenceUpdateOption',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelCalendarEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useDeleteCalendarEventMutation() {
  return Urql.useMutation<
    DeleteCalendarEventMutation,
    DeleteCalendarEventMutationVariables
  >(DeleteCalendarEventDocument)
}
export const UserEventGoingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserEventGoingStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUserEventGoingStatusQuery(
  options: Omit<Urql.UseQueryArgs<UserEventGoingStatusQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    UserEventGoingStatusQuery,
    UserEventGoingStatusQueryVariables
  >({ query: UserEventGoingStatusDocument, ...options })
}
export const UpdateUserEventGoingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserEventGoingStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserEventStatus' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateUserEventGoingStatusMutation() {
  return Urql.useMutation<
    UpdateUserEventGoingStatusMutation,
    UpdateUserEventGoingStatusMutationVariables
  >(UpdateUserEventGoingStatusDocument)
}
export const UserEventQuickViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserEventQuickView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserEventQuickView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserEventQuickView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'calendarId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendar' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'event' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurrenceEventId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isHost' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isOptional' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUserEventQuickViewQuery(
  options: Omit<Urql.UseQueryArgs<UserEventQuickViewQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    UserEventQuickViewQuery,
    UserEventQuickViewQueryVariables
  >({ query: UserEventQuickViewDocument, ...options })
}
export const CourseListConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CourseListConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'access' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lwCourse' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'courseImage' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lwCourseId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCourseListConnectionQuery(
  options?: Omit<
    Urql.UseQueryArgs<CourseListConnectionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    CourseListConnectionQuery,
    CourseListConnectionQueryVariables
  >({ query: CourseListConnectionDocument, ...options })
}
export const CourseViewWithContentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CourseViewWithContents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseViewWithContents' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseViewUnit' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseUnit' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentUnitId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwLearningActivityId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lwSectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseViewWithContents' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwSectionId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'units' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CourseViewUnit' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CourseViewUnit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'children' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CourseViewUnit',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'children' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'CourseViewUnit',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCourseViewWithContentsQuery(
  options: Omit<
    Urql.UseQueryArgs<CourseViewWithContentsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    CourseViewWithContentsQuery,
    CourseViewWithContentsQueryVariables
  >({ query: CourseViewWithContentsDocument, ...options })
}
export const CourseMemberViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CourseMemberView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseMemberView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseMemberView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lwUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCourseMemberViewQuery(
  options: Omit<Urql.UseQueryArgs<CourseMemberViewQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CourseMemberViewQuery, CourseMemberViewQueryVariables>({
    query: CourseMemberViewDocument,
    ...options,
  })
}
export const CourseMemberConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CourseMemberConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CourseMemberStatus' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CourseMemberType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMemberConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CourseMemberListItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseMemberListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lwUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCourseMemberConnectionQuery(
  options?: Omit<
    Urql.UseQueryArgs<CourseMemberConnectionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    CourseMemberConnectionQuery,
    CourseMemberConnectionQueryVariables
  >({ query: CourseMemberConnectionDocument, ...options })
}
export const CourseStaffConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CourseStaffConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CourseStaffStatus' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseStaffConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CourseStaffListItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseStaffListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseStaff' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCourseStaffConnectionQuery(
  options?: Omit<
    Urql.UseQueryArgs<CourseStaffConnectionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    CourseStaffConnectionQuery,
    CourseStaffConnectionQueryVariables
  >({ query: CourseStaffConnectionDocument, ...options })
}
export const CreateCourseStaffDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCourseStaff' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCourseStaff' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseStaffListItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseStaffListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseStaff' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateCourseStaffMutation() {
  return Urql.useMutation<
    CreateCourseStaffMutation,
    CreateCourseStaffMutationVariables
  >(CreateCourseStaffDocument)
}
export const DeleteCourseStaffDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCourseStaff' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseStaffId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardDeleteCourseStaff' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseStaffId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useDeleteCourseStaffMutation() {
  return Urql.useMutation<
    DeleteCourseStaffMutation,
    DeleteCourseStaffMutationVariables
  >(DeleteCourseStaffDocument)
}
export const UpdateCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCourseInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCourse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zohoProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordServerId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateCourseMutation() {
  return Urql.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(
    UpdateCourseDocument,
  )
}
export const DeleteCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCourse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useDeleteCourseMutation() {
  return Urql.useMutation<DeleteCourseMutation, DeleteCourseMutationVariables>(
    DeleteCourseDocument,
  )
}
export const CourseTotalAllowedTaSessionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CourseTotalAllowedTASessions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalAllowedTASessions' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCourseTotalAllowedTaSessionsQuery(
  options: Omit<
    Urql.UseQueryArgs<CourseTotalAllowedTaSessionsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    CourseTotalAllowedTaSessionsQuery,
    CourseTotalAllowedTaSessionsQueryVariables
  >({ query: CourseTotalAllowedTaSessionsDocument, ...options })
}
export const CourseViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CourseView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseView' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'learnerConnection' },
            name: { kind: 'Name', value: 'courseMemberConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'LEARNER' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'NullValue' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mentorConnection' },
            name: { kind: 'Name', value: 'courseMemberConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'MENTOR' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'NullValue' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zohoProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordServerId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCourseViewQuery(
  options: Omit<Urql.UseQueryArgs<CourseViewQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CourseViewQuery, CourseViewQueryVariables>({
    query: CourseViewDocument,
    ...options,
  })
}
export const CreateCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCourseInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCourse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateCourseMutation() {
  return Urql.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(
    CreateCourseDocument,
  )
}
export const LwCourseContentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LwCourseContents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwCourse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'access' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningUnits' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subtitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'icon' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLwCourseContentsQuery(
  options: Omit<Urql.UseQueryArgs<LwCourseContentsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<LwCourseContentsQuery, LwCourseContentsQueryVariables>({
    query: LwCourseContentsDocument,
    ...options,
  })
}
export const UpdateCourseContentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCourseContents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sections' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'CourseSectionInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deletedSectionIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deletedUnitIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCourseContents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sections' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sections' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deletedSectionIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deletedSectionIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deletedUnitIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deletedUnitIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseViewWithContents' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseViewUnit' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseUnit' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentUnitId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwLearningActivityId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lwSectionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseViewWithContents' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwSectionId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'units' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CourseViewUnit' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CourseViewUnit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'children' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'CourseViewUnit',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'children' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'CourseViewUnit',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateCourseContentsMutation() {
  return Urql.useMutation<
    UpdateCourseContentsMutation,
    UpdateCourseContentsMutationVariables
  >(UpdateCourseContentsDocument)
}
export const CourseSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CourseSelect' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '200' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CourseSelectItem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseSelectItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCourseSelectQuery(
  options?: Omit<Urql.UseQueryArgs<CourseSelectQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CourseSelectQuery, CourseSelectQueryVariables>({
    query: CourseSelectDocument,
    ...options,
  })
}
export const LwCourseSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LwCourseSelect' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwCourses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'access' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLwCourseSelectQuery(
  options?: Omit<Urql.UseQueryArgs<LwCourseSelectQueryVariables>, 'query'>,
) {
  return Urql.useQuery<LwCourseSelectQuery, LwCourseSelectQueryVariables>({
    query: LwCourseSelectDocument,
    ...options,
  })
}
export const ZohoProductSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ZohoProductSelect' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zohoProducts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useZohoProductSelectQuery(
  options?: Omit<Urql.UseQueryArgs<ZohoProductSelectQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ZohoProductSelectQuery, ZohoProductSelectQueryVariables>(
    { query: ZohoProductSelectDocument, ...options },
  )
}
export const DeveloperActivityLogConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DeveloperActivityLogConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activities' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'performedBy' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activities' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activities' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'performedBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'performedBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'from' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'to' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'DeveloperActivityLogConnection',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeveloperActivityLogConnection' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useDeveloperActivityLogConnectionQuery(
  options?: Omit<
    Urql.UseQueryArgs<DeveloperActivityLogConnectionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    DeveloperActivityLogConnectionQuery,
    DeveloperActivityLogConnectionQueryVariables
  >({ query: DeveloperActivityLogConnectionDocument, ...options })
}
export const SyncAllCourseMemberProgressFromLwDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncAllCourseMemberProgressFromLw' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncAllCourseMemberProgressFromLw' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useSyncAllCourseMemberProgressFromLwMutation() {
  return Urql.useMutation<
    SyncAllCourseMemberProgressFromLwMutation,
    SyncAllCourseMemberProgressFromLwMutationVariables
  >(SyncAllCourseMemberProgressFromLwDocument)
}
export const NormalizeAllUserEmailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NormalizeAllUserEmails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'normalizeAllUserEmails' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useNormalizeAllUserEmailsMutation() {
  return Urql.useMutation<
    NormalizeAllUserEmailsMutation,
    NormalizeAllUserEmailsMutationVariables
  >(NormalizeAllUserEmailsDocument)
}
export const UpdateFullTextSearchForAllUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFullTextSearchForAllUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFullTextSearchForAllUsers' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateFullTextSearchForAllUsersMutation() {
  return Urql.useMutation<
    UpdateFullTextSearchForAllUsersMutation,
    UpdateFullTextSearchForAllUsersMutationVariables
  >(UpdateFullTextSearchForAllUsersDocument)
}
export const EnsureLearnerLwUserTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnsureLearnerLwUserTags' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'message' },
            name: { kind: 'Name', value: 'ensureEveryLearnerHasLwAccountTags' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useEnsureLearnerLwUserTagsMutation() {
  return Urql.useMutation<
    EnsureLearnerLwUserTagsMutation,
    EnsureLearnerLwUserTagsMutationVariables
  >(EnsureLearnerLwUserTagsDocument)
}
export const UpdateFullTextSearchForAllBookingRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'UpdateFullTextSearchForAllBookingRequests',
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateFullTextSearchForAllBookingRequests',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateFullTextSearchForAllBookingRequestsMutation() {
  return Urql.useMutation<
    UpdateFullTextSearchForAllBookingRequestsMutation,
    UpdateFullTextSearchForAllBookingRequestsMutationVariables
  >(UpdateFullTextSearchForAllBookingRequestsDocument)
}
export const CheckExpiredMentorSessionBookingRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CheckExpiredMentorSessionBookingRequests' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkExpiredBookingRequests' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCheckExpiredMentorSessionBookingRequestsMutation() {
  return Urql.useMutation<
    CheckExpiredMentorSessionBookingRequestsMutation,
    CheckExpiredMentorSessionBookingRequestsMutationVariables
  >(CheckExpiredMentorSessionBookingRequestsDocument)
}
export const NotifyAboutReturningLearnersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NotifyAboutReturningLearners' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'notifyAboutReturningPostponedCourseMembers',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useNotifyAboutReturningLearnersMutation() {
  return Urql.useMutation<
    NotifyAboutReturningLearnersMutation,
    NotifyAboutReturningLearnersMutationVariables
  >(NotifyAboutReturningLearnersDocument)
}
export const FlattenAllCourseUnitsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FlattenAllCourseUnits' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flattenAllCourseUnits' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useFlattenAllCourseUnitsMutation() {
  return Urql.useMutation<
    FlattenAllCourseUnitsMutation,
    FlattenAllCourseUnitsMutationVariables
  >(FlattenAllCourseUnitsDocument)
}
export const UpdateTargetIdsOfAllActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTargetIdsOfAllActivities' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateTargetIdsOfAllRecapActivities',
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateTargetIdsOfAllRescheduleRequestActivities',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateTargetIdsOfAllActivitiesMutation() {
  return Urql.useMutation<
    UpdateTargetIdsOfAllActivitiesMutation,
    UpdateTargetIdsOfAllActivitiesMutationVariables
  >(UpdateTargetIdsOfAllActivitiesDocument)
}
export const SyncAllRecordingsFromVsdkToDbDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncAllRecordingsFromVSDKToDb' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncAllRecordingsFromVSDKToDb' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useSyncAllRecordingsFromVsdkToDbMutation() {
  return Urql.useMutation<
    SyncAllRecordingsFromVsdkToDbMutation,
    SyncAllRecordingsFromVsdkToDbMutationVariables
  >(SyncAllRecordingsFromVsdkToDbDocument)
}
export const UpdateAllChatChannelsTypeToMentorSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAllChatChannelsTypeToMentorSession' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateAllChatChannelsTypeToMentorSession',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateAllChatChannelsTypeToMentorSessionMutation() {
  return Urql.useMutation<
    UpdateAllChatChannelsTypeToMentorSessionMutation,
    UpdateAllChatChannelsTypeToMentorSessionMutationVariables
  >(UpdateAllChatChannelsTypeToMentorSessionDocument)
}
export const GenerateSecretSantaPairsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GenerateSecretSantaPairs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateSecretSantaPairs' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useGenerateSecretSantaPairsMutation() {
  return Urql.useMutation<
    GenerateSecretSantaPairsMutation,
    GenerateSecretSantaPairsMutationVariables
  >(GenerateSecretSantaPairsDocument)
}
export const SyncSchoolEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncSchoolEvents' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'syncSchoolEvents' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useSyncSchoolEventsMutation() {
  return Urql.useMutation<
    SyncSchoolEventsMutation,
    SyncSchoolEventsMutationVariables
  >(SyncSchoolEventsDocument)
}
export const SyncAssignedLsStaffDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncAssignedLSStaff' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncAssignedLSStaff' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useSyncAssignedLsStaffMutation() {
  return Urql.useMutation<
    SyncAssignedLsStaffMutation,
    SyncAssignedLsStaffMutationVariables
  >(SyncAssignedLsStaffDocument)
}
export const AppTokenListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppTokenList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appTokens' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastUsed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useAppTokenListQuery(
  options?: Omit<Urql.UseQueryArgs<AppTokenListQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AppTokenListQuery, AppTokenListQueryVariables>({
    query: AppTokenListDocument,
    ...options,
  })
}
export const DeleteAppTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAppToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'idOrToken' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAppToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'idOrToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'idOrToken' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useDeleteAppTokenMutation() {
  return Urql.useMutation<
    DeleteAppTokenMutation,
    DeleteAppTokenMutationVariables
  >(DeleteAppTokenDocument)
}
export const CreateAppTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAppToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAppTokenInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAppToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateAppTokenMutation() {
  return Urql.useMutation<
    CreateAppTokenMutation,
    CreateAppTokenMutationVariables
  >(CreateAppTokenDocument)
}
export const UpdateAppTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAppToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAppTokenInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAppToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateAppTokenMutation() {
  return Urql.useMutation<
    UpdateAppTokenMutation,
    UpdateAppTokenMutationVariables
  >(UpdateAppTokenDocument)
}
export const DealListConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DealListConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PipedriveDealStatus' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DealListNode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DealListNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Deal' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'classInterested' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wonAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dealId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useDealListConnectionQuery(
  options?: Omit<Urql.UseQueryArgs<DealListConnectionQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    DealListConnectionQuery,
    DealListConnectionQueryVariables
  >({ query: DealListConnectionDocument, ...options })
}
export const DealViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DealView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DealView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DealView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Deal' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedInUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newJobExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyingCodingCoursesBefore' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'studyTimePerWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'classInterested' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookingForJob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'testScoreResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wonAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yearsOfExp' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerViewNode' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dealId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerViewNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useDealViewQuery(
  options: Omit<Urql.UseQueryArgs<DealViewQueryVariables>, 'query'>,
) {
  return Urql.useQuery<DealViewQuery, DealViewQueryVariables>({
    query: DealViewDocument,
    ...options,
  })
}
export const CreateLearnerToEnrollDealDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateLearnerToEnrollDeal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateLearnerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createLearner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateLearnerToEnrollDealMutation() {
  return Urql.useMutation<
    CreateLearnerToEnrollDealMutation,
    CreateLearnerToEnrollDealMutationVariables
  >(CreateLearnerToEnrollDealDocument)
}
export const EnrollLearnerToCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnrollLearnerToCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EnrollLearnerToCourseInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollLearner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useEnrollLearnerToCourseMutation() {
  return Urql.useMutation<
    EnrollLearnerToCourseMutation,
    EnrollLearnerToCourseMutationVariables
  >(EnrollLearnerToCourseDocument)
}
export const MarkDealAsEnrolledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkDealAsEnrolled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateDealEnrollmentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDealEnrollment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dealId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMarkDealAsEnrolledMutation() {
  return Urql.useMutation<
    MarkDealAsEnrolledMutation,
    MarkDealAsEnrolledMutationVariables
  >(MarkDealAsEnrolledDocument)
}
export const LearnerProfileByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnerProfileByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerViewNode' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerViewNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnerProfileByUserIdQuery(
  options: Omit<
    Urql.UseQueryArgs<LearnerProfileByUserIdQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LearnerProfileByUserIdQuery,
    LearnerProfileByUserIdQueryVariables
  >({ query: LearnerProfileByUserIdDocument, ...options })
}
export const UserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'User' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUserQuery(
  options: Omit<Urql.UseQueryArgs<UserQueryVariables>, 'query'>,
) {
  return Urql.useQuery<UserQuery, UserQueryVariables>({
    query: UserDocument,
    ...options,
  })
}
export const LearnerMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnerMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMembers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'course' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnerMemberQuery(
  options: Omit<Urql.UseQueryArgs<LearnerMemberQueryVariables>, 'query'>,
) {
  return Urql.useQuery<LearnerMemberQuery, LearnerMemberQueryVariables>({
    query: LearnerMemberDocument,
    ...options,
  })
}
export const PendingCourseMemberConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PendingCourseMemberConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CourseMemberType' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '100' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMemberConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'EnumValue', value: 'PENDING' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CourseMemberNode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseMemberNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function usePendingCourseMemberConnectionQuery(
  options?: Omit<
    Urql.UseQueryArgs<PendingCourseMemberConnectionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    PendingCourseMemberConnectionQuery,
    PendingCourseMemberConnectionQueryVariables
  >({ query: PendingCourseMemberConnectionDocument, ...options })
}
export const PendingMemberStatisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PendingMemberStatistics' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workplaceStatistic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingDSMembers' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingWebMembers' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingLearners' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingMentors' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function usePendingMemberStatisticsQuery(
  options?: Omit<
    Urql.UseQueryArgs<PendingMemberStatisticsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    PendingMemberStatisticsQuery,
    PendingMemberStatisticsQueryVariables
  >({ query: PendingMemberStatisticsDocument, ...options })
}
export const EnrollToLwDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnrollToLW' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollPendingMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseMemberNode' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseMemberNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useEnrollToLwMutation() {
  return Urql.useMutation<EnrollToLwMutation, EnrollToLwMutationVariables>(
    EnrollToLwDocument,
  )
}
export const LearnerConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnerConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userStatus' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserStatus' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberStatus' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CourseMemberStatus' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learningVelocity' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LearningVelocity' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learnerConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learningVelocity' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learningVelocity' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LearnerListNode' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerListCourseMemberNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestProgressReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerListNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerListCourseMemberNode' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnerConnectionQuery(
  options?: Omit<Urql.UseQueryArgs<LearnerConnectionQueryVariables>, 'query'>,
) {
  return Urql.useQuery<LearnerConnectionQuery, LearnerConnectionQueryVariables>(
    { query: LearnerConnectionDocument, ...options },
  )
}
export const EnrollmentBatchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EnrollmentBatches' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllEnrollmentBatches' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useEnrollmentBatchesQuery(
  options?: Omit<Urql.UseQueryArgs<EnrollmentBatchesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<EnrollmentBatchesQuery, EnrollmentBatchesQueryVariables>(
    { query: EnrollmentBatchesDocument, ...options },
  )
}
export const LearnerViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnerView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerViewNode' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerViewNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnerViewQuery(
  options: Omit<Urql.UseQueryArgs<LearnerViewQueryVariables>, 'query'>,
) {
  return Urql.useQuery<LearnerViewQuery, LearnerViewQueryVariables>({
    query: LearnerViewDocument,
    ...options,
  })
}
export const LearnerProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnerProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerProfile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studyTimePerWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthToChangeJob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reasonToSwitchCareer' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestProgressReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemedMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalMentorSessionEvents' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'englishLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinURL' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedPlace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priorCodingCourses' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPhoneNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'workingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnerProfileQuery(
  options: Omit<Urql.UseQueryArgs<LearnerProfileQueryVariables>, 'query'>,
) {
  return Urql.useQuery<LearnerProfileQuery, LearnerProfileQueryVariables>({
    query: LearnerProfileDocument,
    ...options,
  })
}
export const LearnerCourseConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnerCourseConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMemberConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phone' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollmentDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningPurpose' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'courseExpectation' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'studyTimePerWeek' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'monthToChangeJob' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobExpectation' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learner' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'LearnerProfile' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'courseId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'course' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lwCourseId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studyTimePerWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthToChangeJob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reasonToSwitchCareer' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestProgressReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemedMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalMentorSessionEvents' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'englishLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinURL' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedPlace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priorCodingCourses' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPhoneNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'workingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnerCourseConnectionQuery(
  options: Omit<
    Urql.UseQueryArgs<LearnerCourseConnectionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LearnerCourseConnectionQuery,
    LearnerCourseConnectionQueryVariables
  >({ query: LearnerCourseConnectionDocument, ...options })
}
export const UpdateLearnerMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLearnerMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updates' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCourseMemberInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCourseMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updates' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'updates' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studyTimePerWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthToChangeJob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reasonToSwitchCareer' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestProgressReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemedMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalMentorSessionEvents' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateLearnerMemberMutation() {
  return Urql.useMutation<
    UpdateLearnerMemberMutation,
    UpdateLearnerMemberMutationVariables
  >(UpdateLearnerMemberDocument)
}
export const UpdateLearnerLwUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLearnerLwUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLwUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateLearnerLwUserMutation() {
  return Urql.useMutation<
    UpdateLearnerLwUserMutation,
    UpdateLearnerLwUserMutationVariables
  >(UpdateLearnerLwUserDocument)
}
export const UpdateLearnerProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLearnerProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateLearnerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLearner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerProfile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studyTimePerWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthToChangeJob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reasonToSwitchCareer' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestProgressReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemedMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalMentorSessionEvents' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'englishLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinURL' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedPlace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priorCodingCourses' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPhoneNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'workingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateLearnerProfileMutation() {
  return Urql.useMutation<
    UpdateLearnerProfileMutation,
    UpdateLearnerProfileMutationVariables
  >(UpdateLearnerProfileDocument)
}
export const UpdateLearnerCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLearnerCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateLearnerCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerProfile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studyTimePerWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthToChangeJob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reasonToSwitchCareer' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestProgressReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'remainingMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemedMentorSessionEvents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalMentorSessionEvents' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'englishLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinURL' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedPlace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priorCodingCourses' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'resumeURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryEmail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'secondaryPhoneNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'workingStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lwUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LearnerProfileCourseMember' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateLearnerCodeMutation() {
  return Urql.useMutation<
    UpdateLearnerCodeMutation,
    UpdateLearnerCodeMutationVariables
  >(UpdateLearnerCodeDocument)
}
export const LatestCourseMemberMentorSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LatestCourseMemberMentorSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestCourseMemberMentorSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMembers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEvents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurrence' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLatestCourseMemberMentorSessionQuery(
  options: Omit<
    Urql.UseQueryArgs<LatestCourseMemberMentorSessionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LatestCourseMemberMentorSessionQuery,
    LatestCourseMemberMentorSessionQueryVariables
  >({ query: LatestCourseMemberMentorSessionDocument, ...options })
}
export const DropCourseMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DropCourseMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DropCourseMemberInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dropCourseMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useDropCourseMemberMutation() {
  return Urql.useMutation<
    DropCourseMemberMutation,
    DropCourseMemberMutationVariables
  >(DropCourseMemberDocument)
}
export const PostponeCourseMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PostponeCourseMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PostponeCourseMemberInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postponeCourseMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function usePostponeCourseMemberMutation() {
  return Urql.useMutation<
    PostponeCourseMemberMutation,
    PostponeCourseMemberMutationVariables
  >(PostponeCourseMemberDocument)
}
export const GraduateCourseMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GraduateCourseMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GraduateCourseMemberInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'graduateCourseMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useGraduateCourseMemberMutation() {
  return Urql.useMutation<
    GraduateCourseMemberMutation,
    GraduateCourseMemberMutationVariables
  >(GraduateCourseMemberDocument)
}
export const ReactivateCourseMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReactivateCourseMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reactivateCourseMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useReactivateCourseMemberMutation() {
  return Urql.useMutation<
    ReactivateCourseMemberMutation,
    ReactivateCourseMemberMutationVariables
  >(ReactivateCourseMemberDocument)
}
export const GetCourseMemberDropActivityLogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCourseMemberDropActivityLog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCourseMemberDropActivityLog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DropStatusActivityLog' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DropStatusActivityLog' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberDropStatusActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'performUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dropReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useGetCourseMemberDropActivityLogQuery(
  options: Omit<
    Urql.UseQueryArgs<GetCourseMemberDropActivityLogQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetCourseMemberDropActivityLogQuery,
    GetCourseMemberDropActivityLogQueryVariables
  >({ query: GetCourseMemberDropActivityLogDocument, ...options })
}
export const GetCourseMemberGraduateActivityLogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCourseMemberGraduateActivityLog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCourseMemberGraduateActivityLog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GraduateStatusActivityLog' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GraduateStatusActivityLog' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberGraduateStatusActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'performUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useGetCourseMemberGraduateActivityLogQuery(
  options: Omit<
    Urql.UseQueryArgs<GetCourseMemberGraduateActivityLogQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetCourseMemberGraduateActivityLogQuery,
    GetCourseMemberGraduateActivityLogQueryVariables
  >({ query: GetCourseMemberGraduateActivityLogDocument, ...options })
}
export const GetCourseMemberPostponeActivityLogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCourseMemberPostponeActivityLog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCourseMemberPostponeActivityLog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PostponeStatusActivityLog' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostponeStatusActivityLog' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberPostponeStatusActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'performUserId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postponeReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useGetCourseMemberPostponeActivityLogQuery(
  options: Omit<
    Urql.UseQueryArgs<GetCourseMemberPostponeActivityLogQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetCourseMemberPostponeActivityLogQuery,
    GetCourseMemberPostponeActivityLogQueryVariables
  >({ query: GetCourseMemberPostponeActivityLogDocument, ...options })
}
export const UpdateDropActivityLogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDropActivityLog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityLogId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DropCourseMemberInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDropActivityLog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityLogId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateDropActivityLogMutation() {
  return Urql.useMutation<
    UpdateDropActivityLogMutation,
    UpdateDropActivityLogMutationVariables
  >(UpdateDropActivityLogDocument)
}
export const UpdateGraduateActivityLogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateGraduateActivityLog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityLogId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GraduateCourseMemberInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGraduateActivityLog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityLogId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateGraduateActivityLogMutation() {
  return Urql.useMutation<
    UpdateGraduateActivityLogMutation,
    UpdateGraduateActivityLogMutationVariables
  >(UpdateGraduateActivityLogDocument)
}
export const UpdatePostponeActivityLogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePostponeActivityLog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityLogId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PostponeCourseMemberInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePostponeActivityLog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityLogId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdatePostponeActivityLogMutation() {
  return Urql.useMutation<
    UpdatePostponeActivityLogMutation,
    UpdatePostponeActivityLogMutationVariables
  >(UpdatePostponeActivityLogDocument)
}
export const CourseDurationOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CourseDurationOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseDurationOptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCourseDurationOptionsQuery(
  options: Omit<
    Urql.UseQueryArgs<CourseDurationOptionsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    CourseDurationOptionsQuery,
    CourseDurationOptionsQueryVariables
  >({ query: CourseDurationOptionsDocument, ...options })
}
export const LearnerProfileViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnerProfileView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lwUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnerProfileViewQuery(
  options: Omit<Urql.UseQueryArgs<LearnerProfileViewQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    LearnerProfileViewQuery,
    LearnerProfileViewQueryVariables
  >({ query: LearnerProfileViewDocument, ...options })
}
export const LinkLearnerToLearnWorldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LinkLearnerToLearnWorlds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LinkLearnerToLwUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkLearnerToLwUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lwUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLinkLearnerToLearnWorldsMutation() {
  return Urql.useMutation<
    LinkLearnerToLearnWorldsMutation,
    LinkLearnerToLearnWorldsMutationVariables
  >(LinkLearnerToLearnWorldsDocument)
}
export const CreateMentorSessionEventRescheduleRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'CreateMentorSessionEventRescheduleRequest',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RescheduleMentorSessionEventInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'createMentorSessionEventRescheduleRequest',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userRequestId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userRequest' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateMentorSessionEventRescheduleRequestMutation() {
  return Urql.useMutation<
    CreateMentorSessionEventRescheduleRequestMutation,
    CreateMentorSessionEventRescheduleRequestMutationVariables
  >(CreateMentorSessionEventRescheduleRequestDocument)
}
export const MentorSessionListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorSessionList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionList' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFinished' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorSessionListQuery(
  options?: Omit<Urql.UseQueryArgs<MentorSessionListQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MentorSessionListQuery, MentorSessionListQueryVariables>(
    { query: MentorSessionListDocument, ...options },
  )
}
export const MentorSessionConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorSessionConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'statuses' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MentorSessionStatus' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'statuses' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionList' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionList' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFinished' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorSessionConnectionQuery(
  options?: Omit<
    Urql.UseQueryArgs<MentorSessionConnectionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MentorSessionConnectionQuery,
    MentorSessionConnectionQueryVariables
  >({ query: MentorSessionConnectionDocument, ...options })
}
export const LearnersWithNoSessionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnersWithNoSessions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CourseMemberType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembersWithNoSessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BookMentorSessionLearnerMember',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionLearner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'englishLevel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionLearnerMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BookMentorSessionLearner' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemedMentorSessionEvents' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnersWithNoSessionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<LearnersWithNoSessionsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LearnersWithNoSessionsQuery,
    LearnersWithNoSessionsQueryVariables
  >({ query: LearnersWithNoSessionsDocument, ...options })
}
export const LearnersAvailableForBookingRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LearnersAvailableForBookingRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'learnerMembersAvailableForBookingRequest',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BookMentorSessionLearnerMember',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionLearner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'englishLevel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionLearnerMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BookMentorSessionLearner' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemedMentorSessionEvents' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLearnersAvailableForBookingRequestQuery(
  options?: Omit<
    Urql.UseQueryArgs<LearnersAvailableForBookingRequestQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LearnersAvailableForBookingRequestQuery,
    LearnersAvailableForBookingRequestQueryVariables
  >({ query: LearnersAvailableForBookingRequestDocument, ...options })
}
export const BookMentorReviewLearnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BookMentorReviewLearner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseMemberId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BookMentorSessionLearnerMember',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionLearner' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Learner' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'englishLevel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionLearnerMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BookMentorSessionLearner' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemedMentorSessionEvents' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useBookMentorReviewLearnerQuery(
  options: Omit<
    Urql.UseQueryArgs<BookMentorReviewLearnerQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    BookMentorReviewLearnerQuery,
    BookMentorReviewLearnerQueryVariables
  >({ query: BookMentorReviewLearnerDocument, ...options })
}
export const SuitableMentorMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SuitableMentorMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerCourseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'suitableMentorsForMentorSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerCourseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerCourseMemberId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BookMentorSessionMentorMember',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionMentor' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'capacity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionAvailabilitySchedules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dayOfWeek' } },
                { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetType' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookMentorSessionMentorMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SuitableCourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BookMentorSessionMentor' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bookedSessionsAndPendingRequests' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useSuitableMentorMembersQuery(
  options: Omit<
    Urql.UseQueryArgs<SuitableMentorMembersQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    SuitableMentorMembersQuery,
    SuitableMentorMembersQueryVariables
  >({ query: SuitableMentorMembersDocument, ...options })
}
export const MentorSessionTimeSuggestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorSessionTimeSuggestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerCourseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorCourseMemberId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'durationInMinutes' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endsAfterMonths' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'intervalsFrom' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionTimeSuggestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'durationInMinutes' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'durationInMinutes' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerCourseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerCourseMemberId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorCourseMemberId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorCourseMemberId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endsAfterMonths' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endsAfterMonths' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'intervalsFrom' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'intervalsFrom' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SuggestionItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SuggestionItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionTimeSuggestion' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'end' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorSessionTimeSuggestionsQuery(
  options: Omit<
    Urql.UseQueryArgs<MentorSessionTimeSuggestionsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MentorSessionTimeSuggestionsQuery,
    MentorSessionTimeSuggestionsQueryVariables
  >({ query: MentorSessionTimeSuggestionsDocument, ...options })
}
export const UpdateMentorSessionsStatusBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMentorSessionsStatusBatch' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMentorSessionsStatusBatch' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateMentorSessionsStatusBatchMutation() {
  return Urql.useMutation<
    UpdateMentorSessionsStatusBatchMutation,
    UpdateMentorSessionsStatusBatchMutationVariables
  >(UpdateMentorSessionsStatusBatchDocument)
}
export const BookingRequestViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BookingRequestView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userRequestId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionBookingRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userRequestId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userRequestId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BookingRequestView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zohoProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordServerId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingRequestActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingRequestView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionBookingRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseView' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learners' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BookingRequestActivityLogItem',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useBookingRequestViewQuery(
  options: Omit<Urql.UseQueryArgs<BookingRequestViewQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    BookingRequestViewQuery,
    BookingRequestViewQueryVariables
  >({ query: BookingRequestViewDocument, ...options })
}
export const CancelBookingRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelBookingRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userRequestId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reason' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'cancelPendingMentorSessionBookingRequest',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userRequestId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userRequestId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reason' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reason' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BookingRequestView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CourseView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Course' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAllowedTASessions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'access' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwCourseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zohoProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordServerId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingRequestActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BookingRequestView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionBookingRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CourseView' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learners' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'responses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'BookingRequestActivityLogItem',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCancelBookingRequestMutation() {
  return Urql.useMutation<
    CancelBookingRequestMutation,
    CancelBookingRequestMutationVariables
  >(CancelBookingRequestDocument)
}
export const CreateMentorSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMentorSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMentorSessionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMentorSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateMentorSessionMutation() {
  return Urql.useMutation<
    CreateMentorSessionMutation,
    CreateMentorSessionMutationVariables
  >(CreateMentorSessionDocument)
}
export const CreateMentorSessionMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateMentorSessionMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMembers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorSessions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateMentorSessionMembersQuery(
  options: Omit<
    Urql.UseQueryArgs<CreateMentorSessionMembersQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    CreateMentorSessionMembersQuery,
    CreateMentorSessionMembersQueryVariables
  >({ query: CreateMentorSessionMembersDocument, ...options })
}
export const ConflictingUserEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ConflictingUserEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userIds' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'userId' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'between' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
                    { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useConflictingUserEventsQuery(
  options: Omit<
    Urql.UseQueryArgs<ConflictingUserEventsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ConflictingUserEventsQuery,
    ConflictingUserEventsQueryVariables
  >({ query: ConflictingUserEventsDocument, ...options })
}
export const BookingMemberSessionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BookingMemberSessions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFinished' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useBookingMemberSessionsQuery(
  options?: Omit<
    Urql.UseQueryArgs<BookingMemberSessionsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    BookingMemberSessionsQuery,
    BookingMemberSessionsQueryVariables
  >({ query: BookingMemberSessionsDocument, ...options })
}
export const FindAvailableMentorMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindAvailableMentorMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'intervals' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'TimeInterval' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableMentorCourseMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'intervals' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'intervals' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isFinished' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useFindAvailableMentorMembersQuery(
  options: Omit<
    Urql.UseQueryArgs<FindAvailableMentorMembersQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    FindAvailableMentorMembersQuery,
    FindAvailableMentorMembersQueryVariables
  >({ query: FindAvailableMentorMembersDocument, ...options })
}
export const CreateMentorSessionBookingRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMentorSessionBookingRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateMentorSessionBookingRequestInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMentorSessionBookingRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateMentorSessionBookingRequestMutation() {
  return Urql.useMutation<
    CreateMentorSessionBookingRequestMutation,
    CreateMentorSessionBookingRequestMutationVariables
  >(CreateMentorSessionBookingRequestDocument)
}
export const CreateMentorSessionBookingRequestMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CreateMentorSessionBookingRequestMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMembers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorSessions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateMentorSessionBookingRequestMembersQuery(
  options: Omit<
    Urql.UseQueryArgs<CreateMentorSessionBookingRequestMembersQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    CreateMentorSessionBookingRequestMembersQuery,
    CreateMentorSessionBookingRequestMembersQueryVariables
  >({ query: CreateMentorSessionBookingRequestMembersDocument, ...options })
}
export const CancelMentorSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelMentorSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'args' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CancelMentorSessionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelMentorSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'args' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionRecapItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionRecap' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEventCalendarEventId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionEventItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionRecapItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RescheduleRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zoomMeetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codingSkill' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestProgressReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'LearnerMemberProgressReport',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningPurpose' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthToChangeJob' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studyTimePerWeek' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionEventItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCancelMentorSessionMutation() {
  return Urql.useMutation<
    CancelMentorSessionMutation,
    CancelMentorSessionMutationVariables
  >(CancelMentorSessionDocument)
}
export const CancelSessionEventForStaffDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelSessionEventForStaff' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'canceledByUserId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cancelReason' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createMakeUpEvent' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelMentorSessionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'canceledByUserId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'canceledByUserId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cancelReason' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cancelReason' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createMakeUpEvent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createMakeUpEvent' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSession' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionView' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionRecapItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionRecap' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEventCalendarEventId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionEventItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionRecapItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RescheduleRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zoomMeetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codingSkill' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestProgressReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'LearnerMemberProgressReport',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningPurpose' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthToChangeJob' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studyTimePerWeek' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionEventItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCancelSessionEventForStaffMutation() {
  return Urql.useMutation<
    CancelSessionEventForStaffMutation,
    CancelSessionEventForStaffMutationVariables
  >(CancelSessionEventForStaffDocument)
}
export const CreateMentorSessionEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMentorSessionEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMentorSessionEventInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMentorSessionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateMentorSessionEventMutation() {
  return Urql.useMutation<
    CreateMentorSessionEventMutation,
    CreateMentorSessionEventMutationVariables
  >(CreateMentorSessionEventDocument)
}
export const ExtendMentorSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ExtendMentorSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExtendMentorSessionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extendMentorSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useExtendMentorSessionMutation() {
  return Urql.useMutation<
    ExtendMentorSessionMutation,
    ExtendMentorSessionMutationVariables
  >(ExtendMentorSessionDocument)
}
export const MarkEventAsFinishedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkEventAsFinished' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'calendarEventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markEventAsFinished' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'calendarEventId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'calendarEventId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMarkEventAsFinishedMutation() {
  return Urql.useMutation<
    MarkEventAsFinishedMutation,
    MarkEventAsFinishedMutationVariables
  >(MarkEventAsFinishedDocument)
}
export const RescheduleMentorSessionEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RescheduleMentorSessionEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RescheduleMentorSessionEventInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleMentorSessionEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEventId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endsAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSession' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionView' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionRecapItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionRecap' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEventCalendarEventId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionEventItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionRecapItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RescheduleRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zoomMeetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codingSkill' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestProgressReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'LearnerMemberProgressReport',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningPurpose' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthToChangeJob' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studyTimePerWeek' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionEventItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useRescheduleMentorSessionEventMutation() {
  return Urql.useMutation<
    RescheduleMentorSessionEventMutation,
    RescheduleMentorSessionEventMutationVariables
  >(RescheduleMentorSessionEventDocument)
}
export const RescheduleMentorSessionEventBatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RescheduleMentorSessionEventBatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RescheduleMentorSessionEventBatchInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleMentorSessionEventBatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEventId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'calendarEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startsAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endsAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSession' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionView' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LearnerMemberProgressReport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMemberProgressReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseMemberId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nextUnitPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'path' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentMiniCourse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'section' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextCourseSection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextCourseUnit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'averageScore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overallProgress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studyTimeDuringPeriod' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learningVelocity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weeksOfftrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startSyncedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endSyncedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityLog' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetIds' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientUserIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RescheduleRequestItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEventRescheduleRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionRecapItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionRecap' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionEventCalendarEventId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formSubmission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionEventItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'meetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionRecapItem' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rescheduleRequests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RescheduleRequestItem' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'zoomMeetingRecordings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSession' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'learnerId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codingSkill' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'mentorId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'discordId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expYears' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestProgressReport' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'LearnerMemberProgressReport',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningPurpose' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthToChangeJob' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studyTimePerWeek' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobExpectation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calendarEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'origin' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attendees' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionEvent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorSessionEventItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionRecapReport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requiredRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentorSessionRecapCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentee' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorSessionActivityLogItem' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useRescheduleMentorSessionEventBatchMutation() {
  return Urql.useMutation<
    RescheduleMentorSessionEventBatchMutation,
    RescheduleMentorSessionEventBatchMutationVariables
  >(RescheduleMentorSessionEventBatchDocument)
}
export const MentorSessionBookingRequestConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorSessionBookingRequestConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'UserRequestStatus' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'mentorSessionBookingRequestConnection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'MentorSessionBookingRequestConnectionNode',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'MentorSessionBookingRequestConnectionNode',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionBookingRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learners' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorSessionBookingRequestConnectionQuery(
  options?: Omit<
    Urql.UseQueryArgs<MentorSessionBookingRequestConnectionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MentorSessionBookingRequestConnectionQuery,
    MentorSessionBookingRequestConnectionQueryVariables
  >({ query: MentorSessionBookingRequestConnectionDocument, ...options })
}
export const MentorSessionBookingRequestsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorSessionBookingRequests' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'UserRequestStatus' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionBookingRequests' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'end' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'MentorSessionBookingRequestNode',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RequestLearnerInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CourseMember' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'learningPurpose' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'courseExpectation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studyTimePerWeek' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthToChangeJob' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorSessionBookingRequestNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MentorSessionBookingRequest' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRequestId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userRequest' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'course' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'expiresOn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learners' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'courseMembers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'RequestLearnerInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'learnerIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mentorIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endsAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorSessionBookingRequestsQuery(
  options?: Omit<
    Urql.UseQueryArgs<MentorSessionBookingRequestsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MentorSessionBookingRequestsQuery,
    MentorSessionBookingRequestsQueryVariables
  >({ query: MentorSessionBookingRequestsDocument, ...options })
}
export const CreateMentorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMentor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMentorInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMentor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateMentorMutation() {
  return Urql.useMutation<CreateMentorMutation, CreateMentorMutationVariables>(
    CreateMentorDocument,
  )
}
export const MentorConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'types' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MentorType' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'statuses' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MentorStatus' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'types' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'types' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'statuses' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MentorListNode' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorListNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMembers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'course' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorConnectionQuery(
  options?: Omit<Urql.UseQueryArgs<MentorConnectionQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MentorConnectionQuery, MentorConnectionQueryVariables>({
    query: MentorConnectionDocument,
    ...options,
  })
}
export const MentorManagementStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorManagementStats' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorManagementStatsQuery(
  options?: Omit<
    Urql.UseQueryArgs<MentorManagementStatsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MentorManagementStatsQuery,
    MentorManagementStatsQueryVariables
  >({ query: MentorManagementStatsDocument, ...options })
}
export const MentorViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorViewNode' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorViewNode' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hourlyRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedPlace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oldNationalIdNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pitCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankAccountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankBranch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beneficiary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'capacity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorViewQuery(
  options: Omit<Urql.UseQueryArgs<MentorViewQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MentorViewQuery, MentorViewQueryVariables>({
    query: MentorViewDocument,
    ...options,
  })
}
export const MentorProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorProfile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hourlyRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedPlace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oldNationalIdNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pitCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankAccountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankBranch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beneficiary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'capacity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorProfileQuery(
  options: Omit<Urql.UseQueryArgs<MentorProfileQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MentorProfileQuery, MentorProfileQueryVariables>({
    query: MentorProfileDocument,
    ...options,
  })
}
export const MentorCourseConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MentorCourseConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'courseMemberConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'mentorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phone' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'MentorProfile' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'courseId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'course' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lwCourseId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hourlyRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedPlace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oldNationalIdNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pitCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankAccountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankBranch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beneficiary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'capacity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useMentorCourseConnectionQuery(
  options: Omit<
    Urql.UseQueryArgs<MentorCourseConnectionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MentorCourseConnectionQuery,
    MentorCourseConnectionQueryVariables
  >({ query: MentorCourseConnectionDocument, ...options })
}
export const AddMentorToCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMentorToCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addMentorToCourse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'courseId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'courseId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'mentorId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'mentorId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentor' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lwUserId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useAddMentorToCourseMutation() {
  return Urql.useMutation<
    AddMentorToCourseMutation,
    AddMentorToCourseMutationVariables
  >(AddMentorToCourseDocument)
}
export const UpdateMentorProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMentorProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateMentorInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMentor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MentorProfile' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentorProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Mentor' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hourlyRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lwUserId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nationalIdIssuedPlace' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'nationalIdNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oldNationalIdNumber' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'pitCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankAccountNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankBranch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bankName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'beneficiary' } },
          { kind: 'Field', name: { kind: 'Name', value: 'capacity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expYears' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateMentorProfileMutation() {
  return Urql.useMutation<
    UpdateMentorProfileMutation,
    UpdateMentorProfileMutationVariables
  >(UpdateMentorProfileDocument)
}
export const OngoingMentorSessionCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OngoingMentorSessionCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mentorId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentorSessionConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorIds' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'mentorId' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'SCHEDULED' },
                    { kind: 'EnumValue', value: 'ONGOING' },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'mentorSessionBookingRequestConnection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mentorIds' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'mentorId' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'EnumValue', value: 'PENDING' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useOngoingMentorSessionCountQuery(
  options: Omit<
    Urql.UseQueryArgs<OngoingMentorSessionCountQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    OngoingMentorSessionCountQuery,
    OngoingMentorSessionCountQueryVariables
  >({ query: OngoingMentorSessionCountDocument, ...options })
}
export const SalesDealListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SalesDealList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PipedriveDealStatus' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dealConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SalesDeal' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesDeal' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Deal' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'classInterested' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookingForJob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'testScoreResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wonAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yearsOfExp' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useSalesDealListQuery(
  options?: Omit<Urql.UseQueryArgs<SalesDealListQueryVariables>, 'query'>,
) {
  return Urql.useQuery<SalesDealListQuery, SalesDealListQueryVariables>({
    query: SalesDealListDocument,
    ...options,
  })
}
export const SyncDealsFromPipeDriveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SyncDealsFromPipeDrive' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncDealsFromPipeDrive' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useSyncDealsFromPipeDriveMutation() {
  return Urql.useMutation<
    SyncDealsFromPipeDriveMutation,
    SyncDealsFromPipeDriveMutationVariables
  >(SyncDealsFromPipeDriveDocument)
}
export const SalesDealViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SalesDealView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SalesDeal' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SalesDeal' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Deal' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'classInterested' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codingSkill' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lookingForJob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'testScoreResult' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wonAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'yearsOfExp' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useSalesDealViewQuery(
  options: Omit<Urql.UseQueryArgs<SalesDealViewQueryVariables>, 'query'>,
) {
  return Urql.useQuery<SalesDealViewQuery, SalesDealViewQueryVariables>({
    query: SalesDealViewDocument,
    ...options,
  })
}
export const DisconnectDiscordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DisconnectDiscord' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disconnectDiscord' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useDisconnectDiscordMutation() {
  return Urql.useMutation<
    DisconnectDiscordMutation,
    DisconnectDiscordMutationVariables
  >(DisconnectDiscordDocument)
}
export const UpdateUserPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currentPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentPassword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'currentPassword' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newPassword' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateUserPasswordMutation() {
  return Urql.useMutation<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >(UpdateUserPasswordDocument)
}
export const UpdateUserAvatarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserAvatar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserAvatar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'avatar' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatarFileId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'avatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signedUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blurhash' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateUserAvatarMutation() {
  return Urql.useMutation<
    UpdateUserAvatarMutation,
    UpdateUserAvatarMutationVariables
  >(UpdateUserAvatarDocument)
}
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isExists' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useCreateUserMutation() {
  return Urql.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
  )
}
export const UserConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '10' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roles' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'UserRole' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserStatus' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roles' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'roles' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ConnectionPageInfo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConnectionPageInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ConnectionPageInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUserConnectionQuery(
  options?: Omit<Urql.UseQueryArgs<UserConnectionQueryVariables>, 'query'>,
) {
  return Urql.useQuery<UserConnectionQuery, UserConnectionQueryVariables>({
    query: UserConnectionDocument,
    ...options,
  })
}
export const UserViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slackId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomUserEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUserViewQuery(
  options: Omit<Urql.UseQueryArgs<UserViewQueryVariables>, 'query'>,
) {
  return Urql.useQuery<UserViewQuery, UserViewQueryVariables>({
    query: UserViewDocument,
    ...options,
  })
}
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserView' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserView' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'discordId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slackId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mentor' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoomUserEmail' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useUpdateUserMutation() {
  return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
  )
}
export const SendResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendResetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useSendResetPasswordMutation() {
  return Urql.useMutation<
    SendResetPasswordMutation,
    SendResetPasswordMutationVariables
  >(SendResetPasswordDocument)
}
export const LatestLearnerMentorSessionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LatestLearnerMentorSessions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'learnerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestLearnerMentorSessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'learnerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'learnerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'course' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export function useLatestLearnerMentorSessionsQuery(
  options: Omit<
    Urql.UseQueryArgs<LatestLearnerMentorSessionsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LatestLearnerMentorSessionsQuery,
    LatestLearnerMentorSessionsQueryVariables
  >({ query: LatestLearnerMentorSessionsDocument, ...options })
}
