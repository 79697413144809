import { Gift, Lightning, Sparkle, WarningDiamond } from '@phosphor-icons/react'

import { UserRole } from '@core/graphql'
import type { Icon } from '@core/icons'
import {
  AccountSettingsIcon,
  CoursesIcon,
  DashboardIcon,
  DevtoolIcon,
  LearnerEnrollmentIcon,
  LearnerIcon,
  MentorIcon,
  MentorSessionBookingRequestIcon,
  MentorSessionIcon,
  MentorTimeSheetIcon,
  UserIcon,
  Performance,
  OrgChart,
  CalendarIcon,
  JobBoardIcon,
} from '@core/icons'

import { LINKS } from './config'

export const NAVBAR = {
  DASHBOARD_WIDTH: 220,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 32,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
} as const

export type NavItem = {
  title: string
  path: string
  icon: Icon
  roles?: UserRole[]
  isBeta?: boolean
}

export type NavGroup = {
  subheader: string
  items: NavItem[]
}

export const WP_NAV: NavGroup[] = [
  {
    subheader: 'WeMe',
    items: [
      {
        title: 'Performance Review',
        path: '/v2/workplace/weme/performance',
        icon: Performance,
      },
      {
        title: 'Company chart',
        path: '/v2/workplace/weme/org-chart',
        icon: OrgChart,
      },
      {
        title: 'Secret Santa',
        path: '/v2/workplace/weme/secret-santa',
        icon: Gift,
      },
    ],
  },
  {
    subheader: 'General',
    items: [
      { title: 'dashboard', path: '/workplace/dashboard', icon: DashboardIcon },
      { title: 'users', path: '/workplace/users', icon: UserIcon },
    ],
  },
  // {
  //   subheader: 'calendar',
  //   items: [
  //     {
  //       title: 'calendar:calendar',
  //       path: '/workplace/calendar',
  //       icon: CalendarIcon,
  //     },
  //   ],
  // },
  // {
  //   subheader: 'sales',
  //   items: [
  //     {
  //       title: 'sales:deals',
  //       path: '/workplace/sales/deals',
  //       icon: SalesDealIcon,
  //     },
  //   ],
  // },
  {
    subheader: 'Academics',
    items: [
      {
        title: 'course:course_plural',
        path: '/workplace/academics/courses',
        icon: CoursesIcon,
      },
      {
        title: 'learner:learner_plural',
        path: '/workplace/academics/learners',
        icon: LearnerIcon,
      },
      {
        title: 'mentor:mentor_plural',
        path: '/workplace/academics/mentors',
        icon: MentorIcon,
      },
      {
        title: 'learner:enrollment',
        path: '/workplace/academics/enrollments',
        icon: LearnerEnrollmentIcon,
      },
    ],
  },
  {
    subheader: 'Mentor Sessions',
    items: [
      {
        title: 'mentor_session:session_other',
        path: LINKS.MENTOR_SESSION_EVENT_LIST,
        icon: CalendarIcon,
      },
      {
        title: 'mentor_session:session_series_other',
        path: LINKS.MENTOR_SESSION_LIST,
        icon: MentorSessionIcon,
      },
      {
        title: 'mentor_session:booking_requests',
        path: LINKS.MENTOR_SESSION_BOOKING_REQUEST_LIST,
        icon: MentorSessionBookingRequestIcon,
      },
      {
        title: 'mentor_session:mentor_time_sheet',
        path: LINKS.MENTOR_TIMESHEET_VIEW,
        icon: MentorTimeSheetIcon,
      },
    ],
  },
  {
    subheader: 'Learner Center',
    items: [
      {
        title: 'learners',
        path: LINKS.LEARNER_COURSE_MEMBER_LIST,
        icon: LearnerIcon,
        isBeta: true,
      },
      {
        title: 'learner:incidents',
        path: LINKS.LEARNER_INCIDENTS,
        icon: WarningDiamond,
      },
      {
        title: 'instant_meeting.name',
        path: LINKS.INSTANT_MEETING,
        icon: Lightning,
      },
      // {
      //   title: 'discord_channels',
      //   path: LINKS.DISCORD_CHANNELS,
      //   icon: DiscordLogo,
      // },
    ],
  },
  {
    subheader: 'Career Support',
    items: [
      {
        title: 'Job Board',
        path: LINKS.JOB_BOARD_LIST,
        icon: JobBoardIcon,
      },
    ],
  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'user_settings_menu',
        path: '/workplace/settings',
        icon: AccountSettingsIcon,
      },
      {
        title: 'devtool_menu',
        path: '/workplace/developer',
        icon: DevtoolIcon,
        roles: [UserRole.DEVELOPER],
      },
    ],
  },
  {
    subheader: 'Documentations',
    items: [
      // To be added
      // {
      //   title: 'user_manuals',
      //   path: '',
      //   icon: BookOpenText,
      // },
      {
        title: 'release_notes',
        path: 'https://coderschool.notion.site/9a795c72a2e048a38dbd0e1189e036a7?v=979e04a13efc4ba683443c91ac2e306e&pvs=4',
        icon: Sparkle,
      },
    ],
  },
]
